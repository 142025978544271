import ApiProvider from "../ApiProvider"
import ApiKeyOrSecretInvalidError from "../../exceptions/ApiKeyOrSecretInvalidError"
import Affiliate from "../../models/Affiliate"
import AffiliatesProvider from "./AffiliatesProvider"

export default class AffiliatesApiProvider
  extends ApiProvider
  implements AffiliatesProvider
{
  constructor(private baseUrl: string) {
    super()
  }

  async getAffiliates(token: string): Promise<Affiliate[]> {
    return fetch(`${this.baseUrl}api/v1/affiliates/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf8",
        Authorization: "Bearer " + token,
      },
    }).then(async (response) => {
      this.checkGetAffiliateErrors(response)
      const asd = (await response.json()) as AffiliateResponse[]
      return this.toAffiliates(asd)
    })
  }
  private checkGetAffiliateErrors(response: Response) {
    if (response.status === 405) {
      console.log("ApiKeyOrSecretInvalidError()")
      throw new ApiKeyOrSecretInvalidError()
    }
    super.checkErrors(response)
  }

  private toAffiliates(affiliatesResponse: AffiliateResponse[]): Affiliate[] {
    const affiliates: Affiliate[] = []
    for (const affiliateResponse of affiliatesResponse) {
      affiliates.push(this.toAffiliate(affiliateResponse))
    }
    return affiliates
  }
  private toAffiliate(affiliateResponse: AffiliateResponse): Affiliate {
    return {
      first_name: affiliateResponse?.user?.first_name ?? "",
      last_name: affiliateResponse?.user?.last_name ?? "",
      id: affiliateResponse?.user?.id ?? 0,
      current_accumulated: affiliateResponse?.current ?? 0,
    }
  }
}
interface AffiliateResponse {
  user: any
  monthlyTrack: []
  current: number
}
