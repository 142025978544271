import React, { FC, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  MonthlyFee,
  MonthlyIncome,
} from "../../../../../shared/models/bot/BotInfo"
import useColorTicksGraph from "../../../../../hooks/useColorTicksGraph"
import useColorLegendText from "../../../../../hooks/useColorLegendTextGraph"

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  registerables as registerablesJS,
  Tooltip,
} from "chart.js"
import { Chart } from "react-chartjs-2"

ChartJS.register(...registerablesJS)
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
)
type Props = {
  className?: string
  loading: boolean
  dataBalance: MonthlyIncome[]
  dataFees: MonthlyFee[]
  title?: boolean
  colorTicksLighterInDark?: boolean
}

const N_MONTHS_TO_SHOW = 12

const MonthlyIncomeGraph: FC<Props> = ({
  className,
  loading,
  dataBalance,
  dataFees,
  title = true,
  colorTicksLighterInDark = false,
}) => {
  const { t } = useTranslation()
  const colorTicks = useColorTicksGraph(colorTicksLighterInDark)
  const colorLegendText = useColorLegendText(colorTicksLighterInDark)

  function getRelevantData(): {
    monthlyNetBalance: number[]
    monthlyFee: number[]
    labels: string[]
    totalIncome: number
    totalFees: number
  } {
    const monthlyNetBalance: number[] = []
    const monthlyFee: number[] = []
    const labels: string[] = []
    let totalIncome = 0
    const totalFees = dataFees
      .map((f) => f.fee_amount ?? 0)
      .reduce((a, b) => (a ?? 0) + (b ?? 0), 0)
    const nLastBalanceOrMaximun =
      dataBalance.length < N_MONTHS_TO_SHOW
        ? dataBalance.length
        : N_MONTHS_TO_SHOW

    for (let i = 0; i < nLastBalanceOrMaximun; i++) {
      monthlyNetBalance.unshift(
        (dataBalance[i].amount ?? 0) -
          (i < dataFees.length ? dataFees[i].fee_amount ?? 0 : 0)
      )
      monthlyFee.unshift(i < dataFees.length ? dataFees[i].fee_amount ?? 0 : 0)
      labels.unshift(
        dataBalance[i].month ? t(`month.${dataBalance[i].month! - 1}`) : ""
      )
      totalIncome += dataBalance[i].amount ?? 0
    }
    return {
      monthlyNetBalance,
      monthlyFee,
      labels,
      totalIncome,
      totalFees,
    }
  }

  const relevantData = getRelevantData()

  const [chartData, setChartData] = useState<ChartData<"bar">>({
    datasets: [],
  })
  const chartRef = useRef<ChartJS>(null)
  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }
    const gradientSecondary = chart.ctx.createLinearGradient(0, 0, 0, 123)
    gradientSecondary.addColorStop(0, "rgb(2, 240, 231)")
    gradientSecondary.addColorStop(1, "rgb(52, 247, 169)")

    const labels = loading ? [] : relevantData.labels
    const dataBarsNetMonthlyBalance = loading
      ? []
      : relevantData.monthlyNetBalance
    const dataBarsFee = loading ? [] : relevantData.monthlyFee
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: t("profit.text"),
          data: dataBarsNetMonthlyBalance,
          backgroundColor: gradientSecondary,
          borderRadius: 4,
        },
        {
          label: t("bttm_service_cost.text"),
          data: dataBarsFee,
          backgroundColor: "#EE3378",
          borderRadius: 4,
        },
      ],
    }

    setChartData(chartData)
  }, [loading])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: { color: colorLegendText, padding: 12 },
      },
      y: {
        stacked: true,
        grid: {
          color: colorTicks,
          borderDash: [8, 8],
          lineWidth: 1,
          drawTicks: false,
        },
        ticks: {
          color: colorLegendText,
          padding: 12,
          maxTicksLimit: 12,
          callback: function (value, index, values) {
            return "$" + value
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <div
      className={`p-2 lg:p-6 shadow-md bg-container-panel-bg-light dark:bg-container-panel-bg-dark rounded ${className}`}
      onClick={() => {}}
    >
      {title && (
        <>
          <div className="ml-2 py-3 lg:py-4 flex items-center justify-between">
            <div className="flex items-center">
              <h3 className="dark:text-main-text-dark text-main-text-light dark:font-bold font-semibold text-2xl mr-2">
                {/*{t("profit.text") +
                  ` ($${relevantData.totalIncome.toFixed(2)}) - ` +
                  t("service_cost.text") +
                  ` ($${relevantData.totalFees.toFixed(2)})`}*/}
                {t("earnings_before_interests_taxes_acronyms.text") +
                  ` ($${relevantData.totalIncome.toFixed(2)})`}
              </h3>
            </div>
          </div>
        </>
      )}

      <div className="h-64 mr-2">
        <Chart ref={chartRef} data={chartData} options={options} type="bar" />
      </div>
    </div>
  )
}

export { MonthlyIncomeGraph }
