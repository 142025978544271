import React, { FC } from "react"
import {
  PaymentOrders,
  PendingPaymentOrder,
} from "../../shared/models/payments/Payments"
import PaymentOrdersNotificationPanelItem from "./PaymentOrdersNotificationPanelItem"
import { useTranslation } from "react-i18next"
import { PaymentOrdersNotificationPanelItemItemShimmer } from "./PaymentOrdersNotificationPanelItemItemShimmer"

type Props = {
  className?: string
  payments?: PaymentOrders
  loading: boolean
  onItemClick: (pendingOrder: PendingPaymentOrder) => void
}

const PaymentOrdersNotificationPanel: FC<Props> = ({
  className,
  payments,
  loading,
  onItemClick,
}) => {
  const { t } = useTranslation()
  return (
    <div
      className={`p-4 lg:p-6 shadow dark:bg-container-panel-bg-dark bg-white rounded ${className}`}
    >
      <div className="flex justify-between">
        <h3 className="text-2xl font-bold dark:text-main-text-dark text-main-text-light">
          {t("payment_orders")}
        </h3>
      </div>
      <div className="mt-6 mb-2">
        {loading ? (
          <PaymentOrdersNotificationPanelItemItemShimmer />
        ) : (
          payments?.pendingPaymentOrders?.map(
            (pendingPaymentOrder: PendingPaymentOrder) => (
              <PaymentOrdersNotificationPanelItem
                key={pendingPaymentOrder.id}
                data={pendingPaymentOrder}
                onItemClick={onItemClick}
              />
            )
          )
        )}
      </div>
    </div>
  )
}

export { PaymentOrdersNotificationPanel }
