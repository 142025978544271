import React, { FC } from "react"
import BaseIcon from "./BaseIcon"

type Props = {
  height?: number
  width?: number
  className?: string
  onClick?: () => void
}

const DefaultAvatar: FC<Props> = ({
  height = 24,
  width = 24,
  className,
  onClick,
}) => {
  return (
    <BaseIcon
      onClick={onClick}
      className={className}
      viewBox="0 0 48 48"
      height={height}
      width={width}
      content={[
        <title key="title">Avatar</title>,
        <defs key="defs">
          <linearGradient
            x1="16.553%"
            y1="11.58%"
            x2="100%"
            y2="100%"
            id="prefix__b"
          >
            <stop stopColor="#EE3378" offset="0%" />
            <stop stopColor="#EE33B4" offset="100%" />
          </linearGradient>
          <circle id="prefix__a" cx={24} cy={24} r={24} />
        </defs>,
        <g key="g" fill="none" fillRule="evenodd">
          <mask id="prefix__c" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <use fill="url(#prefix__b)" xlinkHref="#prefix__a" />
          <g mask="url(#prefix__c)">
            <path
              d="M31.111 29C36.021 29 40 32.918 40 37.75l-.222 8.5c0 .966-.796 1.75-1.778 1.75s-1.778-.784-1.778-1.75l.222-8.5c0-2.9-2.387-5.25-5.333-5.25H16.89c-2.946 0-5.333 2.35-5.333 5.25l-.223 8.5c0 .966-.796 1.75-1.777 1.75-.982 0-1.778-.784-1.778-1.75L8 37.75C8 32.918 11.98 29 16.889 29zM24 8c4.91 0 8.889 3.918 8.889 8.75S28.909 25.5 24 25.5c-4.91 0-8.889-3.918-8.889-8.75S19.091 8 24 8zm0 3.5c-2.946 0-5.333 2.35-5.333 5.25S21.054 22 24 22s5.333-2.35 5.333-5.25S26.946 11.5 24 11.5z"
              fill="#F9F9F9"
              fillRule="nonzero"
            />
          </g>
        </g>,
      ]}
    />
  )
}

export default DefaultAvatar
