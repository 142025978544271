import React, { useEffect } from "react"
import LoadingData from "../models/LoadingData"
import actionByError from "../components/common/actionByError"
import { useUserOwn } from "../shared/store/useOwnUser"
import { useNavigate } from "react-router-dom"
import CustomError from "../shared/exceptions/CustomError"
import { BASE_URL } from "../shared/constants/constants"
import AffiliatesApiProvider from "../shared/providers/affiliatesProvider/AffiliatesApiProvider"
import Affiliate from "../shared/models/Affiliate"

export const useAffiliates = () => {
  const { token } = useUserOwn()
  const history = useNavigate()

  const [affiliatesRequest, setAffiliatesRequest] = React.useState({
    loading: true,
    data: undefined,
  } as LoadingData<Affiliate[] | undefined>)

  useEffect(() => {
    new AffiliatesApiProvider(BASE_URL)
      .getAffiliates(token)
      .then((affiliates: Affiliate[]) => {
        setAffiliatesRequest({
          loading: false,
          data: affiliates,
        })
      })
      .catch((error: CustomError) => {
        actionByError(error.errorCode, history) // FIXME: cambiar esto por un useCustomError hook en el MainLayout para mostrar un mensaje o redirigir al login como este caso
      })
  }, [token])
  return {
    affiliates: affiliatesRequest,
    setAffiliatesRequest,
  }
}
