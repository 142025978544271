import React, { FC } from "react"

const TermsAndConditionsPage: FC = () => {
  return (
    <>
      <div className="dark:bg-bg-dark p-8">
        <h1 className="title-legal">TERMS OF SERVICE AGREEMENT</h1>
        <p className="text-legal">
          Terms of Service as a legal and binding contract between SMART FINTECH
          SL, a company domiciled in Spain, Calle Chile n&ordm; 10 Oficina 123,
          Las Rozas de Madrid and with Tax ID number B09869827, (hereinafter
          &quot;SMART&quot;, &quot;our&quot;, &quot;we&quot;, &quot;us&quot; or
          &quot;us&quot;), and the contracting party or parties (the
          &quot;User&quot;, &quot;you&quot; or &quot;your&quot;) accessing
          and/or using the Services.
        </p>

        <p className="text-legal">
          The Terms of Service, together with all other agreements,
          acknowledgements and authorizations of the User in connection with
          registering, hiring or maintaining an account with SMART and using the
          Services, including, without limitation, the APPLICATION PROGRAMMING
          INTERFACE LICENSE USE AGREEMENT (hereinafter API), the Risk Warning
          and the Privacy Policy, found on our website (collectively, the
          &quot;Terms&quot;) represent the terms upon which SMART will provide
          the Services to the User. The Terms set forth the respective rights
          and obligations of both parties in connection with the Services and
          both parties will agree to and be bound by these terms by accepting
          the terms and conditions contained herein. The User agrees that the
          following rights and obligations shall govern the relationship between
          SMART and the User.
        </p>
        <p className="text-legal">
          PLEASE READ THE TERMS OF THIS AGREEMENT CAREFULLY AS THEY GOVERN YOUR
          RELATIONSHIP WITH SMART. IF YOU DO NOT AGREE WITH ANY PART OF THE
          TERMS, YOU SHOULD NOT ACCESS ANY SERVICE OR PRODUCT OFFERED BY OR
          THROUGH SMART. THIS AGREEMENT CONTAINS A BINDING ARBITRATION CLAUSE
          AND CLASS ACTION WAIVER THAT AFFECT YOUR RIGHTS ON HOW TO RESOLVE
          DISPUTES. PLEASE READ IT CAREFULLY.
        </p>
        <p className="text-legal">
          BY REGISTERING AND/OR ACCESSING AND/OR USING OUR SERVICES, YOU
          ACKNOWLEDGE THAT.
        </p>
        <p className="text-legal">
          (I) YOU HAVE READ AND UNDERSTAND YOUR OBLIGATIONS AND RIGHTS UNDER THE
          TERMS AND AGREE AND ACKNOWLEDGE THAT THESE TERMS OF SERVICE, THE API
          LICENSE AGREEMENT, THE RISK WARNING AND PRIVACY POLICY SHALL COMPRISE
          THE COMPLETE TERMS AND CONDITIONS OF YOUR RELATIONSHIP WITH SMART;
        </p>
        <p className="text-legal">
          (II) YOU AGREE THAT YOU ARE FULLY RESPONSIBLE FOR THE USE OF OUR API,
          FOR MAKING ALL DECISIONS REGARDING TRANSACTIONS MADE BY YOU THROUGH
          OUR API ON YOUR ACCOUNT;
        </p>
        <p className="text-legal">
          (III) YOU ACKNOWLEDGE THAT YOU VOLUNTARILY ACTIVATE THE API THAT WE
          PROVIDE TO YOU FOR USE ON THE EXCHANGES THAT YOU VOLUNTARILY ACCESS
          AND THE RISKS ASSOCIATED WITH TRADING DIGITAL ASSETS AND DERIVATIVES
          OF DIGITAL ASSETS;
        </p>
        <p className="text-legal">
          (IV) YOU ASSUME ALL RISKS ASSOCIATED WITH THE USE OF YOUR ACCOUNT ON
          YOUR EXCHANGES AND THE TRADING OF DIGITAL ASSETS AND DIGITAL ASSET
          DERIVATIVES INCLUDING THE USE OF OUR API THAT YOU VOLUNTARILY ACTIVATE
          ON YOUR EXTERNAL EXCHANGE ACCOUNTS;
        </p>
        <p className="text-legal">
          (V) YOU ARE AWARE OF AND ASSUME ALL RISKS RELATED TO THE USE OF THE
          AUTOMATED API EVEN IF YOU USE IT IN EXTERNAL ACCOUNTS AND SOCIAL
          TRADING; AND
        </p>
        <p className="text-legal">
          (VI) SMART SHALL NOT BE LIABLE FOR ANY SUCH RISKS OR ADVERSE RESULTS
          AS YOU EXPRESSLY ACKNOWLEDGE THAT YOU ACT VOLUNTARILY AND WITH
          SUFFICIENT KNOWLEDGE ON THE VARIOUS EXCHANGES USING THE API THAT WE
          PROVIDE TO YOU FOR USE.
        </p>
        <p className="text-legal">
          (VII) YOU DECLARE THAT YOU HAVE DEEMED THAT THE USE OF THE API
          ASSIGNED TO YOU FOR USE ON YOUR EXCHANGE ACCOUNTS, TOGETHER WITH THE
          FOREGOING FACTORS AND IN VIEW OF YOUR CURRENT AND ANTICIPATED
          FINANCIAL RESOURCES, YOU ARE WILLING AND ABLE TO ASSUME THE
          SUBSTANTIAL FINANCIAL RISKS OF TRADING IN DIGITAL ASSETS, FUTURES,
          DERIVATIVES OF DIGITAL ASSETS.
        </p>
        <p className="text-legal">
          IT IS YOUR RESPONSIBILITY TO FIND OUT ALL NECESSARY INFORMATION ABOUT
          DIGITAL ASSET TRADING, DIGITAL ASSET DERIVATIVES, EXTERNAL ACCOUNTS
          AND SOCIAL TRADING, AS WELL AS THE TERMS AND ENSURE THAT ALL RISKS AND
          AGREEMENTS ARE CLEARLY DISCUSSED AND UNDERSTOOD PRIOR TO ANY TRADING
          ACTIVITY OR USE OF THE SERVICES.
        </p>
        <p className="text-legal">
          <strong>1. DEFINITIONS AND INTERPRETATION</strong>
        </p>
        <p className="text-legal">
          <strong>1.1. Definitions</strong>
        </p>
        <p className="text-legal">
          &quot;Account&quot; shall mean the USER&apos;s Account that allows the
          USER to use and maintain the API for use in external exchanges.&nbsp;
        </p>
        <p className="text-legal">
          &quot;Agreement&quot; shall include these Terms of Service and all
          other agreements and authorizations executed by USER in connection
          with the registration or maintenance of an Account with SMART and use
          of the Services.
        </p>
        <p className="text-legal">
          &quot;API&quot; shall mean THE CONNECTION provided by SMART of the
          algorithm assigned in use to the exchanges contracted for and provided
          by User.
        </p>
        <p className="text-legal">
          &quot;Content&quot; shall mean any information or documents or images
          on the Website, and its related sites and mobile applications, data
          available through any API, and any information, documents or images on
          SMART&apos;s accounts on any third party social media platforms,
          including but not limited to Twitter, Instagram, Telegram, Facebook,
          Discord, Github, Medium, YouTube and Reddit.
        </p>
        <p className="text-legal">
          &quot;Algorithm&quot; refers to SMART&apos;s DISTRIBUTED trading
          software that allows Users to individually and independently PERFORM
          trading activities on an Exchange contracted by the User. It is
          understood that SMART&apos;s trading software allows Users to connect
          to exchanges.
        </p>
        <p className="text-legal">
          &quot;Digital Asset Derivatives&quot; are contracts between two or
          more parties whose value is based on an underlying Digital Asset.
          Digital Asset Derivatives include, but are not limited to Options,
          Perpetual Swaps, Futures and other instruments such as forwards and
          contracts for difference.
        </p>
        <p className="text-legal">
          &quot;Digital Asset Exchange&quot; means digital asset exchanges and
          digital asset trading platforms operated by third parties, accessible
          through the Trading Terminal (Exchange).
        </p>
        <p className="text-legal">
          &quot;Digital Assets&quot; shall mean cryptocurrencies or digital
          assets or digital tokens or cryptocurrencies that are based on
          distributed ledger technology and that can be digitally traded or
          transferred. For the avoidance of doubt, Bitcoin and Ether are Digital
          Assets.
        </p>
        <p className="text-legal">
          &quot;Intellectual Property Rights&quot; shall include, without
          limitation, any registered or unregistered patents, database rights,
          data protection rights, trademarks, trade secrets, trade names, trade
          names, corporate names, service marks, tag lines and descriptors,
          domain names, design, structure, designs, layouts, graphic images,
          typography, color palettes and copyrightable works, including but not
          limited to software applications, underlying source code, stationery,
          signage, promotional items, advertising and marketing materials, press
          releases, photographs, forms and electronic media.
        </p>
        <p className="text-legal">
          &quot;KYC/AML and CTF Policy&quot; means the policies and procedures
          implemented by SMART to detect and prevent money laundering, terrorist
          financing and corruption.
        </p>
        <p className="text-legal">
          &quot;Prohibited Activities&quot; means the activities listed in
          Section 5.3. of these Terms of Service.
        </p>
        <p className="text-legal">
          &quot;Prohibited Jurisdiction&quot; shall mean (i) the United States
          of America, the People&apos;s Republic of China, the Province of
          Ontario (Canada), Afghanistan, Belarus, Central African Republic,
          Congo, Democratic Republic of the Congo, Republic of the Ivory Coast,
          Crimean Region of Ukraine, Cuba, El Salvador, Eswatini, Gambia, Iran,
          Iraq, Liberia, Libya, Malawi, Mali, Moldova, Myanmar, Niger, North
          Korea, Palestinian Territory, St. Vincent and the Grenadines, South
          Sudan, Syria, Venezuela, Yemen, Zambia, Zimbabwe; (ii) any state,
          country or other jurisdiction that is sanctioned and/or embargoed by
          the United States of America, the European Union and/or Switzerland;
          (iii) a jurisdiction in which it would be unlawful under local law or
          regulation for you to access or use the Website; or (iv) where the
          provision or availability of the Website is prohibited or contrary to
          local law or regulation, or would subject to any local registration,
          regulation or licensing requirements.
        </p>
        <p className="text-legal">
          &quot;Services&quot; means all services provided by SMART, as
          described in Section 6 hereof.
        </p>
        <p className="text-legal">
          &quot;EXCHANGES&quot; means cryptocurrency trading platforms where the
          User has its funds deposited.
        </p>
        <p className="text-legal">
          &quot;Trading Terminal&quot; means SMART&apos;s trading software,
          web-based applications, mobile applications and APIs that allow Users
          to connect to Digital Asset Exchanges, either through an Exchange
          Sub-Account or through an External Exchange Account, and place orders
          to buy and sell Digital Assets and Digital Asset Derivatives on such
          Digital Asset Exchanges.
        </p>
        <p className="text-legal">
          &quot;User&quot;, &quot;you&quot; or &quot;your&quot; means the party
          (or parties) who have agreed to be bound by the Terms.
        </p>
        <p className="text-legal">
          &quot;User Content&quot; shall mean any content, comments,
          suggestions, ideas or other information or material about SMART, or
          the Services, that you provide to SMART through the Website, the
          Services or any other means, including, but not limited to, blogs,
          bulletin boards, forums and APIs (whether or not submitted through a
          third party).
        </p>
        <p className="text-legal">
          &quot;Website&quot; shall mean https://BOTTHEMOON.com any subdomains,
          related domains, mobile applications and all of their downloadable
          material and emails.
        </p>
        <p className="text-legal">
          &quot;SMART&quot; shall mean, where the context permits or requires,
          SMART FINTECH SL, or its subsidiaries and affiliates.
        </p>
        <br />
        <p className="text-legal">
          <strong>
            1.2. Construction. In this Agreement, unless the context requires
            otherwise
          </strong>
        </p>
        <ol className="text-legal">
          <li>words meaning the singular include the plural and vice versa;</li>
          <li>
            words signifying a gender include both the gender and the neuter;
          </li>
          <li>
            words meaning persons include corporations, associations, and
            entities of persons, whether corporate or not;
          </li>
          <li>
            the words &quot;may&quot; shall be construed as permissive;
            &quot;shall&quot; or &quot;will&quot; shall be construed as
            imperative;
          </li>
          <li>
            the headings contained in these Conditions are for reference
            purposes only, and shall in no way affect the meaning or
            interpretation of the Conditions.
          </li>
        </ol>
        <br />
        <p className="text-legal">
          <strong>2.&nbsp;&nbsp;AGREEMENT</strong>
        </p>
        <p className="text-legal">
          SMART reserves the right, at its sole discretion, to modify or replace
          these Terms at any time, with or without prior notice to the User. Any
          such modification shall be effective immediately upon posting. Your
          continued use of our Services after any modification constitutes your
          acceptance of these modified Terms. If you do not agree to any
          modification of these Terms, you must stop using the Services. SMART
          encourages you to frequently review the Terms to ensure that you
          understand the terms and conditions that apply to your purchase,
          access and use of the Service.
        </p>
        <br />
        <p className="text-legal">
          <strong>3. DISCLAIMER OF LIABILITY</strong>
        </p>
        <p className="text-legal">
          SMART does not provide financial advice, investment management or any
          advisory services or advice on the purchase and sale of any assets.
          SMART is not a bank, investment manager or any other financial
          institution and does not provide financial services.&nbsp;
        </p>
        <p className="text-legal">
          SMART is a software as a service (SAAS) that allows the user to
          connect to the exchange and trade automatically according to the
          user&apos;s own settings.&nbsp;&nbsp;Your access to or use of the
          Services is at your own risk, and you are solely responsible for any
          loss, damage or cost resulting from your use of or access to the
          Services. The Services do not constitute investment advice or a
          marketing, promotion or offer of any product or investment to you or
          any third party. The Services are provided on an &quot;AS IS&quot; and
          &quot;AS AVAILABLE&quot; basis. The Services are provided without
          warranties of any kind, either express or implied, including, but not
          limited to, the implied warranties of merchantability, fitness for a
          particular purpose, non-infringement or course of performance. SMART
          shall in no event be liable for any loss or other damages, including
          but not limited to special, incidental, consequential or other
          damages. Without limiting the foregoing, SMART does not represent or
          warrant that the Services are accurate, complete, reliable, current or
          error-free, or free of viruses or other harmful components. By
          accessing and/or using our Services, you agree that SMART shall have
          no liability, contingent or otherwise, to you or any third party, for
          the correctness, quality, accuracy, security, completeness,
          reliability, performance, timeliness, price or continued availability
          of the Services or for delays or omissions in the Services, or for the
          failure of any connection or communication service that provides or
          maintains your access to the Services, or for any interruption or
          disruption of your access or any miscommunication between SMART and
          you, regardless of cause.
        </p>
        <br />
        <p className="text-legal">
          <strong>4. COMMUNICATION</strong>
        </p>
        <ol className="text-legal">
          <li>
            You understand, acknowledge and agree that the method of
            communication between SMART and you will be electronic via email,
            SMART Discord Account, SMART Telegram Account or the Website. We
            will use the email address provided by you or your username
            registered to your account as our means of communication with you.
            You agree to keep your email address up to date, to check your
            email, your access to our Discord and Telegram account frequently
            and to notify us immediately if there are any changes.
          </li>
        </ol>
        <br />
        <ol className="text-legal">
          <li>
            You understand, acknowledge and agree that delivery of any
            communication to the email address registered to your Account is
            deemed valid, and will be deemed to have been authorized, correct,
            approved and confirmed by you, unless we have received written
            notice to the contrary within three (3) business days of the date
            the communication was sent. If any email communication is returned
            as undeliverable, SMART reserves the right to block your access to
            your Account until you provide and confirm a new valid email
            address.
          </li>
          <li>
            You understand, acknowledge and agree that SMART shall not be liable
            to you for information obtained through unofficial SMART channels.
            If you have any questions or concerns regarding the accuracy of
            information submitted on behalf of SMART, please contact us
            immediately.
          </li>
          <li>
            You understand, acknowledge and agree that it is a violation of
            these Terms to make any untrue statement of a material fact to
            SMART, or the SMART Entities.
          </li>
        </ol>
        <br />
        <p className="text-legal">
          <strong>5. USER REPRESENTATIONS AND WARRANTIES; ELIGIBILITY</strong>
        </p>
        <p className="text-legal">
          By accessing and/or using the Services, you represent and warrant
          that:
        </p>
        <ol className="text-legal">
          <li>
            You are at least 18 years of age or the legal age to enter into a
            binding contract under applicable law, are a natural person, legal
            entity or other organization with full legal capacity and authority
            to enter into these Terms;
          </li>
          <li>
            If you enter into these Terms on behalf of a legal entity of which
            you are an employee or agent, you have all rights and authority
            necessary to bind such legal entity, and (i) you are legally
            authorized to use the Services in your jurisdiction, and (ii) you
            are legally authorized to hold cryptocurrencies in your
            jurisdiction;
          </li>
          <li>
            You are responsible for ensuring compliance with the laws of your
            jurisdiction and acknowledge that SMART is not responsible for your
            compliance with such laws,
          </li>
          <li>
            Your use of the Services does not constitute a violation of the laws
            of your jurisdiction; d. Your use of the Services does not
            constitute a violation of the laws of your jurisdiction
          </li>
          <li>
            You understand the inherent risks associated with Digital Assets,
            and trading of Digital Assets, and Digital Asset Derivatives;
          </li>
          <li>
            You have a working knowledge of the use of Digital Assets, smart
            contract based tokens and blockchain based software systems;
          </li>
          <li>
            Has sufficient investment knowledge and experience and the ability
            to assume the risks arising from the trading of Digital Assets, and
            Digital Asset Derivatives;
          </li>
          <li>Has knowledge and experience in highly volatile markets;</li>
          <li>
            You understand and have sufficient knowledge and experience with the
            use of External Accounts, and their associated risks;
          </li>
          <li>
            You understand and have sufficient knowledge of the operation of
            Social Trading, and its associated risks; j. You understand and have
            sufficient knowledge of the operation of Social Trading, and its
            associated risks;
          </li>
          <li>
            You trade with funds that you can afford to lose, and have a high
            tolerance for risk;
          </li>
          <li>
            You will not engage in any activity that (i) involves income from
            any illegal or unlawful activity (including money laundering or
            terrorist financing); or (iii) violates, or may violate, any
            applicable law;
          </li>
          <li>
            Shall not engage in or initiate any form of market manipulation;
          </li>
          <li>
            You will not use, or misuse, the Services in any way that may impair
            the functionality of the Services, or other systems used to provide
            the Services or impair the ability of any other user to use the
            Services or the Trading Platform; n. You will not use, or misuse,
            the Services in any way that may impair the functionality of the
            Services, or other systems used to provide the Services or impair
            the ability of any other user to use the Services or the Trading
            Platform;
          </li>
          <li>
            You will not transmit or post to the Website any files that may
            damage the computing devices or software of any other person;
            content that may be offensive; or material or data that violates any
            law;
          </li>
          <li>
            You are the owner and have full control of the Digital Assets wallet
            address used for withdrawals from your Account;
          </li>
          <li>
            You are the legal owner (or an authorized agent of the legal owner)
            of the funds you deposit into your Account, and that these funds are
            from a legitimate source;
          </li>
          <li>
            You will not use any method or service to mask your Internet
            Protocol address or your Internet traffic or your actual location or
            your actual connection to the Internet, including but not limited to
            virtual private networks, proxy servers and the Tor browser;
          </li>
          <li>
            You are not a national and/or resident of a Prohibited Jurisdiction;
          </li>
          <li>
            You are not, by reason of your nationality, domicile, citizenship,
            residence or otherwise, subject to the laws of a Prohibited
            Jurisdiction;
          </li>
        </ol>
        <br />
        <p className="text-legal">
          <strong>6. SERVICES</strong>
        </p>
        <p className="text-legal">
          6.1. Services. Subject to your compliance with the Terms, your SMART
          Account provides access to the following Services, unless otherwise
          noted:
        </p>
        <ol className="text-legal">
          <li>The Website; access to Discord and Telegram SMART Accounts.</li>
          <li>The software (APIs) on use.</li>
          <li>Trading Terminal; and</li>
          <li>Any other services provided by SMART from time to time.</li>
        </ol>
        <p className="text-legal">
          6.2. Use of the Licensed API in use. Use of our API on Exchanges
          Platforms involves use in the futures market on which the exchanges
          platforms operate, which is leveraged and the position is used as
          collateral for the leverage and may be subject to liquidation under
          certain market conditions.
        </p>
        <p className="text-legal">
          By entering into Leverage Trades on futures exchanges, you acknowledge
          and agree:
        </p>
        <ol className="text-legal">
          <li>
            The provision of funding is provided by the exchange platform at its
            absolute discretion, with SMART not being responsible;
          </li>
          <li>
            SMART has no discretion over the Exchange platform where you apply
            it, and therefore, we will not assume any liability or damage caused
            by your use through the Account;
          </li>
          <li>
            You have read and understood all the terms and conditions of Trading
            on the relevant Exchange platform;
          </li>
          <li>
            Failure to comply with the Requirements of your Exchange platform
            may result in the liquidation of any open positions with a
            consequent loss;
          </li>
        </ol>
        <p className="text-legal">
          6.3. No Investment Services. SMART does not provide asset management,
          portfolio management, personalized investment recommendations or
          advisory services to Users within the scope of the Services. Any
          information provided by SMART to Users with respect to the API is not,
          and shall not be deemed to be, advice of any kind.
        </p>
        <p className="text-legal">
          SMART&apos;s Right to Pause, Stop or Block. SMART reserves the right
          to pause, stop or block any User at any time in its sole discretion.
          SMART shall not be obligated to give reasons for such decision, and
          shall not be liable for any results from the exercise of such rights.
        </p>
        <p className="text-legal">
          6.5 To ensure full confidentiality of the Account access data; and
        </p>
        <p className="text-legal">
          6.6. Subscription Fee, Assignment Fee and Maintenance Fee. The
          delivery of the API by SMART to the User shall be paid by the User
          through the payment of a Fixed Subscription Fee in the amount of ONE
          HUNDRED AND FIFTY EUROS ($150.00) to be paid by the User within seven
          days from the delivery of the API.
        </p>
        <p className="text-legal">
          The transfer and maintenance of the API by SMART to the User shall be
          paid by the User through the payment of a monthly fee for the transfer
          and maintenance of the API during the transfer period.&nbsp;&nbsp;The
          User understands that the use and maintenance of the API depends on
          the balance of the account in the different exchanges and the number
          of transactions, requiring SMART to do more maintenance work the
          higher the balance and the higher the number of transactions. As the
          User understands that the higher the account balance, the higher the
          number of daily transactions, which entails a greater need for
          maintenance, the User expressly accepts that the fee will vary
          depending on the transactions, the account balance and the results
          obtained by the transactions.
        </p>
        <p className="text-legal">
          The monthly assignment and maintenance fee will be equivalent to
          TWENTY PERCENT of the positive results of the calendar month obtained
          by the operations carried out in its Exchange platform through the API
          assigned in use.&nbsp;&nbsp;The monthly assignment and maintenance fee
          shall NEVER be less than zero, and shall be due on the first day after
          the end of each calendar month.&nbsp;
        </p>
        <p className="text-legal">
          The collection of the monthly fee shall be made, always at
          SMART&apos;s choice, by bank transfer, direct debit, payment gateway,
          or any other accepted means of payment.&nbsp;
        </p>
        <p className="text-legal">
          6.7. API Usage Result.&nbsp;&nbsp;SMART will not accept any complaint
          or request for compensation for the use and the results of the API
          transferred in use. User expressly waives any and all claims for
          damages for the results obtained from the use of the API provided
          hereunder.
        </p>
        <br />
        <p className="text-legal">
          <strong>7. ACCOUNT</strong>
        </p>
        <p className="text-legal">
          7.1. Identity Verification. When you use or access the Services or
          register an Account, we will ask for your name, physical address,
          mailing address, date of birth, and other information, and documents
          that allow us to identify you in line with the KYC/AML &amp; CTF
          Policy. When you register and maintain an account with us, you must
          provide us with information that is accurate, complete and current at
          all times. Failure to do so constitutes a breach of the Terms, which
          may result in immediate termination of your account. You also agree to
          provide us, upon registration of an Account and as requested from time
          to time on an ongoing basis, any additional information for the
          purposes of identity verification and the detection and prevention of
          money laundering, terrorist financing, fraud or any other financial
          crime, including, without limitation a copy of your government-issued
          photo ID, a photograph of you holding your government-issued photo ID,
          video verification of you holding your government-issued photo ID,
          proof of your residential address (such as a utility bill), proof of
          source of funds, proof of source of wealth, and proof of your good
          character.&nbsp;
        </p>
        <p className="text-legal">
          You authorize us to keep a record of such information and to make such
          inquiries, either directly or through third parties, as we deem
          necessary to verify your identity or protect you and others against
          fraud or other financial crimes, and to take such action as we
          reasonably deem necessary based on the results of such inquiries. When
          we conduct these investigations, you acknowledge and agree that your
          personal information may be disclosed to regulatory or enforcement
          agencies.
        </p>
        <p className="text-legal">
          7.2. Security of Your Account. You are solely responsible for
          maintaining the confidentiality and security of your Account login
          credentials or the devices you use to access and/or use the Services,
          and for ensuring that no unauthorized person has access to your
          Account, or any device you use in connection with the Services. We
          will not be liable for any loss or damage arising from your failure to
          protect your Account.
        </p>
        <p className="text-legal">
          7.3. Prohibited Activities. In connection with your access to and/or
          use of the Account or the Services, you may not:
        </p>
        <ol className="text-legal">
          <li>
            Use the Services for resale or commercial purposes, including
            transactions on behalf of other persons or entities;
          </li>
          <li>
            Violate or assist any party to violate any law, statute, ordinance,
            regulation or any rule of any self-regulatory or similar
            organization of which you are or should be a member through your
            access and/or use of the Services;
          </li>
          <li>
            Engage in any trading activity that, in our sole discretion, amounts
            to or may amount to market abuse, including, without limitation,
            pump and dump, engaging in fictitious trading or wash trades, front
            running, bear raiding or engaging in disorderly conduct in the
            marketplace; c. Engage in any trading activity that, in our sole
            discretion, amounts to or may amount to market abuse, including,
            without limitation, pump and dump, engaging in fictitious trading or
            wash trades, front running, bear raiding or engaging in disorderly
            conduct in the marketplace;
          </li>
          <li>
            Engaging in non-competitive trading on the Trading Platform for the
            purpose of assisting another person to engage in trading in
            violation of the Terms or any applicable law or regulation;
          </li>
          <li>
            Engaging in any transaction, practice or conduct on the Trading
            Platform that is commonly known as &quot;spoofing&quot; (bidding or
            offering with the intent to cancel the bid or offer prior to its
            execution); e. Engaging in any transaction, practice or conduct on
            the Trading Platform that is commonly known as &quot;spoofing&quot;
            (bidding or offering with the intent to cancel the bid or offer
            prior to its execution).
          </li>
          <li>
            Engaging in any money laundering, terrorist financing or fradulence
            activity;
          </li>
          <li>
            Infringe our intellectual property rights or the copyrights,
            patents, trademarks or other intellectual property rights of others;
          </li>
          <li>
            Take any action that imposes an unreasonable or disproportionate
            burden on our infrastructure, or that detrimentally interferes with,
            intercepts or expropriates any system, data or information; h. Take
            any action that imposes an unreasonable or disproportionate burden
            on our infrastructure, or that disrupts, intercepts or expropriates
            any system, data or information;
          </li>
          <li>
            Transmit or upload any material on the Website and/or through the
            Services, which contains viruses, Trojan horses, worms or any other
            harmful or deleterious programs;
          </li>
          <li>
            Attempt to gain unauthorized access to or use of the Website, the
            Services, APIs, other Accounts or computer systems connected to the
            Services;
          </li>
          <li>
            Use any deep linking, web crawlers, bots, spiders or other automatic
            devices, programs, scripts, algorithms or methods, or any similar or
            equivalent manual process to access, obtain, copy or monitor any
            part of the Properties, or replicate or circumvent the navigational
            structure or presentation of the Website, the Trading Platform or
            the Services;
          </li>
          <li>
            Probe, scan or test for vulnerabilities in the Website and/or the
            Services or any network connected to the Properties, or breach any
            security or authentication measures on the Website and/or the
            Services;
          </li>
          <li>
            Provide false, inaccurate, incomplete or misleading information;
          </li>
          <li>
            Distribute unsolicited or unauthorized advertising or promotional
            material, written press releases, public announcements and public
            disclosures, junk mail, spam or chain letters;
          </li>
          <li>
            Use a web crawler or similar technique to access our Services or to
            extract data;
          </li>
          <li>
            Reverse engineer or disassemble any aspect of the Website, APIs or
            Services in an effort to access any source code, underlying ideas
            and concepts and algorithms;
          </li>
          <li>
            Perform any unauthorized vulnerability, penetration or similar
            testing on the API;
          </li>
          <li>
            Attempt to gain unauthorized access to or use of the Website, other
            Accounts, computer systems or networks connected to the Website, by
            password mining or any other means;
          </li>
          <li>Transfer any rights granted to you under these Terms; or</li>
          <li>
            Engage in any behavior that violates the Terms or that we deem
            unacceptable in our sole discretion.
          </li>
        </ol>
        <p className="text-legal">
          7.4. Digital Asset Wallet. SMART does not have custody of any Digital
          Assets, as they will be held in your Exchange provider&apos;s Digital
          Asset wallet.&nbsp;
        </p>
        <p className="text-legal">
          We will not be responsible, and you will assume all liability, for
          loss of Digital Assets managed by your Account, including, but not
          limited to, unauthorized access by a third party, loss or breach of
          your passwords, private keys, or errors or mistakes resulting from
          transfer to and from erroneous public keys or Digital Asset addresses,
          or resulting from a mismatch between the distributed ledger network of
          the sender&apos;s address or public key and the recipient&apos;s
          address or public key (&quot;Blockchain Mismatch&quot;). In the event
          of a loss of Digital Assets due to a Blockchain Mismatch when managing
          your Digital Assets from your Account, SMART will not be liable for
          any loss or take any action to attempt to recover such Digital Assets.
          The User acknowledges, understands and agrees that Digital Assets lost
          due to a Blockchain Mismatch are not recoverable by SMART.
        </p>
        <p className="text-legal">
          7.5. Withdrawal of Digital Assets. You acknowledge, understand and
          agree that we may not be able to immediately execute any withdrawal
          request to your exchange provider. Although we will use our best
          efforts to execute a withdrawal request within a reasonable time, we
          make no commitment or guarantee as to the processing time to execute a
          withdrawal request. We are not liable for any damages caused by a
          delay in executing your withdrawal request.
        </p>
        <p className="text-legal">
          7.6. Account History. There may be a historical record of your
          transactions (&quot;Account History&quot;) in your Account. You
          acknowledge, understand and agree that Account History will not be
          treated as an appraisal of your Account. We use commercially
          reasonable efforts to ensure that the information contained in your
          Account History and any notices SMART provides to you are accurate and
          reliable; however, errors may occur from time to time and such errors
          do not affect the actual means and results of a particular
          transaction. It is your sole responsibility to review your account
          history and notices. Any transaction listed in your Account History,
          or other communication including, but not limited to, orders, trades
          or other receipts sent to your email address on record, will be deemed
          and treated as authorized, correct, approved and confirmed by you,
          unless we receive written notice to the contrary within three (3)
          business days of the date the transaction was listed or communication
          was sent (as the case may be).
        </p>
        <p className="text-legal">
          7.7. Forks. The Digital Assets are based on an open source protocol.
          Anyone can clone the source code of the Digital Asset and develop a
          divergent blockchain protocol without anyone&apos;s prior permission,
          also known as &quot;Forks&quot;. Forks can be made of any Digital
          Asset that can change the usability, functions, value or even the name
          of a given Digital Asset. Such Forks may result in multiple versions
          of a Digital Asset and could lead to the dominance of one or more such
          versions of a Digital Asset (each a &quot; Dominant Digital Asset
          &quot;) and the partial or total abandonment or loss of value of any
          other version of such Digital Asset (each a &quot; Non-Dominant
          Digital Asset &quot;).
        </p>
        <p className="text-legal">
          SMART has no obligation and may not be able to support a Fork of a
          Digital Asset that you manage through your Account, regardless of
          whether any resulting version of such Forked Digital Asset is a
          Dominant Digital Asset or a Non-Dominant Digital Asset or has value on
          or after such Fork. In addition, as SMART does not have custody of the
          Digital Assets, we cannot guarantee and assume no responsibility for
          whether a particular Digital Asset Exchange supports a particular
          Fork. If the Digital Asset Exchange does not support the Fork, you may
          not be able to receive the Digital Assets derived from such Fork.
          Forks of Digital Assets can be frequent, contentious and
          unpredictable, and therefore cannot be consistently supported in
          SMART. When managing Digital Assets using your Account, you should
          operate under the assumption that SMART and/or the Digital Asset
          Exchanges will never support any Forks of such Digital Asset. If SMART
          or the Digital Asset Exchanges decide, in their sole discretion, to
          support a Fork of a Digital Asset, it may choose to do so by making a
          public announcement through the Website or the respective sites of the
          Digital Asset Exchanges, or by notifying Users otherwise, and shall
          have no liability for any actual or potential loss that may result
          based on the decision to support such Fork or the timing of the
          implementation of the support. If SMART and/or the Digital Asset
          Exchanges, in their sole discretion, elect not to support a Fork of a
          particular Digital Asset, including determining to support, continue
          to support or cease supporting any Dominant Digital Asset or
          Non-Dominant Digital Asset, SMART assumes no liability for any losses
          or other problems that may arise from an unsupported Fork of a Digital
          Asset.
        </p>
        <br />
        <p className="text-legal">
          <strong>8. GENERAL USE, PROHIBITED USE AND TERMINATION</strong>
        </p>
        <p className="text-legal">
          8.1. Limited License. We grant You a revocable, limited, royalty-free,
          non-exclusive, non-transferable, non-sublicensable license to access
          and use the Services through Your Internet-enabled devices for Your
          personal/internal purposes, subject to the terms of this Agreement, to
          access and use the Website and the Services solely for the approved
          purposes permitted by SMART from time to time. You hereby agree that
          when you use the Services, SMART does not transfer the Services or any
          intellectual property rights to you.
        </p>
        <p className="text-legal">
          8.2. Accuracy of the Website, Services and Related Media. Although we
          intend to provide accurate and timely information, the Content may not
          always be entirely accurate, complete or current and may also include
          technical inaccuracies or typographical errors. In an effort to
          continue to provide you with the most complete and accurate
          information possible, information may be changed or updated from time
          to time without notice, including without limitation information
          regarding our terms, policies, products and services. Accordingly, you
          should verify all information before relying on it, and all decisions
          based on information contained on the website, the trading platform or
          otherwise drawn from the content are your sole responsibility and we
          shall have no liability for any such decisions. Links to unaffiliated
          third party materials (including, without limitation, unaffiliated
          websites and third parties) may be provided as a convenience, but are
          not controlled by us. You acknowledge and agree that we are not
          responsible for any aspect of the information, content or services
          contained in unaffiliated third party materials or unaffiliated third
          party sites that may be accessed by or linked to the Website or the
          Services.
        </p>
        <p className="text-legal">
          Unaffiliated Third Party Access. If, to the extent permitted by SMART
          from time to time, you grant express permission to an unaffiliated
          third party to access your Account, you acknowledge that granting
          permission to an unaffiliated third party to perform specific actions
          on your behalf does not relieve you of any of your responsibilities
          under the Terms. You are fully responsible for all acts or omissions
          of any unaffiliated third party who has access to your Account. You
          further acknowledge and agree that you will not hold SMART responsible
          for, and will indemnify SMART from, any liability arising out of or
          related to any acts or omissions of any unaffiliated third party with
          access to your Account.
        </p>
        <p className="text-legal">
          8.4. Prohibited Use. In connection with your use of the Services, and
          your interactions with other users, and any unaffiliated third
          parties, you agree and represent that you will not engage in any
          Prohibited Activities. We reserve the right at all times to monitor,
          review, retain and/or disclose any information as necessary to satisfy
          any applicable law, regulation, sanctions program, legal process or
          governmental request. We reserve the right to terminate and/or suspend
          your Account if we determine, in our sole discretion, that your
          Account is associated with a Prohibited Activity.
        </p>
        <p className="text-legal">
          8.5. Suspension, termination and cancellation.
        </p>
        <ol className="text-legal">
          <li>
            We may suspend, freeze, restrict or terminate your access to any or
            all Services, and/or deactivate or terminate your Account if (i) we
            are required to do so by a valid subpoena, court order or binding
            order of a governmental authority; (ii) we reasonably suspect that
            you are using your Account in connection with a Prohibited Activity;
            (iii) your use of your Account is subject to any pending litigation,
            investigation or governmental proceeding and/or we perceive a
            heightened risk of legal or regulatory noncompliance associated with
            your Account activity; (iv) you take any action that SMART believes
            is in violation of the Terms.
          </li>
          <li>
            If SMART suspends, freezes, restricts or terminates your Account,
            and/or your access to and/or use of the Services for any reason, we
            may provide you with notice of our actions, unless a court order or
            other legal process prohibits SMART from providing you with such
            notice. You acknowledge that SMART&apos;s decision to take certain
            actions, including limiting access, suspending or closing your
            Account, may be based on confidential criteria that are essential to
            our risk management and security protocols. You agree that SMART is
            under no obligation to disclose the details of its risk management
            and security procedures to you.
          </li>
          <li>
            You acknowledge, understand and agree that if we determine, in our
            sole discretion, that you have breached the Terms, we are entitled
            to debit your Account for (i) the value of any discount or rebate on
            trading commissions you have received in connection with the Terms
            at any time after one year prior to the date on which we become
            aware that you have breached the Terms and (ii) any other damages we
            have suffered as a result of your breach of the Terms.
          </li>
          <li>
            You further acknowledge and agree that if we determine, in our sole
            discretion, that you have colluded, coordinated and/or collaborated
            with any other User or person to breach the Terms, you and such User
            or person shall be jointly and severally liable for the full value
            of any trading commission rebates, refunds and/or damages to which
            we are entitled under the Terms, and such amounts may be debited, in
            our sole discretion, from your and such other User&apos;s or
            person&apos;s account. We will deduct any such amount(s) from the
            respective sufficient Digital Assets account(s) calculated using the
            prevailing market price.
          </li>
          <li>
            You acknowledge, understand and agree that SMART has the right to
            investigate any violation of these Terms, unilaterally determine
            whether you have violated these Terms, and take action under
            relevant laws and regulations without your consent or prior notice.
          </li>
        </ol>
        <p className="text-legal">
          8.6. Exchange Balances upon Account Termination. Upon termination of
          the Account, the remaining balance on the various exchanges shall be
          handled by you directly, releasing SMART from any and all liability
          for any outstanding balances.
        </p>
        <p className="text-legal">
          8.7. Relationship of the Parties. Nothing in this Agreement shall be
          deemed or intended to be, or shall cause you and SMART to be treated
          as, partners, joint ventures or otherwise joint associates for profit,
          nor shall you or SMART be treated as agents of the other party.
        </p>
        <p className="text-legal">
          8.8. Password Security; Contact Information. You are responsible for
          maintaining appropriate security and control over any and all user IDs
          and passwords that you use to access the Services. You are strongly
          encouraged to use two-factor authentication to access the Website and
          the Services, and you may adjust your security features in your user
          settings. If you suspect or become aware of any unauthorized use of
          your Account, you must notify SMART immediately. SMART assumes no
          responsibility or liability for any loss or damage arising from access
          to and/or use of your Account by you or any third party with or
          without your authorization.
        </p>
        <p className="text-legal">
          8.9. Taxes. It is your sole responsibility to determine whether, and
          to what extent, any taxes apply to any transactions you enter into in
          connection with the Services, and to withhold, collect, report and
          remit the correct amounts of taxes to the appropriate taxing
          authorities.
        </p>
        <br />
        <p className="text-legal">
          <strong>9. INTELLECTUAL PROPERTY</strong>
        </p>
        <p className="text-legal">
          1. All Rights Reserved. All Intellectual Property Rights relating to
          the Website, Content, User<strong>&nbsp;</strong>Content and Services
          are owned by SMART. You acknowledge that all SMART Intellectual
          Property Rights in the Website, the Content, the User Content and the
          Services shall remain the property of SMART.
        </p>
        <p className="text-legal">
          2. No Right of Use. You are not granted any right to use, and may not
          use any of SMART&apos;s Intellectual Property Rights, except as set
          forth in these Terms and subject to the following:
        </p>
        <ol className="text-legal">
          <li>
            you may only view and use the Services, Website, Content or User
            Content for your own personal use and may not copy, reproduce,
            republish, repost, upload, upload, repost, modify, transmit,
            distribute or otherwise use the Services, Website, Content or User
            Content for non-personal, public or commercial use without
            SMART&apos;s prior written consent;
          </li>
          <li>
            You may not remove or alter any copyright, trademark or other
            proprietary notices affixed to any part of the Website, Content,
            User Content and Services;
          </li>
          <li>
            You may not modify, publish, transmit, participate in the transfer
            or sale of, create derivative works from, or exploit in any way, any
            of the Content or User Content;
          </li>
          <li>
            Except as expressly permitted by copyright law, no copying,
            redistribution, retransmission, publication or commercial
            exploitation of downloaded material will be permitted without our
            express permission;
          </li>
          <li>
            In the event that copying, redistribution or publication of
            copyrighted material is permitted, the author attribution, trademark
            legend or copyright notice shall not be modified or removed; and
          </li>
          <li>
            You may not use any data mining, robots or similar data gathering or
            extraction methods.
          </li>
        </ol>
        <p className="text-legal">
          9.3. User Content. By submitting any User Content, you warrant that
          you have all necessary intellectual property rights. We reserve the
          right to disclose your identity to any third party who claims that any
          of your User Content constitutes an infringement of their Intellectual
          Property Rights or their right to privacy. You hereby transfer all
          rights, title and interest in and to your User Content and all related
          intellectual property rights to SMART.&nbsp;
        </p>
        <p className="text-legal">
          You have no right and hereby waive any claim for acknowledgment or
          compensation based on any User Content, or any modification based on
          any User Content.
        </p>
        <br />
        <p className="text-legal">
          <strong>10. RISK DISCLOSURE</strong>
        </p>
        <p className="text-legal">
          Trading in Digital Assets and/or Digital Asset Derivatives is risky
          and you should never trade more than you can afford to lose. The
          following list of risks associated with Digital Assets, Digital Asset
          Derivatives and Services is not exhaustive.&nbsp;
        </p>
        <ol className="text-legal">
          <li>
            The prices of Digital Assets are highly volatile and trading in
            Digital Assets is extremely risky. In addition, there are a number
            of factors that may affect market liquidity for a particular Digital
            Asset, such as regulatory activity, market manipulation, acts or
            omissions of the issuer of the Digital Asset or unexplained price
            volatility. By using the Services, you accept the risk of trading in
            Digital Assets and Digital Asset Derivatives. By entering into any
            transaction, you represent that you have been, are and will be
            solely responsible for making your own independent assessment of the
            risks of the transaction and the underlying Digital Asset.
          </li>
          <li>
            We do not advise on the merits of any particular Digital Assets,
            Digital Asset Derivatives, Signal Providers and/or Digital Asset
            Exchanges. We do not advise on transactions, or associated risks, or
            provide any other financial, investment or legal advice in
            connection with the Services. To the extent that we provide trading
            data, post market commentary, articles or make social media posts,
            the act of doing so is incidental to your relationship with us and
            such information cannot be considered financial or investment advice
            and should not be construed as such. THE PURCHASE OR SALE OF DIGITAL
            ASSETS AND/OR DERIVATIVES OF DIGITAL ASSETS IS THE SOLE DECISION OF
            THE USER AND WE SHALL NOT BE LIABLE FOR ANY CONSEQUENTIAL LOSS.
          </li>
          <li>
            The availability of a Signal Provider through the Services does not
            indicate our approval or disapproval of its merits. We make no
            warranty as to the suitability or reliability of Signal Providers,
            and we have no fiduciary obligation in our dealings with you. You
            must conduct your own due diligence.
          </li>
          <li>
            No brokering of transactions or investment advice. We provide
            software and a platform to connect Users with Signal Providers and
            Digital Asset Exchanges. While we may have a degree of control over
            your Exchange Sub-Accounts maintained under our Master Trading
            Account, and your trades executed under the Exchange Sub-Accounts
            are recorded in your account segregated from the Master Trading
            Account, we do not broker transactions on your behalf. Our
            relationship does not constitute a broker-client relationship. We do
            not act as principal, counterparty or market maker in transactions
            executed through the Services or in the funding of transactions
            funded on the Trading Terminal.
          </li>
          <li>
            Users who choose to configure any number or type of trading signals
            made available by Signal Providers do so with the understanding that
            SMART cannot be responsible for any delayed, failed or incorrectly
            transmitted notifications. It is possible that a trading signal may
            not reach the user for a variety of reasons, such as server failure.
            Even without such failure or delay of an alert, the validity and
            usefulness of the information represented in a successfully
            transmitted alert of a trading signal may be diminished due to
            market volatility, incorrect pricing or other information from a
            Digital Asset Exchange, or other unforeseen technical problems or
            market conditions. In particular, a Digital Asset or Digital Asset
            Derivative may be temporarily or permanently delisted from a Digital
            Asset Exchange without notice, or support for certain Digital
            Assets, Digital Asset Derivatives and/or Digital Asset Exchanges may
            become unavailable, in which case the related trading signals may
            have diminished utility.
          </li>
          <li>
            SMART expressly represents to the User that it does not guarantee
            profits or repeat performance rates that have been realized in the
            past and cautions that no positive record of implementation of the
            trading strategy ensures repeat trading success in the future.
          </li>
          <li>
            User assumes financial responsibility for losses incurred in the
            account as a result of the use of the API and/or trading activities.
          </li>
          <li>
            The User accepts full responsibility for all financial losses, such
            as direct losses or loss of profit resulting from the following
            risks i) risk that the Signal Provider does not possess the
            necessary qualifications (if required) or knowledge to carry out the
            trading activities; ii) risk that the Signal Provider may carry out
            fraudulent transactions using the functionality of the Services;
            iii) risk that the, Signal Provider may lose control of the account
            and that third parties may gain access to the account; iv) risk that
            the User may misunderstand these Terms; v) risk of closure of the,
            Signal Provider&apos;s account.
          </li>
          <li>
            We do not guarantee continuous, uninterrupted or secure access to
            any part of the Services, and the operation of our Website or
            Services may be interfered with by numerous factors beyond our
            control, including regulatory reasons and problems with various
            Blockchain networks.
          </li>
          <li>
            SMART provides the Trading Terminal, an interface that connects
            users to the Digital Asset Exchanges. However, SMART does not
            operate a Digital Asset Exchange. We do not administer the
            underlying trading platforms of the Digital Asset Exchanges for bids
            or offers and do not enforce contracts between parties participating
            in the financing activities available on those Digital Asset
            Exchanges through the Trading Terminal. The underlying trading
            platform of the Trading Terminal is managed and operated by the
            Digital Asset Exchange. In no event will we be liable for any loss
            or other damages, including, but not limited to, special,
            incidental, consequential or other damages arising out of the acts
            of any operator of the Digital Asset Exchange. You are responsible
            for understanding the Terms and Conditions of the operators of the
            Digital Asset Exchanges.
          </li>
          <li>
            SMART does not provide custodial services. Digital Assets held in
            your SMART account are held in your External Exchange Account and/or
            in your Exchange Sub-Account under the Master Trading Account of a
            Digital Asset Exchange. You acknowledge, understand and agree that
            SMART will not be liable for any losses arising from any security
            issues of the Digital Asset Exchanges or any actions taken by the
            Digital Asset Exchanges.
          </li>
          <li>
            While SMART makes every effort to conduct thorough due diligence on
            the Digital Asset Exchanges available on the Trading Terminal, these
            Digital Asset Exchanges may be unregulated exchanges and their
            practices may vary widely, including with respect to their security,
            encryption and liability for losses. Digital Asset Exchanges may not
            be required to comply with any regulations regarding wash trades,
            insider trading, promoting price discovery, loss protection, fraud
            protection, ensuring anti-money laundering compliance and many other
            salient features. Digital Asset Exchanges may conduct fraudulent
            transactions or fronts for fraudulent schemes and be in a
            jurisdiction where there is no likelihood of recovery or reward.
            Digital Asset Exchanges could provide incorrect, delayed or faulty
            data for a variety of reasons, including as a result of software
            errors and the aforementioned limited oversight of the
            cryptocurrency markets. Such faulty data could misinform user
            trading strategies or generate inaccurate price discovery
            mechanisms. Errors, fraud and other problems with Digital Asset
            Exchanges have resulted in large and irrecoverable losses on
            multiple occasions in the recent past. The software and underlying
            mechanisms of the Digital Asset Exchanges could malfunction,
            executing trades for Users at faulty prices and adversely affecting
            Users&apos; investments. The Digital Asset Exchanges could choose to
            reverse trades to the benefit of other parties but to the detriment
            of Users. In general, errors and unreviewable decisions by exchanges
            could adversely affect the value of Users&apos; investment.
          </li>
        </ol>
        <br />
        <p className="text-legal">
          <strong>11. LIQUIDATION OF ACCOUNTS</strong>
        </p>
        <p className="text-legal">In case of:</p>
        <ol className="text-legal">
          <li>The death or judicial declaration of incapacity of the User;</li>
          <li>
            The filing of a petition in bankruptcy, or a petition for the
            appointment of a receiver, or the institution of any insolvency or
            similar proceedings by or against the User;
          </li>
          <li>
            The User&apos;s failure to provide us with the information requested
            under these Terms or applicable law; or
          </li>
          <li>
            Any abuse of trade practices, tampering and/or fraud by the User or
            any other person authorized to use the account; or
          </li>
          <li>
            Any other circumstance or event that we deem appropriate for your
            protection, and in SMART&apos;s sole discretion,
          </li>
        </ol>
        <br />
        <p className="text-legal">
          <strong>12. INDEMNIFICATION</strong>
        </p>
        <p className="text-legal">
          You agree to indemnify and hold harmless SMART, its subsidiaries,
          affiliates, agents, employees, attorneys-in-fact, successors and
          assigns (&quot;SMART Entities&quot;) from and against any and all
          liabilities, losses, damages, costs and expenses, including
          attorneys&apos; fees, incurred by any of the SMART Entities as a
          result of your breach of your obligations hereunder or under any third
          party license, or in the event that any of your representations and
          warranties are not true and correct. User also agrees to promptly pay
          to the SMART Entities all damages, costs and expenses, including
          attorneys&apos; fees, incurred in enforcing any of the provisions of
          these Terms and any other agreement between SMART and User.&nbsp;
        </p>
        <p className="text-legal">
          In addition to the limitations of liability specified elsewhere in
          these Terms, the SMART Entities shall not be liable for and are
          released from all claims and losses incurred in connection therewith
          if the claim or loss was caused or contributed to by:
        </p>
        <ol className="text-legal">
          <li>The acts or omissions on the part of the User;</li>
          <li>
            Any act or omission by any person obtaining access to the
            User&apos;s account, whether or not the User has authorized such
            access;
          </li>
          <li>
            System malfunction, equipment failure (whether User&apos;s or
            SMART&apos;s equipment), system interruption or system
            unavailability;
          </li>
          <li>
            Delays, failures or errors in the execution of any instructions;
          </li>
          <li>
            Inaccurate or incomplete instructions received by SMART from User;
            or
          </li>
          <li>
            Any reliance or use by the User or any other third party with access
            to the User&apos;s account of any financial and market data, quotes,
            news, analyst opinions, research reports, charts or any other data
            or information available through the trading platform or any third
            party license, whether to complete a transaction on the trading
            platform or for any other purpose.
          </li>
        </ol>
        <p className="text-legal">
          The SMART Entities reserve the right to exercise sole control over the
          defense, at their expense, of any claim subject to indemnification
          under this section.
        </p>
        <p className="text-legal">
          <strong>DISCLAIMER OF WARRANTIES / LIMITATION OF LIABILITY</strong>
        </p>
        <p className="text-legal">
          13.1 The SMART Entities shall not be liable for any loss or damage
          caused, directly or indirectly, by any event, act or omission beyond
          SMART&apos;s control, including, without limitation, loss or damage
          resulting, directly or indirectly, from any delay or inaccuracy in the
          transmission of orders and/or information due to a breakdown or
          failure of transmission or communication facilities, or a power
          failure.
        </p>
        <p className="text-legal">
          13.2. Neither SMART nor any of its subsidiaries, affiliates or agents
          warrant that the Website and/or the Services will be available without
          interruption or will be error free, and such Website and/or Services
          are provided &quot;as is&quot; without any representation or warranty
          of any kind, except as otherwise set forth herein.
        </p>
        <p className="text-legal">
          13.3. Under no circumstances shall SMART or any of its subsidiaries,
          affiliates or agents be liable for any direct, indirect, punitive,
          incidental, special or consequential damages resulting from the use or
          inability to use the Website and/or Services, including, but not
          limited to, loss of profits, loss of business, business loss, loss of
          data or use of data, any unauthorized access to, alteration, theft or
          destruction of User&apos;s computers, computer systems, data files,
          programs or information, or the costs of procurement of substitute
          goods or services.
        </p>
        <p className="text-legal">
          13.4. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, USER
          EXPRESSLY UNDERSTANDS AND AGREES THAT IN NO EVENT SHALL THE SMART
          ENTITIES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS,
          OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR
          CONTRACTORS BE LIABLE FOR ANY INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE,
          CONSEQUENTIAL OR SIMILAR DAMAGES OR LIABILITY (INCLUDING, WITHOUT
          LIMITATION, DAMAGES FOR LOSS OF DATA, INFORMATION, REVENUE, INCOME,
          PROFITS OR OTHER BUSINESS OR FINANCIAL BENEFITS) ARISING OUT OF THE
          SERVICES, ANY PERFORMANCE OR NON-PERFORMANCE OF THE SERVICES, OR ANY
          OTHER PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF THE
          SMART ENTITIES, WHETHER PURSUANT TO CONTRACT, STATUTE, STRICT
          LIABILITY OR ANY OTHER THEORY, EVEN IF THE SMART ENTITIES HAVE BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, EXCEPT TO THE EXTENT THAT
          SUCH DAMAGES ARE FINALLY JUDICIALLY DETERMINED TO HAVE RESULTED FROM
          THE GROSS NEGLIGENCE OF THE SMART ENTITIES, FRAUD, WILLFUL MISCONDUCT
          OR INTENTIONAL VIOLATION OF LAW.
        </p>
        <p className="text-legal">
          NOTWITHSTANDING THE FOREGOING, IN NO EVENT SHALL THE LIABILITY OF THE
          SMART ENTITIES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS,
          OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR
          CONTRACTORS ARISING OUT OF THE SERVICES OFFERED BY OR ON BEHALF OF THE
          SMART ENTITIES, ANY PERFORMANCE OR NON-PERFORMANCE OF THE SERVICES, OR
          ANY OTHER PRODUCT, SERVICE OR OTHER ITEM, WHETHER UNDER CONTRACT,
          STATUTE, STRICT LIABILITY OR OTHER THEORY, EXCEED THE AMOUNT OF FEES
          PAID BY YOU TO THE SMART ENTITIES UNDER THESE TERMS IN THE
          TWELVE-MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE
          CLAIM OF LIABILITY.
        </p>
        <p className="text-legal">
          IF YOU ARE DISSATISFIED WITH ANY PART OF THE SERVICES OR THIS
          AGREEMENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP USING THE
          SERVICES AND CLOSE YOUR ACCOUNT. THE SERVICES ARE PROVIDED &quot;AS
          IS&quot; AND WITHOUT ANY REPRESENTATIONS OR WARRANTIES, EITHER EXPRESS
          OR IMPLIED. THE SMART ENTITIES SPECIFICALLY DISCLAIM ANY IMPLIED
          WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
          OR NON-INFRINGEMENT. THE SMART ENTITIES DO NOT WARRANT THAT (I) THE
          SERVICES WILL MEET YOUR NEEDS, (II) THE SERVICES WILL BE
          UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, OR (III) THE QUALITY,
          SUITABILITY AND PERFORMANCE OF THE DIGITAL ASSETS, DIGITAL ASSET
          DERIVATIVES, SERVICES, INFORMATION OR OTHER MATERIAL PURCHASED OR
          OBTAINED BY YOU WILL MEET YOUR EXPECTATIONS. THE SERVICES ARE PROVIDED
          ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT
          ANY REPRESENTATIONS OR WARRANTIES, WHETHER EXPRESS, IMPLIED OR
          STATUTORY. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE
          SMART ENTITIES SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF TITLE,
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND/OR
          NON-INFRINGEMENT. THE SMART ENTITIES MAKE NO REPRESENTATION OR
          WARRANTY THAT ACCESS TO THE WEB SITE, ANY PART OF THE SERVICES, THE
          TRADING PLATFORM, THE MOBILE APPLICATIONS OR ANY OF THE MATERIALS
          CONTAINED THEREIN WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY OR
          ERROR-FREE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
          LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
          LIMITATION MAY NOT APPLY TO YOU.
        </p>
        <br />
        <p className="text-legal">
          <strong>14. NO WAIVER</strong>
        </p>
        <p className="text-legal">
          From time to time, SMART may not enforce or strictly enforce any
          provision of these Terms. SMART may also fail to exercise any or all
          of its rights hereunder. Any such failure shall not be construed as a
          waiver or relinquishment of SMART&apos;s right to assert or rely on
          any such provision or right in that or any other instance. Where
          applicable, an express waiver by SMART of any condition, provision or
          requirement of these Terms shall not constitute a waiver of any future
          obligation to comply with such condition, provision or requirement.
        </p>
        <br />
        <p className="text-legal">
          <strong>15. GOVERNING LAW AND JURISDICTION</strong>
        </p>
        <p className="text-legal">
          These Terms, and any terms posted on the Website, are governed by and
          will be construed in accordance with the laws of Spain, without giving
          effect to any conflict of law principles that would establish the
          application of the law of another jurisdiction.
        </p>
        <p className="text-legal">
          You agree to submit any Dispute (as defined below) to arbitration in
          accordance with the terms of Section 16. To the extent that the
          arbitration agreement is ineffective or void, you agree to submit to
          the non-exclusive jurisdiction of the courts of Madrid.
        </p>
        <br />
        <p className="text-legal">
          <strong>16. SUBMISSION TO ARBITRATION</strong>
        </p>
        <p className="text-legal">
          Any dispute, claim, suit, action, cause of action, demand or
          proceeding arising out of or relating to these Terms, and any terms
          posted on the Website (including with respect to their validity,
          existence or termination), any Service, action or transaction under or
          contemplated by these Terms, (any &quot;Dispute&quot;) that is not
          resolved by you and SMART within 30 days from the date either party
          notifies the other party in writing of the Dispute, shall be referred
          to and finally resolved by arbitration. In addition, the parties agree
          that.
        </p>
        <ol className="text-legal">
          <li>
            Attempt informal resolution prior to any demand for arbitration for
            at least 30 days before initiating any arbitration or court
            proceeding. Such informal negotiations are initiated upon receipt of
            written notice from you. If we are unable to resolve the dispute
            informally, you and we agree that any dispute arising under this
            Agreement will be finally resolved in binding arbitration on an
            individual basis;
          </li>
          <li>
            That any dispute, controversy or claim arising out of or relating to
            the Terms, or the breach, termination or invalidity thereof, shall
            be resolved by arbitration in accordance with the rules of the
            Madrid Court of Arbitration;
          </li>
          <li>That the number of arbitrators shall be one;</li>
          <li>
            That the seat of arbitration shall be the Madrid Court of
            Arbitration, unless otherwise agreed by the Parties;
          </li>
          <li>
            That the language to be used in the arbitration proceedings shall be
            Spanish;
          </li>
          <li>
            That the courts of Madrid have exclusive jurisdiction over any
            appeal of an arbitration award and over any lawsuit between the
            parties not subject to arbitration;
          </li>
          <li>
            That the arbitrator is empowered to grant any remedy that would
            otherwise be available in the courts; and
          </li>
          <li>
            That the parties shall share the costs and expenses of any
            arbitration and bear their own legal costs and expenses.
          </li>
        </ol>
        <br />
        <br />
        <p className="text-legal">
          <strong>
            17.WAIVER OF CLASS ACTION AND CLASS ARBITRATION RIGHTS.
          </strong>
        </p>
        <p className="text-legal">
          You and SMART agree that any claims relating to these Terms or your
          relationship with SMART as a user of the Services (whether based in
          contract, tort, statute, fraud, misrepresentation or any other legal
          theory, and whether the claims arise during or after termination of
          these Terms) shall be brought against the other party in arbitration
          on an individual basis and not as a plaintiff or class member in a
          putative class or representative action. Further, you and SMART agree
          to waive any right to have such claims brought, heard or arbitrated as
          a class, collective, representative or private attorney general
          action, to the extent permitted by applicable law. You agree not to
          join with any other individual or entity or group of individuals or
          entities for the purpose of attempting to resolve the respective
          Disputes on a consolidated or representative basis.
        </p>
        <br />
        <p className="text-legal">
          <strong>18. PRIVACY POLICY</strong>
        </p>
        <p className="text-legal">
          SMART&apos;s Privacy Policy can be found at{" "}
          <a href="https://beta-frontend.botthemoon.com/privacy-policy">
            https://beta-frontend.botthemoon.com/privacy-policy
          </a>
          &nbsp; &nbsp;&nbsp;
        </p>
        <br />
        <p className="text-legal">
          <strong>19.MISCELLANEOUS</strong>
        </p>
        <p className="text-legal">
          19.1. Assignment. You may not assign any rights and/or licenses
          granted under these Terms. We reserve the right to assign our rights
          without restriction, including without limitation to any of our
          affiliates or subsidiaries, or to any successor in interest to any
          business associated with the Services. Any attempted transfer or
          assignment in violation hereof shall be null and void. Subject to the
          foregoing, this Agreement shall be binding upon and inure to the
          benefit of the parties, their successors and permitted assigns.
        </p>
        <p className="text-legal">
          19.2. Severability. If any provision of the Terms is determined to be
          invalid or unenforceable under any rule, law or regulation or any
          governmental agency, local, state or federal, such provision shall be
          modified and construed to accomplish the purposes of the provision to
          the greatest extent possible under any applicable law and the validity
          or enforceability of any other provision of the Terms shall not be
          affected.
        </p>
        <p className="text-legal">
          19.3 Force Majeure. We shall have no liability for any failure or
          delay resulting from any abnormal or unforeseeable circumstances
          beyond our reasonable control, the consequences of which would have
          been unavoidable despite all efforts to the contrary, including,
          without limitation, governmental actions or acts of terrorism, war,
          earthquake, fire, flood or other acts of God, labor conditions, delays
          or failures caused by problems with another system or network,
          mechanical breakdowns or data processing failures or where we are
          bound by other legal obligations.
        </p>
        <p className="text-legal">
          19.4. Survival. Upon termination of your account or this Agreement for
          any other reason, all rights and obligations of the parties which by
          their nature are continuing shall survive such termination.
        </p>
        <p className="text-legal">
          19.5. Third Party Rights. The Terms are not intended to and shall not
          be construed as creating any rights or remedies in parties other than
          you and us and your affiliates, each of whom shall be a third party
          beneficiary of the Terms, and no other person shall be entitled to
          assert any rights as a third party beneficiary hereunder.
        </p>
        <p className="text-legal">
          YOU ACKNOWLEDGE THAT YOU HAVE RECEIVED, READ AND UNDERSTAND THE
          FOREGOING TERMS AND CONDITIONS AND HEREBY AGREE TO BE BOUND BY ALL OF
          THE TERMS AND CONDITIONS HEREOF.
        </p>
      </div>
    </>
  )
}

export { TermsAndConditionsPage }
