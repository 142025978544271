import { DailyBalance } from "../../models/dataTrack/DailyBalance"

export enum FilterDayOptions {
  Last7Days = "7_days",
  Last15Days = "15_days",
  Last30Days = "30_days",
}

export function getRelevantData(
  dataBalance: DailyBalance[],
  dollarGraph: boolean,
  optionFilerDays: string
): {
  dailyBalance: number[]
  labels: string[]
  totalIncome: number
  averageIncome: number
} {
  const dailyBalance: number[] = []
  const labels: string[] = []
  let totalIncome = 0
  const nLastBalance = getNLast(optionFilerDays)
  const nLastBalanceOrMaximun =
    dataBalance.length < nLastBalance ? dataBalance.length : nLastBalance
  /*const fromBalance =
    nLastBalance > dataBalance.length ? 0 : dataBalance.length - nLastBalance*/

  for (let i = 0; i < nLastBalanceOrMaximun; i++) {
    dailyBalance.unshift(
      (dollarGraph ? dataBalance[i].netIncome : dataBalance[i].percentage) ?? 0
    )
    labels.unshift(dataBalance[i].day ?? "")
    totalIncome +=
      (dollarGraph ? dataBalance[i].netIncome : dataBalance[i].percentage) ?? 0
  }
  return {
    dailyBalance,
    labels,
    totalIncome,
    averageIncome: totalIncome / dailyBalance.length,
  }
}

function getNLast(optionFilerDays: string): number {
  if (optionFilerDays === FilterDayOptions.Last7Days) {
    return 7
  }
  if (optionFilerDays === FilterDayOptions.Last15Days) {
    return 15
  }
  return 30
}
