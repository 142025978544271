import { createContext, FC, ReactNode, useContext } from "react"
import useWebSocket from "react-use-websocket"

type Context = ReturnType<typeof useLivePricesContext>
const LivePricesContext = createContext<Context | undefined>(undefined)

export const LivePricesProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <LivePricesContext.Provider value={useLivePricesContext()}>
      {children}
    </LivePricesContext.Provider>
  )
}

export const useLivePrices = () => {
  const context = useContext(LivePricesContext)
  if (!context) throw Error("LivePricesContext not available")
  return context
}

function useLivePricesContext(): { symbols } {
  let socketUrl = "wss://stream.binance.com:9443/stream?streams=!ticker@arr"
  const { lastJsonMessage } = useWebSocket(socketUrl)
  return { symbols: lastJsonMessage?.data }
}
