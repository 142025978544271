import React, { FC } from "react"
import { BaseModal } from "../BaseModal"
import { HeaderModal } from "../HeaderModal"
import { HelpSectionModal } from "./HelpSectionModal"

type Props = {
  className?: string
  onCloseClick: () => void
  onHelpClick: () => void
  onContactClick: () => void
  onFeatureClick: () => void
}

const HelpModal: FC<Props> = ({
  className,
  onCloseClick,
  onHelpClick,
  onContactClick,
  onFeatureClick,
}) => {
  return (
    <BaseModal displayBlackBg={true} maxWidth="md:!max-w-[70%]">
      <HeaderModal
        onBackClick={onCloseClick}
        onCloseClick={onCloseClick}
        title={"Help"}
        subtitle={"Lorem ipsum dolor sit amet"}
        hasCloseIcon={true}
        hasBackArrow={false}
      />
      <div className="overflow-scroll max-h-[70vh] sm:max-h-[80vh]">
        {/* <HelpSectionModal
          title="Help Center"
          subtitle="Discover all the details"
          onClick={onHelpClick}
          bottomBorder={true}
        />
        <HelpSectionModal
          title="Contact Support"
          subtitle="Do you have any doubt? Contact us"
          onClick={onContactClick}
          bottomBorder={true}
        /> */}
        <HelpSectionModal
          title="Feature Request"
          subtitle="We’d like to receive your requests"
          onClick={onFeatureClick}
        />
      </div>
    </BaseModal>
  )
}

export { HelpModal }
