import { PrimaryButton } from "components/PrimaryButton"
import React, { FC } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import AuthApIProvider from "../../../shared/providers/auth/AuthApIProvider"
import { notify, notifySuccess } from "../../Toasts/ToastUtils"
import LogoText from "../../../assets/logo-text-long-circle-white.png"
import { BASE_URL } from "../../../shared/constants/constants"

interface MyState {
  email: string
}

const VerifyEmailPage: FC = () => {
  const { t } = useTranslation()

  const history = useNavigate()
  const location = useLocation()
  const email = (location?.state as MyState)?.email
  if (!email) {
    history("/login")
  }

  return (
    <>
      <div className="flex h-screen dark:bg-bg-dark">
        <div className="m-auto sm:w-[32rem] w-full pl-4 pr-4 ">
          <div className="flex justify-center">
            <div>
              <a href="/">
                <img
                  src={LogoText}
                  className={"w-56 sm:w-72 mb-6"}
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <div className="w-full self-center px-6 py-8 rounded shadow-2xl bg-container-panel-bg-light dark:bg-container-panel-bg-dark">
            <div className="mt-4 text-center text-white font-medium text-xl">
              <p className="mb-2">{t("verify_email_page.header")}</p>
              <p className="mb-6">{t("verify_email_page.body")}</p>
              <p>{t("verify_email_page.button")}</p>
            </div>

            <PrimaryButton
              loading={false}
              className="h-10 mt-16 w-full !py-0"
              onClick={() => history("/login")}
            >
              <p>{t("login.text")}</p>
            </PrimaryButton>

            <button
              className="mt-8 w-full font-semibold text-white text-lg"
              onClick={() => {
                new AuthApIProvider(BASE_URL)
                  .resendVerificationEmail(email)
                  .then(() => {
                    notifySuccess(t("email_sent.text"))
                  })
                  .catch((e: Error) => {
                    notify(t("api_message_errors.unexpected_error"))
                  })
              }}
            >
              <p>{t("resend_confirmation_email.text")}</p>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export { VerifyEmailPage }
