import { DailyBalance } from "./models/dataTrack/DailyBalance"
import { TransactionsBot } from "./models/bot/BotInfo"

export function getDailyPercentage(
  dailyBalance: DailyBalance[],
  nLastDays: number
): number {
  let totalIncome = 0
  const nLastBalanceOrMaximun =
    dailyBalance.length < nLastDays ? dailyBalance.length : nLastDays

  for (let i = 0; i < nLastBalanceOrMaximun; i++) {
    totalIncome += dailyBalance[i]?.percentage ?? 0
  }
  return totalIncome / nLastBalanceOrMaximun
}

export function getCommissionSymbol(transactions: TransactionsBot[] = []) {
  const assetSymbol =
    transactions.find((transaction) => transaction.type === "COMMISSION")
      ?.asset ?? ""

  return assetSymbol === "USDT" ? "$" : assetSymbol
}

export function isDemo(): boolean {
  return process.env.REACT_APP_DEMO === undefined
    ? false
    : process.env.REACT_APP_DEMO === "true"
}
