import React, { FC, useEffect } from "react"
import { BotListItemType } from "shared/components/Bot/BotListItem/BotListItemTypes"
import { IncomesGraph } from "../pages/DashboardPage/components/IncomesGraph"
import LoadingData from "../../models/LoadingData"
import DataTrackApiProvider from "../../shared/providers/dataTrack/DataTrackApiProvider"
import { DailyBalance } from "../../shared/models/dataTrack/DailyBalance"
import { useTranslation } from "react-i18next"
import { usePrivateModeAtom } from "../../shared/store/store"
import { getDailyPercentage, isDemo } from "../../shared/utils"
import { BASE_URL } from "../../shared/constants/constants"
import { useDailyBalance } from "../../hooks/useDailyBalance"
import DataTrackMockProvider from "../../shared/providers/dataTrack/DataTrackMockProvider"

type Props = {
  isAdmin?: boolean
  onBotClick?: (botId: string) => void
  data: BotListItemType
  isSubscribed?: boolean
  token?: string
  title?: boolean
}

const COMPARE_N_LAST = 30

const MyBot: FC<Props> = ({
  isAdmin = false,
  onBotClick,
  data,
  isSubscribed = false,
  token,
}) => {
  //const [avatarError, setAvatarError] = useState(false)
  const { t } = useTranslation()
  const [privateMode] = usePrivateModeAtom()

  /*function getImage(): React.ReactNode {
    if (avatarError) {
      return (
        <DefaultAvatar className="object-cover w-10 h-10 mr-2 rounded-full" />
      )
    }
    return (
      <img
        className="object-cover w-10 h-10 mr-2 rounded-full"
        src={data?.avatar ?? ""}
        onError={() => {
          setAvatarError(true)
        }}
        alt="bot avatar"
      />
    )
  }
*/
  /*const [incomeResponse, setIncomeResponse] = React.useState<{
    loading: boolean
    data?: Income[]
  }>({ loading: true, data: undefined })*/

  const { dailyBalance } = useDailyBalance(
    data?.subscriptionId?.toString() ?? "-1",
    isDemo() ? new DataTrackMockProvider() : new DataTrackApiProvider(BASE_URL)
  )
  /*useEffect(() => {
    setBalanceResponse({
      loading: true,
      data: undefined,
    } as LoadingData<DailyBalance[]>)

    if (!data.subscriptionId) return
    new DataTrackApiProvider(BASE_URL)
      .getDailyBalance(token ?? "", data.subscriptionId ?? -1)
      .then((balanceResponse: DailyBalance[]) => {
        setBalanceResponse({
          loading: false,
          data: balanceResponse,
        } as LoadingData<DailyBalance[]>)
      })
      .catch(() => {
        setBalanceResponse({
          loading: false,
          data: [],
        } as LoadingData<DailyBalance[]>)
      })
  }, [token, data.subscriptionId])*/

  const dailyPercentage = getDailyPercentage(
    dailyBalance.data ?? [],
    COMPARE_N_LAST
  )

  return (
    <div
      className="cursor-pointer rounded shadow dark:hover:shadow-3xl  hover:shadow-xl transform hover:-translate-y-0.5 bg-white dark:bg-container-panel-bg-dark"
      onClick={() => {
        onBotClick?.(String(data.id))
      }}
    >
      <div className="flex items-center justify-between p-4">
        <div className="flex items-center">
          {/*{getImage()}*/}
          <div className="">
            <span className="block text font-semibold text-primary-text-light dark:text-primary-text-dark">
              {privateMode ? data.name?.replace(/.*/g, "**") : data.name}
            </span>
          </div>
        </div>
        {isAdmin ? (
          <div className="text-right">
            <span className="block text-sm font-semibold dark:text-main-text-dark text-main-text-light">
              {(isNaN(dailyPercentage) ? 0 : dailyPercentage).toFixed(2)}%
            </span>
            <span className="block text-xs uppercase dark:text-sub-text-dark text-sub-text-dark">
              {t("avg_daily_gain.text")}
            </span>
          </div>
        ) : (
          <div className="text-right">
            <span className="block text-sm font-semibold dark:text-main-text-dark text-main-text-light">
              {(isNaN(dailyPercentage) ? 0 : dailyPercentage).toFixed(2)}%
            </span>
            <span className="block text-xs uppercase dark:text-sub-text-dark text-sub-text-dark">
              {t("avg_daily_gain.text")}
            </span>
          </div>
          /*<PrimaryButton
            fill={false}
            color={"text-secondary-2-dark"}
            className={"!py-2 !px-4 !text-sm"}
          >
            {t("subscribed.text")}
          </PrimaryButton>*/
        )}
      </div>
      <hr className="border-1 dark:border-my-bot-divider-dark border-my-bot-divider-light" />
      <div className="p-6">
        {/*{data.strategy && (
          <>
            <h4 className="pb-2 dark:text-white">Strategy</h4>
            <div
              className="dark:text-sub-text-dark"
              dangerouslySetInnerHTML={{
                __html: data.strategy ? data.strategy : "",
              }}
            />
          </>
        )}*/}

        <IncomesGraph
          dataBalance={dailyBalance?.data ?? []}
          loading={dailyBalance?.loading ?? true}
          className="!shadow-none !p-0"
          title={false}
          colorTicksLighterInDark={true}
        />
      </div>
    </div>
  )
}

export { MyBot }
