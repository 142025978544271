import { useThemeAtom } from "../UseTheme"
import { useState } from "react"
import { getInitialTheme } from "../Utils"

export default function useColorLegendText(
  colorTicksLighterInDark: boolean
): string {
  const [theme] = useThemeAtom()
  let themeAux = getInitialTheme()
  themeAux = theme === "" ? themeAux : theme

  return themeAux === "dark"
    ? colorTicksLighterInDark
      ? "#76757E"
      : "#fff"
    : "#8C8B93"
}
