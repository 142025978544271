import React, { FC, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { SwitchCustom } from "../../../../SwithCustom"
import PercentageIcon from "../../../../../assets/percentage-icon.svg"
import DollarIcon from "../../../../../assets/dolar-percentage.svg"
import { DailyBalance } from "../../../../../shared/models/dataTrack/DailyBalance"
import useColorTicksGraph from "../../../../../hooks/useColorTicksGraph"
import useColorLegendText from "../../../../../hooks/useColorLegendTextGraph"
import {
  FilterDayOptions,
  getRelevantData,
} from "../../../../../shared/components/IncomesGraph/IncomesGraph"

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  registerables as registerablesJS,
  Tooltip,
} from "chart.js"
import { Chart } from "react-chartjs-2"

ChartJS.register(...registerablesJS)
ChartJS.register(
  CategoryScale,
  LinearScale,
  Legend,
  BarElement,
  PointElement,
  LineElement,
  Tooltip
)

type Props = {
  className?: string
  loading: boolean
  dataBalance: DailyBalance[]
  title?: boolean
  colorTicksLighterInDark?: boolean
}

const IncomesGraph: FC<Props> = ({
  className,
  loading,
  dataBalance,
  title = true,
  colorTicksLighterInDark = false,
}) => {
  const { t } = useTranslation()
  const [optionFilerDays, setOptionFilerDays] = React.useState(
    FilterDayOptions.Last30Days.toString()
  )
  const colorTicks = useColorTicksGraph(colorTicksLighterInDark)
  const colorLegendText = useColorLegendText(colorTicksLighterInDark)
  const [dollarGraph, setDollarGraph] = React.useState(true)
  const relevantData = getRelevantData(
    dataBalance,
    dollarGraph,
    optionFilerDays
  )

  const [chartData, setChartData] = useState<ChartData<"bar">>({
    datasets: [],
  })
  const chartRef = useRef<ChartJS>(null)

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }
    const gradientSecondary = chart.ctx.createLinearGradient(0, 0, 0, 123)
    gradientSecondary.addColorStop(0, "rgb(2, 240, 231)")
    gradientSecondary.addColorStop(1, "rgb(52, 247, 169)")

    const labels = loading ? [] : relevantData.labels
    const dataBars = loading ? [] : relevantData.dailyBalance
    const data = {
      labels: labels,
      datasets: [
        {
          data: dataBars,
          backgroundColor: gradientSecondary,
          borderRadius: 4,
        },
      ],
    }

    setChartData(data)
  }, [loading, dollarGraph, optionFilerDays])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: { color: colorLegendText, padding: 12 },
      },
      y: {
        grid: {
          color: colorTicks,
          borderDash: [8, 8],
          lineWidth: 1,
          drawTicks: false,
        },
        ticks: {
          color: colorLegendText,
          padding: 12,
          maxTicksLimit: 12,
          callback: function (value, index, values) {
            return dollarGraph ? "$" + value : value.toFixed(1) + "%"
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  const titleWithData = dollarGraph
    ? t("daily_pnl.text") +
      ` (${t("total.text")}: $${relevantData.totalIncome.toFixed(2)})`
    : t("daily_average_pnl.text") +
      ` (%${relevantData.averageIncome.toFixed(2)})`

  return (
    <div
      className={`p-2 lg:px-6 lg:py-4 shadow-md bg-container-panel-bg-light dark:bg-container-panel-bg-dark rounded ${className}`}
      onClick={() => {}}
    >
      {title && (
        <>
          <div className="flex justify-between items-center py-3 mb-2">
            <SwitchCustom
              className={""}
              classSelected="bg-secondary-2-dark"
              classNotSelected="bg-component-bg-light-dark"
              svgPathLeft={DollarIcon}
              svgPathRight={PercentageIcon}
              onChange={(isCheckedLeft) => {
                setDollarGraph(isCheckedLeft)
              }}
              classSvgLeft="!h-5 !my-1.5"
              isCheckedLeft={true}
            />
            <select
              style={{
                boxShadow: "none",
              }}
              //className="mr-2 border-2 outline-none active:border-secondary-gradien1-dark active:ring-secondary-gradien1-darkrounded-md active:outline-none dark:border-search-bar-border-input-dark border-search-bar-border-input-light dark:bg-dropdown-bg-dark dark:text-white bg-dropdown-bg-light text-main-text-light focus:ring-1 focus:ring-secondary-gradien1-dark"
              className="mr-2 dropdown"
              name="days"
              id="days_select"
              onChange={(event) => setOptionFilerDays(event.target.value)}
            >
              <option value={FilterDayOptions.Last30Days}>
                {t("last_30_days.text")}
              </option>
              <option value={FilterDayOptions.Last15Days}>
                {t("last_15_days.text")}
              </option>
              <option value={FilterDayOptions.Last7Days}>
                {t("last_7_days.text")}
              </option>
            </select>
          </div>
          <div className="flex justify-between items-center py-3 ml-2 lg:py-4">
            <div className="flex items-center">
              <h3 className="mr-2 text-2xl font-semibold dark:text-main-text-dark text-main-text-light dark:font-bold">
                {titleWithData}
              </h3>
            </div>
          </div>
        </>
      )}

      <div className="mr-2 h-64">
        <Chart ref={chartRef} data={chartData} options={options} type="bar" />
      </div>
    </div>
  )
}

export { IncomesGraph }
