import { PrimaryButton } from "components/PrimaryButton"
import React, { FC } from "react"
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { ValidationFieldForm } from "../../common/forms/ValidationFieldForm"
import AuthApIProvider from "shared/providers/auth/AuthApIProvider"
import { useTranslation } from "react-i18next"
import { notify } from "../../Toasts/ToastUtils"
import LogoText from "../../../assets/logo-text-long-circle-white.png"
import { BASE_URL } from "../../../shared/constants/constants"

const ForgotPasswordPage: FC = () => {
  const { t } = useTranslation()

  const [loading, setLoading] = React.useState(false)

  const history = useNavigate()

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t("email.input.validation")),
    }),
    onSubmit: (values) => {
      setLoading(true)
      new AuthApIProvider(BASE_URL)
        .resetPassword(values.email)
        .then(() => {
          setLoading(false)
          history(`/forgot-password-email-sent`)
          return
        })
        .catch((e: Error) => {
          setLoading(false)
          notify(t("api_message_errors.unexpected_error"))
        })
    },
  })

  return (
    <>
      <div className="flex h-screen dark:bg-bg-dark bg-bg-light">
        <div className="m-auto sm:w-96 w-full pl-4 pr-4 ">
          <div className="flex justify-center">
            <div>
              <a href="/">
                <img
                  src={LogoText}
                  className={"w-56 sm:w-72 mb-6"}
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="w-full self-center px-6 py-8 rounded shadow-2xl bg-container-panel-bg-light dark:bg-container-panel-bg-dark">
              <div className="mt-4">
                <input
                  className={`input-sign-up-login`}
                  id="email"
                  type="text"
                  placeholder={t("email.input.placeholder")}
                  {...formik.getFieldProps("email")}
                />

                {formik.touched.email && formik.errors.email ? (
                  <ValidationFieldForm
                    className="mt-2"
                    text={formik.errors.email}
                  />
                ) : null}
              </div>

              <PrimaryButton
                type="submit"
                loading={loading}
                className="h-10 mt-8 w-full !py-0"
              >
                <p>{t("recover_password.text")}</p>
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ForgotPasswordPage
