import React, { FC } from "react"

type Props = {
  height?: number
  width?: number
  className?: string
  viewBox: string
  content?: React.ReactNode
  isSelected?: boolean
  onClick?: (isChecked: boolean) => void
}

//TODO: Decorator pattern para el selector
const BaseCryptoLogoIcon: FC<Props> = ({
  height = 48,
  width = 48,
  className,
  viewBox,
  onClick,
  content,
  isSelected,
}) => {
  //const [checked, setChecked] = React.useState(true)

  const myOnClick = () => {
    if (onClick) onClick(!isSelected)
    //setChecked(!checked)
  }

  const classNameCheked = isSelected
    ? "border-2 border-primary-1-dark rounded-md"
    : "border-2 border-transparent"
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={height}
      height={width}
      viewBox={viewBox}
      className={`${className} ${classNameCheked} cursor-pointer p-0.5`}
      onClick={myOnClick}
    >
      {content}
    </svg>
  )
}

export default BaseCryptoLogoIcon
