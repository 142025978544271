import React, { FC } from "react"
import BaseCryptoLogoIcon from "./BaseCryptoLogoIcon"

type Props = {
  height?: number
  width?: number
  className?: string
  isSelected: boolean
  onClick?: (isChecked: boolean) => void
}

//TODO: Decorator pattern
const BinanceLogoIcon: FC<Props> = ({
  height = 48,
  width = 48,
  className,
  onClick,
  isSelected,
}) => {
  return (
    <BaseCryptoLogoIcon
      onClick={onClick}
      className={className}
      viewBox="0 0 126.61 126.61"
      height={height}
      width={width}
      isSelected={isSelected}
      content={[
        <title key="title">Binance</title>,
        <g key="icon" fill="#f3ba2f">
          <path d="M38.73 53.2l24.59-24.58 24.6 24.6 14.3-14.31L63.32 0l-38.9 38.9zM0 63.31L14.3 49l14.31 14.31-14.31 14.3zM38.73 73.41L63.32 98l24.6-24.6 14.31 14.29-38.9 38.91-38.91-38.88zM98 63.31L112.3 49l14.31 14.3-14.31 14.32z" />
          <path d="M77.83 63.3L63.32 48.78 52.59 59.51l-1.24 1.23-2.54 2.54 14.51 14.5 14.51-14.47z" />
        </g>,
      ]}
    />
  )
}

export default BinanceLogoIcon
