import React, { FC } from "react"

type Props = {
  height: number
  width: number
  className?: string
  viewBox: string
  content?: React.ReactNode
  onClick?: () => void
}

const BaseIcon: FC<Props> = ({
  height,
  width,
  className,
  viewBox,
  onClick,
  content,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={height}
      height={width}
      viewBox={viewBox}
      className={`${className}`}
      onClick={onClick}
      fill="none"
    >
      {content}
    </svg>
  )
}

export default BaseIcon
