import BinanceLogoIcon from "components/icons/CryptoApps/BinanceLogoIcon"
import React, { FC } from "react"

type Props = {
  className?: string
  onChangeSelected: (selectedPosition: number) => void
}

const ExchangeLogoRadioGroup: FC<Props> = ({ className, onChangeSelected }) => {
  const setSelectedRadio = (selected: boolean, position: number) => {
    if (!selected) return

    setSelectedPosition(position)
    onChangeSelected(position)
  }
  const [selectedPosition, setSelectedPosition] = React.useState(0)

  return (
    <div className={`${className}`}>
      <BinanceLogoIcon
        onClick={(selected: boolean) => {
          setSelectedRadio(selected, 0)
        }}
        isSelected={selectedPosition === 0}
        className="mr-6 "
      />
      {/* <BitmexLogoIcon
        onClick={(selected: boolean) => {
          setSelectedRadio(selected, 1)
        }}
        isSelected={selectedPosition === 1}
        className="mr-6 "
      />
      <CoinNext
        onClick={(selected: boolean) => {
          setSelectedRadio(selected, 2)
        }}
        isSelected={selectedPosition === 2}
        className="mr-6 "
      /> */}
    </div>
  )
}

export { ExchangeLogoRadioGroup }
