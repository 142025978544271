import { useNavigate } from "react-router-dom"
import actionByError from "../components/common/actionByError"
import { useEffect, useState } from "react"

export const useCustomNavigation = () => {
  const history = useNavigate()
  const [errorCode, setErrorCode] = useState<string | undefined>()

  useEffect(() => {
    if (errorCode) {
      actionByError(errorCode, history)
    }
  }, [errorCode, history])

  return { setErrorCode }
}
