import React, { FC } from "react"
import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table"
import EyeIcon from "../../../../icons/EyeIcon"
import Tippy from "@tippyjs/react"
import { Tooltip } from "../../../../Tooptip"
import InfoIcon from "../../../../icons/InfoIcon"
import { SymbolLicePrice } from "../../../../../shared/models/livePrices/SymbolPrice"
import { getColorTextPositiveNegative } from "../../../../../Utils"

type Props = {
  className?: string
  columns: Column<SymbolLicePrice>[]
  dataLivePrices: SymbolLicePrice[]
  onClickSymbol?: (symbol: string) => void
}

const TableMarketPricesCells: FC<Props> = ({
  className,
  columns,
  dataLivePrices,
  onClickSymbol,
}) => {
  const tableInstance = useTable(
    {
      columns,
      data: dataLivePrices,
      initialState: { pageSize: 20, pageIndex: 0 },
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    setGlobalFilter,
  } = tableInstance

  return (
    <div className={`table-base ${className}`}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.getHeaderGroupProps().key}>
              {headerGroup.headers.map((column) => (
                <th className="px-6 pb-4 table-text-header">
                  <div className="flex">
                    <div
                      className={"align-text-top"}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span className="text-sm ml-2">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : "⇅"}
                      </span>
                    </div>
                    {column["tooltip"] && (
                      <div className={"ml-4"}>
                        <Tippy
                          interactive={true}
                          hideOnClick={true}
                          animation={false}
                          render={(attrs) => (
                            <Tooltip
                              {...attrs}
                              content={column["tooltip"] ?? ""}
                            />
                          )}
                          disabled={!column["tooltip"]}
                          placement={"bottom"}
                        >
                          <div className={""}>
                            <InfoIcon className="table-text-header-info-icon" />
                          </div>
                        </Tippy>
                      </div>
                    )}
                  </div>
                  <div className={"flex pt-6"}>
                    {column["id"] === "symbol" && (
                      <input
                        style={{
                          boxShadow: "none",
                        }}
                        className={`w-24 input`}
                        id="symbol_input"
                        type="search"
                        placeholder="search"
                        onChange={(e) => setGlobalFilter(e.target.value)}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="">
          {page.map((row, index) => {
            prepareRow(row)
            const livePricePercentageColor = row.original?.P
              ? getColorTextPositiveNegative(parseFloat(row.original.P))
              : " dark:text-secondary-text-dark text-secondary-text-light "
            const livePriceColor =
              row.original?.c && row.original?.previousPrice
                ? getColorTextPositiveNegative(
                    parseFloat(row.original?.c) -
                      parseFloat(row.original?.previousPrice)
                  )
                : " "

            return (
              <tr
                className={`${
                  index === rows.length - 1
                    ? ""
                    : "border-b border-table-row-divider-bg-light-light dark:border-table-row-divider-bg-dark-dark"
                }  dark:hover:bg-component-highligted-bg-dark hover:bg-component-highligted-bg-light`}
              >
                <>
                  <td className="px-6 py-4 table-text-body">
                    <button
                      onClick={() => {
                        onClickSymbol?.(row.original.s ?? "")
                      }}
                    >
                      <div className={"flex"}>
                        {row.original.s && (
                          <EyeIcon
                            onClick={() => {}}
                            className="table-eye-icon"
                          />
                        )}
                        <p className="table-text-body">{row.original.s}</p>
                      </div>
                    </button>
                  </td>
                  <td
                    className={`px-6 py-4 table-text-body !font-bold ${livePriceColor}`}
                  >
                    {row.original.c
                      ? "$" + Number.parseFloat(row.original?.c).toFixed(4)
                      : "-"}
                  </td>
                  <td
                    className={`px-6 py-4 table-text-highlighted-body ${livePricePercentageColor}`}
                  >
                    %
                    {row.original?.P
                      ? parseFloat(row.original?.P).toFixed(2)
                      : "-"}
                  </td>
                  <td className={`px-6 py-4 table-text-body `}>
                    {row.original.q
                      ? "$" + Number.parseFloat(row.original?.q).toFixed(2)
                      : "-"}
                  </td>
                </>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export { TableMarketPricesCells }
