import React, { useEffect } from "react"
import LoadingData from "../models/LoadingData"
import { MonthlyTrackData } from "../shared/models/dataTrack/MonthlyTrackData"
import DataTrackApiProvider from "../shared/providers/dataTrack/DataTrackApiProvider"
import { Income } from "../shared/models/dataTrack/Income"
import { useUserOwn } from "../shared/store/useOwnUser"
import { BASE_URL } from "../shared/constants/constants"
import DataTrackProvider from "../shared/providers/dataTrack/DataTrackProvider"

export const useMonthlyBalance = (
  subscriptionId: string | undefined,
  provider: DataTrackProvider
) => {
  const { token } = useUserOwn()
  const [monthlyBalanceResponse, setMonthlyBalanceResponse] = React.useState<{
    loading: boolean
    data?: MonthlyTrackData[]
  }>({ loading: true, data: undefined })

  useEffect(() => {
    setMonthlyBalanceResponse({
      loading: true,
      data: undefined,
    } as LoadingData<MonthlyTrackData[]>)

    if (parseInt(subscriptionId ?? "-1") === -1) return

    provider
      .getMonthlyTrack(token, parseInt(subscriptionId!))
      .then((monthlyIncomeResponse: Income[]) => {
        setMonthlyBalanceResponse({
          loading: false,
          data: monthlyIncomeResponse,
        } as LoadingData<MonthlyTrackData[]>)
      })
      .catch(() => {
        setMonthlyBalanceResponse({
          loading: false,
          data: [],
        } as LoadingData<MonthlyTrackData[]>)
      })
  }, [])

  return { monthlyBalance: monthlyBalanceResponse }
}
