import { BaseSingleBorderedContainer } from "components/BaseSingleBorderedContainer"
import { ValidationFieldForm } from "components/common/forms/ValidationFieldForm"
import { FormikProps } from "formik/dist/types"
import React, { FC } from "react"

type Props = {
  className?: string
  selectedTab: TabsPositions
  dataSpot?: { apiKey: string; apiSecret: string }
  dataFutures?: { apiKey: string; apiSecret: string }
  formikFutures?: FormikProps<{
    name: string | undefined
    apiKey: string | undefined
    apiSecret: string | undefined
  }>
}

export enum TabsPositions {
  Spot = 0,
  Futures,
}

function getTabBody(
  tapPosition: TabsPositions,
  className?: string,
  dataSpot?: { apiKey: string; apiSecret: string },
  dataFutures?: { apiKey: string; apiSecret: string },
  formikFutures?: FormikProps<{
    name: string | undefined
    apiKey: string | undefined
    apiSecret: string | undefined
  }>
): React.ReactNode {
  switch (tapPosition) {
    case TabsPositions.Futures:
      return (
        <div className={`${className}`}>
          <BaseSingleBorderedContainer className="flex mt-6 bg-white border-gray-highligted-light !border-[1px]">
            <input
              className={`py-3 ring-0 focus:ring-0 px-1 flex-1 border-0  text-main-text-light placeholder-placeholder-text-dark focus:outline-none`}
              id="account_api_key"
              type="text"
              placeholder="Account API Key"
              //value={dataFutures?.apiKey}
              {...formikFutures?.getFieldProps("apiKey")}
            />
          </BaseSingleBorderedContainer>
          {formikFutures?.touched.apiKey && formikFutures?.errors.apiKey ? (
            <ValidationFieldForm
              className="mt-2"
              text={formikFutures?.errors.apiKey}
            />
          ) : null}
          <BaseSingleBorderedContainer className="flex mt-4 bg-white border-gray-highligted-light !border-[1px]">
            <input
              className={`py-3 ring-0 focus:ring-0 px-1 flex-1 border-0  text-main-text-light placeholder-placeholder-text-dark focus:outline-none`}
              id="account_api_secret"
              type="text"
              placeholder="Account API Secret"
              //value={dataFutures?.apiSecret}
              {...formikFutures?.getFieldProps("apiSecret")}
            />
          </BaseSingleBorderedContainer>
          {formikFutures?.touched.apiSecret &&
          formikFutures?.errors.apiSecret ? (
            <ValidationFieldForm
              className="mt-2"
              text={formikFutures?.errors.apiSecret}
            />
          ) : null}
          <p className="mt-4 text-sm font-medium text-placeholder-text-dark">
            Where to find this keys?
          </p>
          <p className="mt-1 text-xs font-medium text-placeholder-text-dark">
            - Open https://accounts.binance.com
            <br />- Log in to your account - Hover over profile and click “API
            Management” <br />- Give the API key a label at the top of the
            screen <br />- Click create <br />- Follow authentication
            instructions <br />- When done, copy the API keys of the newly
            created API back into the corresponding input fields above <br />-
            Done
          </p>
        </div>
      )
    case TabsPositions.Spot:
      return (
        <div className={`${className}`}>
          <BaseSingleBorderedContainer className="flex mt-4 bg-white border-gray-highligted-dark !border-[1px]">
            <input
              className={`py-3 ring-0 focus:ring-0 px-1 flex-1 border-0  text-main-text-light placeholder-placeholder-text-dark focus:outline-none`}
              id="name"
              type="text"
              placeholder="Name"
              value={dataSpot?.apiKey}
            />
          </BaseSingleBorderedContainer>
          <BaseSingleBorderedContainer className="flex mt-6 bg-white border-gray-highligted-dark !border-[1px]">
            <input
              className={`py-3 ring-0 focus:ring-0 px-1 flex-1 border-0  text-main-text-light placeholder-placeholder-text-dark focus:outline-none`}
              id="name"
              type="text"
              placeholder="Name"
              value={dataSpot?.apiSecret}
            />
          </BaseSingleBorderedContainer>
          <p className="mt-4 text-sm font-medium text-placeholder-text-light">
            Where to find this keys?
          </p>
          <p className="mt-1 text-xs font-medium text-placeholder-text-light">
            - Open https://accounts.binance.com
            <br />- Log in to your account - Hover over profile and click “API
            Management” <br />- Give the API key a label at the top of the
            screen <br />- Click create <br />- Follow authentication
            instructions <br />- When done, copy the API keys of the newly
            created API back into the corresponding input fields above <br />-
            Done
          </p>
        </div>
      )
  }
}

const ConnectExchangeModalTabs: FC<Props> = ({
  className,
  selectedTab,
  dataSpot,
  dataFutures,
  formikFutures,
}) => {
  return (
    <>
      {" "}
      {getTabBody(
        selectedTab,
        className,
        dataSpot,
        dataFutures,
        formikFutures
      )}{" "}
    </>
  )
}

export { ConnectExchangeModalTabs }
