import React, { FC } from "react"
import { LinesShimmer } from "../shimmers/LinesShimmer"
import { CircleShimmer } from "../shimmers/CircleShimmer"

type Props = {
  className?: string
  light?: boolean
  selected?: boolean
}

const PaymentOrdersNotificationPanelItemItemShimmer: FC<Props> = ({
  className,
  light = false,
  selected = false,
}) => {
  return (
    <div className={`py-8 flex items-center ${className}`}>
      <CircleShimmer classWidth="w-3" classHeight="h-3" className="mr-4" />
      <LinesShimmer nLines={1} thinLine={true} className="" />
    </div>
  )
}

export { PaymentOrdersNotificationPanelItemItemShimmer }
