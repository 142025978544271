import UsersProvider from "./UsersProvider"
import { UserOwn } from "../../models/user/UserOwn"
import ApiProvider from "../ApiProvider"

export default class UsersApiProvider
  extends ApiProvider
  implements UsersProvider
{
  constructor(
    private readonly token: string,
    private readonly baseUrl: string
  ) {
    super()
  }

  logout(): void {
    //TODO
  }

  getOwn(): Promise<UserOwn> {
    return fetch(`${this.baseUrl}api/v1/users/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf8",
        Authorization: "Bearer " + this.token,
      },
    }).then((response) => {
      super.checkErrors(response)
      return response.json() as Promise<UserOwn>
    })
  }
}
