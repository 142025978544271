import { PrimaryButton } from "components/PrimaryButton/PrimaryButton"
import { LinesShimmer } from "components/shimmers/LinesShimmer"
import React, { FC } from "react"
import {
  getButttonText,
  getSubbuttonText,
  getTitle,
  hasButton,
  hasSubbutton,
} from "../../shared/components/SubHeader/SubHeaderFunctions"
import { BotStatus } from "../../shared/components/Bot/BotStatusEmun"

enum SubHeaderType {
  BotDetails,
  Home,
  Dashboard,
  TradingTerminal,
}

type Props = {
  onButtonClick: () => void
  onSubuttonClick?: () => void
  className?: string
  type?: SubHeaderType
  title?: string
  subtitle?: string
  data?: SubHeaderModelType
  loading?: boolean
  loadingSubbutton?: boolean
  loadingMainButton?: boolean
  isSubscribed?: boolean
  botStatus?: string
}

type SubHeaderModelType = {
  avatar?: string
  title?: string
  subtitle?: string
}

const SubHeader: FC<Props> = ({
  className,
  onButtonClick,
  onSubuttonClick,
  type = SubHeaderType.Home,
  title,
  subtitle,
  data,
  loading = false,
  loadingSubbutton = false,
  loadingMainButton = false,
  isSubscribed,
  botStatus,
}) => {
  //const [avatarError, setAvatarError] = useState(false)

  /*function getImage(): React.ReactNode {
    if (hasImage(type)) {
      if (loading) {
        return (
          <CircleShimmer
            classWidth="w-16"
            classHeight="h-16"
            className="mr-4"
          />
        )
      }
      if (avatarError) {
        return (
          <DefaultAvatar className="object-cover w-16 h-16 mr-4 rounded-full" />
        )
      }
      return (
        <img
          className="object-cover w-16 h-16 mr-4 rounded-full"
          src={data?.avatar ?? ""}
          onError={() => {
            setAvatarError(true)
          }}
          alt="bot avatar"
        />
      )
    }
    return null
  }*/

  function getColorSubbutton(): string | undefined {
    switch (botStatus) {
      case BotStatus.RUNNING: {
        return "text-red-stop-bot"
      }
      case BotStatus.STOPPED: {
        return "text-secondary-2-dark"
      }
      case BotStatus.INITIAL: {
        return "text-secondary-2-dark"
      }

      default: {
        return ""
      }
    }
  }

  return (
    <div
      className={`py-5 flex-none sm:flex items-center justify-between ${className}`}
    >
      <div className="flex items-center">
        {/*{getImage()}*/}

        <div className="">
          <span className="block text-5xl font-light dark:text-white main-text-light">
            {!title && getTitle(title, type)}
          </span>

          {loading ? (
            <div className="self-center w-20">
              <LinesShimmer
                nLines={1}
                thinLine={false}
                className=""
                mRightPx={0}
              />
            </div>
          ) : (
            <>
              <span className="block text-xl font-medium dark:text-white main-text-light mr-4 mb-4 sm:mb-0">
                {data?.title}
              </span>
              <span className="block text-xl font-medium dark:text-white main-text-light mr-4 mb-8 sm:mb-0">
                {data?.subtitle}
              </span>
            </>
          )}

          {/* {data?.subtitle && (
            <span className="block text-lg font-light dark:text-white main-text-light">
          {data?.subtitle}
            </span>
          )}*/}
        </div>
      </div>

      <div className={"flex-col flex sm:flex-none sm:flex-row"}>
        {hasSubbutton(type, botStatus) && (
          <PrimaryButton
            className={"mr-0 sm:mr-4 sm:mb-0 mb-1"}
            onClick={onSubuttonClick}
            fill={false}
            loading={loadingSubbutton}
            color={getColorSubbutton()}
          >
            {getSubbuttonText(botStatus)}
          </PrimaryButton>
        )}
        {hasButton(type) && (
          <PrimaryButton
            className={"sm:mt-0 mt-1"}
            onClick={onButtonClick}
            loading={loadingMainButton}
          >
            {getButttonText(type, isSubscribed)}
          </PrimaryButton>
        )}
      </div>
    </div>
  )
}
export { SubHeader, SubHeaderType }
