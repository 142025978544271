import { FC } from "react"

type Props = {
  className?: string
  step: number
  text: string
}

const InfoPill: FC<Props> = ({ className, step, text }) => {
  return (
    <div
      className={`px-3 pt-6 pb-6 mb-8 shadow-xl rounded-md bg-gradient-to-br dark:from-info-pill-gradient1-bg-dark from-info-pill-gradient1-bg-light dark:to-info-pill-gradient2-bg-dark to-info-pill-gradient2-bg-light ${className}`}
    >
      <p className="mx-auto h-8 w-8 mb-4 rounded-full bg-primary-1-dark font-bold text-white m-auto flex items-center justify-center">
        {step}
      </p>

      <p
        className="dark:text-main-text-dark text-main-text-light text-center text-sm"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  )
}

export { InfoPill }
