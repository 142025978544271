import React, { FC, useState } from "react"
import { PendingPaymentOrder } from "../../shared/models/payments/Payments"
import { BaseModal } from "../modals/BaseModal"
import { HeaderModal } from "../modals/HeaderModal"
import { useTranslation } from "react-i18next"
import { PrimaryButton } from "../PrimaryButton"
import CopyIcon from "../../assets/copy.svg"
import { notifySuccess } from "../Toasts/ToastUtils"

type Props = {
  className?: string
  onCloseClick: () => void
  pendingPaymentOrder?: PendingPaymentOrder
}

const PendingPaymentOrderModal: FC<Props> = ({
  className,
  onCloseClick,
  pendingPaymentOrder,
}) => {
  const { t } = useTranslation()
  const [displayInstructions, setDisplayInstructions] = useState<boolean>(false)

  function getFiatSymbol(currencyFiat: string | undefined) {
    switch (currencyFiat) {
      case "EUR":
        return "€"
      case "USD":
        return "$"
      default:
        return "€"
    }
  }

  const fiatSymbol = getFiatSymbol(pendingPaymentOrder?.currency_fiat)
  const IVAAmount = (
    ((pendingPaymentOrder?.total_amount_fiat ?? 0) *
      (pendingPaymentOrder?.tax ? Number(pendingPaymentOrder.tax) : 0)) /
    100
  ).toFixed(2)
  return (
    <BaseModal
      displayBlackBg={true}
      maxWidth="!w-[100%] !max-w-[100%] md:!w-[75%] md:!max-w-[75%] lg:!w-[700px] lg:!max-w-[700px]"
    >
      <HeaderModal
        onBackClick={onCloseClick}
        onCloseClick={onCloseClick}
        title={`${t("pending_payment_order")} ${pendingPaymentOrder?.id}`}
        hasCloseIcon={true}
        hasBackArrow={false}
      />
      <div className="px-4 py-8">
        {displayInstructions ? (
          <div>
            <div className="flex">
              <p className="text-payment-modal ml-auto mr-auto text-center text-lg mb-2">
                {t("payment_instructions_1")}
              </p>
            </div>
            <div className="flex mb-24">
              <p className="text-payment-modal ml-auto mr-auto font-semibold text-lg text-center">
                {t("payment_instructions_2")}
              </p>
            </div>
            <div className="flex mb-2 md:mx-8 mx-1">
              <span className="text-payment-modal">{t("company")}</span>
              <span className="ml-auto text-payment-modal font-bold mr-8">
                Smart Fintech SL
              </span>
            </div>
            <div className="flex mb-2 md:mx-8 mx-1">
              <span className="text-payment-modal">{t("bank")}</span>
              <span className="ml-auto text-payment-modal font-bold mr-8">
                Santander
              </span>
            </div>
            <div className="flex mb-2  md:mx-8 mx-1">
              <span className="text-payment-modal align-middle">IBAN</span>
              <span className="ml-auto text-payment-modal font-bold my-auto">
                ES19 0049 0679 4128 1115 0371
              </span>

              <img
                src={CopyIcon}
                className={`h-4 ml-4 my-auto  cursor-pointer`}
                onClick={() => {
                  navigator.clipboard
                    .writeText("ES1900490679412811150371")
                    .then(() => {
                      notifySuccess(
                        t("text_copied"),
                        "!dark:text-secondary-text-light !text-secondary-text-light"
                      )
                    })
                }}
                alt={"Copy icon"}
              />
            </div>
            <div className="flex mb-24  md:mx-8 mx-1">
              <span className="text-payment-modal">{t("bank_concept")}</span>
              <span className="ml-auto text-payment-modal font-bold">
                {"BOT" + pendingPaymentOrder?.id}
              </span>

              <img
                src={CopyIcon}
                className={`h-4 ml-4 my-auto  cursor-pointer`}
                onClick={() => {
                  navigator.clipboard
                    .writeText(
                      "BOT" + pendingPaymentOrder?.id?.toString() ?? ""
                    )
                    .then(() => {
                      notifySuccess(
                        t("text_copied"),
                        "!dark:text-secondary-text-light !text-secondary-text-light"
                      )
                    })
                }}
                alt={"Copy icon"}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="flex mb-4">
              <span className="text-payment-modal">{t("period")}</span>
              <span className="ml-auto whitespace-pre">{` ${new Date(
                pendingPaymentOrder?.start_period ?? ""
              ).toLocaleDateString()}  -  ${new Date(
                pendingPaymentOrder?.end_period ?? ""
              ).toLocaleDateString()}`}</span>
            </div>
            <div className="flex mb-1">
              <span className="text-payment-modal">
                {t("service_cost.text")}
              </span>
              <span className="ml-auto text-payment-modal">{`${pendingPaymentOrder?.amount_fiat?.toFixed(
                2
              )} ${fiatSymbol}`}</span>
            </div>
            <div className="flex mb-4">
              <span className="ml-auto text-payment-modal">{`${pendingPaymentOrder?.amount_usdt?.toFixed(
                2
              )} USDT`}</span>
            </div>
            <div className="flex mb-4">
              <span className="text-payment-modal">{`${t("iva")} (${
                pendingPaymentOrder?.tax
              }%)`}</span>
              <span className="ml-auto text-payment-modal">{`${IVAAmount} ${fiatSymbol}`}</span>
            </div>
            <div className="flex mb-4">
              <span className="text-payment-modal">
                {t("cuota_sub_amount")}
              </span>
              <span className="ml-auto text-payment-modal">{`${pendingPaymentOrder?.cuota_sub_amount} ${fiatSymbol}`}</span>
            </div>
            {pendingPaymentOrder?.cuota_sub_discount !== undefined &&
              pendingPaymentOrder?.cuota_sub_discount > 0 && (
                <div className="flex mb-6">
                  <span className="text-payment-modal">
                    {t("commercial_discount")}
                  </span>
                  <span className="ml-auto text-payment-modal">{`-${pendingPaymentOrder?.cuota_sub_discount} ${fiatSymbol}`}</span>
                </div>
              )}

            <div className="flex mt-4 mb-1">
              <span className="text-highlighted-payment-modal">Total</span>
              <span className="ml-auto text-highlighted-payment-modal font-bold">
                {`${pendingPaymentOrder?.total_amount_fiat?.toFixed(
                  2
                )} ${fiatSymbol}`}
              </span>
            </div>
            <div className="flex mb-12">
              <span className="ml-auto text-highlighted-payment-modal font-bold">
                {`${pendingPaymentOrder?.total_amount_usdt?.toFixed(2)} USDT`}
              </span>
            </div>
          </div>
        )}

        <div className="flex">
          {!displayInstructions && (
            <PrimaryButton
              className="ml-auto mr-auto !px-24 mb-4"
              onClick={() => {
                setDisplayInstructions(true)
              }}
              fill={true}
            >
              {`${t("pay").toUpperCase()}`}
            </PrimaryButton>
          )}
        </div>
        <div className="flex">
          <p className="text-payment-modal text-center text-sm ml-auto mr-auto">
            {t("do_you_need_help")}
          </p>
        </div>
        <div className="flex">
          <p className="text-payment-modal text-sm ml-auto mr-auto !text-blue-text-light">
            administracion@botthemoon.com
          </p>
        </div>
      </div>
    </BaseModal>
  )
}

export { PendingPaymentOrderModal }
