import React, { FC } from "react"
import "tippy.js/dist/tippy.css"
import { BotPosition } from "../../shared/models/bot/BotInfo"
import { PositionListBot } from "./PositionListBot"
import { SearchBar } from "../Searchbar"
import { t } from "i18next"
import { SortByOptions } from "../../shared/models/bot/SortByBotDataOptions"
import InfiniteScroll from "react-infinite-scroll-component"
import { useStaticPaginatedData } from "../../hooks/useStaticPaginatedData"
import { useLivePrices } from "../../hooks/useLivePricesContext"

const PAGE_SIZE = 10
type Props = {
  isAdmin?: boolean
  className?: string
  positions: BotPosition[]
  loading?: boolean
  onClickSymbol?: (position?: BotPosition) => void
}

const PositionsListBot: FC<Props> = ({
  isAdmin = false,
  className,
  positions,
  onClickSymbol,
}) => {
  const { list, setList, next, hasMore, setHasMore, getMoreData, setCount } =
    useStaticPaginatedData<BotPosition>(positions, PAGE_SIZE)
  const { symbols } = useLivePrices()
  const [sortByOption, setSortBy] = React.useState(
    SortByOptions.DcasDownToUp.toString()
  )

  function sortBy() {
    switch (sortByOption) {
      case SortByOptions.Symbol:
        return list.sort((a, b) => a.symbol?.localeCompare(b.symbol ?? "") ?? 0)
      case SortByOptions.UnrealizedPNLDownToUp:
        return list.sort(
          (a, b) => (a.unrealizedProfit ?? 0) - (b.unrealizedProfit ?? 0)
        )
      case SortByOptions.UnrealizedPNLUpToDown:
        return list.sort(
          (a, b) => (b?.unrealizedProfit ?? 0) - (a?.unrealizedProfit ?? 0)
        )
      case SortByOptions.DcasDownToUp:
        return list.sort((a, b) => (a?.dcas ?? 0) - (b?.dcas ?? 0))
      case SortByOptions.DcasUpToDown:
        return list.sort((a, b) => (b?.dcas ?? 0) - (a?.dcas ?? 0))
      default:
        return list
    }
  }

  sortBy()
  return (
    <div className={`pt-6 dark:bg-bg-dark bg-white ${className}`}>
      <div className="flex flex-wrap">
        <SearchBar
          className="w-28 mx-3 lg:mx-12 mb-4"
          placeholder={t("symbol.text")}
          onChangeSearchText={(newValue) => {
            setCount({
              prev: 0,
              next: PAGE_SIZE,
            })
            setHasMore(true)
            setList(
              positions.filter((position) =>
                position?.symbol?.toLowerCase().includes(newValue.toLowerCase())
              )
            )
          }}
          autoComplete={false}
        />
        <div className="mx-3 lg:mx-12 ">
          <select
            style={{
              boxShadow: "none",
            }}
            className="dropdown"
            name="order"
            onChange={(event) => setSortBy(event.target.value)}
            defaultValue={SortByOptions.DcasUpToDown}
          >
            <option value={SortByOptions.Symbol}>
              {t<string>("sort_by_symbol.text")}
            </option>
            <option value={SortByOptions.UnrealizedPNLUpToDown}>
              {t<string>("sort_by_unrealized_pnl_up_to_down.text")}
            </option>
            <option value={SortByOptions.UnrealizedPNLDownToUp}>
              {t<string>("sort_by_unrealized_pnl_down_to_up.text")}
            </option>
            <option value={SortByOptions.DcasUpToDown}>
              {t<string>("sort_by_dcas_up_to_down.text")}
            </option>
            <option value={SortByOptions.DcasDownToUp}>
              {t<string>("sort_by_dcas_down_to_up.text")}
            </option>
          </select>
        </div>
      </div>
      <div className={`grid grid-cols-1`}>
        {list.length > 0 && (
          <InfiniteScroll
            dataLength={next}
            next={getMoreData}
            hasMore={hasMore}
            loader={<></>}
            scrollableTarget="baseScroll"
          >
            {list.slice(0, next)?.map((position) => (
              <div key={position.id}>
                <PositionListBot
                  className="mx-3 lg:mx-12"
                  position={position}
                  livePrice={symbols?.find((m) => m.s === position.symbol)}
                  onClickSymbol={(positionClick) => {
                    onClickSymbol?.(positionClick)
                  }}
                  isAdmin={isAdmin}
                />
                <div className="w-full h-px dark:bg-divider-bg-lighter-dark bg-divider-bg-lighter-light" />
              </div>
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  )
}

export { PositionsListBot }
