import CountryProvider from "./CountryProvider"
import { Countries, Country } from "../../models/country/Country"
import ApiProvider from "../ApiProvider"

export default class CountryApiProvider
  extends ApiProvider
  implements CountryProvider
{
  constructor(private baseUrl: string) {
    super()
  }
  async getCountries(): Promise<{ countries: Country[] }> {
    return fetch(`${this.baseUrl}api/v1/countries/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf8",
      },
    }).then((response) => {
      super.checkErrors(response)
      return response.json() as Promise<{ countries: Country[] }>
    })
  }
}
