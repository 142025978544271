import React, { FC } from "react"

type Props = {
  maxWidth?: string
  className?: string
  displayBlackBg?: boolean
  children: React.ReactNode
}

const BaseModal: FC<Props> = ({
  maxWidth,
  className,
  children,
  displayBlackBg = true,
}) => {
  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex items-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none ${className}`}
      >
        <div className={`max-w-[90%] relative mx-auto ${maxWidth}`}>
          <div className="relative w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
            {children}
          </div>
        </div>
      </div>
      {displayBlackBg && (
        <div className="fixed inset-0 z-40 bg-black opacity-50"></div>
      )}
    </>
  )
}

export { BaseModal }
