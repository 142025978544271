import { ExchangeAccount } from "models/exchangeAccount/ExchangeAccount"
import React, { FC, useState } from "react"
import { ConnectAccountsModal } from "../ConnectAccountsModal"
import { ConnectExchangeAccountModal } from "../ConnectExchangeAccountModal"

let connectAccountsPagerModalToggle: () => void

type Props = {
  onExchangeAddedSuccessListener?: (exchangeAccount: ExchangeAccount) => void
}

const ConnectAccountsPagerModal: FC<Props> = ({
  onExchangeAddedSuccessListener,
}) => {
  const [modalFormPage, setModalFormPage] = useState<number>(-1)

  const onExchangeAddedSuccess = (exchangeAccount: ExchangeAccount): void => {
    setModalFormPage(-1)
    onExchangeAddedSuccessListener?.(exchangeAccount)
  }

  const onExchangeEditedSuccess = (exchangeAccount: ExchangeAccount): void => {
    setModalFormPage(-1)
  }

  const onCreateExchangeAccountModalClick = () => {
    //TODO:
  }

  const onCloseConnectYourAccountModal = () => {
    setModalFormPage(-1)
  }

  const onConnectExchangesAccountModal = () => {
    setModalFormPage(1)
  }

  const onCloseConnectExchangesAccountModal = () => {
    setModalFormPage(0)
  }

  connectAccountsPagerModalToggle = () => {
    if (modalFormPage === -1) {
      setModalFormPage(0)
      return
    }
    setModalFormPage(-1)
  }
  return (
    <>
      {modalFormPage === 0 && (
        <ConnectAccountsModal
          onCloseClick={onCloseConnectYourAccountModal}
          onBackClick={onCloseConnectYourAccountModal}
          onConnectClick={onConnectExchangesAccountModal}
          onCreateClick={onCreateExchangeAccountModalClick}
        />
      )}
      {modalFormPage === 1 && (
        <ConnectExchangeAccountModal
          onExchangeAddedSuccess={onExchangeAddedSuccess}
          onCloseClick={onCloseConnectExchangesAccountModal}
          onBackClick={onCloseConnectExchangesAccountModal}
          onRightClick={() => {}}
          onLeftClick={onCloseConnectExchangesAccountModal}
        />
      )}
    </>
  )
}

export { ConnectAccountsPagerModal, connectAccountsPagerModalToggle }
