import { BaseSingleBorderedContainer } from "components/BaseSingleBorderedContainer"
import { Checkbox } from "components/common/forms/Checkbox"
import { PrimaryButton } from "components/PrimaryButton"
import React, { FC } from "react"
import { BaseModal } from "../BaseModal"
import { HeaderModal } from "../HeaderModal"

type Props = {
  className?: string
  onBackClick: () => void
}

const GiveFeedbackModal: FC<Props> = ({ className, onBackClick }) => {
  return (
    <BaseModal displayBlackBg={true} maxWidth="md:!max-w-[70%]">
      <HeaderModal
        onBackClick={onBackClick}
        title={"Give feedback"}
        subtitle={
          "Tell us about new features we should consider or how we can improve existing features."
        }
        hasCloseIcon={false}
        hasBackArrow={true}
      />
      <div className="overflow-scroll max-h-[70vh] sm:max-h-[80vh]">
        <BaseSingleBorderedContainer className="flex mt-6 mx-28 dark:bg-white dark:border-gray-highligted-light !border-[1px]">
          <input
            className={`py-3 ring-0 focus:ring-0 px-1 flex-1 border-0  text-main-text-light placeholder-placeholder-text-light focus:outline-none`}
            id="title_key"
            type="text"
            placeholder="title"
          />
        </BaseSingleBorderedContainer>
        <BaseSingleBorderedContainer className="flex mt-6 mx-28 dark:bg-white dark:border-gray-highligted-light !border-[1px]">
          <textarea
            className={`h-48 py-3 ring-0 focus:ring-0 px-1 flex-1 border-0  text-main-text-light placeholder-placeholder-text-light focus:outline-none`}
            id="description_key"
            placeholder="Description of your idea"
          />
        </BaseSingleBorderedContainer>
        <BaseSingleBorderedContainer className="flex mt-6 mx-28 dark:bg-white dark:border-gray-highligted-light !border-[1px]">
          <input
            className={`py-3 ring-0 focus:ring-0 px-1 flex-1 border-0  text-main-text-light placeholder-placeholder-text-light focus:outline-none`}
            id="your_name_key"
            type="text"
            placeholder="Your name"
          />
        </BaseSingleBorderedContainer>
        <BaseSingleBorderedContainer className="flex mt-6 mx-28 dark:bg-white dark:border-gray-highligted-light !border-[1px]">
          <input
            className={`py-3 ring-0 focus:ring-0 px-1 flex-1 border-0  text-main-text-light placeholder-placeholder-text-light focus:outline-none`}
            id="your_email_key"
            type="text"
            placeholder="Your email"
          />
        </BaseSingleBorderedContainer>
        <Checkbox
          className="mt-6 mx-28 "
          light={true}
          checked={false}
          label="Notify me if people comment on my idea"
        />
        <div className="flex flex-wrap justify-center pb-6 mt-12">
          <PrimaryButton className="mr-2" onClick={() => {}} fill={false}>
            Cancel
          </PrimaryButton>
          <PrimaryButton className="ml-2" onClick={() => {}} fill={true}>
            Submit
          </PrimaryButton>
        </div>
      </div>
    </BaseModal>
  )
}

export { GiveFeedbackModal }
