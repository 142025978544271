import React, { FC } from "react"
import DiscordIcon from "../icons/DiscordIcon"

type Props = {
  className?: string
  onClick: () => void
}

const DiscordButton: FC<Props> = ({ className, onClick }) => {
  return (
    <button
      type="button"
      className={`text-white bg-gradient-to-r from-primary-gradien1-dark to-primary-gradien2-dark focus:outline-none py-2.5 px-4 transform hover:shadow-lg rounded-t-md ${className}`}
      onClick={onClick}
    >
      <div className="flex">
        <DiscordIcon
          onClick={onClick}
          className="self-center mr-2 text-white fill-current"
        />
        <p>Discord</p>
      </div>
    </button>
  )
}

export { DiscordButton }
