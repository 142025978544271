import React, { FC } from "react"

type Props = {
  className?: string
  content: string
}

export type TooltipProps = {}

const Tooltip: FC<Props> = ({ className, content }) => {
  return (
    <div
      className={`p-4 max-w-[300px] min-w-10 rounded-md bg-white shadow-3xl ${className}`}
    >
      <p className={"text-sm text-main-text-light"}>{content}</p>
    </div>
  )
}

export { Tooltip }
