import { PrimaryButton } from "components/PrimaryButton"
import React, { FC } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import LogoText from "../../../assets/logo-text-long-circle-white.png"

const LiteSignUpSuccessPage: FC = () => {
  const { t } = useTranslation()

  const history = useNavigate()

  return (
    <>
      <div className="flex h-screen dark:bg-bg-dark">
        <div className="m-auto sm:w-[32rem] w-full pl-4 pr-4 ">
          <div className="flex justify-center">
            <div>
              <a href="/">
                <img
                  src={LogoText}
                  className={"w-56 sm:w-72 mb-6"}
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <div className="w-full self-center px-6 py-8 rounded shadow-2xl bg-container-panel-bg-light dark:bg-container-panel-bg-dark">
            <div className="mt-4 text-center text-white font-medium text-xl">
              <p className="mb-2">{t("lite_sign_up_success_page.header")}</p>
              <p className="mb-2">{t("lite_sign_up_success_page.body")}</p>
            </div>

            <PrimaryButton
              loading={false}
              className="h-10 mt-16 w-full !py-0"
              onClick={() => history("/login")}
            >
              <p>{t("lite_sign_up_success_page.button")}</p>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  )
}

export { LiteSignUpSuccessPage }
