import React, { FC } from "react"
import { useTranslation } from "react-i18next"

const PrivacyPolicyPage: FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="dark:bg-bg-dark p-8">
        <h1 className="title-legal">PRIVACY POLICY</h1>
        <p className="text-legal">
          SMART FINTECH SL (&quot;SMART &quot;, &quot;we&quot;, &quot;us&quot;,
          &quot;our &quot; or &quot;us &quot;) collects information about you
          from various sources to provide you with the Services, to protect our
          legitimate interests, to analyze and improve our Services, to
          communicate with you and to comply with our legal and regulatory
          obligations. This Privacy Policy (&quot;Privacy Policy&quot;) covers
          our treatment of the personally identifiable information we collect
          when you access or use our Services.
        </p>
        <p className="text-legal">
          From time to time, we may revise or modify this Privacy Policy to
          reflect changes in the law, our Personal Data collection and use
          practices, features of the Services, or advances in technology. This
          Privacy Policy does not cover the practices of entities we do not own
          or control, or of persons we do not direct.
        </p>
        <p className="text-legal">
          Our Privacy Policy applies to all Services we offer, but excludes any
          products, applications or services that have separate privacy policies
          that do not incorporate this Privacy Policy. For the purposes of the
          data protection laws of the European Union (&quot;EU&quot;), and other
          relevant jurisdictions where Customers are located (&quot;Data
          Protection Laws&quot;), SMART is a data controller (i.e., the company
          that is responsible for and controls the processing of your Personal
          Data).
        </p>
        <p className="text-legal">
          Your access and/or use of our Services constitutes acceptance of this
          Privacy Policy, as well as the terms and provisions contained in the
          Terms of Service Agreement. Terms used in this Privacy Policy shall
          have the same meaning as in the Terms of Service, unless otherwise
          stated herein. For the avoidance of doubt, the term
          &quot;Services&quot; refers to all services provided by SMART,
          including, but not limited to the{" "}
          <a href="https://www.botthemoon.com">https://www.botthemoon.com</a>{" "}
          websites,{" "}
          <a href="http://app.botthemoon.com">http://app.botthemoon.com</a>, the
          desktop and mobile applications, SMART&apos;s Discord and Telegram
          accounts; any other services provided by SMART from time to time (the
          &quot;Services&quot;).
        </p>
        <br />
        <p className="text-legal">
          <strong>What Data We Collect?</strong>
        </p>
        <p className="text-legal">
          To the extent permitted by applicable data protection laws, we collect
          the following data and information (collectively, &quot;Personal
          Data&quot;):
        </p>
        <ol className="text-legal">
          <li>
            full name, date of birth, gender, country of residence, username,
            email, passwords, tax identification numbers, and data included in
            government-issued identification documents;
          </li>
          <li>
            Financial information, sources of wealth or funds or income,
            expected level of activity, distributed ledger network wallet
            addresses; 3;
          </li>
          <li>
            Personal documents such as government-issued identification
            documents, passport, bank statements, utility bills, internet bills,
            income, account balances, financial transaction history, credit
            history, tax information and credit scores, and other forms of
            identification, address verification, and source of funds and wealth
            verification;
          </li>
          <li>
            Corporate information, such as legal entity name, contact email,
            company name, legal address, tax address, object;
          </li>
          <li>
            Corporate documents such as certificate of registration,
            incorporation, articles of incorporation, bylaws, certificate of
            title, register of directors, register of shareholders, register of
            authorized signatories, register extracts, financial statements;
          </li>
          <li>
            Information about how you access and use our Services, such as your
            actions on the Services, including your interactions with others on
            the Services, photos or media you upload to the Services, your
            username and other Customer Content you provide. We may access your
            device&apos;s camera roll and camera with your permission. If you
            need to update your permissions, you can do so in your device
            settings;
          </li>
          <li>
            Contact lists on your mobile device or in your email accounts so
            that you can identify your contacts on the Services and direct
            invite your contacts. We only access your contact list information
            with your explicit permission;
          </li>
          <li>
            Communications between SMART and you, including emails, social media
            posts, job applications, customer service inquiries;
          </li>
          <li>
            Current and historical information regarding your geographic
            location, GPS location, transaction location and IP addresses that
            you use when using and/or accessing our Services. We may use third
            party services, such as the Google Maps API, to collect information
            about your location. Google uses various technologies to determine
            your location, such as IP address, GPS and other sensors that may,
            for example, provide Google with information about nearby devices,
            Wi-Fi hotspots and cell towers (see Google&apos;s Privacy Policy for
            more information);
          </li>
          <li>
            Information about your device and software, such as your IP address,
            browser type, Internet service provider, platform type, device type,
            operating system, operating system, date and time, a unique
            identifier (which allows us to uniquely identify your browser,
            mobile device or account) and other similar information.
          </li>
        </ol>
        <p className="text-legal">
          We may monitor, record and store your personal information to protect
          your safety or the safety of other Customers, comply with applicable
          laws, assist in regulatory or law enforcement efforts, to protect and
          defend our rights and property, or for other reasons related to our
          provision of the Services. By using the Services, you consent to the
          recording, storage and disclosure of information you submit or receive
          for these purposes.
        </p>
        <p className="text-legal">
          The Services are not available to minors under the age of eighteen
          (18) (&quot;Minors&quot;). SMART has implemented appropriate controls
          to ensure that Minors do not use or access our Services. Therefore,
          SMART will never knowingly and intentionally collect Personal Data
          from Minors. If you are a parent or guardian of a Child and believe or
          have knowledge that a Child has provided us with Personal Data, please
          contact us immediately. If we become aware that we have collected
          Personal Data from Children with or without verification of parental
          consent, we will take steps to delete that information from our
          systems.
        </p>
        <br />
        <p className="text-legal">
          <strong>How do we collect Personal Data?</strong>
        </p>
        <p className="text-legal">
          To the extent permitted by applicable data protection laws, we collect
          Personal Data about you and any other person whose information you
          provide to us when:
        </p>
        <ol className="text-legal">
          <li>You access or use our Services;</li>
          <li>You create an account;</li>
          <li>
            You fill out online forms (including callback requests), participate
            in surveys, post in any forums, download information such as white
            papers or other publications, or participate in any other
            interactive areas appearing on our Services;
          </li>
          <li>Interact with us using social media;</li>
          <li>
            You provide your contact details when you register to use or access
            any of the services we make available to you or when you update such
            details; and
          </li>
          <li>
            Contact us or otherwise connect with us, whether online or offline.
          </li>
        </ol>
        <p className="text-legal">
          We will also collect Personal Data when you partially complete and/or
          abandon any information entered through our Services and/or other
          online forms and may use this information to contact you to remind you
          to complete any outstanding information and/or for marketing purposes.
        </p>
        <p className="text-legal">
          We may also collect information from your devices (including mobile
          devices) and the applications you or your users use to access and use
          our Services (for example, we may collect device number and type,
          location information and login information, such as statistics about
          page views, traffic to and from the Services, referring URL, ad data,
          your Internet Protocol (IP) address, your browsing history and your
          web log information) and we will ask your permission before doing so.
          We may do so using cookies or similar technologies (as described
          below).
        </p>
        <p className="text-legal">
          If you intend to provide us with Personal Data of another person, you
          are responsible for ensuring that you comply with the obligations and
          consent of applicable Data Protection Laws. To the extent required by
          applicable Data Protection Laws, you should ensure that you have your
          prior explicit consent to do so and explain how we collect, use,
          disclose and retain your Personal Data, or direct you to read our
          Privacy Policy.
        </p>
        <br />
        <p className="text-legal">
          <strong>How do we use your Personal Data?</strong>
        </p>
        <p className="text-legal">
          To the extent permitted by applicable data protection laws, we use
          your information to:
        </p>
        <ol className="text-legal">
          <li>
            provide any information or Services you have requested or ordered;
          </li>
          <li>
            Compare information for accuracy and verify it with third parties;
          </li>
          <li>Provide, maintain, protect and improve our Services; 4;</li>
          <li>
            Manage, monitor and administer your use of the Services and provide
            an enhanced and personal user experience;
          </li>
          <li>
            Manage our relationship with you (e.g., customer service and
            technical support activities);
          </li>
          <li>
            Conduct internal testing of the Services or systems to test and
            improve their security and performance (in these circumstances, we
            would delete any information used for such testing purposes);
          </li>
          <li>
            To provide you with any information that we are required to send to
            you in order to comply with our regulatory or legal obligations;
          </li>
          <li>
            Comply with applicable Know Your Customer, Anti-Money Laundering,
            Anti-Terrorist Financing and Anti-Corruption laws and regulations;
          </li>
          <li>
            Detect, prevent, investigate or remedy, crimes, Illegal or
            Prohibited Activities or to otherwise protect our legal rights
            (including liaising with regulators and law enforcement agencies for
            these purposes);
          </li>
          <li>
            Contact you to see if you would like to participate in our customer
            research (e.g., feedback about your use of our Services);
          </li>
          <li>
            Monitor, conduct statistical analysis and benchmarking (provided
            that in such circumstances this will be on an aggregate basis which
            will not be linked to you or any living individual);
          </li>
          <li>
            To provide advertising, marketing (including but not limited to
            in-product messages) or information that may be useful to you;
          </li>
          <li>
            Provide joint content and services with third parties with whom you
            have an independent relationship (e.g., social media providers); and
          </li>
          <li>
            In addition to the legal and business uses listed above, we may be
            required to provide any and all of your Personal Data to government
            authorities as necessary to comply with the law. To the extent
            required by data protection laws, or where we have a legitimate and
            lawful purpose to do so, we retain information about you after you
            withdraw funds through the Services, the closure of your Account, if
            an Account registration is declined, or if you decide not to proceed
            with an Account registration.
          </li>
        </ol>
        <p className="text-legal">
          Our Services may contain technology that allows us to:
        </p>
        <ol className="text-legal">
          <li>
            check your device-specific information or systems directly related
            to your use of the Services, applications or Services against our
            logs to ensure that the Services&apos; services are being used in
            accordance with our end user agreements and to troubleshoot any
            problems;
          </li>
          <li>
            Obtain information related to any technical errors or other problems
            with our Services;
          </li>
          <li>
            Collect information about your use of our Services features; and 4.
          </li>
          <li>
            Collect statistical information about the operating system and
            environment from which you access our Services.
          </li>
        </ol>
        <p className="text-legal">
          If you become a follower on SMART&apos;s social networks, the
          processing of Personal Data will be governed by the policies of the
          Social Network, as well as by its terms of use, privacy policies and
          regulations of the social network that apply in each case and that you
          have previously accepted.
        </p>
        <p className="text-legal">
          SMART will process your Personal Data for the purposes of properly
          managing your presence in the social network, informing you of
          SMART&apos;s activities, Services, as well as for any other purpose
          that the regulations of the social networks allow.
        </p>
        <p className="text-legal">
          In no case SMART will use the profiles of the followers in the social
          networks to send advertising on an individual basis.
        </p>
        <p className="text-legal">
          Parties we may share your Personal Data with
        </p>
        <ol className="text-legal">
          <li>
            third party internet service and marketing providers, including but
            not limited to MailChimp, Sendinblue, Active Campaign, Facebook,
            Twitter, Discord, Telegram, GoogleCloud, Google Analytics;
          </li>
          <li>
            Governmental and state authorities to respond to subpoenas, court
            orders, legal process, law enforcement requests, legal claims or
            governmental investigations and to protect and defend the rights,
            interests, safety and security of SMART, our affiliates, customers
            or the public;
          </li>
          <li>SMART entities and SMART affiliated entities;</li>
          <li>
            Third parties in connection with corporate transactions such as the
            sale of a website, a merger, consolidation, reorganization,
            financing, change or control or acquisition of all or a portion of
            our business by another company or third party, sale of assets,
            initial public offering, or in the unlikely event of bankruptcy or
            similar proceeding.
          </li>
        </ol>
        <br />
        <p className="text-legal">
          <strong>Retention of Data</strong>
        </p>
        <p className="text-legal">
          We will retain your Personal Data for as long as necessary to perform
          a contract, comply with our legal and regulatory obligations, and
          protect our legitimate interests. In particular, we reserve the right
          to retain your Personal Data for the purposes of complying with
          applicable Know Your Customer, Anti-Money Laundering, Anti-Terrorist
          Financing and Anti-Corruption laws and regulations for a period of at
          least seven (7) years, and for the purposes of legal proceedings,
          claims and disputes for as long as necessary to protect our legitimate
          interests.
        </p>
        <br />
        <p className="text-legal">
          <strong>Persons who may access your Personal Data</strong>
        </p>
        <p className="text-legal">
          Authorized SMART personnel, including but not limited to our
          Compliance Officers, will have access to your Personal Data on a
          need-to-know basis. Our Compliance Officers and other authorized
          personnel are subject to confidentiality and non-disclosure agreements
          and strict company policies regarding access to and use of data.
        </p>
        <p className="text-legal">
          We may from time to time engage third party service providers to
          assist SMART in the implementation of the KYC/AML and CTF Policy, as
          well as compliance with applicable Know Your Customer, Anti-Money
          Laundering, Counter-Terrorism Financing and Anti-Corruption laws and
          regulations. Where applicable, we will enter into a data processing
          agreement with these third parties to ensure data security and
          protection of your Personal Data against data beaches. Such data
          processors will only process your Personal Data to the extent
          necessary for the provision of the services for which they have been
          engaged.
        </p>
        <p className="text-legal">
          We may provide your Personal Data to competent authorities upon their
          request to the extent legally necessary or to the extent necessary to
          defend our rights in legal proceedings or investigations.
        </p>
        <br />
        <p className="text-legal">
          <strong>Data Security</strong>
        </p>
        <p className="text-legal">
          To protect your Personal Data, SMART takes all reasonable precautions
          and follows industry best practices to prevent the loss, misuse,
          improper access, disclosure, alteration or destruction of your
          Personal Data.
        </p>
        <p className="text-legal">
          In addition to the purposes described in this section, we may also use
          the information we collect to provide you with targeted,
          interest-based advertising, marketing (including in-product messaging)
          or information that may be useful based on your use of the Services or
          other information we have about you (depending on the Services, you
          may be able to configure these features to match your preferences).
        </p>
        <p className="text-legal">
          In the EU, we collect your Personal Data for the following purposes:
        </p>
        <ol className="text-legal">
          <li>
            Based on your consent, for marketing purposes, which may be revoked
            at any time;
          </li>
          <li>
            Where necessary to perform any contract we enter into or have
            entered into with you to provide services to you or provide you with
            access to our services;
          </li>
          <li>
            Where it is necessary for our legitimate business interests (or
            those of a third party) where your interests and fundamental rights
            do not override those interests; and
          </li>
          <li>
            Where we need to comply with a legal or regulatory obligation,
            whether in the EU or elsewhere.
          </li>
        </ol>
        <p className="text-legal">You have the following rights:</p>
        <ol className="text-legal">
          <li>The right to access your own Personal Data;</li>
          <li>
            The right to have your Personal Data rectified if it is inaccurate
            or incomplete;
          </li>
          <li>
            The right to request the erasure or deletion of your Personal Data
            when there is no valid reason for the processing to continue;
          </li>
          <li>
            The right to restrict the processing of your Personal Data when
            there is no good reason for the processing to continue; 4. The right
            to restrict the processing of your Personal Data when there is no
            good reason for the processing to continue;
          </li>
          <li>
            The right to data portability to allow the moving, copying or
            transfer of Personal Data from one platform to another;
          </li>
          <li>
            The right to object to the processing of your Personal Data in
            certain circumstances;&nbsp;
          </li>
          <li>
            Rights relating to profiling and automated decision making arising
            from the processing of your Personal Data.
          </li>
        </ol>
        <p className="text-legal">
          The exercise of these rights is personal and therefore must be
          exercised directly by the data subject, requesting it directly to
          SMART, which means that any Customer, user, subscriber or collaborator
          who has provided his/her data at any time may contact SMART and
          request information about the data it has stored and how it was
          obtained, request the rectification of such data, request the
          portability of his/her Personal Data, object to the processing, limit
          its use or request the cancellation of such data in SMART&apos;s
          files.
        </p>
        <p className="text-legal">
          To exercise your rights of access, rectification, cancellation,
          portability and opposition, you must send an email to SMART&apos;s
          Data Protection Officer legal@botthemoon.com together with a valid
          proof of identity such as a government-issued identity document.
        </p>
        <br />
        <p className="text-legal">
          <strong>Content of other Services</strong>
        </p>
        <p className="text-legal">
          The Services may include embedded content (e.g., videos, images,
          articles, etc.). Embedded content from other websites behaves exactly
          as if you had visited other websites.
        </p>
        <p className="text-legal">
          These Internet websites may collect data about you, use cookies, embed
          additional third-party tracking code and monitor your interaction
          using this code.
        </p>
        <br />
        <p className="text-legal">
          <strong>Cookie Policy</strong>
        </p>
        <p className="text-legal">
          Our Services use cookies. When you access our Services we will inform
          you, via a pop-up banner, of our use of cookies.
        </p>
        <ol className="text-legal">
          <li>
            <strong>About cookies</strong>
          </li>
        </ol>
        <p className="text-legal">
          Cookies are files, often with unique identifiers, that web servers
          send to web browsers and that may be sent back to the server each time
          the browser requests a page from the server.
        </p>
        <p className="text-legal">
          Web servers use cookies to identify and track users as they navigate
          through the different pages of the Services, as well as to identify
          users who return to the Services.
        </p>
        <p className="text-legal">
          Cookies can be &quot;persistent&quot; or &quot;session&quot; cookies.
          A persistent cookie consists of a text file sent by a web server to an
          Internet browser, which is stored by the browser and remains valid
          until the defined expiration date (unless deleted by the user before
          the expiration date). In contrast, a session cookie expires at the end
          of the user&apos;s session, when the Internet browser is closed.
        </p>
        <br />
        <br />
        <br />
        <ol className="text-legal">
          <li>
            <strong>Services Cookies</strong>
          </li>
        </ol>
        <p className="text-legal">
          On our Services, including our Website, and mobile applications, we
          use session and persistent cookies:
        </p>
        <ol className="text-legal">
          <ol className="text-legal">
            <li>Session cookies</li>
            <li>
              Google Analytics: This cookie allows us to identify unique users,
              unique sessions, regulate the rate of requests and store
              information about user sessions and campaigns.
            </li>
          </ol>
        </ol>
        <p className="text-legal">
          The first cookie is a session cookie, while the second cookie is a
          persistent cookie.
        </p>
        <br />
        <ol className="text-legal">
          <li>
            <strong>How we use cookies</strong>
          </li>
        </ol>
        <p className="text-legal">
          Cookies do not contain personally identifiable information, but we
          have the ability to link the personal data we store about you with the
          information obtained and stored from cookies.
        </p>
        <p className="text-legal">
          We use the information we obtain from the use of our cookies for the
          following purposes:
        </p>
        <ol className="text-legal">
          <ol className="text-legal">
            <li>
              To recognize your computer when you access and/or use our
              Services.
            </li>
            <li>Improve the usability of the Services.</li>
            <li>Analyze the use of our Services.</li>
            <li>Manage the Services.</li>
            <li>Third Party Cookies</li>
          </ol>
        </ol>
        <p className="text-legal">
          When you use the Services, you may also be sent third party cookies.
          Our service providers may send you cookies. They use the information
          they obtain through their cookies for the following purposes:
        </p>
        <ol className="text-legal">
          <ol className="text-legal">
            <li>Track your browser across different Services.</li>
            <li>Create a profile of your Internet browsing.</li>
            <li>Select specific advertisements that may be of interest.</li>
          </ol>
        </ol>
        <br />
        <ol className="text-legal">
          <li>
            <strong>Deleting and Blocking Cookies</strong>
          </li>
        </ol>
        <p className="text-legal">
          You may, at any time, restrict, block or delete cookies from the
          Services. To do so, you must modify your browser settings regarding
          the use of cookies through the &quot;Preferences&quot;,
          &quot;Options&quot; or &quot;Tools&quot; menu (the name of the menus
          or the procedure for accessing the cookie options vary depending on
          the browser used). Most browsers allow you to refuse cookies and
          delete them.&nbsp;
        </p>
        <p className="text-legal">
          The methods for doing so vary from one browser to another and from one
          version to another. However, you can obtain up-to-date information on
          blocking and deleting cookies through these links:
        </p>
        <ul className="text-legal">
          <li>https://support.google.com/chrome/answer/95647 (Chrome);</li>
          <li>
            https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-Services-preferences
            (Firefox);
          </li>
          <li>
            https://help.opera.com/en/latest/security-and-privacy/ (Opera);
          </li>
          <li>
            https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
            (Internet Explorer);
          </li>
          <li>
            https://support.apple.com/en-gb/guide/safari/manage-cookies-and-Services-data-sfri11471/mac
            (Safari);
          </li>
          <li>
            https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
            (Edge).
          </li>
        </ul>
        <br />
        <p className="text-legal">
          <strong>Browsing</strong>
        </p>
        <p className="text-legal">
          When accessing and/or using the Services, non-identifying data may be
          collected, which may include IP address, geolocation, a record of how
          the Services and Services are used, browsing habits and other data
          that cannot be used to identify the User.
        </p>
        <p className="text-legal">
          SMART uses the information collected to gather statistical data,
          analyze trends, administer the Services, study browsing patterns and
          gather demographic information.
        </p>
        <br />
        <p className="text-legal">
          <strong>Accuracy and Accuracy of Personal Information</strong>
        </p>
        <p className="text-legal">
          You agree that the information provided to SMART is correct, complete,
          accurate and current. You are solely responsible for the truthfulness
          and accuracy of the data you submit when accessing and/or using the
          Services, exonerating SMART from any liability in this regard.
        </p>
        <br />
        <p className="text-legal">
          <strong>Acceptance and consent</strong>
        </p>
        <p className="text-legal">
          You declare that you have been informed of the conditions on Personal
          Data protection, accept and consent to the processing of such data by
          SMART in the manner and for the purposes indicated in this Privacy
          Policy.
        </p>
        <br />
        <p className="text-legal">
          <strong>Revocability</strong>
        </p>
        <p className="text-legal">
          To exercise your rights of access, rectification, cancellation,
          portability and opposition, you must send an email to
          legal@botthemoon.com together with your valid identity document, such
          as an identification document issued by the Government. The exercise
          of your rights does not include Personal Data that SMART is required
          to retain for administrative, contract enforcement, legal or security
          purposes.
        </p>
        <br />
        <p className="text-legal">
          <strong>Change of Operator</strong>
        </p>
        <p className="text-legal">
          In the event of incorporation, acquisition, merger or any other cause
          that results in a change of Operator of the Services, you expressly
          consent to the transfer of your registration data and information by
          SMART to the new Operator. When and if this occurs, SMART will, in any
          event, comply with its duty of disclosure to you.
        </p>
        <br />
        <br />
        <br />
        <br />
        <p className="text-legal">
          <strong>Changes to Privacy Policy</strong>
        </p>
        <p className="text-legal">
          SMART reserves the right to change this Privacy Policy to conform to
          new legislation and industry practice. SMART may notify you of changes
          to this Privacy Policy by email or through the Services.
        </p>
        <br />
        <p className="text-legal">
          <strong>Operator of the Services</strong>
        </p>
        <p className="text-legal">
          The Services are operated by SMART FINTECH SL, a Spanish limited
          liability company with registered office in Las Rozas de Madrid
          (28290), calle Chile n&ordm; 10 oficina 123.&nbsp;
        </p>
        <br />
        <p className="text-legal">
          <strong>Contact</strong>
        </p>
        <p className="text-legal">
          In case you have any questions or complaints about the privacy policy,
          you can contact our data protection officer by e-mail to{" "}
          <a href="mailto:legal@botthemoon.com">legal@botthemoon.com</a>.
        </p>
        {/*<h1>Terms and Conditions</h1>
        <h1>General Site Usage</h1>
        <p className="text-legal">Last Revised: December 16, 2013</p>
        <p className="text-legal">
          Welcome to www.lorem-ipsum.info. This site is provided as a service to
          our visitors and may be used for informational purposes only. Because
          the Terms and Conditions contain legal obligations, please read them
          carefully.
        </p>
        <h2>1. YOUR AGREEMENT</h2>
        <p className="text-legal">
          By using this Site, you agree to be bound by, and to comply with,
          these Terms and Conditions. If you do not agree to these Terms and
          Conditions, please do not use this site.
        </p>
        <blockquote>
          PLEASE NOTE: We reserve the right, at our sole discretion, to change,
          modify or otherwise alter these Terms and Conditions at any time.
          Unless otherwise indicated, amendments will become effective
          immediately. Please review these Terms and Conditions periodically.
          Your continued use of the Site following the posting of changes and/or
          modifications will constitute your acceptance of the revised Terms and
          Conditions and the reasonableness of these standards for notice of
          changes. For your information, this page was last updated as of the
          date at the top of these terms and conditions.
        </blockquote>
        <h2>2. PRIVACY</h2>
        <p className="text-legal">
          Please review our Privacy Policy, which also governs your visit to
          this Site, to understand our practices.
        </p>
        <h2>3. LINKED SITES</h2>
        <p className="text-legal">
          This Site may contain links to other independent third-party Web sites
          ("Linked Sites&#x201D;). These Linked Sites are provided solely as a
          convenience to our visitors. Such Linked Sites are not under our
          control, and we are not responsible for and does not endorse the
          content of such Linked Sites, including any information or materials
          contained on such Linked Sites. You will need to make your own
          independent judgment regarding your interaction with these Linked
          Sites.
        </p>
        <h2>4. FORWARD LOOKING STATEMENTS</h2>
        <p className="text-legal">
          All materials reproduced on this site speak as of the original date of
          publication or filing. The fact that a document is available on this
          site does not mean that the information contained in such document has
          not been modified or superseded by events or by a subsequent document
          or filing. We have no duty or policy to update any information or
          statements contained on this site and, therefore, such information or
          statements should not be relied upon as being current as of the date
          you access this site.
        </p>
        <h2>5. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h2>
        <p className="text-legal">
          A. THIS SITE MAY CONTAIN INACCURACIES AND TYPOGRAPHICAL ERRORS. WE
          DOES NOT WARRANT THE ACCURACY OR COMPLETENESS OF THE MATERIALS OR THE
          RELIABILITY OF ANY ADVICE, OPINION, STATEMENT OR OTHER INFORMATION
          DISPLAYED OR DISTRIBUTED THROUGH THE SITE. YOU EXPRESSLY UNDERSTAND
          AND AGREE THAT: (i) YOUR USE OF THE SITE, INCLUDING ANY RELIANCE ON
          ANY SUCH OPINION, ADVICE, STATEMENT, MEMORANDUM, OR INFORMATION
          CONTAINED HEREIN, SHALL BE AT YOUR SOLE RISK; (ii) THE SITE IS
          PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS; (iii) EXCEPT AS
          EXPRESSLY PROVIDED HEREIN WE DISCLAIM ALL WARRANTIES OF ANY KIND,
          WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          WORKMANLIKE EFFORT, TITLE AND NON-INFRINGEMENT; (iv) WE MAKE NO
          WARRANTY WITH RESPECT TO THE RESULTS THAT MAY BE OBTAINED FROM THIS
          SITE, THE PRODUCTS OR SERVICES ADVERTISED OR OFFERED OR MERCHANTS
          INVOLVED; (v) ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH
          THE USE OF THE SITE IS DONE AT YOUR OWN DISCRETION AND RISK; and (vi)
          YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM
          OR FOR ANY LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH
          MATERIAL.
        </p>
        <p className="text-legal">
          B. YOU UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES, INCLUDING,
          BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE BE LIABLE FOR ANY DIRECT,
          INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES THAT
          RESULT FROM THE USE OF, OR THE INABILITY TO USE, ANY OF OUR SITES OR
          MATERIALS OR FUNCTIONS ON ANY SUCH SITE, EVEN IF WE HAVE BEEN ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATIONS SHALL
          APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
          REMEDY.
        </p>
        <h2>6. EXCLUSIONS AND LIMITATIONS</h2>
        <p className="text-legal">
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR
          THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
          CONSEQUENTIAL DAMAGES. ACCORDINGLY, OUR LIABILITY IN SUCH JURISDICTION
          SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
        </p>
        <h2>7. OUR PROPRIETARY RIGHTS</h2>
        <p className="text-legal">
          This Site and all its Contents are intended solely for personal,
          non-commercial use. Except as expressly provided, nothing within the
          Site shall be construed as conferring any license under our or any
          third party's intellectual property rights, whether by estoppel,
          implication, waiver, or otherwise. Without limiting the generality of
          the foregoing, you acknowledge and agree that all content available
          through and used to operate the Site and its services is protected by
          copyright, trademark, patent, or other proprietary rights. You agree
          not to: (a) modify, alter, or deface any of the trademarks, service
          marks, trade dress (collectively "Trademarks") or other intellectual
          property made available by us in connection with the Site; (b) hold
          yourself out as in any way sponsored by, affiliated with, or endorsed
          by us, or any of our affiliates or service providers; (c) use any of
          the Trademarks or other content accessible through the Site for any
          purpose other than the purpose for which we have made it available to
          you; (d) defame or disparage us, our Trademarks, or any aspect of the
          Site; and (e) adapt, translate, modify, decompile, disassemble, or
          reverse engineer the Site or any software or programs used in
          connection with it or its products and services.
        </p>
        <p className="text-legal">
          The framing, mirroring, scraping or data mining of the Site or any of
          its content in any form and by any method is expressly prohibited.
        </p>
        <h2>8. INDEMNITY</h2>
        <p className="text-legal">
          By using the Site web sites you agree to indemnify us and affiliated
          entities (collectively "Indemnities") and hold them harmless from any
          and all claims and expenses, including (without limitation) attorney's
          fees, arising from your use of the Site web sites, your use of the
          Products and Services, or your submission of ideas and/or related
          materials to us or from any person's use of any ID, membership or
          password you maintain with any portion of the Site, regardless of
          whether such use is authorized by you.
        </p>
        <h2>9. COPYRIGHT AND TRADEMARK NOTICE</h2>
        <p className="text-legal">
          Except our generated dummy copy, which is free to use for private and
          commercial use, all other text is copyrighted.
          generator.lorem-ipsum.info &#xA9; 2013, all rights reserved
        </p>
        <h2>10. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS</h2>
        <p className="text-legal">
          It is our policy to respond expeditiously to claims of intellectual
          property infringement. We will promptly process and investigate
          notices of alleged infringement and will take appropriate actions
          under the Digital Millennium Copyright Act ("DMCA") and other
          applicable intellectual property laws. Notices of claimed infringement
          should be directed to:
        </p>
        <p className="text-legal">generator.lorem-ipsum.info</p>
        <p className="text-legal">126 Electricov St.</p>
        <p className="text-legal">Kiev, Kiev 04176</p>
        <p className="text-legal">Ukraine</p>
        <p className="text-legal">contact@lorem-ipsum.info</p>
        <h2>11. PLACE OF PERFORMANCE</h2>
        <p className="text-legal">
          This Site is controlled, operated and administered by us from our
          office in Kiev, Ukraine. We make no representation that materials at
          this site are appropriate or available for use at other locations
          outside of the Ukraine and access to them from territories where their
          contents are illegal is prohibited. If you access this Site from a
          location outside of the Ukraine, you are responsible for compliance
          with all local laws.
        </p>
        <h2>12. GENERAL</h2>
        <p className="text-legal">
          A. If any provision of these Terms and Conditions is held to be
          invalid or unenforceable, the provision shall be removed (or
          interpreted, if possible, in a manner as to be enforceable), and the
          remaining provisions shall be enforced. Headings are for reference
          purposes only and in no way define, limit, construe or describe the
          scope or extent of such section. Our failure to act with respect to a
          breach by you or others does not waive our right to act with respect
          to subsequent or similar breaches. These Terms and Conditions set
          forth the entire understanding and agreement between us with respect
          to the subject matter contained herein and supersede any other
          agreement, proposals and communications, written or oral, between our
          representatives and you with respect to the subject matter hereof,
          including any terms and conditions on any of customer's documents or
          purchase orders.
        </p>
        <p className="text-legal">
          B. No Joint Venture, No Derogation of Rights. You agree that no joint
          venture, partnership, employment, or agency relationship exists
          between you and us as a result of these Terms and Conditions or your
          use of the Site. Our performance of these Terms and Conditions is
          subject to existing laws and legal process, and nothing contained
          herein is in derogation of our right to comply with governmental,
          court and law enforcement requests or requirements relating to your
          use of the Site or information provided to or gathered by us with
          respect to such use.
        </p>*/}
      </div>
    </>
  )
}

export { PrivacyPolicyPage }
