import { FC } from "react"

type Props = {
  className?: string
}

export const BackButtonIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enable-background="new 0 0 1000 1000"
      className={`fill-current  ${className}`}
    >
      <metadata>Back button</metadata>
      <g>
        <path d="M987.4,481.3c-3.9-13.8-12.3-25.1-23.8-33.9c-12.7-9.6-27-13-42.9-13c-229.4,0.2-458.8,0.1-688.3,0.1c-2.3,0-4.5,0-8,0c2.1-2.6,3.2-4.5,4.7-6c88.1-87.7,176.1-175.4,264.3-263.1c10.4-10.3,18.7-22,20.8-36.4c2.2-14.6,1.7-29.4-5.8-43c-11.8-21.5-29.9-33.8-54.1-35.9c-20.8-1.8-38.4,6-53.2,20.7C361.9,110,322.6,149,283.4,188.1C199.5,271.7,115.6,355.4,31.6,439c-15.1,15-22.5,32.6-21.5,53.7c0.1,2.7,1.9,5.7,1.3,8.1c-2.1,8-1.3,15.6,0.1,23.7c2.3,13.8,8.7,25,18.4,34.7c52.9,52.6,105.8,105.4,158.6,158c70.7,70.5,141.5,140.9,212.1,211.5c15,15,32.9,22.8,54.1,21.1c7.7-0.6,15.6-2.9,22.7-6.2c19.9-9.2,33-25,36.8-46.4c4-22.9-1.8-43.7-19-60.7c-88.8-88.2-177.4-176.6-266-264.9c-1.6-1.6-2.8-3.5-4.8-6c3.4,0,5.6,0,7.8,0c160.9,0,321.8,0,482.7,0c69.1,0,138.1-0.1,207.2,0.1c10.7,0,20.8-1.6,30.5-6.4C983,544.3,996,511.4,987.4,481.3z" />
      </g>
    </svg>
  )
}
