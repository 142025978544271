import React, { FC } from "react"
import UsersApiProvider from "../../../shared/providers/users/UsersApIProvider"
import { useThemeAtom } from "../../../UseTheme"
import { useTranslation } from "react-i18next"
import { useUserOwn } from "../../../shared/store/useOwnUser"
import { BASE_URL } from "../../../shared/constants/constants"

type Props = {
  className?: string
  myRef: React.RefObject<HTMLElement>
}

const AvatarDropDown: FC<Props> = ({ className, myRef }) => {
  const [, setTheme] = useThemeAtom()
  const { token, setToken } = useUserOwn()
  const { t } = useTranslation()

  return (
    <div
      className={`origin-top-right absolute right-0 w-auto min-w-10 z-50 rounded-md shadow-lg bg-white dark:bg-dropdown-bg-dark ${className}`}
      ref={myRef as React.RefObject<HTMLDivElement>}
    >
      <div
        className="py-1 pr-6"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <a
          href="/login"
          className="block px-4 py-2 text-xs dark:text-white hover:text-dropdown-text-hover-dark  cursor-pointer"
          role="menuitem"
          onClick={() => {
            new UsersApiProvider(token, BASE_URL).logout()
            setToken("")
            localStorage.clear()
          }}
        >
          <span className="flex flex-col">
            <span>{t("sign_out")}</span>
          </span>
        </a>
        <a
          href="/forgot-password"
          className="block px-4 py-2 text-xs dark:text-white hover:text-dropdown-text-hover-dark cursor-pointer"
          role="menuitem"
          onClick={() => {}}
        >
          <span className="flex flex-col">
            <span>{t("change_password")}</span>
          </span>
        </a>
        <span
          className="block px-4 py-2 text-xs dark:text-white hover:text-dropdown-text-hover-dark cursor-pointer"
          role="menuitem"
          onClick={() => {
            const root = window.document.documentElement
            const theme = window.localStorage.getItem("bttm-theme")
            const themeToAdd = theme === "dark" ? "light" : "dark"
            if (theme) {
              root.classList.remove(theme)
            }
            root.classList.add(themeToAdd)
            window.localStorage.setItem("bttm-theme", themeToAdd)
            setTheme(themeToAdd)
          }}
        >
          {t("switch_theme")}
        </span>
      </div>
    </div>
  )
}

export { AvatarDropDown }
