import React, { FC } from "react"
import { Column } from "react-table"
import { TableTransactions } from "./TableTransactions"
import { TableRowShimmer } from "../../../../shimmers/TableRowShimmer"

import { TransactionsBot } from "../../../../../shared/models/bot/BotInfo"
import { compareNumericReactTableString } from "../../../../../Utils"
import { useTranslation } from "react-i18next"

type Props = {
  isAdmin?: boolean
  className?: string
  loading?: boolean
  transactions?: TransactionsBot[]
  onClickSymbol?: (symbol: string) => void
}

const TableTransactionsBot: FC<Props> = ({
  isAdmin = false,
  className,
  loading,
  transactions,
  onClickSymbol,
}) => {
  const { t } = useTranslation()
  const columnsUser = [
    {
      id: "symbol",
      Header: t("symbol.text").toUpperCase(),
      accessor: "symbol",
    },
    {
      Header: t("type.text").toUpperCase(),
      accessor: "type",
    },
    {
      Header: t("income.text").toUpperCase(),
      accessor: "income",
      sortType: compareNumericReactTableString,
    },
    {
      Header: t("date.text").toUpperCase(),
      accessor: "time",
    },
  ] as Column<TransactionsBot>[]
  const columnsAdmin = [
    {
      id: "symbol",
      Header: t("symbol.text").toUpperCase(),
      accessor: "symbol",
    },
    {
      Header: t("type.text").toUpperCase(),
      accessor: "type",
    },
    {
      Header: t("income.text").toUpperCase(),
      accessor: "income",
      sortType: compareNumericReactTableString,
    },
    {
      Header: t("date.text").toUpperCase(),
      accessor: "time",
    },
  ] as Column<TransactionsBot>[]

  return (
    <div className={`table-base ${className}`}>
      {loading ? (
        <table className={"mt-7"}>
          <thead>
            <tr>
              {(isAdmin ? columnsAdmin : columnsUser).map((column, index) => (
                <th key={index} className="px-6 pb-4 table-text-header">
                  {column.Header as string}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            <TableRowShimmer
              nRow={(isAdmin ? columnsAdmin : columnsUser).length}
            />
            <TableRowShimmer
              nRow={(isAdmin ? columnsAdmin : columnsUser).length}
            />
            <TableRowShimmer
              nRow={(isAdmin ? columnsAdmin : columnsUser).length}
            />
            <TableRowShimmer
              nRow={(isAdmin ? columnsAdmin : columnsUser).length}
            />
            <TableRowShimmer
              nRow={(isAdmin ? columnsAdmin : columnsUser).length}
            />
            <TableRowShimmer
              nRow={(isAdmin ? columnsAdmin : columnsUser).length}
            />
          </tbody>
        </table>
      ) : (
        <TableTransactions
          className={"pt-12"}
          columns={isAdmin ? columnsAdmin : columnsUser}
          dataTransactions={transactions ?? []}
          onClickSymbol={onClickSymbol}
        />
      )}
    </div>
  )
}

export { TableTransactionsBot }
