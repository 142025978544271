import { FC } from "react"

type Props = {
  videoId: string
  className?: string
}

const YoutubeEmbed: FC<Props> = ({ className, videoId }) => {
  return (
    <div className={`relative overflow-hidden h-0 pb-[56.25%] ${className}`}>
      <iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        className={`absolute inset-0 w-full h-full`}
        width="853"
        height="480"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  )
}

export { YoutubeEmbed }
