import { UserOwn } from "../models/user/UserOwn"
import {
  FC,
  useEffect,
  useState,
  createContext,
  useContext,
  ReactNode,
} from "react"
import UsersApiProvider from "../providers/users/UsersApIProvider"
import { BASE_URL } from "../constants/constants"
import CustomError from "../exceptions/CustomError"

type Context = ReturnType<typeof useUserOwnContext>

const UserOwnContext = createContext<Context | undefined>(undefined)

export const UserOwnProvider: FC<{
  children: ReactNode
  onUserSuccess: (ownUser: UserOwn) => void
  onUserError: (errorCode: string) => void
}> = ({ children, onUserSuccess, onUserError }) => {
  return (
    <UserOwnContext.Provider
      value={useUserOwnContext(onUserSuccess, onUserError)}
    >
      {children}
    </UserOwnContext.Provider>
  )
}

export const useUserOwn = () => {
  const context = useContext(UserOwnContext)

  if (!context) throw Error("UserOwnContext not available")
  return context
}

function useUserOwnContext(
  onUserSuccess: (ownUser: UserOwn) => void,
  onUserError: (errorCode: string) => void
) {
  const getToken = () => {
    const tokenString = localStorage.getItem("jwt")
    if (!tokenString) return null
    return JSON.parse(tokenString)
  }

  const [token, setToken] = useState(getToken())

  const saveToken = (userToken: string) => {
    localStorage.setItem("jwt", JSON.stringify(userToken))
    setToken(userToken)
  }
  const getOwnUSer = (): UserOwn | null => {
    const userStorage = localStorage.getItem("user")
    if (!userStorage) return null
    const onwUser = JSON.parse(userStorage || "")
    if (!onwUser) return null
    return onwUser
  }

  const [value, setValue] = useState(getOwnUSer())

  useEffect(() => {
    if (!value && token) {
      new UsersApiProvider(token, BASE_URL)
        .getOwn()
        .then((ownUser: UserOwn) => {
          onUserSuccess(ownUser)
          setValue(ownUser)
        })
        .catch((error: CustomError) => {
          onUserError(error.errorCode)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    if (value) {
      localStorage.setItem("user", JSON.stringify(value))
    }
  }, [value])

  return { ownUser: value, setOwnUser: setValue, setToken: saveToken, token }
}
