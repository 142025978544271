import React from "react"
import Router from "./components/router/Router"
import reportWebVitals from "./reportWebVitals"
import "assets/styles/index.css"
import "./translations/i18n"
import dotenv from "dotenv"
import { ThemeProvider } from "./ThemeProvider"

import { createRoot } from "react-dom/client"
dotenv.config()
const container = document.getElementById("root")
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(
  // <React.StrictMode>
  <ThemeProvider>
    <Router />
  </ThemeProvider>
  // </React.StrictMode>
)

/*
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <UserOwnProvider>
        <Router />
      </UserOwnProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
)
*/

/*
Cuando cambio a la nueva forma de renderizar se rompe el popup del avatar

ReactDOM.render is no longer supported in React 18. Use createRoot instead. Until you switch to the new API, your app will behave as if it's running React 17. Learn more: https://reactjs.org/link/switch-to-createroot

import { createRoot } from "react-dom/client"
const container = document.getElementById("root")
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <UserOwnProvider>
        <Router />
      </UserOwnProvider>
    </ThemeProvider>
  </React.StrictMode>
)*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
