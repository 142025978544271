import { BotInfo } from "../../models/bot/BotInfo"
import { BotMinimalInfo } from "../../models/bot/BotMinimalInfo"
import { BotPost } from "../../models/bot/BotPost"
import PaginatedData from "../../models/PaginatedData"
import MyResponse from "../../providers/MyResponse"
import BotProvider from "./BotProvider"

import { BotSubscription } from "../../models/bot/BotSubscription"
import ApiProvider from "../ApiProvider"

export default class BotApiProvider extends ApiProvider implements BotProvider {
  constructor(private baseUrl: string) {
    super()
  }
  /*async getBotPositions(token: string, id: string): Promise<BotPositions> {
    return fetch(`${this.baseUrl}api/v1/botsubs/subscription/${id}/botinfo/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf8",
        Authorization: "Bearer " + token,
      },
    }).then((response) => {
      super.checkErrors(response)
      return response.json() as Promise<BotPositions>
    })
  }*/

  async getBots(token: string): Promise<PaginatedData<BotMinimalInfo>> {
    return fetch(`${this.baseUrl}api/v1/botsubs/bot/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf8",
        Authorization: "Bearer " + token,
      },
    }).then((response) => {
      super.checkErrors(response)
      return response.json() as Promise<PaginatedData<BotMinimalInfo>>
    })
  }

  async getBotInfo(token: string, id: string): Promise<BotInfo> {
    return fetch(`${this.baseUrl}api/v1/subscriptions/${id}/botinfo`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf8",
        Authorization: "Bearer " + token,
      },
    }).then((response) => {
      super.checkErrors(response)
      return response.json() as Promise<BotInfo>
    })
  }

  async getSubscriptions(
    token: string,
    page: number
  ): Promise<PaginatedData<BotSubscription>> {
    return fetch(`${this.baseUrl}api/v1/subscriptions/?page=${page}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf8",
        Authorization: "Bearer " + token,
      },
    }).then((response) => {
      super.checkErrors(response)
      return response.json() as Promise<PaginatedData<BotSubscription>>
    })
  }

  async unsubscribe(token: string, subscriptionId: number): Promise<void> {
    return fetch(
      `${this.baseUrl}api/v1/botsubs/subscription/${subscriptionId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: "Bearer " + token,
        },
      }
    ).then((response) => {
      super.checkErrors(response)
    })
  }

  async stopBot(token: string, subscriptionId: number): Promise<void> {
    return fetch(
      `${this.baseUrl}api/v1/botsubs/subscription/${subscriptionId}/stop/`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: "Bearer " + token,
        },
      }
    ).then((response) => {
      super.checkErrors(response)
    })
  }

  async startBot(token: string, subscriptionId: number): Promise<void> {
    return fetch(
      `${this.baseUrl}api/v1/botsubs/subscription/${subscriptionId}/start/`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: "Bearer " + token,
        },
      }
    ).then((response) => {
      super.checkErrors(response)
    })
  }

  async subscribe(
    token: string,
    botId: number,
    version: string,
    name: string,
    exchangeSelectedId: number
  ): Promise<BotSubscription> {
    return fetch(`${this.baseUrl}api/v1/subscription/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        bot: botId,
        version: version,
        name: name,
        exchange_account: exchangeSelectedId,
      }),
    }).then((response) => {
      super.checkErrors(response)
      return response.json() as Promise<BotSubscription>
    })
  }
}
