import { FC } from "react"
import { PendingPaymentOrder } from "../../shared/models/payments/Payments"
import { useTranslation } from "react-i18next"

type Props = {
  data: PendingPaymentOrder
  onItemClick: (pendingOrder: PendingPaymentOrder) => void
  className?: string
}

const PaymentOrdersNotificationPanelItem: FC<Props> = ({
  data,
  onItemClick,
  className,
}) => {
  const { t } = useTranslation()
  return (
    <div className={`flex items-center py-4 md:ml-4 ml-1 ${className}`}>
      <div className="mr-4 md:mr-2 w-3 h-3 rounded-full bg-red-alert-dark" />
      <div className="flex">
        <p className="dark:text-main-text-soft-dark text-main-text-soft-light text-sm md:text-base">
          {t("payment_panel.item_part_1")}
        </p>
        <p
          className="whitespace-pre cursor-pointer text-primary-text-dark text-sm md:text-base"
          onClick={() => onItemClick(data)}
        >{` #${data.id} `}</p>
        <p className="dark:text-main-text-soft-dark text-main-text-soft-light text-sm md:text-base">
          {t("payment_panel.item_part_2")}
        </p>
      </div>
      <span
        className="ml-auto cursor-pointer dark:text-main-text-soft-dark text-main-text-soft-light text-sm md:text-base"
        onClick={() => onItemClick(data)}
      >
        {t("open").toUpperCase()}
      </span>
    </div>
  )
}

export default PaymentOrdersNotificationPanelItem
