import React, { FC } from "react"
import { Column } from "react-table"
import { TableRowShimmer } from "../../../../shimmers/TableRowShimmer"
import { compareNumericReactTableString } from "../../../../../Utils"
import { useTranslation } from "react-i18next"
import { TableMarketPricesCells } from "./TableMarketPricesCells"
import { SymbolLicePrice } from "../../../../../shared/models/livePrices/SymbolPrice"
import useLivePricesLessRefresh from "../../../../../hooks/useLivePricesLessRefresh"

type Props = {
  isAdmin?: boolean
  className?: string
  onClickSymbol?: (symbol: string) => void
}

const TableMarketPrices: FC<Props> = ({ className, onClickSymbol }) => {
  const { livePrices } = useLivePricesLessRefresh()
  const { t } = useTranslation()
  const columnsUser = [
    {
      id: "symbol",
      Header: t("symbol.text").toUpperCase(),
      accessor: "s",
    },
    {
      Header: t("price.text").toUpperCase(),
      accessor: "c",
      sortType: compareNumericReactTableString,
    },
    {
      Header: t("24hchange.text").toUpperCase(),
      accessor: "P",
      sortType: compareNumericReactTableString,
    },
    {
      Header: t("24hvolume.text").toUpperCase(),
      accessor: "q",
      sortType: compareNumericReactTableString,
    },
  ] as Column<SymbolLicePrice>[]

  return (
    <div className={`table-base ${className}`}>
      {!livePrices ? (
        <table className={"mt-7"}>
          <thead>
            <tr>
              {columnsUser.map((column, index) => (
                <th key={index} className="px-6 pb-4 table-text-header">
                  {column.Header as string}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            <TableRowShimmer nRow={columnsUser.length} />
            <TableRowShimmer nRow={columnsUser.length} />
            <TableRowShimmer nRow={columnsUser.length} />
            <TableRowShimmer nRow={columnsUser.length} />
            <TableRowShimmer nRow={columnsUser.length} />
            <TableRowShimmer nRow={columnsUser.length} />
          </tbody>
        </table>
      ) : (
        <TableMarketPricesCells
          className={"pt-12"}
          columns={columnsUser}
          dataLivePrices={livePrices ?? []}
          onClickSymbol={onClickSymbol}
        />
      )}
    </div>
  )
}

export { TableMarketPrices }
