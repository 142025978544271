import { useEffect, useState } from "react"
import { SymbolLicePrice } from "../shared/models/livePrices/SymbolPrice"
import { useLivePrices } from "./useLivePricesContext"

let lastRefresh: Date
export default function useMarketLivePrices(milliseconds: number = 3000) {
  const { symbols } = useLivePrices()
  const [livePrices, setLivePrices] = useState<SymbolLicePrice[] | undefined>(
    undefined
  )

  useEffect(() => {
    if (
      symbols &&
      (lastRefresh == null ||
        lastRefresh?.getTime() + milliseconds < new Date().getTime())
    ) {
      lastRefresh = new Date()
      if (!livePrices) {
        setLivePrices(
          symbols.filter((livePrice) =>
            livePrice.s.toLowerCase().includes("usdt")
          )
        )
      } else {
        setLivePrices(
          livePrices!.map((price) => {
            const priceAux = symbols?.find(
              (priceAux) => priceAux.s === price?.s
            )
            if (priceAux) {
              price.previousPrice = price.c
              price.A = priceAux.A
              price.B = priceAux.B
              price.C = priceAux.C
              price.E = priceAux.E
              price.F = priceAux.F
              price.L = priceAux.L
              price.O = priceAux.O
              price.P = priceAux.P
              price.Q = priceAux.Q
              price.a = priceAux.a
              price.c = priceAux.c
              price.e = priceAux.e
              price.h = priceAux.h
              price.l = priceAux.l
              price.n = priceAux.n
              price.p = priceAux.p
              price.q = priceAux.q
              price.s = priceAux.s
              price.v = priceAux.v
              price.w = priceAux.w
              price.x = priceAux.x
            }
            return price
          })
        )
      }
    }
  }, [symbols])

  return { livePrices, setLivePrices }
}
