import React, { FC } from "react"
import { Column, useGlobalFilter, useSortBy, useTable } from "react-table"
import { TransactionsBot } from "../../../../../shared/models/bot/BotInfo"
import EyeIcon from "../../../../icons/EyeIcon"
import Tippy from "@tippyjs/react"
import { Tooltip } from "../../../../Tooptip"
import InfoIcon from "../../../../icons/InfoIcon"
import InfiniteScroll from "react-infinite-scroll-component"
import { useStaticPaginatedDataNoHandleListState } from "../../../../../hooks/useStaticPaginatedDataNoHandleListState"

type Props = {
  className?: string
  columns: Column<TransactionsBot>[]
  dataTransactions: TransactionsBot[]
  onClickSymbol?: (symbol: string) => void
}

const PAGE_SIZE = 20
const TableTransactions: FC<Props> = ({
  className,
  columns,
  dataTransactions,
  onClickSymbol,
}) => {
  const { next, getMoreData } =
    useStaticPaginatedDataNoHandleListState<TransactionsBot>(
      dataTransactions,
      PAGE_SIZE
    )

  const tableInstance = useTable(
    {
      columns,
      data: dataTransactions,
    },
    useGlobalFilter,
    useSortBy
  )

  const { getTableProps, headerGroups, rows, prepareRow, setGlobalFilter } =
    tableInstance

  return (
    <InfiniteScroll
      dataLength={next}
      next={getMoreData}
      hasMore={true}
      loader={<></>}
      scrollableTarget="baseScroll"
    >
      <div className={`table-base ${className}`}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.getHeaderGroupProps().key}>
                {headerGroup.headers.map((column) => (
                  <th key={column.id} className="px-6 pb-4 table-text-header">
                    <div className="flex">
                      <div
                        className={"align-text-top"}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span className="text-sm ml-2">
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : "⇅"}
                        </span>
                      </div>
                      {column["tooltip"] && (
                        <div className={"ml-4"}>
                          <Tippy
                            interactive={true}
                            hideOnClick={true}
                            animation={false}
                            render={(attrs) => (
                              <Tooltip
                                {...attrs}
                                content={column["tooltip"] ?? ""}
                              />
                            )}
                            disabled={!column["tooltip"]}
                            placement={"bottom"}
                          >
                            <div className={""}>
                              <InfoIcon className="table-text-header-info-icon" />
                            </div>
                          </Tippy>
                        </div>
                      )}
                    </div>
                    <div className={"flex pt-6"}>
                      {column["id"] === "symbol" && (
                        <input
                          style={{
                            boxShadow: "none",
                          }}
                          className={`w-24 input`}
                          id="symbol_input"
                          type="search"
                          placeholder="search"
                          onChange={(e) => setGlobalFilter(e.target.value)}
                        />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="">
            {rows?.slice(0, next)?.map((row, index) => {
              prepareRow(row)
              const colorIncomeClass =
                (row.original.income ?? 0) < 0
                  ? " dark:text-red-danger-dark  text-red-danger-light "
                  : " dark:text-secondary-text-dark text-secondary-text-light "

              return (
                <tr
                  key={row.original.id}
                  className={`${
                    index === rows.length - 1
                      ? ""
                      : "border-b border-table-row-divider-bg-light-light dark:border-table-row-divider-bg-dark-dark"
                  }  dark:hover:bg-component-highligted-bg-dark hover:bg-component-highligted-bg-light`}
                >
                  <>
                    <td className="px-6 py-4 table-text-body">
                      <button
                        onClick={() => {
                          onClickSymbol?.(row.original.symbol ?? "")
                        }}
                      >
                        <div className={"flex"}>
                          {row.original.symbol && (
                            <EyeIcon
                              onClick={() => {}}
                              className="table-eye-icon"
                            />
                          )}
                          <p className="table-text-body">
                            {row.original.symbol}
                          </p>
                        </div>
                      </button>
                    </td>
                    <td className="px-6 py-4 table-text-body">
                      {row.original.type}
                    </td>
                    <td
                      className={`px-6 py-4 table-text-body ${colorIncomeClass}`}
                    >
                      {`${
                        row?.original?.asset === "USDT"
                          ? "$"
                          : row?.original?.asset
                      }${row.original?.income}` ?? ""}
                    </td>
                    <td className="px-6 py-4 table-text-body">
                      {new Date(
                        row.original.registration_datetime ?? ""
                      ).toLocaleString()}
                    </td>
                  </>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </InfiniteScroll>
  )
}

export { TableTransactions }
