import { atom, useAtom } from "jotai"
import { ExchangeAccount } from "../models/exchangeAccount/ExchangeAccount"
import { BotSubscription } from "../models/bot/BotSubscription"
import { UserOwn } from "../models/user/UserOwn"
import { BotInfo } from "../models/bot/BotInfo"
import LoadingData from "../models/LoadingData"

const botSubscriptionsAtom = atom([] as BotSubscription[])
export const useBotSubscriptionsAtom = () => useAtom(botSubscriptionsAtom)

const exchangeAccountsAtom = atom([] as ExchangeAccount[])
export const useExchangeAccountsAtom = () => useAtom(exchangeAccountsAtom)

const botInfoDataResponseAtom = atom(
  undefined as LoadingData<BotInfo> | undefined
)
export const useBotInfoDataResponseAtom = () => useAtom(botInfoDataResponseAtom)

const privateModeAtom = atom(false)
export const usePrivateModeAtom = () => useAtom(privateModeAtom)
