import { FC } from "react"

type Props = {
  className?: string
  text?: string
}

const ValidationFieldForm: FC<Props> = ({ className, text }) => {
  return (
    <p className={`text-sm text-red-validation-light-dark ${className}`}>
      {text}
    </p>
  )
}

export { ValidationFieldForm }
