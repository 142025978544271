import { FC, useEffect, useState } from "react"
import CountryApiProvider from "../../shared/providers/countries/CountryApiProvider"
import Select from "react-select"
import { Country } from "../../shared/models/country/Country"
import LoadingData from "../../models/LoadingData"
import { useTranslation } from "react-i18next"
import { BASE_URL } from "../../shared/constants/constants"

type Props = {
  className?: string
  onSelected: (country: Country) => void
}

const CountryPicker: FC<Props> = ({ className, onSelected }) => {
  const { t } = useTranslation()
  const [countries, setCountries] = useState<LoadingData<Country[]>>({
    data: [] as Country[],
    loading: true,
  })

  const [selectedCountry, setSelectedCountry] = useState<
    { value: string | undefined; label: string | undefined } | undefined
  >(undefined)
  useEffect(() => {
    new CountryApiProvider(BASE_URL)
      .getCountries()
      .then((countries) => {
        console.log(countries?.countries)
        setCountries({
          loading: false,
          data: countries?.countries ?? [{ code: "es", name: "España" }],
        })
      })
      .catch((e) => {
        console.log("Countries error", e)
      })
  }, [])

  console.log(countries?.data)
  return (
    <div className={`${className}`}>
      <Select
        placeholder={`${t("country.input.placeholder")}`}
        styles={{
          control: (provided) => ({
            ...provided,
            backgroundColor: "#2C2B3A",
            border: "1px solid #353542",
            borderRadius: "5px",
            color: "#FFF",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "#fff",
          }),
          input: (provided) => ({
            ...provided,
            color: "#fff",
          }),
          option: (provided) => ({
            ...provided,

            cursor: "pointer",
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "#76757E",
          }),
        }}
        defaultValue={selectedCountry}
        onChange={(selectedItem) => {
          setSelectedCountry({
            value: selectedItem?.value,
            label: selectedItem?.label,
          })
          onSelected({
            code: selectedItem?.value,
            name: selectedItem?.label,
          } as Country)
        }}
        options={
          countries?.data?.map((country) => {
            return { value: country.code ?? "", label: country.name ?? "" }
          }) ?? []
        }
      />
    </div>
  )
}

export { CountryPicker }
