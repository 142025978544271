import { FC } from "react"

type Props = {
  color?: string
  height?: number
  width?: number
  className?: string
}

const CloseIcon: FC<Props> = ({
  color = "#FFF",
  height = 16,
  width = 16,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={height}
      height={width}
      viewBox="0 0 16 16"
      className={className}
    >
      <title>Close</title>
      <defs>
        <path
          d="M.293.293a1 1 0 011.414 0L7 5.585 12.293.293a1 1 0 011.32-.083l.094.083a1 1 0 010 1.414L8.415 7l5.292 5.293a1 1 0 01.083 1.32l-.083.094a1 1 0 01-1.414 0L7 8.415l-5.293 5.292a1 1 0 01-1.32.083l-.094-.083a1 1 0 010-1.414L5.585 7 .293 1.707A1 1 0 01.21.387z"
          id="CloseIcon"
        />
      </defs>
      <use
        fillRule="nonzero"
        xlinkHref="#CloseIcon"
        transform="translate(1 1)"
      />
    </svg>
  )
}

export default CloseIcon
