import { FC } from "react"
import { useThemeAtom } from "../../UseTheme"
import SunIcon from "../../assets/sun-icon.svg"
import MoonIcon from "../../assets/moon-icon.svg"

type Props = {
  className?: string
}

const SwitchTheme: FC<Props> = ({ className }) => {
  const [, setTheme] = useThemeAtom()
  const theme = window.localStorage.getItem("bttm-theme")
  const root = window.document.documentElement
  const toggleTheme = () => {
    const themeToAdd = theme === "dark" ? "light" : "dark"
    if (theme) {
      root.classList.remove(theme)
    }
    root.classList.add(themeToAdd)
    window.localStorage.setItem("bttm-theme", themeToAdd)
    setTheme(themeToAdd)
  }

  const classMoon =
    theme === "dark"
      ? " bg-primary-gradien1-dark "
      : " bg-component-bg-light-dark "
  const classSun =
    theme === "light"
      ? " bg-primary-gradien1-dark "
      : " bg-component-bg-light-dark "

  return (
    <div className={`flex ${className}`}>
      <div
        className={`w-8 self-center rounded-l-2xl ${classMoon}`}
        onClick={() => {
          if (theme === "dark") return
          toggleTheme()
        }}
      >
        <img
          src={MoonIcon}
          className="h-4 my-2 text-primary-2-dark self-center m-auto"
          alt={"Moon icon"}
        />
      </div>
      <div
        className={`w-8 self-center rounded-r-2xl ${classSun}`}
        onClick={() => {
          if (theme === "light") return
          toggleTheme()
        }}
      >
        <img
          src={SunIcon}
          className="h-4 my-2 text-white self-center m-auto"
          alt={"Sun icon"}
        />
      </div>
    </div>
  )
}

export { SwitchTheme }
