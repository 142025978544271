import { FC } from "react"

type Props = {
  className?: string
  classWidth: string
  classHeight: string
}

function getClass(classWidth: string, classHeight: string): string {
  let base = "rounded-full dark:bg-shimmer-bg-dark animate-pulse"
  base += " " + classWidth + " " + classHeight
  return base
}

const CircleShimmer: FC<Props> = ({ className, classWidth, classHeight }) => {
  return <div className={`${className} ${getClass(classWidth, classHeight)}`} />
}

export { CircleShimmer }
