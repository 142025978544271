import { SubHeaderType } from "./SubHeaderTypes"

export function getTitle(
  title?: string,
  type?: SubHeaderType
): string | undefined {
  switch (type) {
    case SubHeaderType.BotDetails: {
      return title
    }
    case SubHeaderType.Home: {
      return "Home"
    }
    case SubHeaderType.Dashboard: {
      return "Dashboard"
    }
    case SubHeaderType.TradingTerminal: {
      return "Trading terminal"
    }

    default: {
      return undefined
    }
  }
}

export function getButtonText(
  type: SubHeaderType,
  isSubscribed?: boolean
): string | undefined {
  switch (type) {
    case SubHeaderType.BotDetails: {
      return isSubscribed ? "Unsubcribe" : "Subscribe"
    }
    case SubHeaderType.Home: {
      return "Create bot"
    }

    default: {
      return undefined
    }
  }
}

export function getSubbuttonText(botStatus?: string): string | undefined {
  switch (botStatus) {
    case "RUNNING": {
      return "Stop bot"
    }
    case "STOPPED": {
      return "Start bot"
    }
    case "INITIAL": {
      return "Start bot"
    }

    default: {
      return undefined
    }
  }
}
export function getButttonText(
  type?: SubHeaderType,
  isSubscribed?: boolean
): string | undefined {
  switch (type) {
    case SubHeaderType.BotDetails: {
      return isSubscribed ? "Unsubcribe" : "Subscribe"
    }
    case SubHeaderType.Home: {
      return "Create bot"
    }

    default: {
      return undefined
    }
  }
}

export function hasSubbutton(
  type?: SubHeaderType,
  botStatus?: string
): boolean {
  return (
    type !== SubHeaderType.Dashboard &&
    type !== SubHeaderType.TradingTerminal &&
    type !== SubHeaderType.Home &&
    (botStatus === "RUNNING" ||
      botStatus === "STOPPED" ||
      botStatus === "INITIAL")
  )
}

export function hasButton(type?: SubHeaderType): boolean {
  return (
    type !== SubHeaderType.Dashboard &&
    type !== SubHeaderType.TradingTerminal &&
    type !== SubHeaderType.Home
  )
}

export function hasImage(type?: SubHeaderType): boolean {
  return type === SubHeaderType.BotDetails
}
