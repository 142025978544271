export default class CustomError extends Error {
  public static TOKEN_INVALID = "TOKEN_INVALID"
  public static BAD_REQUEST = "BAD_REQUEST"
  public static INVITATION_CODE_INVALID = "INVITATION_CODE_INVALID"
  public static EMAIL_ALREADY_EXISTS = "EMAIL_ALREADY_EXISTS"
  public static ACCOUNT_NOT_ACTIVATED = "ACCOUNT_NOT_ACTIVATED"
  public static INVALID_CREDENTIALS = "INVALID_CREDENTIALS"
  public static ALREADY_VERIFIED = "ALREADY_VERIFIED"
  public static API_KEY_OR_SECRET_INVALID = "API_KEY_OR_SECRET_INVALID"

  constructor(public errorCode: string) {
    super()
  }
}
