export enum SortByOptions {
  Symbol = "symbol",
  UnrealizedPNLUpToDown = "unrealized_pnl_up_to_down",
  UnrealizedPNLDownToUp = "unrealized_pnl_down_to_up",
  DcasUpToDown = "dcas_up_to_down",
  DcasDownToUp = "dcas_down_to_up",
  QuantityUpToDown = "quantity_up_to_down",
  QuantityDownToUp = "quantity_down_to_up",
  OrderPriceUpToDown = "order_up_to_down",
  OrderPriceDownToUp = "order_down_to_up",
  TransactionTypeUpToDown = "transaction_up_to_down",
  TransactionTypeDownToUp = "transaction_down_to_up",
  IncomeUpToDown = "income_up_to_down",
  IncomeDownToUp = "income_down_to_up",
  DateUpToDown = "date_up_to_down",
  DateDownToUp = "date_down_to_up",
}
