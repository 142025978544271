import { PrimaryButton } from "components/PrimaryButton"
import React, { FC } from "react"
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { ref } from "yup"
import { ValidationFieldForm } from "../../common/forms/ValidationFieldForm"
import AuthApIProvider from "shared/providers/auth/AuthApIProvider"
import { useTranslation } from "react-i18next"
import BadRequestError from "../../../shared/exceptions/BadRequestError"
import InvalidInvitationCodeError from "../../../shared/exceptions/InvalidInvitationCodeError"
import EmailAlreadyExistsError from "../../../shared/exceptions/EmailAlreadyExistsError"
import { ShowHideToggleIcon } from "../../ShowHideToggleIcon"
import { Checkbox } from "../../common/forms/Checkbox"
import LogoText from "../../../assets/logo-text-long-circle-white.png"
import { CountryPicker } from "../../CountryPicker/CountryPicker"
import { notifyError } from "../../Toasts/ToastUtils"
import {
  BASE_URL,
  PASSWORD_MIN_CHARS,
} from "../../../shared/constants/constants"

const SignUpPage: FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)
  const [passwordShown, setPasswordShown] = React.useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = React.useState(false)
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown)
  }
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(!confirmPasswordShown)
  }
  const history = useNavigate()

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirm_password: "",
      invitation_code: "",
      country: "",
      termsAndConditions: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t("email.input.validation")),
      country: Yup.string().required(t("country.input.validation")),
      firstName: Yup.string().required(t("first_name.input.validation")),
      lastName: Yup.string().required(t("last_name.input.validation")),
      password: Yup.string()
        .required(t("password.input.validation"))
        .min(PASSWORD_MIN_CHARS, t("password.input.validation_min_chars")),
      invitation_code: Yup.string().required(
        t("invitation_code.input.validation")
      ),
      confirm_password: Yup.string()
        .required(t("confirm_password.input.validation_required"))
        .oneOf([ref("password")], t("confirm_password.input.validation_match")),
      termsAndConditions: Yup.boolean().oneOf(
        [true],
        t("terms_and_conditions_signup_label.validation")
      ),
    }),
    onSubmit: (values) => {
      setLoading(true)
      new AuthApIProvider(BASE_URL)
        .signup(
          values.email,
          values.firstName,
          values.lastName,
          values.password,
          values.invitation_code,
          values.country
        )
        .then(() => {
          setLoading(false)
          history("/verify-email", { state: { email: values.email } })
        })
        .catch((e: Error) => {
          setLoading(false)
          console.log(e)
          if (e instanceof InvalidInvitationCodeError) {
            notifyError(t("api_message_errors.invalid_invitation_code"))
            return
          }
          if (e instanceof BadRequestError) {
            notifyError(t("api_message_errors.unexpected_error"))
            return
          }
          if (e instanceof EmailAlreadyExistsError) {
            notifyError(t("api_message_errors.email_already_exists_error"))
            return
          }
          notifyError(t("api_message_errors.unexpected_error"))
        })
    },
  })

  return (
    <>
      <div className="h-screen dark:bg-bg-dark bg-bg-light">
        <div className="flex-1 dark:bg-bg-dark bg-bg-light">
          <div className="m-auto sm:w-96 w-full pl-4 pr-4 pt-12 pb-12">
            <div className="flex justify-center">
              <div>
                <a href="/">
                  <img
                    src={LogoText}
                    className={"w-56 sm:w-72 mb-6"}
                    alt="logo"
                  />
                </a>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="w-full self-center px-6 py-8 rounded shadow-2xl bg-container-panel-bg-light dark:bg-container-panel-bg-dark">
                <div className="mt-4">
                  <input
                    className={`input-sign-up-login`}
                    id="email"
                    type="text"
                    placeholder={t("email.input.placeholder")}
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.email}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  <input
                    className={`input-sign-up-login`}
                    id="firstName"
                    type="text"
                    placeholder={t("first_name.input.placeholder")}
                    {...formik.getFieldProps("firstName")}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.firstName}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  <input
                    className={`input-sign-up-login`}
                    id="lastName"
                    type="text"
                    placeholder={t("last_name.input.placeholder")}
                    {...formik.getFieldProps("lastName")}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.lastName}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  <CountryPicker
                    onSelected={(country) => {
                      formik.setFieldValue("country", country.code)
                    }}
                  />
                  {formik.touched.country && formik.errors.country ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.country}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  <div className="relative flex w-full ">
                    <input
                      className={`!pl-2 !pr-12 input-sign-up-login`}
                      id="password"
                      type={passwordShown ? "text" : "password"}
                      autoComplete={"off"}
                      placeholder={t("password.input.placeholder")}
                      {...formik.getFieldProps("password")}
                    />

                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="absolute right-4 top-[20%]"
                    >
                      <ShowHideToggleIcon
                        showedIcon={passwordShown}
                        className="h-6"
                      />
                    </button>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.password}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  <div className="relative flex w-full ">
                    <input
                      className={`!pl-2 !pr-12 input-sign-up-login`}
                      id="confirm_password"
                      type={confirmPasswordShown ? "text" : "password"}
                      autoComplete={"off"}
                      placeholder={t("confirm_password.input.placeholder")}
                      {...formik.getFieldProps("confirm_password")}
                    />

                    <button
                      type="button"
                      onClick={toggleConfirmPasswordVisibility}
                      className="absolute right-4 top-[20%]"
                    >
                      <ShowHideToggleIcon
                        showedIcon={confirmPasswordShown}
                        className="h-6"
                      />
                    </button>
                  </div>
                  {formik.touched.confirm_password &&
                  formik.errors.confirm_password ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.confirm_password}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  <input
                    className={`input-sign-up-login`}
                    id="invitation_code"
                    type="text"
                    placeholder={t("invitation_code.input.placeholder")}
                    {...formik.getFieldProps("invitation_code")}
                  />
                  {formik.touched.invitation_code &&
                  formik.errors.invitation_code ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.invitation_code}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  <div className="flex">
                    <Checkbox
                      id="termsAndConditions"
                      className="shadow-none appearance-none focus:ring-1 focus:ring-secondary-gradien1-dark"
                      light={true}
                      checked={false}
                      label={""}
                      onChange={(checked: boolean) => {
                        formik.setFieldValue("termsAndConditions", checked)
                      }}
                    />
                    <div className="flex-row dark:text-main-text-dark text-main-text-light ">
                      {t("terms_and_conditions_signup_label.text") + " "}
                      <a href="/terms-and-conditions" className="font-bold">
                        {t("terms_and_conditions.text") + " "}
                      </a>
                      {t("and_terms_and_conditions_signup_label.text") + " "}
                      <a href="/privacy-policy" className="font-bold">
                        {t("privacy_policy.text") + " "}
                      </a>
                    </div>
                  </div>
                  {formik.touched.termsAndConditions &&
                  formik.errors.termsAndConditions ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.termsAndConditions}
                    />
                  ) : null}
                </div>

                <PrimaryButton
                  type="submit"
                  loading={loading}
                  className="h-10 mt-8 w-full !py-0"
                >
                  <p>{t("signup.text")}</p>
                </PrimaryButton>

                <button
                  className="mt-8 w-full font-semibold dark:text-main-text-dark text-main-text-light text-lg"
                  onClick={() => history("/login")}
                >
                  <p>{t("login.text")}</p>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export { SignUpPage }
