import { PrimaryButton } from "components/PrimaryButton"
import React, { FC } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Toast } from "../../Toasts"
import { YoutubeEmbed } from "../../YoutubeEmbed"
import { InfoPill } from "../../InfoPill"

const WelcomeVideoPage: FC = () => {
  const { t } = useTranslation()

  const history = useNavigate()

  return (
    <>
      <div className="flex">
        <div className="mx-auto sm:mt-12 md:mt-16 lg:mt-32 sm:w-[64rem] w-full pl-4 pr-4 ">
          <div className="flex justify-center">
            <h1 className="text-5xl mb-12 font-bold dark:text-main-text-dark text-main-text-light">
              {t("welcome_video_page.title")}
            </h1>
          </div>
          <div className="w-full self-center align-middle justify-center px-6 py-8 rounded shadow-2xl bg-container-panel-bg-light dark:bg-container-panel-bg-dark">
            <div className="mt-4 text-center dark:text-main-text-dark text-main-text-light font-medium text-xl">
              <p className="mb-6">{t("welcome_video_page.header")}</p>
              <p className="mb-16">{t("welcome_video_page.body")}</p>
            </div>

            <div className="mx-auto block md:flex justify-items-center justify-center">
              <InfoPill
                className="mx-auto md:mx-0 md:mr-4 md:w-56 w-auto"
                step={1}
                text={t("welcome_video_page.info_pill_1_text")}
              />
              <InfoPill
                className="mx-auto md:mx-0 md:w-56 w-auto"
                step={2}
                text={t("welcome_video_page.info_pill_2_text")}
              />
              <InfoPill
                className="mx-auto md:mx-0 md:ml-4 md:w-56 w-auto"
                step={3}
                text={t("welcome_video_page.info_pill_3_text")}
              />
            </div>

            <YoutubeEmbed videoId="5AzcRAM73iU" />

            <div className="flex">
              <PrimaryButton
                loading={false}
                className="h-12 mt-16 !py-0 mx-auto"
                onClick={() => history("/welcome-api-key")}
              >
                <p>{t("welcome_video_page.button")}</p>
              </PrimaryButton>
            </div>
          </div>
        </div>
        <Toast />
      </div>
    </>
  )
}

export { WelcomeVideoPage }
