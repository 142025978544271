import { PrimaryButton } from "components/PrimaryButton"
import React, { FC } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const WelcomePage: FC = () => {
  const { t } = useTranslation()

  const history = useNavigate()

  return (
    <>
      <div className="flex">
        <div className="mx-auto sm:mt-12 md:mt-16 lg:mt-32 sm:w-[32rem] w-full pl-4 pr-4 ">
          <div className="flex justify-center">
            <h1 className="text-5xl mb-12 font-bold dark:text-main-text-dark text-main-text-light">
              {t("welcome.text")}
            </h1>
          </div>
          <div className="w-full self-center px-6 py-8 rounded shadow-2xl bg-container-panel-bg-light dark:bg-container-panel-bg-dark">
            <div className="mt-4 text-center dark:text-main-text-dark text-main-text-light font-medium text-xl">
              <p className="mb-6">{t("welcome_page.header")}</p>
              <p className="mb-4">{t("welcome_page.body")}</p>
            </div>

            <PrimaryButton
              loading={false}
              className="h-12 mt-16 w-full !py-0"
              onClick={() => history("/welcome-video")}
            >
              <p>{t("welcome_page.button")}</p>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  )
}

export { WelcomePage }
