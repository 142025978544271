import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Toast } from "../../Toasts"

const ForgotPasswordEmailSentPage: FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex h-screen dark:bg-bg-dark bg-bg-light">
        <div className="m-auto sm:w-[32rem] w-full pl-4 pr-4 ">
          <div className="flex justify-center">
            <h1 className="text-5xl mb-10 text-center font-bold dark:text-main-text-dark text-main-text-light uppercase">
              {t("recovering_password.text")}
            </h1>
          </div>
          <div className="w-full self-center px-6 py-8 rounded shadow-2xl bg-container-panel-bg-light dark:bg-container-panel-bg-dark">
            <div className="mt-4 text-center dark:text-main-text-dark text-main-text-light font-medium text-xl">
              <p className="mb-2">
                {t("forgot_password_email_sent_page.header")}
              </p>
              <p className="mb-6">
                {t("forgot_password_email_sent_page.body")}
              </p>
            </div>
          </div>
        </div>
        <Toast />
      </div>
    </>
  )
}

export { ForgotPasswordEmailSentPage }
