import React, { useState } from "react"

export function useStaticPaginatedDataNoHandleListState<T>(
  list: T[],
  pageSize: number
) {
  const [count, setCount] = useState({
    prev: 0,
    next: pageSize,
  })
  const [hasMore, setHasMore] = useState(list.length > pageSize)

  React.useEffect(() => {
    setHasMore(list.length > pageSize)
  }, [list, pageSize])

  const getMoreData = () => {
    if (count.prev >= list.length) {
      setHasMore(false)
      return
    }

    setCount((prevState) => ({
      prev: prevState.prev + pageSize,
      next: prevState.next + pageSize,
    }))
  }

  return {
    next: count.next,
    hasMore,
    setHasMore,
    getMoreData,
    setCount,
  }
}
