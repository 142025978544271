import React, { FC } from "react"
import { BotPosition } from "../../../../../shared/models/bot/BotInfo"
import { useTranslation } from "react-i18next"

type Props = {
  className?: string
  data: BotPosition[]
}

const TOTAL_EXPOSURE = 300.0

const WalletExposure: FC<Props> = ({ className, data }) => {
  const { t } = useTranslation()
  const walletTotalExposure = data.reduce(
    (acc, curr) => acc + Math.abs(curr.walletExposure ?? 0),
    0
  )
  const walletTotalExposurePercentage =
    (walletTotalExposure * 100) / TOTAL_EXPOSURE
  const widthPercentageClass =
    "w-[" +
    (walletTotalExposurePercentage >= 100
      ? 99
      : walletTotalExposurePercentage
    ).toFixed(0) +
    "%]"

  function panelPercentageMlClass(percentage: number): string {
    if (percentage > 10 && percentage < 20) {
      return "-ml-6"
    }
    if (percentage > 20 && walletTotalExposurePercentage <= 95) {
      return "-ml-12"
    }

    return ""
  }

  return (
    <div
      className={`p-1 lg:p-6 shadow-md bg-container-panel-bg-light dark:bg-container-panel-bg-dark rounded grid-cols-2  flex flex-col ${className}`}
      onClick={() => {}}
    >
      <div className="p-3 mb-2 lg:p-4 items-center justify-between  flex-1">
        <div className="flex items-center">
          <h3 className="dark:text-main-text-dark text-main-text-light dark:font-bold font-semibold text-2xl mr-2">
            {t("wallet_exposure.text")}
          </h3>
        </div>
      </div>
      <div className={"flex-1"}>
        <div className={"flex px-2"}>
          <div className={`${widthPercentageClass}`} />
          <div
            className={`w-24 mb-3 ${panelPercentageMlClass(
              walletTotalExposurePercentage
            )}`}
          >
            <div
              className={`bg-bg-dark dark:bg-white dark:text-main-text-light text-main-text-dark rounded text-center py-2  `}
            >
              <p
                className={`font-bold text-xl mx-2`}
              >{`${walletTotalExposurePercentage.toFixed(1)}%`}</p>
            </div>
            <div className={"flex justify-center"}>
              {/*<div
                className="bg-bg-dark dark:bg-white  h-4 w-4 -mt-3 justify-self-center rounded"
                style={{ transform: "rotate(45deg)" }}
              />*/}
            </div>
          </div>
        </div>
        <div className="mx-12">
          <div className="relative">
            <div className="overflow-hidden h-4 mb-4 text-xs flex rounded bg-gradient-to-r from-green-ok-dark via-orange-danger-dark to-red-danger-dark " />
          </div>
        </div>
      </div>
    </div>
  )
}

export { WalletExposure }
