import React, { useEffect } from "react"
import LoadingData from "../models/LoadingData"
import { DailyBalance } from "../shared/models/dataTrack/DailyBalance"
import DataTrackApiProvider from "../shared/providers/dataTrack/DataTrackApiProvider"
import { Income } from "../shared/models/dataTrack/Income"
import { useUserOwn } from "../shared/store/useOwnUser"
import { BASE_URL } from "../shared/constants/constants"
import DataTrackProvider from "../shared/providers/dataTrack/DataTrackProvider"

export const useDailyBalance = (
  subscriptionId: string | undefined,
  provider: DataTrackProvider
) => {
  const { token } = useUserOwn()

  const [dailyBalanceResponse, setDailyBalanceResponse] = React.useState<{
    loading: boolean
    data?: DailyBalance[]
  }>({ loading: true, data: undefined })

  useEffect(() => {
    setDailyBalanceResponse({
      loading: true,
      data: undefined,
    } as LoadingData<DailyBalance[]>)

    if (parseInt(subscriptionId ?? "-1") === -1) return
    provider
      .getDailyBalance(token, parseInt(subscriptionId!))
      .then((incomeResponse: Income[]) => {
        setDailyBalanceResponse({
          loading: false,
          data: incomeResponse,
        } as LoadingData<DailyBalance[]>)
      })
      .catch(() => {
        setDailyBalanceResponse({
          loading: false,
          data: [],
        } as LoadingData<DailyBalance[]>)
      })
  }, [])

  return { dailyBalance: dailyBalanceResponse }
}
