export const TRANSLATIONS_EN = {
  connected_accounts: {
    text: "Connected accounts",
    tooltip: "Binance accounts connected to Bot the Moon.",
  },
  daily_pnl: {
    text: "Daily PNL",
    tooltip: "Total profit and loss within the day",
  },
  monthly_pnl: {
    text: "Monthly profit",
  },
  monthly_wallet_balance: {
    text: "Monthly Wallet Balance",
  },
  daily_average_pnl: {
    text: "Daily Average PNL",
    tooltip: "Average profit and loss within the day",
  },
  avg_daily_gain: {
    text: "Avg Daily Gain",
  },
  daily_net_income: {
    text: "Daily net income",
  },
  wallet_balance: {
    text: "Wallet Balance",
    tooltip:
      "Wallet Balance = Total Net Transfer + Total Reaalized Profit + Total Net Funding Fee - Total Commission.",
  },
  available_balance: {
    text: "Available Balance",
    tooltip:
      "Available amount disposable to transfer to spot or other accounts.",
  },
  symbol: {
    text: "Symbol",
  },
  unrealized_pnl: {
    text: "Unrealized PNL",
    tooltip: "Profit and loss calculated based on the price basis selected.",
  },
  wallet_exposure: {
    text: "Wallet Exposure",
    tooltip: "Percentage of total capital allocated to each position",
  },
  entry_price: {
    text: "Entry Price",
    tooltip: "Purchase price of an asset in a specific moment of time.",
  },
  price: {
    text: "Price",
  },
  dcas: {
    text: "DCAs",
    tooltip:
      "An investment strategy in which the total amount to be invested is divided into periodic purchases of a target asset in an effort to reduce the impact of volatility on the total purchase",
  },
  next_dca: {
    text: "Next DCA",
    tooltip: "TBD",
  },
  liquidation_price: {
    text: "Liquidation price",
    tooltip: "TBD",
  },
  position_size: {
    text: "Position Size",
  },
  next_tp: {
    text: "Next TP",
    tooltip: "TBD",
  },
  symbol_performance_30d: {
    text: "Last 30 days performance",
  },
  lasttp: {
    text: "Last TP",
  },
  funding_fee: {
    text: "Funding Fee",
    tooltip:
      "Binance fee settled every 8 hours. Depending on whether it is positive or negative, the user can exit to pay or receive.",
  },
  live_price: {
    text: "Live Price",
    tooltip: "TBD",
  },
  commission: {
    text: "Commission",
    tooltip: "Commission fee for trading in Futures.",
  },
  daily_commission: {
    text: "Daily commission",
    tooltip: "Daily commission fee for trading in Futures.",
  },
  order_price: {
    text: "Order Price",
    tooltip: "Price of an order for an asset in a specific moment of time.",
  },
  quantity: {
    text: "Quantity",
    tooltip:
      "Trading units using stable coins in USDT-M Futures, open position amounts shown are calculated based on the actual coin quantity. ",
  },
  buy_sell: {
    text: "Buy/Sell",
  },
  date: {
    text: "Date",
  },
  income: {
    text: "Income",
  },
  type: {
    text: "Type",
  },
  profit: {
    text: "Profit",
  },
  bttm_service_cost: {
    text: "Service and Maintenance Fee",
  },
  earnings_before_interests_taxes_acronyms: {
    text: "EBIT",
  },
  service_cost: {
    text: "Service and Maintenance Fee",
  },
  positions: {
    text: "Positions",
  },
  open_orders: {
    text: "Open Orders",
  },
  transactions: {
    text: "Transactions",
  },
  market: {
    text: "Market",
  },
  "24hchange": {
    text: "24h Change",
  },
  "24hvolume": {
    text: "24h Volume",
  },
  risk: {
    text: "Risk",
  },
  last_30_days: {
    text: "Lasts 30 days",
  },
  last_15_days: {
    text: "Lasts 15 days",
  },
  last_7_days: {
    text: "Lasts 7 days",
  },
  sort_by_symbol: {
    text: "Alphabetical order",
  },
  sort_by_unrealized_pnl_up_to_down: {
    text: "Unrealized PNL ⬇️",
  },
  sort_by_unrealized_pnl_down_to_up: {
    text: "Unrealized PNL ⬆️",
  },
  sort_by_dcas_up_to_down: {
    text: "DCAs ⬇️",
  },
  sort_by_dcas_down_to_up: {
    text: "DCAs ⬆️",
  },
  sort_by_quantity_up_to_down: {
    text: "Quantity ⬇️",
  },
  sort_by_quantity_down_to_up: {
    text: "Quantity ⬆️",
  },
  sort_by_order_price_up_to_down: {
    text: "Order Price ⬇️",
  },
  sort_by_order_price_down_to_up: {
    text: "Order Price ⬆️",
  },
  sort_by_income_up_to_down: {
    text: "Income ⬇️",
  },
  sort_by_income_down_to_up: {
    text: "Income ⬆️",
  },
  sort_by_transaction_type_up_to_down: {
    text: "Transaction Type ⬇️",
  },
  sort_by_transaction_type_down_to_up: {
    text: "Transaction Type ⬆️️",
  },
  sort_by_date_up_to_down: {
    text: "Date ⬇️",
  },
  sort_by_date_down_to_up: {
    text: "Date ⬆️",
  },
  side_long_buy: {
    text: "Open Long",
  },
  side_long_sell: {
    text: "Close long",
  },
  side_short_buy: {
    text: "Close Short",
  },
  side_short_sell: {
    text: "Open Short",
  },
  total: {
    text: "Total",
  },
  password_successfully_updated: {
    text: "Password successfully updated",
  },
  invalid_url: {
    text: "Invalid url.",
  },
  email: {
    input: {
      placeholder: "Email",
      validation: "* Required",
    },
  },
  password: {
    input: {
      placeholder: "Password",
      validation: "* Required",
      validation_min_chars: "At least 8 characters",
    },
  },
  confirm_password: {
    input: {
      placeholder: "Confirm password",
      validation_required: "* Required",
      validation_match: "Password does not match",
    },
  },
  invitation_code: {
    input: {
      placeholder: "Código de invitación",
      validation: "* Required",
    },
  },
  exchange_account_name: {
    input: {
      placeholder: "e.j. Binance Futuros API key",
      validation: "* Required",
    },
  },
  api_key: {
    input: {
      placeholder: "Account API Key",
      validation: "* Required",
    },
  },
  api_secret: {
    input: {
      placeholder: "Account API Secret",
      validation: "* Required",
    },
  },
  first_name: {
    input: {
      placeholder: "First name",
      validation: "* Required",
    },
  },
  current_accumulated: {
    input: {
      placeholder: "Current accumulated",
    },
  },
  last_name: {
    input: {
      placeholder: "Last name",
      validation: "* Required",
    },
  },
  country: {
    input: {
      placeholder: "Country",
      validation: "* Required",
    },
  },
  terms_and_conditions_signup_label: {
    text: "I agree to the",
    validation: "* Required",
  },
  terms_and_conditions: {
    text: "terms and conditions",
  },
  privacy_policy: {
    text: "privacy policy",
  },
  and_terms_and_conditions_signup_label: {
    text: "and",
  },
  login: {
    text: "Login",
  },
  managed_symbol: {
    text: "Bot managed",
  },
  signup: {
    text: "Sing up",
  },
  invalid_credentials: {
    text: "Invalid credentials",
  },
  resend_confirmation_email: {
    text: "Resend confirmation email",
  },
  email_sent: {
    text: "Email sent",
  },
  subscribed: {
    text: "Subscribed",
  },
  welcome: {
    text: "Welcome",
  },
  forgot_password: {
    text: "Forgot password",
  },
  recover_password: {
    text: "Recover password",
  },
  insert_the_new_password: {
    text: "Insert the new password",
  },
  insert_email_to_recover_pass: {
    text: "Type your email and we send you a link to recover your password.",
  },
  recovering_password: {
    text: "Recovering password",
  },
  welcome_page: {
    header: "Welcome to Bot the moon 🎉",
    body: "Complete your profile to start your trip to the moon.",
    button: "Complete profile",
  },
  welcome_video_page: {
    title: "CONNECT YOUR EXCHANGE",
    header: "1. Create a Binance API Key",
    body: "Check the following video explaining how to create an API Key in Binance WEB. Do not forget to enable 2 Factor authentication, it is a mandatory requirement for API Key creation.",
    button: "Next",
    info_pill_1_text:
      "<b>Before saving</b>, copy - paste the API KEY and SECRET KEY into Bot the moon (next step after the video).",
    info_pill_2_text:
      "Keep in mind that <b>SECRET key is only shown during the API creation. It will be hidden afterwards.</b> Please do not share with anyone.",
    info_pill_3_text:
      '<b>Tick all options except "Enable Withdraws"</b> in the API restrictions, bot the moon does not need that kind of access.',
  },
  welcome_api_key_page: {
    title: "BINANCE API KEY",
    header: "2. Add your Keys to connect to BotMoon!",
    body: "Add your User name and the information of your new API: API and Secret Keys.",
    button: "Create 🚀",
    subbutton: "Skip",
  },
  api_message_errors: {
    invalid_invitation_code: "Invalid invitation code",
    unexpected_error: "An unexpected error happened. Please try again later.",
    email_already_exists_error: "Email already in use",
    account_not_activated:
      "Account not activated yet. Please check your inbox and spam folders.",
    invalid_api_key_and_secret_credentials: "API Key or API Secret invalid.",
  },
  api_message_success: {
    exchange_account_created: "Account successfully created 🎉",
  },
  verify_email_page: {
    header: "Hey 👋",
    body: "Welcome abroad, we have just sent you an email to verify your email.",
    button: "Confirm it to 🚀",
  },
  lite_sign_up_success_page: {
    header: "Hey 👋",
    body: "We have registered your account. We will let you know when it is ready.",
    button: "Login",
  },
  activate_page: {
    header: "Hey 👋",
    body1: "We are validating your account. Please, wait a few seconds.",
    body2: "Account validated.",
    body3: "You can now login 🎉",
    body4: "There was a problem validating your account at",
    body5: "Please reach us at",
    email: "email@email.com",
    button: "Go to login",
  },
  forgot_password_email_sent_page: {
    header: "Hey 👋",
    body: "We have sent you an email to recover you password, please check your inbox. Wait a few minutes if you don't see it yet.",
  },
  month: {
    0: "Junuary",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
  },
  disclaimer_1:
    "Spot trading or Future trading in virtual assets carries a high level of risk to your capital. Trading is not suitable for everyone and may result in losses that are greater than your deposit. Always do your own research and trade with money you can afford to lose." +
    "<br/><br/>" +
    "This risk warning provides you with a non-exhaustive overview of the key risks that you should consider when deciding whether to open an account and trade in virtual assets. Also, when subscribing to any of our configurations (bots) you need to take into considerations that it may result in losses." +
    "<br/><br/>" +
    "It is important that you read and understand the relevant legal documentation to fully be aware of the risks involved according to your personal circumstances before deciding to open an account and access or use our services. We recommend that you seek independent advice if you are unsure. Please check our risk warning disclosure for more information.",
  payment_panel: { item_part_1: "Order", item_part_2: "pending to be paid" },
  open: "Open",
  payment_orders: "Payment orders",
  pending_payment_order: "Pending payment order",
  pay: "Pay",
  iva: "VAT",
  period: "Period",
  cuota_sub_amount: "Set up Fee",
  discount: "Discount",
  payment_instructions_1:
    "Payment will be made by Bank Transfer. Soon we will include new payment methods.",
  payment_instructions_2: "Don't forget to include the concept!",
  do_you_need_help: "Do you need help? Contact us at",
  text_copied: "Text copied",
  bank_concept: "Reference",
  bank: "Bank",
  commercial_discount: "Commercial Discount",
  company: "Company",
  sign_out: "Sign out",
  change_password: "Change password",
  switch_theme: "Switch theme",
  total_earnings: {
    text: "Total earnings",
  },
  affiliates_number: {
    text: "Number of affiliates",
  },
  affiliates_page: {
    header: "Hey 👋",
    body_1: "Invite your friends and earn money 🤑",
    body_2: "Copy or share this referral link:",
    button_copy: "Copiar",
    button_share: "Compartir enlace",
  },
}
