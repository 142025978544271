import React, { FC } from "react"
import { BaseModal } from "../BaseModal"
import { HeaderModal } from "../HeaderModal"
import {
  FeatureRequestItem,
  ModelProps as FeatureRequestItemType,
} from "./FeatureRequestItem"

type Props = {
  onCloseClick?: () => void
  onBackClick?: () => void
  onGiveFeedbackClick?: () => void
  onFeatureClick: (item: FeatureRequestItemType) => void
}
const requests = [
  {
    id: 1,
    title:
      "MAX CONCURRENT POSITIONS option for Providers of Profit Share & Copy-Trade. Center",
    description:
      "The Max Concurrent Positions (MCP) restriction is an ESSENTIAL for RISK MANAGEMENT tool enjoyed by Signal Providers and…",
    comments: 12,
    likes: 43,
  },
  {
    id: 2,
    title: "PLEASE ACTIVATE COIN M FUTURES BINANCE",
    description:
      "i used your service regularly until bull market and now I do not want to trade in anything except for BTC or ETH margine…",
    comments: 35,
    likes: 497,
  },
  {
    id: 3,
    title: "Apps for Android or ios",
    description:
      "Android or ios Zignaly app for more easy and convinient access, so we don't have to keep opening the browser everytim…",
    comments: 2,
    likes: 8,
  },
  {
    id: 4,
    title: "Profit sharing withdraw ratio",
    description:
      "It would be great to able to setup a profit sharing with 50% ratio of withdraw and reinvest the rest.",
    comments: 2,
    likes: 3,
  },
  {
    id: 5,
    title: "Traders' Review",
    description:
      "When I came across your website I was over the moon. I thought I found the Holy Grail of trading.I followed the one mo…",
    comments: 98,
    likes: 4840,
  },
  {
    id: 6,
    title: "Add possibility to receive error log message in Telegram",
    description:
      "Please make available a choice to receive error log message. For instance when maximum positions is reached I still…",
    comments: 1,
    likes: 0,
  },
  {
    id: 7,
    title: "Lorem",
    description: "Lorem ipsum",
    comments: 152,
    likes: 436,
  },
] as Array<FeatureRequestItemType>

const FeatureRequestsModal: FC<Props> = ({
  onCloseClick,
  onBackClick,
  onGiveFeedbackClick,
  onFeatureClick,
}) => {
  return (
    <BaseModal displayBlackBg={true} maxWidth="md:!max-w-[70%]">
      <HeaderModal
        onBackClick={onBackClick}
        onButtonClick={onGiveFeedbackClick}
        title={"Feature Request"}
        subtitle={"We’d like to receive your request"}
        hasCloseIcon={false}
        hasBackArrow={true}
        buttonMessage="Give feedback"
      />
      {/* <FeatureRequestModelSearchSection /> */}

      <div className="overflow-scroll max-h-[70vh] sm:max-h-[70vh]">
        {requests.map((request) => (
          <FeatureRequestItem
            key={request.id}
            model={request}
            onClick={onFeatureClick}
          />
        ))}
      </div>
      <div className="overflow-scroll max-h-[70vh] sm:max-h-[80vh]"></div>
    </BaseModal>
  )
}

export { FeatureRequestsModal }
