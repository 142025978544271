import { AvatarDropDown } from "components/dropdowns/AvatarDropDown/AvatarDropDown"
import React, { FC, useRef, useState } from "react"
import DefaultAvatar from "../icons/DefaultAvatar"
import { SwitchTheme } from "../SwithTheme"
import { LanguagePicker } from "../LanguagePicker"

import LogoText from "../../assets/logo-text-long-circle-pink.png"
import { PrivateViewSwitch } from "../PrivateViewSwitch"
import { useUserOwn } from "shared/store/useOwnUser"

type Props = {
  className?: string
}

const Header: FC<Props> = ({ className }) => {
  const dropDownRef = useRef<HTMLElement>(null)

  const [isDropDownOpen, setDropDownOpen] = useState(false)

  const { ownUser } = useUserOwn()
  const [avatarError, setAvatarError] = useState(false)

  const onAvatarClick = () => {
    setDropDownOpen(!isDropDownOpen)
  }

  return (
    <nav className={`relative py-5 ${className}`}>
      <div className="flex flex-wrap items-center">
        {/*<LogoTextLongPinkIcon className={"h-24 h-96"} />*/}
        <div>
          <a href="/">
            <img src={LogoText} className={"h-10 md:h-16 mb-2"} alt="logo" />
          </a>
        </div>

        <div className="flex flex-grow">
          <SwitchTheme className="ml-auto" />
          {ownUser?.is_superuser && (
            <PrivateViewSwitch className="ml-4 md:ml-10" />
          )}
          <LanguagePicker className="ml-4 md:ml-10" />
          <ul className="flex ml-4 list-none md:ml-10">
            <li className="nav-item" onClick={onAvatarClick}>
              {!avatarError ? (
                <img
                  className="w-10 h-10 rounded-full md:w-12 md:h-12"
                  src={ownUser?.avatar ?? ""}
                  onError={() => {
                    setAvatarError(true)
                  }}
                  alt="avatar"
                  onClick={onAvatarClick}
                />
              ) : (
                <DefaultAvatar className="w-10 h-10 rounded-full md:w-12 md:h-12" />
              )}
            </li>
          </ul>
        </div>
      </div>
      {isDropDownOpen && (
        <AvatarDropDown className={"mr-6 "} myRef={dropDownRef} />
      )}
    </nav>
  )
}

export default Header
