import React, { useState } from "react"

export function useStaticPaginatedData<T>(receivedList: T[], pageSize: number) {
  const [list, setList] = React.useState<T[]>(receivedList)
  const [count, setCount] = useState({
    prev: 0,
    next: pageSize,
  })
  const [hasMore, setHasMore] = useState(list.length > pageSize)

  React.useEffect(() => {
    setList(receivedList)
    setHasMore(receivedList.length > pageSize)
  }, [receivedList, pageSize])

  const getMoreData = () => {
    if (count.prev >= list.length) {
      setHasMore(false)
      return
    }

    setCount((prevState) => ({
      prev: prevState.prev + pageSize,
      next: prevState.next + pageSize,
    }))
  }

  return {
    list,
    setList,
    next: count.next,
    hasMore,
    setHasMore,
    getMoreData,
    setCount,
  }
}
