import React, { FC } from "react"
import { MyBot } from "../MyBot"
import { BotSubscription } from "../../shared/models/bot/BotSubscription"
import { useUserOwn } from "../../shared/store/useOwnUser"
import { useNavigate } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component"
import { useSubscriptions } from "../../hooks/useSubscriptions"
import { useBotInfo } from "../../hooks/useBotInfo"
import { BotMockProvider } from "../../shared/providers/bots/BotMockProvider"
import BotApiProvider from "../../shared/providers/bots/BotApiProvider"
import { BASE_URL } from "../../shared/constants/constants"
import { isDemo } from "../../shared/utils"

type Props = {
  className?: string
}

const MyBots: FC<Props> = ({ className }) => {
  const { ownUser, token } = useUserOwn()
  const history = useNavigate()
  const { nextPage, botSubscriptions, hasMore } = useSubscriptions(
    isDemo() ? new BotMockProvider() : new BotApiProvider(BASE_URL)
  )

  if (botSubscriptions?.length === 1) {
    history({
      pathname: `/bot/${botSubscriptions[0]?.id}`,
    })
  }
  const onMyBotClick = (botId: string, subscription: BotSubscription) => {
    history({
      pathname: `/bot/${subscription?.id}`,
    })
  }

  return (
    <InfiniteScroll
      dataLength={botSubscriptions.length}
      next={nextPage}
      hasMore={hasMore}
      loader={<></>}
      scrollableTarget="baseScroll"
    >
      <div
        className={`grid grid-cols-1 gap-4 mx-3 lg:mx-12 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 ${className}`}
      >
        {botSubscriptions?.map((botSubscription) => (
          <MyBot
            key={botSubscription.id}
            data={{
              id: 1,
              avatar: "",
              name: botSubscription.name,
              strategy: "",
              about: "",
              currency: "",
              rating: 0,
              subscriptionId: botSubscription.id,
            }}
            isSubscribed={
              ownUser?.id === botSubscription.user_id &&
              (botSubscription.status === "INITIAL" ||
                botSubscription.status === "RUNNING" ||
                botSubscription.status === "STOPPED")
            }
            token={token}
            title={false}
            onBotClick={(botId: string) => onMyBotClick(botId, botSubscription)}
            isAdmin={ownUser?.is_superuser}
          />
        ))}
      </div>
    </InfiniteScroll>
  )
}

export { MyBots }
