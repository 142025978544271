import { SimpleTabs } from "components/tabs/SimpleTabs"
import React, { FC, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { TablePositionsBot } from "../DashboardPage/components/TablePositionsBot"
import { BotPosition } from "../../../shared/models/bot/BotInfo"
import { SingleDataInfo } from "../../SingleDataInfo"
import { TableOpenOrdersBot } from "./components/TableOpenOrdersBot"
import { IncomesGraph } from "../DashboardPage/components/IncomesGraph"
import { WalletExposure } from "../DashboardPage/components/WalletExposure"
import { TradingViewModal } from "../../modals/TradingViewModal"
import { TableTransactionsBot } from "./components/TableTransactionsBot"
import { useTranslation } from "react-i18next"
import { PositionsListBot } from "../../PositionsListBot"
import { TransactionsListBot } from "../../TransactionsListBot"
import useWindowDimensions from "../../../hooks/useWindowDimensions"
import {
  getRefFromLocation,
  isThinnerEqualThan,
  isWidderThan,
  ScreenSize,
} from "../../../Utils"
import { useUserOwn } from "../../../shared/store/useOwnUser"
import { MonthlyTrack } from "../DashboardPage/components/MonthlyTrack"
import { MonthlyIncomeGraph } from "../DashboardPage/components/MonthlyIncomeGraph"
import { TableMarketPrices } from "./components/TableMarketPrices"
import {
  BotTabs,
  BotTabsPath,
  getBotTabPath,
} from "../../../shared/models/bot/BotTabs"
import { getCommissionSymbol, isDemo } from "../../../shared/utils"
import ReactGA from "react-ga4"
import { PendingPaymentOrder } from "../../../shared/models/payments/Payments"
import { PaymentOrdersNotificationPanel } from "../../PaymentOrdersNotificationPanel"
import { PendingPaymentOrderModal } from "../../PendingPaymentOrderModal/PendingPaymentOrderModal"
import { useBotInfo } from "../../../hooks/useBotInfo"
import { usePaymentOrders } from "../../../hooks/usePaymentOrders"
import { useDailyBalance } from "../../../hooks/useDailyBalance"
import { useMonthlyBalance } from "../../../hooks/useMonthlyBalance"
import { OpenOrdersListBot } from "../../OpenOrdersListBot"
import {
  LiveSubscriptionDataProvider,
  useLiveSubscription,
} from "../../../hooks/useLiveSubscriptionDataContext"
import { BotMockProvider } from "../../../shared/providers/bots/BotMockProvider"
import BotApiProvider from "../../../shared/providers/bots/BotApiProvider"
import { BASE_URL } from "../../../shared/constants/constants"
import DataTrackMockProvider from "../../../shared/providers/dataTrack/DataTrackMockProvider"
import DataTrackApiProvider from "../../../shared/providers/dataTrack/DataTrackApiProvider"
/*import {
  LiveSubscriptionDataProvider,
  useLiveSubscription,
} from "../../../hooks/useLiveSubscriptionDataContext"*/

const Display: FC = () => {
  function getTabFromUrl(): BotTabs {
    switch (getRefFromLocation(window.location)) {
      case BotTabsPath.Positions:
        return BotTabs.Positions
      case BotTabsPath.Transactions:
        return BotTabs.Transactions
      case BotTabsPath.OpenOrders:
        return BotTabs.OpenOrders
      case BotTabsPath.MarketPrices:
        return BotTabs.MarketPrices
      default:
        return BotTabs.Positions
    }
  }

  const history = useNavigate()
  const { subscriptionId } = useParams<{ subscriptionId: string }>()
  const subscriptionIdInt = parseInt(subscriptionId ?? "-1")

  const openTab = getTabFromUrl()
  const query = new URLSearchParams(window.location.search)
  const symbol = query.get("symbol") ?? undefined
  const paymentOrder = query.get("payment-order") ?? undefined

  const { botInfoData, setBotInfoDataResponse } = useBotInfo(
    subscriptionId,
    isDemo() ? new BotMockProvider() : new BotApiProvider(BASE_URL)
  )
  const { paymentOrders } = usePaymentOrders(subscriptionId)
  const { dailyBalance } = useDailyBalance(
    subscriptionId,
    isDemo() ? new DataTrackMockProvider() : new DataTrackApiProvider(BASE_URL)
  )
  const { monthlyBalance } = useMonthlyBalance(
    subscriptionId,
    isDemo() ? new DataTrackMockProvider() : new DataTrackApiProvider(BASE_URL)
  )
  const [tradingViewSymbol, setTradingViewSymbol] = React.useState<{
    open: boolean
    position?: BotPosition
    symbol?: string
  }>({ open: false, position: undefined })
  const [paymentOrderPopup, setPaymentOrderPopup] = React.useState<{
    open: boolean
    pendingPaymentOrder?: PendingPaymentOrder
  }>({ open: false, pendingPaymentOrder: undefined })
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const { ownUser } = useUserOwn()
  const liveData = useLiveSubscription()

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href })
  }, [window.location.href])

  useEffect(() => {
    document.getElementById("baseScroll")?.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (symbol) {
      setTradingViewSymbol({
        open: true,
        position: getPositionBySymbol(symbol),
        symbol: symbol,
      })
      return
    }
    setTradingViewSymbol({
      open: false,
      position: undefined,
      symbol: undefined,
    })
  }, [symbol, botInfoData?.data?.positions])

  useEffect(() => {
    if (paymentOrder && paymentOrders?.data?.pendingPaymentOrders) {
      const pendingPaymentOrderFound =
        paymentOrders?.data?.pendingPaymentOrders?.find(
          (paymentOrderToFind) =>
            paymentOrderToFind?.id?.toString() === paymentOrder
        )
      if (pendingPaymentOrderFound) {
        setPaymentOrderPopup({
          open: true,
          pendingPaymentOrder: pendingPaymentOrderFound,
        })
      }
      return
    }
    setPaymentOrderPopup({
      open: false,
      pendingPaymentOrder: undefined,
    })
  }, [paymentOrder, paymentOrders?.data?.pendingPaymentOrders])

  /*const { data } = useLiveSubscription()*/

  useEffect(() => {
    if (liveData.positions) {
      if (botInfoData?.data?.positions) {
        botInfoData.data.positions = []
        botInfoData.data.positions = botInfoData.data.positions.concat(
          liveData?.positions
        )
      }
    }
    if (liveData.orders) {
      if (botInfoData?.data?.openOrders) {
        botInfoData.data.openOrders = []
        botInfoData.data.openOrders = botInfoData.data.openOrders.concat(
          liveData?.orders
        )
      }
    }
    if (liveData.account) {
      if (botInfoData?.data?.balance) {
        botInfoData.data.balance.registration_datetime =
          liveData.account.registration_datetime
        botInfoData.data.balance.totalWalletBalance =
          liveData.account.totalWalletBalance
        botInfoData.data.balance.totalUnrealizedProfit =
          liveData.account.totalUnrealizedProfit
        botInfoData.data.balance.totalInitialMargin =
          liveData.account.totalInitialMargin
        botInfoData.data.balance.totalMaintMargin =
          liveData.account.totalMaintMargin
        botInfoData.data.balance.totalMarginBalance =
          liveData.account.totalMarginBalance
        botInfoData.data.balance.totalPositionInitialMargin =
          liveData.account.totalPositionInitialMargin
        botInfoData.data.balance.totalOpenOrderInitialMargin =
          liveData.account.totalOpenOrderInitialMargin
        botInfoData.data.balance.availableBalance =
          liveData.account.availableBalance
        botInfoData.data.balance.maxWithdrawAmount =
          liveData.account.maxWithdrawAmount
        botInfoData.data.balance.exchange = liveData.account.exchange
      }
    }

    if (liveData.positions || liveData.orders) {
      setBotInfoDataResponse(botInfoData)
    }
  }, [liveData])
  if (subscriptionIdInt === -1) {
    history(-1)
    return <></>
  }

  const onTabChanged = (tab: number) => {
    switch (tab) {
      case BotTabs.Positions:
        history(`/bot/${subscriptionIdInt}#${BotTabsPath.Positions}`, {
          replace: true,
        })
        break
      case BotTabs.OpenOrders:
        history(`/bot/${subscriptionIdInt}#${BotTabsPath.OpenOrders}`, {
          replace: true,
        })
        break
      case BotTabs.Transactions:
        history(`/bot/${subscriptionIdInt}#${BotTabsPath.Transactions}`, {
          replace: true,
        })
        break
      case BotTabs.MarketPrices:
        history(`/bot/${subscriptionIdInt}#${BotTabsPath.MarketPrices}`, {
          replace: true,
        })
        break
      default:
        break
    }
  }

  function displayTab(tab: BotTabs): React.ReactNode {
    switch (tab) {
      case BotTabs.Positions:
        return (
          <>
            {isWidderThan(width, ScreenSize.md) && (
              <TablePositionsBot
                positions={botInfoData?.data?.positions}
                loading={botInfoData?.loading}
                className="mx-3 lg:mx-12"
                onClickSymbol={(position?: BotPosition) => {
                  history({
                    pathname: `${window.location.pathname}?symbol=${position?.symbol}#${BotTabsPath.Positions}`,
                  })
                }}
                isAdmin={ownUser?.is_superuser}
              />
            )}
            {isThinnerEqualThan(width, ScreenSize.md) && (
              <PositionsListBot
                positions={botInfoData?.data?.positions ?? []}
                loading={botInfoData?.loading}
                onClickSymbol={(position?: BotPosition) => {
                  history({
                    pathname: `${window.location.pathname}?symbol=${position?.symbol}#${BotTabsPath.Positions}`,
                  })
                }}
                isAdmin={ownUser?.is_superuser}
              />
            )}
          </>
        )
      case BotTabs.OpenOrders:
        return (
          <>
            {isWidderThan(width, ScreenSize.md) && (
              <TableOpenOrdersBot
                positions={botInfoData?.data?.openOrders ?? []}
                loading={botInfoData?.loading}
                className="mx-3 lg:mx-12"
                onClickSymbol={(symbol: string) => {
                  history({
                    pathname: `${window.location.pathname}?symbol=${symbol}#${BotTabsPath.OpenOrders}`,
                  })
                }}
              />
            )}
            {isThinnerEqualThan(width, ScreenSize.md) && (
              <OpenOrdersListBot
                openOrders={botInfoData?.data?.openOrders ?? []}
                loading={botInfoData?.loading}
                onClickSymbol={(symbol?: string) => {
                  history({
                    pathname: `${window.location.pathname}?symbol=${symbol}#${BotTabsPath.OpenOrders}`,
                  })
                }}
              />
            )}
          </>
        )
      case BotTabs.Transactions:
        return (
          <>
            <div className="grid mx-3 mb-4 lg:mx-12 sm:grid-cols-1 md:grid-cols-3 xl:grid-flow-col">
              <SingleDataInfo
                className="mb-4 md:mr-4 md:mb-0"
                loading={botInfoData?.data?.transactions === undefined}
                title={t("daily_pnl.text")}
                value={
                  "$" +
                  (
                    (botInfoData?.data?.todayIncome ?? 0) +
                    (botInfoData?.data?.todayFunding ?? 0)
                  ).toFixed(2)
                }
                tooltipContent={t("daily_pnl.tooltip")}
              />
              <SingleDataInfo
                className="mb-4 md:mr-4 md:mb-0"
                loading={botInfoData?.data?.transactions === undefined}
                title={t("funding_fee.text")}
                value={"$" + (botInfoData?.data?.todayFunding?.toFixed(2) ?? 0)}
                tooltipContent={t("funding_fee.tooltip")}
              />
              <SingleDataInfo
                className=""
                loading={botInfoData?.data?.transactions === undefined}
                title={t("commission.text")}
                value={
                  getCommissionSymbol(botInfoData?.data?.transactions) +
                    botInfoData?.data?.todayCommissions?.toFixed(5) ?? "-"
                }
                tooltipContent={t("commission.tooltip")}
              />
            </div>
            {isWidderThan(width, ScreenSize.md) ? (
              <TableTransactionsBot
                transactions={botInfoData?.data?.transactions ?? []}
                loading={botInfoData?.loading}
                className="mx-3 lg:mx-12"
                onClickSymbol={(symbol: string) => {
                  history({
                    pathname: `${window.location.pathname}?symbol=${symbol}#${BotTabsPath.Transactions}`,
                  })
                }}
                isAdmin={ownUser?.is_superuser}
              />
            ) : (
              <TransactionsListBot
                transactions={botInfoData?.data?.transactions ?? []}
                loading={botInfoData?.loading}
                onClickSymbol={(symbol?: string) => {
                  history({
                    pathname: `${window.location.pathname}?symbol=${symbol}#${BotTabsPath.Transactions}`,
                  })
                }}
              />
            )}
          </>
        )
      case BotTabs.MarketPrices:
        return (
          <TableMarketPrices
            className="mx-3 lg:mx-12"
            onClickSymbol={(symbol: string) => {
              history({
                pathname: `${window.location.pathname}?symbol=${symbol}#${BotTabsPath.MarketPrices}`,
              })
            }}
          />
        )
    }
    return null
  }

  //TODO: Use memo, ContentTab

  function getPositionBySymbol(symbol): BotPosition | undefined {
    return botInfoData?.data?.positions?.find(
      (position) => position.symbol === symbol
    )
  }

  const tabs = [
    t("positions.text") +
      (botInfoData?.data?.positions
        ? " - " + botInfoData?.data?.positions.length
        : ""),
    t("open_orders.text") +
      (botInfoData?.data?.openOrders
        ? " - " + botInfoData?.data?.openOrders.length
        : ""),
    t("transactions.text"),
    t("market.text"),
  ]
  return (
    <div className="">
      {/*<div>{data}</div>*/}
      <div className="mt-8">
        {!paymentOrders.loading &&
          paymentOrders?.data?.pendingPaymentOrders &&
          paymentOrders?.data?.pendingPaymentOrders?.length > 0 && (
            <PaymentOrdersNotificationPanel
              className="mx-3 mb-4 lg:mx-12 lg:mb-8"
              payments={paymentOrders?.data}
              loading={paymentOrders.loading}
              onItemClick={(pendingOrder: PendingPaymentOrder) => {
                history({
                  pathname: `${window.location.pathname}?payment-order=${
                    pendingOrder?.id
                  }#${getBotTabPath(openTab)}`,
                })
              }}
            />
          )}
      </div>
      <div className="grid mx-3 lg:mx-12 lg:grid-cols-4 xl:grid-flow-col">
        <SingleDataInfo
          className="mb-4 sm:mb-4 lg:mr-4"
          loading={false}
          title={t("daily_pnl.text")}
          value={
            "$" +
            (
              (botInfoData?.data?.todayIncome ?? 0) +
              (botInfoData?.data?.todayFunding ?? 0)
            ).toFixed(2)
          }
          tooltipContent={t("daily_pnl.tooltip")}
        />
        <SingleDataInfo
          className="mb-4 sm:mb-4 lg:mr-4"
          loading={false}
          title={t("wallet_balance.text")}
          value={
            botInfoData?.data?.balance?.totalWalletBalance
              ? "$" + botInfoData?.data?.balance?.totalWalletBalance.toFixed(2)
              : "-"
          }
          tooltipContent={t("wallet_balance.tooltip")}
        />
        <SingleDataInfo
          className="mb-4 sm:mb-4 lg:mr-4"
          loading={false}
          title={t("available_balance.text")}
          value={
            botInfoData?.data?.balance?.availableBalance
              ? "$" + botInfoData?.data?.balance?.availableBalance.toFixed(2)
              : "-"
          }
          tooltipContent={t("available_balance.tooltip")}
        />
        <SingleDataInfo
          className="mb-4 sm:mb-4"
          loading={false}
          title={t("unrealized_pnl.text")}
          value={
            botInfoData?.data?.balance?.totalUnrealizedProfit
              ? "$" +
                botInfoData?.data?.balance?.totalUnrealizedProfit.toFixed(2)
              : "-"
          }
          tooltipContent={t("unrealized_pnl.tooltip")}
        />
      </div>

      <div className="grid grid-cols-1 mx-3 mb-12 sm:mb-12 lg:mx-12 xl:grid-cols-2">
        <IncomesGraph
          dataBalance={dailyBalance?.data ?? []}
          loading={dailyBalance?.loading ?? true}
          className="mr-0 mb-4 xl:mr-4 xl:mb-0"
        />
        <MonthlyIncomeGraph
          dataBalance={botInfoData?.data?.monthlyIncome ?? []}
          dataFees={botInfoData?.data?.monthly_fee ?? []}
          loading={botInfoData?.loading ?? true}
          className="mr-0 xl:mb-0"
        />
      </div>

      <SimpleTabs
        className="mx-3 mb-8 lg:mx-12"
        onTabChanged={onTabChanged}
        tabs={tabs}
        activeTab={openTab}
      />
      {displayTab(openTab)}
      {tradingViewSymbol.open && (
        <TradingViewModal
          onCloseClick={() => {
            history(-1)
          }}
          position={tradingViewSymbol.position}
          symbol={tradingViewSymbol.symbol}
        />
      )}
      {paymentOrderPopup.open && (
        <PendingPaymentOrderModal
          pendingPaymentOrder={paymentOrderPopup?.pendingPaymentOrder}
          onCloseClick={() => {
            history(-1)
          }}
        />
      )}
    </div>
  )
}

const BotPage: FC = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>()
  return (
    <LiveSubscriptionDataProvider subscriptionId={subscriptionId!}>
      <Display />
    </LiveSubscriptionDataProvider>
  )
}

export default BotPage
