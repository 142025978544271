export type ExchangeAccount = {
  id: number
  created: string
  modified: string
  account_name: string
  account_type: string
  key: string
  password: string
  account_status: string
  account_provider: number
  account_user: number
}

export enum ExchangeAccountType {
  FUTURES = "futures",
}

export enum ExchangeAccountId {
  BINANCE = 1,
}
