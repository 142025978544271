import React, { FC } from "react"
import { LinesShimmer } from "../LinesShimmer"

type Props = {
  className?: string
  nRow: number
}

const TableRowShimmer: FC<Props> = ({ className, nRow }) => {
  function getRaw(key: number): React.ReactNode {
    return (
      <td key={key} className="px-6 py-4 ">
        <LinesShimmer />
      </td>
    )
  }
  const rowsComponent: React.ReactNode[] = []
  for (let i = 0; i < nRow; i++) {
    rowsComponent.push(getRaw(i))
  }
  return (
    <tr
      className={
        "border-b border-table-row-divider-bg-light-light dark:border-table-row-divider-bg-dark-dark"
      }
    >
      {rowsComponent}
    </tr>
  )
}

export { TableRowShimmer }
