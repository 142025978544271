import React, { FC } from "react"
import BaseIcon from "./BaseIcon"

type Props = {
  color?: string
  height?: number
  width?: number
  className?: string
  onClick: () => void
}

const HelpIcon: FC<Props> = ({
  color = "#FFF",
  height = 24,
  width = 24,
  className,
  onClick,
}) => {
  return (
    <BaseIcon
      onClick={onClick}
      className={className}
      viewBox="0 0 71 55"
      height={height}
      width={width}
      content={[
        <title key="title">DiscordIcon</title>,
        <g key="g" clipPath="url(#prefix__clip0)">
          <path
            d="M60.105 4.898A58.55 58.55 0 0045.653.415a.22.22 0 00-.233.11 40.784 40.784 0 00-1.8 3.697c-5.456-.817-10.886-.817-16.23 0-.485-1.164-1.201-2.587-1.828-3.697a.228.228 0 00-.233-.11 58.386 58.386 0 00-14.451 4.483.207.207 0 00-.095.082C1.578 18.73-.944 32.144.293 45.39a.244.244 0 00.093.167c6.073 4.46 11.955 7.167 17.729 8.962a.23.23 0 00.249-.082 42.08 42.08 0 003.627-5.9.225.225 0 00-.123-.312 38.772 38.772 0 01-5.539-2.64.228.228 0 01-.022-.378c.372-.279.744-.569 1.1-.862a.22.22 0 01.23-.03c11.619 5.304 24.198 5.304 35.68 0a.219.219 0 01.233.027c.356.293.728.586 1.103.865a.228.228 0 01-.02.378 36.384 36.384 0 01-5.54 2.637.227.227 0 00-.121.315 47.249 47.249 0 003.624 5.897.225.225 0 00.249.084c5.801-1.794 11.684-4.502 17.757-8.961a.228.228 0 00.092-.164c1.48-15.315-2.48-28.618-10.497-40.412a.18.18 0 00-.093-.084zm-36.38 32.427c-3.497 0-6.38-3.211-6.38-7.156 0-3.944 2.827-7.156 6.38-7.156 3.583 0 6.438 3.24 6.382 7.156 0 3.945-2.827 7.156-6.381 7.156zm23.593 0c-3.498 0-6.38-3.211-6.38-7.156 0-3.944 2.826-7.156 6.38-7.156 3.582 0 6.437 3.24 6.38 7.156 0 3.945-2.798 7.156-6.38 7.156z"
            fill={color}
          />
        </g>,
        <defs key="defs">
          <clipPath id="prefix__clip0">
            <path fill="#fff" d="M0 0h71v55H0z" />
          </clipPath>
        </defs>,
      ]}
    />
  )
}

export default HelpIcon
