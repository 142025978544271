import { FC } from "react"

type Props = {
  onClick?: () => void
  className?: string
  title?: string
  subtitle?: string
  topBorder?: boolean
  bottomBorder?: boolean
}

const HelpSectionModal: FC<Props> = ({
  className,
  title,
  subtitle,
  onClick,
  topBorder = false,
  bottomBorder = false,
}) => {
  let borderStyles = topBorder ? "border-t-[1px] " : ""
  borderStyles += bottomBorder ? "border-b-[1px] " : ""

  return (
    <div
      onClick={onClick}
      className={`cursor-pointer border-light-divider-bg-light-dark ${borderStyles} ${className}`}
    >
      {title && title.length > 0 && (
        <p className="mx-4 mt-6 text-main-text-light">{title}</p>
      )}
      {subtitle && subtitle.length > 0 && (
        <p className="mx-4 mb-6 text-sm text-sub-text-light">{subtitle}</p>
      )}
    </div>
  )
}

export { HelpSectionModal }
