import { PrimaryButton } from "components/PrimaryButton"
import React, { FC } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { ref } from "yup"
import { ValidationFieldForm } from "../../common/forms/ValidationFieldForm"
import AuthApIProvider from "shared/providers/auth/AuthApIProvider"
import { useTranslation } from "react-i18next"
import { ShowHideToggleIcon } from "../../ShowHideToggleIcon"
import LogoText from "../../../assets/logo-text-long-circle-white.png"
import { YoutubeEmbed } from "../../YoutubeEmbed"
import { CountryPicker } from "../../CountryPicker/CountryPicker"
import { notifyError } from "../../Toasts/ToastUtils"
import {
  BASE_URL,
  PASSWORD_MIN_CHARS,
} from "../../../shared/constants/constants"

const LiteSignUpPage: FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const userLeadCode = query.get("referral") ?? undefined
  const [loading, setLoading] = React.useState(false)
  const [passwordShown, setPasswordShown] = React.useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = React.useState(false)
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown)
  }
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(!confirmPasswordShown)
  }

  const history = useNavigate()

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirm_password: "",
      country: "",
      exchange: "",
      apiKey: undefined,
      apiSecret: undefined,
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t("email.input.validation")),
      country: Yup.string().required(t("country.input.validation")),
      firstName: Yup.string().required(t("first_name.input.validation")),
      lastName: Yup.string().required(t("last_name.input.validation")),
      password: Yup.string()
        .required(t("password.input.validation"))
        .min(PASSWORD_MIN_CHARS, t("password.input.validation_min_chars")),
      confirm_password: Yup.string()
        .required(t("confirm_password.input.validation_required"))
        .oneOf([ref("password")], t("confirm_password.input.validation_match")),
      apiKey: Yup.string().optional(),
      apiSecret: Yup.string().optional(),
    }),
    onSubmit: (values) => {
      setLoading(true)
      new AuthApIProvider(BASE_URL)
        .liteSignUp(
          values.email,
          values.firstName,
          values.lastName,
          values.password,
          values.country,
          //values.exchange,
          "bybit",
          values.apiKey,
          values.apiSecret,
          userLeadCode
        )
        .then(() => {
          setLoading(false)
          history("/sign-up-lite-success", { state: { email: values.email } })
        })
        .catch((e: Error) => {
          setLoading(false)
          notifyError(t("api_message_errors.unexpected_error"))
        })
    },
  })

  return (
    <>
      <div className="h-screen dark:bg-bg-dark bg-bg-light">
        <div className="flex-1 dark:bg-bg-dark bg-bg-light">
          <div className="m-auto sm:w-96 w-full pl-4 pr-4 pt-12 pb-12">
            <div className="flex justify-center">
              <div>
                <a href="/">
                  <img
                    src={LogoText}
                    className={"w-56 sm:w-72 mb-6"}
                    alt="logo"
                  />
                </a>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="w-full self-center px-6 py-8 rounded shadow-2xl bg-container-panel-bg-light dark:bg-container-panel-bg-dark">
                <div className="mt-4">
                  <input
                    className={`input-sign-up-login`}
                    id="email"
                    type="text"
                    placeholder={t("email.input.placeholder")}
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.email}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  <input
                    className={`input-sign-up-login`}
                    id="firstName"
                    type="text"
                    placeholder={t("first_name.input.placeholder")}
                    {...formik.getFieldProps("firstName")}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.firstName}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  <input
                    className={`input-sign-up-login`}
                    id="lastName"
                    type="text"
                    placeholder={t("last_name.input.placeholder")}
                    {...formik.getFieldProps("lastName")}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.lastName}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  <CountryPicker
                    onSelected={(country) => {
                      formik.setFieldValue("country", country.code)
                    }}
                  />
                  {formik.touched.country && formik.errors.country ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.country}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  <div className="relative flex w-full ">
                    <input
                      className={`!pl-2 !pr-12 input-sign-up-login`}
                      id="password"
                      type={passwordShown ? "text" : "password"}
                      autoComplete={"off"}
                      placeholder={t("password.input.placeholder")}
                      {...formik.getFieldProps("password")}
                    />

                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="absolute right-4 top-[20%]"
                    >
                      <ShowHideToggleIcon
                        showedIcon={passwordShown}
                        className="h-6"
                      />
                    </button>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.password}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  <div className="relative flex w-full ">
                    <input
                      className={`!pl-2 !pr-12 input-sign-up-login`}
                      id="confirm_password"
                      type={confirmPasswordShown ? "text" : "password"}
                      autoComplete={"off"}
                      placeholder={t("confirm_password.input.placeholder")}
                      {...formik.getFieldProps("confirm_password")}
                    />

                    <button
                      type="button"
                      onClick={toggleConfirmPasswordVisibility}
                      className="absolute right-4 top-[20%]"
                    >
                      <ShowHideToggleIcon
                        showedIcon={confirmPasswordShown}
                        className="h-6"
                      />
                    </button>
                  </div>
                  {formik.touched.confirm_password &&
                  formik.errors.confirm_password ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.confirm_password}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  <input
                    className={`input-sign-up-login`}
                    id="apiKey"
                    type="text"
                    placeholder={t("api_key.input.placeholder")}
                    {...formik.getFieldProps("apiKey")}
                  />
                  {formik.touched.apiKey && formik.errors.apiKey ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.apiKey}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  <input
                    className={`input-sign-up-login`}
                    id="apiSecret"
                    type="text"
                    placeholder={t("api_secret.input.placeholder")}
                    {...formik.getFieldProps("apiSecret")}
                  />
                  {formik.touched.apiSecret && formik.errors.apiSecret ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.apiSecret}
                    />
                  ) : null}
                </div>

                <PrimaryButton
                  type="submit"
                  loading={loading}
                  className="h-10 mt-8 w-full !py-0"
                >
                  <p>{t("signup.text")}</p>
                </PrimaryButton>
              </div>
            </form>
          </div>
          {/*<div className="mx-auto sm:mt-2 md:mt-4 lg:mt-8 sm:w-96 md:w-full lg:w-[64rem] pl-4 pr-4 pb-12">
            <YoutubeEmbed videoId="5AzcRAM73iU" />
          </div>*/}
        </div>
      </div>
    </>
  )
}

export { LiteSignUpPage }
