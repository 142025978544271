import { FC } from "react"

type Props = {
  onButtonlick?: () => void
  onChangeSearchText?: (newValue: string) => void
  placeholder: string
  className?: string
  light?: boolean
  autoComplete?: boolean
}

const SearchBar: FC<Props> = ({
  className,
  placeholder,
  onChangeSearchText,
  onButtonlick,
  light = false,
  autoComplete = true,
}) => {
  //className={`  dark:text-white dark:placeholder-input-text-placeholder-dark `}

  /*const colorStyles = light
    ? "border-search-bar-border-input-light bg-search-bar-bg-light text-search-bar-text-light placeholder-search-bar-text-placeholder-light "
    : "dark:border-input-border-dark dark:bg-input-bg-dark dark:text-white dark:placeholder-input-text-placeholder-dark "*/
  return (
    <div className="flex">
      <input
        style={{
          boxShadow: "none",
        }}
        className={`py-2 px-3 input ${className}`}
        id="search"
        type="search"
        placeholder={placeholder}
        onChange={(e) => onChangeSearchText?.(e.target.value)}
        autoComplete={autoComplete ? "on" : "off"}
      />
    </div>
  )
}

export { SearchBar }
