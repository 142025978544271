import { JwtInfo } from "../../models/user/JwtInfo"
import ApiProvider from "../ApiProvider"
import AuthProvider from "./AuthProvider"
import TokenInvalidError from "../../exceptions/TokenInvalidError"
import BadRequestError from "../../exceptions/BadRequestError"
import InvalidInvitationCodeError from "../../exceptions/InvalidInvitationCodeError"
import EmailAlreadyExistsError from "../../exceptions/EmailAlreadyExistsError"
import AccountNotActivatedError from "../../exceptions/AccountNotActivatedError"
import InvalidCredentialsError from "../../exceptions/InvalidCredentialsError"
import AlreadyVerifiedError from "../../exceptions/AlreadyVerifiedError"

export default class AuthApiProvider
  extends ApiProvider
  implements AuthProvider
{
  constructor(private baseUrl: string) {
    super()
  }

  async signup(
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    invitationCode: string,
    countryCode: string
  ): Promise<void> {
    return fetch(`${this.baseUrl}api/v1/users/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf8",
      },
      body: JSON.stringify({
        email: email,
        first_name: firstName,
        last_name: lastName,
        password: password,
        invitation: invitationCode,
        country: countryCode,
      }),
    }).then(async (response) => {
      await this.checkSignUpErrors(response)
    })
  }

  async liteSignUp(
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    countryCode: string,
    exchange: string,
    apiKey?: string,
    apiSecret?: string,
    userLeadCode?: string
  ): Promise<void> {
    console.log("email", email)
    console.log("firstName", firstName)
    console.log("lastName", lastName)
    console.log("password", password)
    console.log("countryCode", countryCode)
    console.log("exchange", exchange)
    console.log("api_key", apiKey)
    console.log("api_pass", apiSecret)
    console.log("userLeadCode", userLeadCode)
    return fetch(`${this.baseUrl}api/v1/userlead/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf8",
      },
      body: JSON.stringify({
        email: email,
        first_name: firstName,
        last_name: lastName,
        password: password,
        exchange: exchange,
        api_key: apiKey,
        api_pass: apiSecret,
        country: countryCode,
        user_lead_code: userLeadCode,
      }),
    }).then(async (response) => {
      await this.checkSignUpErrors(response)
    })
  }

  async login(email: string, password: string): Promise<JwtInfo | null> {
    return fetch(`${this.baseUrl}api/v1/login/access-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: "username=" + email + "&password=" + password,
    }).then(async (response) => {
      await this.checkLoginErrors(response)
      return response.json() as Promise<JwtInfo>
    })
  }

  async resendVerificationEmail(email: string): Promise<void> {
    return fetch(`${this.baseUrl}api/v1/users/resend_activation/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf8",
      },
      body: JSON.stringify({ email: email }),
    }).then(async (response) => {
      await this.checkResendActivationEmailErrors(response)
    })
  }

  async verifyUser(uid: string, token: string): Promise<void> {
    return fetch(`${this.baseUrl}api/v1/users/activation/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf8",
      },
      body: JSON.stringify({ uid: uid, token: token }),
    }).then(async (response) => {
      await this.checkVeryUserErrors(response)
    })
  }

  async resetPassword(email: string): Promise<void> {
    return fetch(`${this.baseUrl}api/v1/password-recovery/${email}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf8",
      },
      body: JSON.stringify({ email: email }),
    }).then(async (response) => {
      await this.checkVeryResetPasswordErrors(response)
    })
  }

  async recoverPassword(
    password: string,
    token: string,
    uid: string
  ): Promise<void> {
    return fetch(`${this.baseUrl}api/v1/users/reset_password_confirm/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf8",
      },
      body: JSON.stringify({
        uid: uid,
        token: token,
        new_password: password,
        re_new_password: password,
      }),
    }).then(async (response) => {
      await this.checkVeryResetPasswordErrors(response)
    })
  }

  private async checkVeryUserErrors(response: Response) {
    if (response.status === 403) {
      console.log("AlreadyVerifiedError()")
      throw new AlreadyVerifiedError()
    }
    if (response.status === 400 || response.status === 401) {
      console.log("BadRequestError()")
      throw new BadRequestError()
    }
  }

  private async checkVeryResetPasswordErrors(response: Response) {
    if (response.status === 400 || response.status === 401) {
      console.log("BadRequestError()")
      throw new BadRequestError()
    }
  }

  private async checkSignUpErrors(response: Response) {
    if (response.status === 400) {
      const errorResponse = await response.json()
      if (errorResponse?.invitation?.includes("Invitation not available")) {
        console.log("InvalidInvitationCodeError()")
        throw new InvalidInvitationCodeError()
      }
      if (
        errorResponse?.email?.includes("User with this email already exists.")
      ) {
        console.log("EmailAlreadyExistsError()")
        throw new EmailAlreadyExistsError()
      }
      console.log("BadRequestError()")
      throw new BadRequestError()
    }
  }

  private async checkLoginErrors(response: Response) {
    if (response.status === 400 || response.status === 401) {
      const errorResponse = await response.json()
      if (
        errorResponse?.detail ===
        "No active account found with the given credentials"
      ) {
        console.log("InvalidCredentialsError()")
        throw new InvalidCredentialsError()
      }
      console.log("BadRequestError()")
      throw new BadRequestError()
    }
  }

  private async checkResendActivationEmailErrors(response: Response) {
    if (response.status === 400 || response.status === 401) {
      console.log("BadRequestError()")
      throw new BadRequestError()
    }
  }
}
