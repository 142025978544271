import React, { FC } from "react"
import Tippy from "@tippyjs/react/headless"
import "tippy.js/dist/tippy.css"
import { Tooltip } from "../Tooptip"
import InfoIcon from "../icons/InfoIcon"

type Props = {
  className?: string
  title: string
  value: String
  loading?: boolean
  tooltipContent?: string
}

export const SingleDataInfo: FC<Props> = ({
  className,
  title,
  value,
  loading = false,
  tooltipContent,
}) => {
  return (
    <div className={`single-data-info-container ${className}`}>
      <p className="single-data-info-title">{loading ? "-" : value}</p>
      <div className={"flex justify-center"}>
        <h4 className="text-sm dark:text-sub-text-dark">{title}</h4>
        {tooltipContent && (
          <Tippy
            interactive={true}
            hideOnClick={true}
            render={(attrs) => (
              <Tooltip {...attrs} content={tooltipContent ?? ""} />
            )}
            disabled={!tooltipContent}
            placement={"bottom"}
          >
            <div className={"self-center "}>
              <InfoIcon className="single-data-info-icon" />
            </div>
          </Tippy>
        )}
      </div>
    </div>
  )
}
