import React, { FC } from "react"
import { Column } from "react-table"
import { TablePositions } from "./TablePositions"
import { TableRowShimmer } from "../../../../shimmers/TableRowShimmer"
import { BotPosition } from "../../../../../shared/models/bot/BotInfo"
import {
  compareNumericAbsoluteReactTableString,
  compareNumericReactTableString,
} from "../../../../../Utils"
import { useTranslation } from "react-i18next"

type Props = {
  isAdmin?: boolean
  className?: string
  loading?: boolean
  positions?: BotPosition[]
  onClickSymbol?: (position?: BotPosition) => void
}

const TablePositionsBot: FC<Props> = ({
  isAdmin = false,
  className,
  loading,
  positions,
  onClickSymbol,
}) => {
  const { t } = useTranslation()
  const columnsUser = [
    {
      id: "symbol",
      Header: t("symbol.text").toUpperCase(),
      accessor: "symbol",
    },
    {
      Header: t("entry_price.text").toUpperCase(),
      tooltip: t("entry_price.tooltip"),
      accessor: "entry_price",
      sortType: compareNumericReactTableString,
    },
    {
      Header: t("dcas.text"),
      tooltip: t("dcas.tooltip"),
      accessor: "dcas",
    },
    {
      Header: t("wallet_exposure.text").toUpperCase(),
      tooltip: t("wallet_exposure.tooltip"),
      accessor: "walletExposure",
      sortType: compareNumericReactTableString,
    },
    {
      Header: t("unrealized_pnl.text").toUpperCase(),
      tooltip: t("unrealized_pnl.tooltip"),
      accessor: "unrealizedProfit",
      sortType: compareNumericReactTableString,
    },
    {
      Header: t("next_dca.text").toUpperCase(),
      accessor: "nextdca",
      sortType: compareNumericReactTableString,
    },
    {
      Header: t("next_tp.text").toUpperCase(),
      accessor: "nexttp",
      sortType: compareNumericReactTableString,
    },
    {
      Header: t("liquidation_price.text").toUpperCase(),
      accessor: "liquidation_price",
      sortType: compareNumericReactTableString,
    },
    {
      Header: t("position_size.text").toUpperCase(),
      accessor: "position_size",
    },
    {
      Header: "side".toUpperCase(),
      accessor: "side",
    },
  ] as Column<BotPosition>[]
  const columnsAdmin = [
    {
      id: "symbol",
      Header: t("symbol.text").toUpperCase(),
      accessor: "symbol",
    },
    {
      Header: t("entry_price.text").toUpperCase(),
      tooltip: t("entry_price.tooltip"),
      accessor: "entry_price",
      sortType: compareNumericReactTableString,
    },
    {
      Header: t("dcas.text"),
      tooltip: t("dcas.tooltip"),
      accessor: "dcas",
    },
    {
      Header: t("wallet_exposure.text").toUpperCase(),
      tooltip: t("wallet_exposure.tooltip"),
      accessor: "walletExposure",
      sortType: compareNumericAbsoluteReactTableString,
    },
    {
      Header: t("unrealized_pnl.text").toUpperCase(),
      tooltip: t("unrealized_pnl.tooltip"),
      accessor: "unrealizedProfit",
      sortType: compareNumericReactTableString,
    },
    {
      Header: t("next_dca.text").toUpperCase(),
      accessor: "nextdca",
      sortType: compareNumericReactTableString,
    },
    {
      Header: t("next_tp.text").toUpperCase(),
      accessor: "nexttp",
      sortType: compareNumericReactTableString,
    },
    {
      Header: t("liquidation_price.text").toUpperCase(),
      accessor: "liquidation_price",
      sortType: compareNumericReactTableString,
    },
    {
      Header: t("position_size.text").toUpperCase(),
      accessor: "position_size",
    },
    {
      Header: t("lasttp.text").toUpperCase(),
      accessor: "lasttp",
    },
    {
      Header: t("symbol_performance_30d.text").toUpperCase(),
      accessor: "symbol_performance",
    },
    {
      Header: t("managed_symbol.text").toUpperCase(),
      accessor: "managed_symbol",
    },
    {
      Header: t("risk.text").toUpperCase(),
      accessor: "risk",
    },
    {
      Header: "side".toUpperCase(),
      accessor: "side",
    },
  ] as Column<BotPosition>[]

  return (
    <div className={`table-base ${className}`}>
      {loading ? (
        <table className={"mt-7"}>
          <thead>
            <tr>
              {(isAdmin ? columnsAdmin : columnsUser).map((column, index) => (
                <th key={index} className="px-6 pb-4 table-text-header">
                  {column.Header as string}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            <TableRowShimmer
              nRow={(isAdmin ? columnsAdmin : columnsUser).length}
            />
            <TableRowShimmer
              nRow={(isAdmin ? columnsAdmin : columnsUser).length}
            />
            <TableRowShimmer
              nRow={(isAdmin ? columnsAdmin : columnsUser).length}
            />
            <TableRowShimmer
              nRow={(isAdmin ? columnsAdmin : columnsUser).length}
            />
            <TableRowShimmer
              nRow={(isAdmin ? columnsAdmin : columnsUser).length}
            />
            <TableRowShimmer
              nRow={(isAdmin ? columnsAdmin : columnsUser).length}
            />
          </tbody>
        </table>
      ) : (
        <TablePositions
          className={"pt-12"}
          columns={isAdmin ? columnsAdmin : columnsUser}
          dataPositions={positions ?? []}
          onClickSymbol={onClickSymbol}
          isAdmin={isAdmin}
        />
      )}
    </div>
  )
}

export { TablePositionsBot }
