import React, { FC } from "react"
import "tippy.js/dist/tippy.css"
import { BotOpenOrder } from "../../shared/models/bot/BotInfo"
import { OpenOrderListBot } from "./OpenOrderListBot"
import { SearchBar } from "../Searchbar"
import { t } from "i18next"
import { SortByOptions } from "../../shared/models/bot/SortByBotDataOptions"
import InfiniteScroll from "react-infinite-scroll-component"
import { useStaticPaginatedData } from "../../hooks/useStaticPaginatedData"

const PAGE_SIZE = 10
type Props = {
  className?: string
  openOrders: BotOpenOrder[]
  loading?: boolean
  onClickSymbol?: (symbol?: string) => void
}

const OpenOrdersListBot: FC<Props> = ({
  className,
  openOrders,
  onClickSymbol,
}) => {
  const { list, setList, next, hasMore, setHasMore, getMoreData, setCount } =
    useStaticPaginatedData<BotOpenOrder>(openOrders, PAGE_SIZE)

  const [sortByOption, setSortBy] = React.useState(
    SortByOptions.Symbol.toString()
  )

  function sortBy() {
    switch (sortByOption) {
      case SortByOptions.Symbol:
        return list.sort((a, b) => a.symbol.localeCompare(b.symbol))
      case SortByOptions.QuantityUpToDown:
        return list.sort((a, b) => a.quantity - b.quantity)
      case SortByOptions.QuantityDownToUp:
        return list.sort((a, b) => b.quantity - a.quantity)
      case SortByOptions.OrderPriceUpToDown:
        return list.sort((a, b) => a.price - b.price)
      case SortByOptions.OrderPriceDownToUp:
        return list.sort((a, b) => b.price - a.price)
      default:
        return list
    }
  }
  sortBy()

  return (
    <div className={`pt-6 dark:bg-bg-dark bg-white ${className}`}>
      <div className="flex flex-wrap">
        <SearchBar
          className="w-28 mx-3 lg:mx-12 mb-4"
          placeholder={t("symbol.text")}
          onChangeSearchText={(newValue) => {
            setCount({
              prev: 0,
              next: PAGE_SIZE,
            })
            setHasMore(true)
            setList(
              openOrders.filter((position) =>
                position.symbol.toLowerCase().includes(newValue.toLowerCase())
              )
            )
          }}
          autoComplete={false}
        />
        <div className="mx-3 lg:mx-12 ">
          <select
            style={{
              boxShadow: "none",
            }}
            className="dropdown"
            name="days"
            id="days_select"
            onChange={(event) => setSortBy(event.target.value)}
          >
            <option value={SortByOptions.Symbol}>
              {t<string>("sort_by_symbol.text")}
            </option>
            <option value={SortByOptions.QuantityDownToUp}>
              {t<string>("sort_by_quantity_up_to_down.text")}
            </option>
            <option value={SortByOptions.QuantityUpToDown}>
              {t<string>("sort_by_quantity_down_to_up.text")}
            </option>
            <option value={SortByOptions.OrderPriceDownToUp}>
              {t<string>("sort_by_order_price_up_to_down.text")}
            </option>
            <option value={SortByOptions.OrderPriceUpToDown}>
              {t<string>("sort_by_order_price_down_to_up.text")}
            </option>
          </select>
        </div>
      </div>
      <div className={`grid grid-cols-1`}>
        {list.length > 0 && (
          <InfiniteScroll
            dataLength={next}
            next={getMoreData}
            hasMore={hasMore}
            loader={<></>}
            scrollableTarget="baseScroll"
          >
            {list?.slice(0, next)?.map((openOrder) => (
              <>
                <OpenOrderListBot
                  className="mx-3 lg:mx-12"
                  key={openOrder.id}
                  openOrder={openOrder}
                  onClickSymbol={(symbol) => {
                    onClickSymbol?.(symbol)
                  }}
                />
                <div className="w-full h-px dark:bg-divider-bg-lighter-dark bg-divider-bg-lighter-light" />
              </>
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  )
}

export { OpenOrdersListBot }
