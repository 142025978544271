export const TRANSLATIONS_ES = {
  connected_accounts: {
    text: "Cuentas conectadas",
    tooltip: "Cuentas de Binance conectadas a Bot the Moon.",
  },
  daily_pnl: {
    text: "Ganancia Diaria",
    tooltip: "Ganancias y pérdidas totales en el día",
  },
  monthly_pnl: {
    text: "Ganancia Mensual",
  },
  monthly_wallet_balance: {
    text: "Balance Cartera Mensual",
  },
  daily_average_pnl: {
    text: "Ganancia Media Diaria",
    tooltip: "Media de ganancias y pérdidas totales en el día",
  },
  avg_daily_gain: {
    text: "Media Diaria",
  },
  daily_net_income: {
    text: "Ganancia diaria",
  },
  wallet_balance: {
    text: "Balance cartera",
    tooltip:
      "Saldo de la billetera = Transferencia total neta + Ganancia total realizada + Tarifa de financiación total neta - Comisión total.",
  },
  available_balance: {
    text: "Balance disponible",
    tooltip:
      "Cantidad disponible para transferir a cuentas spot o a otras cuentas.",
  },
  symbol: {
    text: "Símbolo",
  },
  unrealized_pnl: {
    text: "P&G sin resolver",
    tooltip:
      "Ganancias y pérdidas calculadas en función de la base de precios seleccionada.",
  },
  wallet_exposure: {
    text: "Exposición cartera",
    tooltip: "Porcentaje del capital total destinado a cada posición",
  },
  entry_price: {
    text: "Precio de entrada",
    tooltip:
      "Precio de compra de un activo en un momento determinado del tiempo.",
  },
  price: {
    text: "Precio",
  },
  dcas: {
    text: "DCAs",
    tooltip:
      "Estrategia de inversión en la que se divide la cantidad total a invertir en compras periódicas de un activo objetivo en un esfuerzo por reducir el impacto de la volatilidad en la compra total.",
  },
  next_dca: {
    text: "Siguiente DCA",
    tooltip: "TBD",
  },
  liquidation_price: {
    text: "Precio de liquidación",
    tooltip: "TBD",
  },
  position_size: {
    text: "Tamaño posición",
  },
  next_tp: {
    text: "Siguiente TP",
    tooltip: "TBD",
  },
  symbol_performance_30d: {
    text: "Rendimiento últimos 30 días",
  },
  lasttp: {
    text: "Último TP",
  },
  funding_fee: {
    text: "Tasa de financiación",
    tooltip:
      "Fee de binance liquidada cada 8 horas. Dependiendo si es positiva o negativa, el usuario puede salir a pagar o a recibir.",
  },
  live_price: {
    text: "Precio actual",
    tooltip: "TBD",
  },
  commission: {
    text: "Comisión",
    tooltip: "Comisión cobrada por hacer trading en Futuros.",
  },
  daily_commission: {
    text: "Comisión diaria",
    tooltip: "Comisión diaria cobrada por hacer trading en Futuros.",
  },
  order_price: {
    text: "Precio de Orden",
    tooltip:
      "Precio de una orden de un activo en un momento determinado del tiempo.",
  },
  quantity: {
    text: "Cantidad",
    tooltip:
      "Las unidades que utilizan monedas estables en los Futuros USDT-M, los importes de las posiciones abiertas que se muestran se calculan sobre la base de la cantidad real de monedas.",
  },
  buy_sell: {
    text: "Compra/Venta",
  },
  date: {
    text: "Fecha",
  },
  income: {
    text: "Ganancia",
  },
  type: {
    text: "Tipo",
  },
  profit: {
    text: "Beneficio",
  },
  bttm_service_cost: {
    text: "Cuota del servicio y mantenimiento",
  },
  earnings_before_interests_taxes_acronyms: {
    text: "BAII",
  },
  service_cost: {
    text: "Cuota de servicio y mantenimiento",
  },
  positions: {
    text: "Posiciones",
  },
  open_orders: {
    text: "Órdenes abiertas",
  },
  transactions: {
    text: "Transacciones",
  },
  market: {
    text: "Mercado",
  },
  "24hchange": {
    text: "24h Cambio",
  },
  "24hvolume": {
    text: "24h Volumen",
  },
  risk: {
    text: "Riesgo",
  },
  last_30_days: {
    text: "Últimos 30 días",
  },
  last_15_days: {
    text: "Últimos 15 días",
  },
  last_7_days: {
    text: "Últimos 7 días",
  },
  sort_by_symbol: {
    text: "Orden Alfabético",
  },
  sort_by_unrealized_pnl_up_to_down: {
    text: "P&G sin resolver ⬇️",
  },
  sort_by_unrealized_pnl_down_to_up: {
    text: "P&G sin resolver ⬆️",
  },
  sort_by_dcas_up_to_down: {
    text: "DCAs ⬇️",
  },
  sort_by_dcas_down_to_up: {
    text: "DCAs ⬆️",
  },
  sort_by_quantity_up_to_down: {
    text: "Cantidad ⬇️",
  },
  sort_by_quantity_down_to_up: {
    text: "Cantidad ⬆️",
  },
  sort_by_order_price_up_to_down: {
    text: "Precio de Orden ⬇️",
  },
  sort_by_order_price_down_to_up: {
    text: "Precio de Orden ⬆️",
  },
  sort_by_income_up_to_down: {
    text: "Ganancia ⬇️",
  },
  sort_by_income_down_to_up: {
    text: "Ganancia ⬆️",
  },
  sort_by_transaction_type_up_to_down: {
    text: "Tipo de transacción ⬇️",
  },
  sort_by_transaction_type_down_to_up: {
    text: "Tipo de transacción ⬆️",
  },
  sort_by_date_up_to_down: {
    text: "Fecha ⬇️",
  },
  sort_by_date_down_to_up: {
    text: "Fecha ⬆️",
  },
  side_long_buy: {
    text: "Abrir Largo",
  },
  side_long_sell: {
    text: "Cerrar Largo",
  },
  side_short_buy: {
    text: "Cerrar Corto",
  },
  side_short_sell: {
    text: "Abrir Corto",
  },
  total: {
    text: "Total",
  },
  password_successfully_updated: {
    text: "Contraseña actualizada.",
  },
  invalid_url: {
    text: "Url inválida.",
  },
  email: {
    input: {
      placeholder: "Email",
      validation: "* Obligatorio",
    },
  },
  password: {
    input: {
      placeholder: "Contraseña",
      validation: "* Obligatorio",
      validation_min_chars: "Mínimo 8 caracteres",
    },
  },
  confirm_password: {
    input: {
      placeholder: "Confirmar contraseña",
      validation_required: "* Obligatorio",
      validation_match: "Las contraseñsas no coinciden",
    },
  },
  invitation_code: {
    input: {
      placeholder: "Código de invitación",
      validation: "* Obligatorio",
    },
  },
  exchange_account_name: {
    input: {
      placeholder: "e.j. Binance Futuros API key",
      validation: "* Obligatorio",
    },
  },
  api_key: {
    input: {
      placeholder: "Account API Key",
      validation: "* Obligatorio",
    },
  },
  api_secret: {
    input: {
      placeholder: "Account API Secret",
      validation: "* Obligatorio",
    },
  },
  first_name: {
    input: {
      placeholder: "Nombre",
      validation: "* Obligatorio",
    },
  },
  current_accumulated: {
    input: {
      placeholder: "Acumulado",
    },
  },
  last_name: {
    input: {
      placeholder: "Apellidos",
      validation: "* Obligatorio",
    },
  },
  country: {
    input: {
      placeholder: "País",
      validation: "* Obligatorio",
    },
  },
  terms_and_conditions_signup_label: {
    text: "Estoy de acuerdo con los",
    validation: "* Obligatorio",
  },
  terms_and_conditions: {
    text: "términos y condiciones",
  },
  privacy_policy: {
    text: "política de privacidad",
  },
  and_terms_and_conditions_signup_label: {
    text: "y con la",
  },
  login: {
    text: "Login",
  },
  managed_symbol: {
    text: "Gestionado por el bot",
  },
  signup: {
    text: "Registro",
  },
  invalid_credentials: {
    text: "Credenciales inválidos.",
  },
  resend_confirmation_email: {
    text: "Reenviar email de confirmación",
  },
  email_sent: {
    text: "Email enviado",
  },
  subscribed: {
    text: "Subscrito",
  },
  welcome: {
    text: "Bienvenido",
  },
  forgot_password: {
    text: "Olvidé mi contraseña",
  },
  recover_password: {
    text: "Recuperar contraseña",
  },
  insert_email_to_recover_pass: {
    text: "Escribe tu dirección email y te enviaremos un correo para resetear tu contraseña.",
  },
  insert_the_new_password: {
    text: "Escriba su nueva contraseña",
  },
  recovering_password: {
    text: "Recuperando contraseña",
  },
  welcome_page: {
    header: "Bienvenido a Bot the moon 🎉",
    body: "Completa tu perfil para comenzar tu viaje a la luna.",
    button: "Completar perfil",
  },
  welcome_video_page: {
    title: "BINANCE API KEY",
    header: "1. Crea la API Key de Binance ",
    body: "Haz click en el video para ver cómo crear una API KEY en Binance WEB. No olvides habilitar la autenticación de 2 factores, es un requisito obligatorio para la creación de la API Key.",
    button: "Siguiente paso",
    info_pill_1_text:
      "<b>Antes de guardar</b>, copia y pega la API y la SECRET KEY en Bot the moon (siguiente paso tras el vídeo).",
    info_pill_2_text:
      "Ten en cuenta que la <b>SECRET Key sólo se muestra durante la creación de la API. Después se ocultará.</b> Por favor, no la compartas con nadie.",
    info_pill_3_text:
      '<b>Marca todas las opciones excepto “Activar retiros"</b> en las restricciones de la API, Bot the moon no necesita ese tipo de acceso.',
  },
  welcome_api_key_page: {
    title: "CONECTA TU EXCHANGE",
    header: "2. Añade tus datos a Bot The Moon",
    body: "Añade tu nombre de usuario y la información sobre tu API: API y Secret Key.",
    button: "Crear 🚀",
    subbutton: "Omitir",
  },
  api_message_errors: {
    invalid_invitation_code: "Código de invitación inválido.",
    unexpected_error: "Se ha producido un error inesperado. Pruebe más tarde.",
    email_already_exists_error: "Este email ya está en uso.",
    account_not_activated:
      "Cuenta no activada todavía. Por favor consulta tu correo y revisa la bandeja de spam.",
    invalid_api_key_and_secret_credentials: "API Key o API Secret inválidos.",
  },
  api_message_success: {
    exchange_account_created: "Cuenta creada con éxito 🎉",
  },
  verify_email_page: {
    header: "Hola 👋",
    body: "Bienvenido, te hemos enviado un email para confirmar tu cuenta.",
    button: "Confírmalo para 🚀",
  },
  lite_sign_up_success_page: {
    header: "Hola 👋",
    body: "Hemos registrado tu cuenta. Te contactaremos cuando esté lista.",
    button: "Login",
  },
  activate_page: {
    header: "Hola 👋",
    body1: "Estamos confirmando tu cuenta. Por favor, espere unos segundos.",
    body2: "Cuenta validada.",
    body3: "Ya puedes loguearte 🎉",
    body4: "Hubo un problema validando tu cuenta.",
    body5: "Por favor, contacta con nosotros en",
    email: "email@email.com",
    button: "Ir al login",
  },
  forgot_password_email_sent_page: {
    header: "Hola 👋",
    body: "Te hemos enviado un email para recuperar tu contraseña, por favor comprueba tu bandeja de entrada. Quizá tarde unos minutos en llegar.",
  },
  month: {
    0: "Enero",
    1: "Febrero",
    2: "Marzo",
    3: "Abril",
    4: "Mayo",
    5: "Junio",
    6: "Julio",
    7: "Agosto",
    8: "Septiembre",
    9: "Octubre",
    10: "Noviembre",
    11: "Diciembre",
  },
  disclaimer_1:
    "El comercio en Spot o de futuros en activos virtuales conlleva un alto nivel de riesgo para su capital. Las operaciones no son adecuadas para todo el mundo y pueden dar lugar a pérdidas superiores a su depósito. Investigue siempre por su cuenta y opere con el dinero que pueda permitirse perder." +
    "<br/><br/>" +
    "Esta advertencia de riesgo le proporciona una visión general no exhaustiva de los principales riesgos que debe tener en cuenta a la hora de decidir si abrir una cuenta y operar con activos virtuales. Además, al suscribirse a cualquiera de nuestras configuraciones (bots) debe tener en cuenta que puede ocasionar pérdidas." +
    "<br/><br/>" +
    "Es importante que lea y comprenda la documentación legal pertinente para ser plenamente consciente de los riesgos que conlleva según sus circunstancias personales antes de decidir abrir una cuenta y acceder a nuestros servicios. Le recomendamos que busque asesoramiento independiente si no está seguro. Para más información, consulte nuestra declaración de advertencia de riesgos.",
  payment_panel: { item_part_1: "Orden", item_part_2: "pendiente de pago" },
  open: "Abrir",
  payment_orders: "Órdenes de pago",
  pending_payment_order: "Orden pendiente de pago",
  pay: "Pagar",
  iva: "IVA",
  period: "Periodo",
  cuota_sub_amount: "Cuota de subscripción",
  discount: "Descuento",
  payment_instructions_1:
    "El pago se realizará mediante transferencia bancaria. Pronto incluiremos nuevos métodos de pago.",
  payment_instructions_2: "¡No olvides incluir el concepto!",
  do_you_need_help:
    "Si necesitas ayuda puedes contactarnos a través del email:",
  text_copied: "Texto copiado",
  bank_concept: "Concepto o referencia",
  bank: "Banco",
  commercial_discount: "Descuento Comercial",
  company: "Empresa",
  sign_out: "Cerrar sesión",
  change_password: "Cambiar contraseña",
  switch_theme: "Cambiar tema",
  total_earnings: {
    text: "Ganancias totales",
  },
  affiliates_number: {
    text: "Número de afiliados",
  },
  affiliates_page: {
    header: "Hola 👋",
    body_1: "Invita amigos y gana dinero 🤑",
    body_2: "Copia o comparte este enlace de referido:",
    button_copy: "Copiar",
    button_share: "Compartir enlace",
  },
}
