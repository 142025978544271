import ApiProvider from "../ApiProvider"
import PaymentProvider from "./PaymentsProvider"
import { PaymentOrders } from "../../models/payments/Payments"

export default class PaymentApiProvider
  extends ApiProvider
  implements PaymentProvider
{
  constructor(private baseUrl: string) {
    super()
  }

  async getPaymentOrders(
    token: string,
    subscriptionId: number
  ): Promise<PaymentOrders> {
    return fetch(
      `${this.baseUrl}api/v1/billing/paymentorder?subscription=${subscriptionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: "Bearer " + token,
        },
      }
    ).then((response) => {
      super.checkErrors(response)
      return response.json() as Promise<PaymentOrders>
    })
  }
}
