import React, { FC } from "react"
import { Column, useGlobalFilter, useSortBy, useTable } from "react-table"
import { BotPosition } from "../../../../../shared/models/bot/BotInfo"
import EyeIcon from "../../../../icons/EyeIcon"
import InfoIcon from "../../../../icons/InfoIcon"
import { Tooltip } from "../../../../Tooptip"
import Tippy from "@tippyjs/react/headless"
import "tippy.js/dist/tippy.css"
import { getColorTextPositiveNegative, getDcaClass } from "../../../../../Utils"
import useLivePrices2 from "../../../../../hooks/useLivePrices2"
import { useLivePrices } from "../../../../../hooks/useLivePricesContext"

type Props = {
  isAdmin?: boolean
  className?: string
  columns: Column<BotPosition>[]
  dataPositions: BotPosition[]
  onClickSymbol?: (position?: BotPosition) => void
}

const TablePositions: FC<Props> = ({
  isAdmin = false,
  className,
  columns,
  dataPositions,
  onClickSymbol,
}) => {
  const { symbols } = useLivePrices()
  const tableInstance = useTable(
    {
      columns,
      data: dataPositions,
    },
    useGlobalFilter,
    useSortBy
  )

  const { getTableProps, headerGroups, rows, prepareRow, setGlobalFilter } =
    tableInstance

  return (
    <div
      className={`flex flex-col overflow-x-scroll pt-6 rounded shadow bg-container-panel-bg-light dark:bg-container-panel-bg-dark ${className}`}
    >
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.getHeaderGroupProps().key}>
              {headerGroup.headers.map((column) => (
                <th key={column.id} className="px-6 pb-4 table-text-header">
                  <div className="flex">
                    <div
                      className={"align-text-top"}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span className="ml-2 text-sm">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : "⇅"}
                      </span>
                    </div>
                    {column["tooltip"] && (
                      <div className={"ml-4"}>
                        <Tippy
                          interactive={true}
                          hideOnClick={true}
                          render={(attrs) => (
                            <Tooltip
                              {...attrs}
                              content={column["tooltip"] ?? ""}
                            />
                          )}
                          disabled={!column["tooltip"]}
                          placement={"bottom"}
                        >
                          <div className={""}>
                            <InfoIcon className="table-text-header-info-icon" />
                          </div>
                        </Tippy>
                      </div>
                    )}
                  </div>
                  <div className={"flex pt-6"}>
                    {column["id"] === "symbol" && (
                      <input
                        style={{
                          boxShadow: "none",
                        }}
                        className={`w-24 input`}
                        id="symbol_input"
                        type="search"
                        placeholder="search"
                        onChange={(e) => setGlobalFilter(e.target.value)}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="">
          {rows.map((row, index) => {
            prepareRow(row)
            const key = (row.original.symbol ?? "") + (row.original.side ?? "")
            const dcasClass = getDcaClass(row.original.dcas)
            const widthPercentage =
              Math.abs(row.original.walletExposure ?? 0) / 3
            const widthPercentageClass =
              "w-[" +
              (widthPercentage >= 100 ? 99 : widthPercentage).toFixed(0) +
              "%]"
            const colorUnrealizedClass = row.original.unrealizedProfit
              ? getColorTextPositiveNegative(row.original.unrealizedProfit)
              : ""

            const livePrice = symbols?.find((m) => m.s === row.original.symbol)

            const livePriceDisplay =
              "$" + (livePrice?.c ? parseFloat(livePrice?.c) : "-")
            const livePricePercentageDisplay =
              "(" +
              (livePrice?.P ? parseFloat(livePrice?.P).toFixed(2) : "-") +
              "%)"
            const livePricePercentageColor = livePrice?.P
              ? getColorTextPositiveNegative(livePrice?.P)
              : " dark:text-secondary-text-dark text-secondary-text-light "
            const liqPriceClass =
              row.original.liquidation_price &&
              row.original.liquidation_price >= 0
                ? "dark:text-red-danger-dark text-red-danger-light font-bold"
                : ""

            return (
              <tr
                key={key}
                className={`${
                  index === rows.length - 1
                    ? ""
                    : "border-b border-table-row-divider-bg-light-light dark:border-table-row-divider-bg-dark-dark"
                }  dark:hover:bg-component-highligted-bg-dark hover:bg-component-highligted-bg-light`}
              >
                <>
                  <td className="px-6 py-4 text dark:text-main-text-dark text-main-text-light">
                    <button
                      onClick={() => {
                        onClickSymbol?.(row.original)
                      }}
                    >
                      <div className={"flex"}>
                        <EyeIcon
                          onClick={() => {}}
                          className="table-eye-icon"
                        />
                        <p className="table-text-body">{row.original.symbol}</p>
                      </div>
                      <div className="flex">
                        {/*TODO: Component for live prive*/}
                        <p className="ml-7 table-text-live-price">
                          {livePriceDisplay}
                        </p>
                        <p
                          className={`text-sm ${livePricePercentageColor} text-left ml-1 font-bold`}
                        >
                          {livePricePercentageDisplay}
                        </p>
                      </div>
                    </button>
                  </td>
                  <td className="px-6 py-4 table-text-body">
                    {row.original.entry_price
                      ? "$" + parseFloat(row.original.entry_price.toFixed(8))
                      : "-"}
                  </td>
                  <td className={``}>
                    <p
                      className={`ml-4 flex items-center justify-center rounded-full text text-white w-6 h-6 ${dcasClass}`}
                    >
                      {row.original.dcas}
                    </p>
                  </td>
                  <td className="px-6 py-4 table-text-body">
                    {row.original.walletExposure
                      ? (Math.abs(row.original.walletExposure) / 3).toFixed(2) +
                        "%"
                      : "-"}
                    <div className="relative pt-1">
                      <div className="flex overflow-hidden mb-4 h-2 rounded text dark:bg-placeholder-text-dark bg-placeholder-text-light">
                        <div
                          className={`shadow-none flex flex-col ${widthPercentageClass} text-center whitespace-nowrap text-white justify-center bg-gradient-to-r from-primary-gradien1-dark to-primary-gradien2-dark`}
                        />
                      </div>
                    </div>
                  </td>
                  <td
                    className={`px-6 py-4 font-bold text ${colorUnrealizedClass}`}
                  >
                    {row.original.unrealizedProfit
                      ? row.original.unrealizedProfit.toFixed(2) + "$"
                      : ""}
                  </td>
                  <td className="px-6 py-4 table-text-body">
                    {row.original?.nextdca
                      ? "$" + parseFloat(row.original?.nextdca?.toString())
                      : "-"}
                  </td>
                  <td className="px-6 py-4 table-text-body">
                    {row.original.nexttp
                      ? "$" + parseFloat(row.original.nexttp.toString())
                      : "-"}
                  </td>
                  <td className={`px-6 py-4 table-text-body ${liqPriceClass}`}>
                    {row.original.liquidation_price &&
                    row.original.liquidation_price >= 0
                      ? "$" +
                        parseFloat(row.original.liquidation_price.toString())
                      : "-"}
                  </td>
                  <td className="px-6 py-4 table-text-body">
                    {row.original.position_size
                      ? row.original.position_size
                      : "-"}
                  </td>
                  {isAdmin && (
                    <td className="px-6 py-4 table-text-body">
                      {new Date(row.original.lasttp ?? "").toLocaleString()}
                    </td>
                  )}
                  {isAdmin && (
                    <td className="px-6 py-4 table-text-body">
                      {row.original.symbol_performance
                        ? "$" + row.original.symbol_performance.toFixed(2)
                        : "-"}
                    </td>
                  )}
                  {isAdmin && (
                    <td className="px-6 py-4 table-text-body">
                      {row.original.managed_symbol ? "Yes" : "No"}
                    </td>
                  )}
                  {isAdmin && (
                    <td className={`px-6 py-4 text table-text-body`}>
                      {row.original.risk?.toFixed(1) ?? "-"}
                    </td>
                  )}
                  <td className={`px-6 py-4 text table-text-body`}>
                    {row.original.side}
                  </td>
                </>
                {/*{row.cells.map((cell) => {
                  return (
                    <td className="px-6 py-4 text-xs dark:text-main-text-dark">
                      {cell.render("Cell")}
                    </td>
                  )
                })}*/}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export { TablePositions }
