import React, { useEffect } from "react"
import LoadingData from "../models/LoadingData"
import { ExchangeAccount } from "../models/exchangeAccount/ExchangeAccount"
import ExchangeAccountsApiProvider from "../shared/providers/exchangeAccounts/ExchangeAccountsProvider"
import actionByError from "../components/common/actionByError"
import { useUserOwn } from "../shared/store/useOwnUser"
import { useNavigate } from "react-router-dom"
import CustomError from "../shared/exceptions/CustomError"
import { BASE_URL } from "../shared/constants/constants"

export const useDataExchangeAccounts = () => {
  const { token } = useUserOwn()
  const history = useNavigate()

  const [dataExchangeAccountsRequest, setDataExchangeAccountsRequest] =
    React.useState({
      loading: true,
      data: null,
    } as LoadingData<[ExchangeAccount] | null>)

  useEffect(() => {
    new ExchangeAccountsApiProvider(BASE_URL)
      .getExchangeAccounts(token)
      .then((exchangeAccounts: [ExchangeAccount]) => {
        setDataExchangeAccountsRequest({
          loading: false,
          data: exchangeAccounts,
        })
      })
      .catch((error: CustomError) => {
        actionByError(error.errorCode, history) // FIXME: cambiar esto por un useCustomError hook en el MainLayout para mostrar un mensaje o redirigir al login como este caso
      })
  }, [token])
  return {
    exchangeAccounts: dataExchangeAccountsRequest,
    setDataExchangeAccountsRequest,
  }
}
