import React, { FC, useState } from "react"

import { GiveFeedbackModal } from "../GiveFeedbackModal"
import { HelpModal } from "../HelpModal"
import { ModelProps as FeatureRequestItemType } from "../FeatureRequestsModal/FeatureRequestItem"
import { FeatureRequestsModal } from "../FeatureRequestsModal"
import { FeatureRequestModal } from "../FeatureRequestModal/FeatureRequestModal"

const stackModals: Array<number> = []

type Props = {
  className?: string
}

enum ModalIds {
  HELP = 0,
  FEATURE_REQUEST,
  FEATURE,
  GIVE_FEEDBACK,
}

let helpModalToggle: () => void
let seletedFeature: FeatureRequestItemType

const HelpPagerModal: FC<Props> = ({ className }) => {
  function openPrevious() {
    const previous = stackModals.pop()
    if (!previous) {
      helpModalToggle()
      return
    }
    if (stackModals.length > 0) {
      setOpenModalId(stackModals[stackModals.length - 1])
    }
  }

  const [openModalId, setOpenModalId] = useState<number>(-1)

  helpModalToggle = () => {
    if (openModalId === -1) {
      stackModals.length = 0
      onHelpModal()
      return
    }
    stackModals.length = 0
    setOpenModalId(-1)
  }

  const onHelpModal = () => {
    stackModals.push(ModalIds.HELP)
    setOpenModalId(ModalIds.HELP)
  }

  const onFeatureModel = () => {
    stackModals.push(ModalIds.FEATURE_REQUEST)
    setOpenModalId(ModalIds.FEATURE_REQUEST)
  }

  const onGiveFeedback = () => {
    stackModals.push(ModalIds.GIVE_FEEDBACK)
    setOpenModalId(ModalIds.GIVE_FEEDBACK)
  }

  const onCloseFeatureRequestModal = () => {
    openPrevious()
  }

  const onCloseGiveHelpModal = () => {
    openPrevious()
  }

  const onCloseFeatureModal = () => {
    openPrevious()
  }

  const onCloseHelpModal = () => {
    openPrevious()
  }

  const onContactClick = () => {}

  const onHelpClick = () => {}

  const onFeatureClick = (item: FeatureRequestItemType) => {
    seletedFeature = item
    stackModals.push(ModalIds.FEATURE)
    setOpenModalId(ModalIds.FEATURE)
  }

  return (
    <>
      {openModalId === ModalIds.HELP && (
        <HelpModal
          onContactClick={onContactClick}
          onHelpClick={onHelpClick}
          onFeatureClick={onFeatureModel}
          onCloseClick={onCloseHelpModal}
        />
      )}
      {openModalId === ModalIds.FEATURE_REQUEST && (
        <FeatureRequestsModal
          onFeatureClick={onFeatureClick}
          onGiveFeedbackClick={onGiveFeedback}
          onBackClick={onCloseFeatureRequestModal}
        />
      )}
      {openModalId === ModalIds.FEATURE && (
        <FeatureRequestModal
          onBackClick={onCloseFeatureModal}
          item={seletedFeature}
        />
      )}
      {openModalId === ModalIds.GIVE_FEEDBACK && (
        <GiveFeedbackModal onBackClick={onCloseGiveHelpModal} />
      )}
    </>
  )
}

export { HelpPagerModal, helpModalToggle }
