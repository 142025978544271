import { FC } from "react"

type Props = {
  id?: string
  className?: string
  checked?: boolean
  disabled?: boolean
  label: string
  type?: "checkbox" | "radio"
  light?: boolean
  onChange?: (checked: boolean) => void
}

const Checkbox: FC<Props> = ({
  id,
  className,
  checked = false,
  disabled = false,
  label,
  type = "checkbox",
  light = false,
  onChange,
}) => {
  const themeStyle = light
    ? "dark:border-gray-highligted-light dark:checked:border-primary-1-dark dark:bg-white dark:checked:bg-primary-1-dark "
    : "dark:border-white dark:checked:border-primary-1-dark bg-container-panel-bg-light dark:bg-container-panel-bg-dark dark:checked:bg-primary-1-dark "
  const themeTextStyle = light ? "text-sub-text-light " : "text-main-text-dark "

  return (
    // <label className={"text-primary-gradien2-dark"}>
    //   <input
    //     type="checkbox"
    //     className="rounded text-secondary-gradien1-dark bg-secondary-gradien2-dark"
    //   />
    //   <Field
    //     className="rounded text-secondary-gradien1-dark bg-secondary-gradien2-dark"
    //     type={type}
    //     name={name}
    //     value={value}
    //     checked={checked}
    //     disabled={disabled}
    //   />
    //   {label}
    // </label>

    <label className={`flex ${className} `}>
      <input
        id={id}
        type="checkbox"
        className={`w-5 h-5 mr-2 border-2 rounded ${themeStyle}`}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.checked)
          }
        }}
      />

      <span className={`text-sm bg-transparent ${themeTextStyle}`}>
        {label}
      </span>
    </label>
  )
}

export { Checkbox }
