import { ConnectAccountsPagerModal } from "components/modals/ConnectAccountsPagerModal"
import { HelpPagerModal } from "components/modals/HelpPagerModal"
import { ExchangeAccount } from "models/exchangeAccount/ExchangeAccount"
import React, { FC, useEffect } from "react"
import { DiscordButton } from "../../DiscordButton"
import { MyBots } from "../../MyBots"
import ReactGA from "react-ga4"
import { useUserOwn } from "../../../shared/store/useOwnUser"
import { useDataExchangeAccounts } from "../../../hooks/useDataExchangeAccounts"

const HomePage: FC = () => {
  useEffect(() => {
    document.getElementById("baseScroll")?.scrollTo(0, 0)
  }, [])

  const { token } = useUserOwn()

  const { exchangeAccounts, setDataExchangeAccountsRequest } =
    useDataExchangeAccounts()
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href })
  }, [window.location.href])

  useEffect(() => {}, [token])

  const onDiscordClick = () => {
    window.open(
      "https://discord.com/channels/831798057531605052/831798057531605055",
      "_blank"
    )
  }

  const onExchangeAddedSuccess = (exchangeAccount: ExchangeAccount): void => {
    //TODO: Success message
    exchangeAccounts.data?.push(exchangeAccount)
    setDataExchangeAccountsRequest({
      loading: false,
      data: exchangeAccounts.data,
    })
  }

  return (
    <>
      <div className="mt-8">
        <MyBots />
      </div>
      <ConnectAccountsPagerModal
        onExchangeAddedSuccessListener={onExchangeAddedSuccess}
      />
      <HelpPagerModal />
      <DiscordButton
        className="fixed bottom-0 align-middle right-20"
        onClick={onDiscordClick}
      />
    </>
  )
}

export default HomePage
