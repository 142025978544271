import React, { FC } from "react"
import { getInitialTheme } from "./Utils"

const ThemeContext = React.createContext<string>("")

const ThemeProvider: FC<{ children: any }> = ({ children }) => {
  const initialTheme = getInitialTheme()

  const rawSetTheme = (theme: string) => {
    const root = window.document.documentElement
    const isDark = theme === "dark"

    root.classList.remove(isDark ? "light" : "dark")
    root.classList.add(theme)

    localStorage.setItem("bttm-theme", theme)
  }

  if (initialTheme) {
    rawSetTheme(initialTheme)
  }

  React.useEffect(() => {
    rawSetTheme(initialTheme)
  }, [initialTheme])

  return <ThemeContext.Provider value="">{children}</ThemeContext.Provider>
}

export { ThemeProvider, ThemeContext }
