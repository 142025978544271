import React, { useEffect } from "react"
import LoadingData from "../models/LoadingData"
import { PaymentOrders } from "../shared/models/payments/Payments"
import PaymentApiProvider from "../shared/providers/paymentOrders/PaymentsApiProvider"
import { useUserOwn } from "../shared/store/useOwnUser"
import { BASE_URL } from "../shared/constants/constants"

export const usePaymentOrders = (subscriptionId: string | undefined) => {
  const { token } = useUserOwn()

  const [paymentOrdersResponse, setPaymentOrdersResponse] = React.useState<
    LoadingData<PaymentOrders>
  >({ loading: true, data: undefined })

  useEffect(() => {
    if (parseInt(subscriptionId ?? "-1") === -1) return
    setPaymentOrdersResponse({
      loading: true,
      data: undefined,
    } as LoadingData<PaymentOrders>)
    new PaymentApiProvider(BASE_URL)
      .getPaymentOrders(token, parseInt(subscriptionId!))
      .then((paymentOrders: PaymentOrders) => {
        setPaymentOrdersResponse({
          loading: false,
          data: paymentOrders,
        } as LoadingData<PaymentOrders>)
      })
      .catch((error) => {
        console.log(error)
        setPaymentOrdersResponse({
          loading: false,
          data: undefined,
        } as LoadingData<PaymentOrders>)
      })
  }, [token])

  return { paymentOrders: paymentOrdersResponse }
}
