import { FC, ReactNode } from "react"

import Header from "components/Header"

type Props = {
  children: ReactNode
}

const MainLayout: FC<Props> = ({ children }) => {
  return (
    <>
      <div className="flex h-screen">
        {/*<div className="invisible w-0 h-1 lg:w-auto lg:visible">
          <SideMenu className="" />
        </div>*/}

        <div
          id="baseScroll"
          className="flex-1 pb-24 overflow-y-scroll lg:mb-0 dark:bg-bg-dark bg-bg-light"
        >
          <Header className="mx-3 lg:mx-12" />
          {children}
        </div>
      </div>
      {/*<div className="visible w-auto lg:w-0 lg:invisible">
        <BottomNavBar />
      </div>*/}
    </>
  )
}

export default MainLayout
