import React, { FC } from "react"

type Props = {
  tabs: string[]
  className?: string
  onTabChanged: (tabPosition: number) => void
  activeTab: number
}

const SimpleTabs: FC<Props> = ({
  tabs,
  className,
  onTabChanged,
  activeTab,
}) => {
  const tabsElements = tabs.map((tab, index) => (
    <li key={`${index}`} className="my-2 mr-2 lg:mr-4">
      <a
        className={activeTab === index ? "simple-tab-selected" : "simple-tab"}
        onClick={(e) => {
          e.preventDefault()
          onTabChanged(index)
        }}
        data-toggle="tab"
        href={``}
        role="tablist"
      >
        {tab}
      </a>
    </li>
  ))

  return (
    <ul
      className={`flex list-none overflow-x-auto flex-grow ${className}`}
      role="tablist"
    >
      {tabsElements}
    </ul>
  )
}

export { SimpleTabs }
