import { PrimaryButton } from "components/PrimaryButton"
import React, { FC } from "react"
import { useNavigate } from "react-router-dom"
import { ValidationFieldForm } from "../../common/forms/ValidationFieldForm"
import { useTranslation } from "react-i18next"
import { Toast } from "../../Toasts"
import { ShowHideToggleIcon } from "../../ShowHideToggleIcon"
import LogoText from "../../../assets/logo-text-long-circle-white.png"
import { useLogin } from "../../../hooks/useLogin"

const LoginPage: FC = () => {
  const { t } = useTranslation()

  const [passwordShown, setPasswordShown] = React.useState(false)
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown)
  }

  const history = useNavigate()
  const { formik, loading } = useLogin()

  return (
    <>
      <div className="flex h-screen dark:bg-bg-dark bg-bg-light">
        <div className="m-auto sm:w-96 w-full pl-4 pr-4 ">
          <div className="flex justify-center">
            <div>
              <a href="/">
                <img
                  src={LogoText}
                  className={"w-56 sm:w-72 mb-6"}
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="w-full self-center px-6 py-8 rounded shadow-2xl bg-container-panel-bg-light dark:bg-container-panel-bg-dark">
              <div className="mt-4">
                <input
                  className={`input-sign-up-login`}
                  id="username"
                  type="text"
                  placeholder={t("email.input.placeholder")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      formik.handleSubmit()
                    }
                  }}
                  {...formik.getFieldProps("username")}
                />

                {formik.touched.username && formik.errors.username ? (
                  <ValidationFieldForm
                    className="mt-2"
                    text={formik.errors.username}
                  />
                ) : null}
              </div>
              <div className="mt-4">
                <div className="relative flex w-full ">
                  <input
                    className={`!pl-2 !pr-12 input-sign-up-login`}
                    id="password"
                    type={passwordShown ? "text" : "password"}
                    autoComplete={"off"}
                    placeholder={t("password.input.placeholder")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        formik.handleSubmit()
                      }
                    }}
                    {...formik.getFieldProps("password")}
                  />

                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute right-4 top-[20%]"
                  >
                    <ShowHideToggleIcon
                      showedIcon={passwordShown}
                      className="h-6"
                    />
                  </button>
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <ValidationFieldForm
                    className="mt-2"
                    text={formik.errors.password}
                  />
                ) : null}
              </div>

              <button
                type="button"
                className="mt-3 w-full font-thin dark:text-main-text-dark text-main-text-light text-right"
                onClick={() => history("/forgot-password")}
              >
                <p>{t("forgot_password.text")}</p>
              </button>

              <PrimaryButton
                type="submit"
                loading={loading}
                className="h-10 mt-8 w-full !py-0"
              >
                <p>{t("login.text")}</p>
              </PrimaryButton>

              <button
                className="mt-8 w-full font-semibold dark:text-main-text-dark text-main-text-light text-lg"
                onClick={() => history("/sign-up")}
              >
                <p>{t("signup.text")}</p>
              </button>
            </div>
          </form>
        </div>
        <Toast />
      </div>
    </>
  )
}

export default LoginPage
