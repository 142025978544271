import React, { FC } from "react"
import { BaseModal } from "../BaseModal"
import { HeaderModal } from "../HeaderModal"
import TradingViewWidget, { Themes } from "react-tradingview-widget"
import { BotPosition } from "../../../shared/models/bot/BotInfo"

type Props = {
  className?: string
  onCloseClick: () => void
  position?: BotPosition
  symbol?: string
}

const TradingViewModal: FC<Props> = ({
  className,
  onCloseClick,
  position,
  symbol,
}) => {
  return (
    <BaseModal
      displayBlackBg={true}
      maxWidth="!w-[100%] !max-w-[100%] md:!w-[90%] md:!max-w-[90%]"
    >
      <HeaderModal
        onBackClick={onCloseClick}
        onCloseClick={onCloseClick}
        title={symbol}
        subtitle={
          "Entry price " +
          (position?.entry_price
            ? "$" + parseFloat(position?.entry_price!.toString())
            : "-")
        }
        subtitle2={
          "Next TP " +
          (position?.nexttp
            ? "$" + parseFloat(position?.nexttp!.toFixed(8))
            : "- ")
        }
        subtitle3={
          "Next DCA " +
          (position?.nextdca
            ? "$" + parseFloat(position?.nextdca!.toString())
            : "-")
        }
        hasCloseIcon={true}
        hasBackArrow={false}
      />
      <div className="h-[80vh]">
        <TradingViewWidget
          autosize={true}
          theme={Themes.DARK}
          symbol={`BINANCE:${symbol}`}
          interval="240"
        />
        {/* <HelpSectionModal
          title="Help Center"
          subtitle="Discover all the details"
          onClick={onHelpClick}
          bottomBorder={true}
        />
        <HelpSectionModal
          title="Contact Support"
          subtitle="Do you have any doubt? Contact us"
          onClick={onContactClick}
          bottomBorder={true}
        /> */}
      </div>
    </BaseModal>
  )
}

export { TradingViewModal }
