import { HeaderModal } from "components/modals/HeaderModal"
import { PrimaryButton } from "components/PrimaryButton"
import React, { FC } from "react"
import { BaseModal } from "../BaseModal"

type Props = {
  onCloseClick?: () => void
  onBackClick?: () => void
  onConnectClick: () => void
  onCreateClick: () => void
  displayBlackBg?: boolean
}

const ConnectAccountsModal: FC<Props> = ({
  onCloseClick,
  onBackClick,
  onConnectClick,
  onCreateClick,
  displayBlackBg = true,
}) => {
  return (
    <>
      <BaseModal displayBlackBg={displayBlackBg}>
        <HeaderModal
          onCloseClick={onCloseClick}
          onBackClick={onBackClick}
          title={"Connect accounts"}
          subtitle={"Lorem ipsum dolor sit ammet"}
          hasCloseIcon={true}
          hasBackArrow={false}
        />

        <div className="overflow-scroll max-h-[70vh] sm:max-h-[80vh]">
          <p className="px-4 pt-6 pb-12 my-4 font-medium">
            To be able to trade on LOGO, you need to connect or create at least
            one exchange accounts like BitMEX o Binance or VCCE or KuCoin
          </p>
          <p className="px-4 mb-4 font-medium text-center">
            Already have an exchange account?
          </p>
          <div className="flex justify-center pb-10">
            <PrimaryButton onClick={onConnectClick}>
              Connect existing exchange account
            </PrimaryButton>
          </div>
          {/* <hr className="mb-10 border-1 border-my-bot-light-divider-dark" />
          <p className="px-4 mb-4 font-medium text-center">
            You don’t have an exchange account yet?
          </p>
          <div className="flex justify-center pb-10">
            <PrimaryButton onClick={onCreateClick} fill={false}>
              Create an exchange account
            </PrimaryButton>
          </div> */}
        </div>
      </BaseModal>
    </>
  )
}

export { ConnectAccountsModal }
