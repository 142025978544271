import React, { FC } from "react"
import { TransactionsBot } from "../../shared/models/bot/BotInfo"
import { SearchBar } from "../Searchbar"
import { t } from "i18next"
import { SortByOptions } from "../../shared/models/bot/SortByBotDataOptions"
import { TransactionListBot } from "./TransactionListBot"
import InfiniteScroll from "react-infinite-scroll-component"
import { useStaticPaginatedData } from "../../hooks/useStaticPaginatedData"

const PAGE_SIZE = 10
type Props = {
  className?: string
  transactions: TransactionsBot[]
  loading?: boolean
  onClickSymbol?: (symbol?: string) => void
}

const TransactionsListBot: FC<Props> = ({
  className,
  transactions,
  onClickSymbol,
}) => {
  const { list, setList, next, hasMore, setHasMore, getMoreData, setCount } =
    useStaticPaginatedData<TransactionsBot>(transactions, PAGE_SIZE)
  const [sortByOption, setSortBy] = React.useState(
    SortByOptions.DateUpToDown.toString()
  )

  function sortBy() {
    switch (sortByOption) {
      case SortByOptions.Symbol:
        return list.sort((a, b) =>
          a.symbol && b.symbol ? a.symbol.localeCompare(b.symbol) : 0
        )
      case SortByOptions.TransactionTypeDownToUp:
        return list.sort((a, b) =>
          a.type && b.type ? a.type?.localeCompare(b.type) : 0
        )
      case SortByOptions.TransactionTypeUpToDown:
        return list.sort((a, b) =>
          a.type && b.type ? b.type?.localeCompare(a.type) : 0
        )
      case SortByOptions.IncomeDownToUp:
        return list.sort((a, b) => (a.income ?? 0) - (b.income ?? 0))
      case SortByOptions.IncomeUpToDown:
        return list.sort((a, b) => (b.income ?? 0) - (a.income ?? 0))
      case SortByOptions.DateDownToUp:
        return list.sort(
          (a, b) =>
            (a.time ? new Date(a.time).getTime() : new Date().getTime()) -
            (b.time ? new Date(b.time).getTime() : new Date().getTime())
        )
      case SortByOptions.DateUpToDown:
        return list.sort(
          (a, b) =>
            (b.time ? new Date(b.time).getTime() : new Date().getTime()) -
            (a.time ? new Date(a.time).getTime() : new Date().getTime())
        )
      default:
        return list
    }
  }

  sortBy()

  return (
    <div className={`pt-6 dark:bg-bg-dark bg-white ${className}`}>
      <div className="flex flex-wrap">
        <SearchBar
          className="w-28 mx-3 lg:mx-12 mb-4"
          placeholder={t("symbol.text")}
          onChangeSearchText={(newValue) => {
            setCount({
              prev: 0,
              next: PAGE_SIZE,
            })
            setHasMore(true)
            setList(
              transactions.filter((transaction) =>
                transaction.symbol
                  ?.toLowerCase()
                  .includes(newValue.toLowerCase())
              )
            )
          }}
          autoComplete={false}
        />
        <div className="mx-3 lg:mx-12 ">
          <select
            style={{
              boxShadow: "none",
            }}
            className="dropdown"
            name="days"
            id="days_select"
            onChange={(event) => setSortBy(event.target.value)}
          >
            <option value={SortByOptions.Symbol}>
              {t<string>("sort_by_symbol.text")}
            </option>
            <option value={SortByOptions.TransactionTypeUpToDown}>
              {t<string>("sort_by_transaction_type_up_to_down.text")}
            </option>
            <option value={SortByOptions.TransactionTypeDownToUp}>
              {t<string>("sort_by_transaction_type_down_to_up.text")}
            </option>
            <option value={SortByOptions.IncomeUpToDown}>
              {t<string>("sort_by_income_up_to_down.text")}
            </option>
            <option value={SortByOptions.IncomeDownToUp}>
              {t<string>("sort_by_income_down_to_up.text")}
            </option>
            <option selected value={SortByOptions.DateUpToDown}>
              {t<string>("sort_by_date_up_to_down.text")}
            </option>
            <option value={SortByOptions.DateDownToUp}>
              {t<string>("sort_by_date_down_to_up.text")}
            </option>
          </select>
        </div>
      </div>
      <div className={`grid grid-cols-1`}>
        {list.length > 0 && (
          <InfiniteScroll
            dataLength={next}
            next={getMoreData}
            hasMore={hasMore}
            loader={<></>}
            scrollableTarget="baseScroll"
          >
            {list?.slice(0, next)?.map((transaction) => (
              <>
                <TransactionListBot
                  className="mx-3 lg:mx-12"
                  key={transaction.transaction_id}
                  transaction={transaction}
                  onClickSymbol={(symbol) => {
                    onClickSymbol?.(symbol)
                  }}
                />
                <div className="w-full h-px dark:bg-divider-bg-lighter-dark bg-divider-bg-lighter-light" />
              </>
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  )
}

export { TransactionsListBot }
