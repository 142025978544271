import React, { FC } from "react"
import { BotPosition } from "../../../shared/models/bot/BotInfo"
import { useTranslation } from "react-i18next"
import EyeIcon from "../../icons/EyeIcon"
import {
  DOT_UNDERLINE_CLASS,
  getColorTextPositiveNegative,
  getDcaClass,
} from "../../../Utils"
import { Tooltip } from "../../Tooptip"
import Tippy from "@tippyjs/react/headless"
import "tippy.js/dist/tippy.css"

type Props = {
  isAdmin?: boolean
  position: BotPosition
  className?: string
  livePrice?: { c?: string; P?: string }
  onClickSymbol?: (position?: BotPosition) => void
}

const PositionListBot: FC<Props> = ({
  isAdmin = false,
  position,
  livePrice,
  className,
  onClickSymbol,
}) => {
  const { t } = useTranslation()

  const dcasClass = getDcaClass(position.dcas)
  const widthPercentage = Math.abs(position.walletExposure ?? 0) / 3
  const widthPercentageClass =
    "w-[" + (widthPercentage >= 100 ? 99 : widthPercentage).toFixed(0) + "%]"

  const livePriceDisplay = "$" + (livePrice?.c ? parseFloat(livePrice?.c) : "-")
  const livePricePercentageDisplay =
    "(" + (livePrice?.P ? parseFloat(livePrice?.P).toFixed(2) : "-") + "%)"
  const livePricePercentageColor = livePrice?.P
    ? getColorTextPositiveNegative(parseFloat(livePrice?.P))
    : " dark:text-secondary-text-dark text-secondary-text-light "
  const liqPriceClass =
    position.liquidation_price && position.liquidation_price >= 0
      ? "dark:text-red-danger-dark text-red-danger-light font-bold"
      : ""
  return (
    <div className={`py-8 ${className}`}>
      <div className="flex mb-8">
        <div>
          <div
            className={"flex"}
            onClick={() => {
              onClickSymbol?.(position)
            }}
          >
            <EyeIcon onClick={() => {}} className="list-eye-icon" />
            <p className="list-text-title">{position.symbol}</p>
          </div>

          <div className="flex  ml-6">
            {/*TODO: Component for live prive*/}
            <p className="list-text-live-price">{livePriceDisplay}</p>
            <p
              className={`text-sm ${livePricePercentageColor} text-left ml-1 font-bold`}
            >
              {livePricePercentageDisplay}
            </p>
          </div>
        </div>
        <p
          className={`${getColorTextPositiveNegative(
            position.unrealizedProfit ?? 0
          )} font-medium mr-2 ml-auto`}
        >
          {position.unrealizedProfit
            ? "$" + position.unrealizedProfit.toFixed(2)
            : "-"}
        </p>
        <div>
          <Tippy
            interactive={true}
            hideOnClick={true}
            render={(attrs) => (
              <Tooltip {...attrs} content={t("unrealized_pnl.tooltip") ?? ""} />
            )}
            placement={"bottom"}
          >
            <p className={`list-text-secondary mt-1 ${DOT_UNDERLINE_CLASS}`}>
              {t("unrealized_pnl.text")}
            </p>
          </Tippy>
        </div>
      </div>

      <div className="flex">
        <div className="">
          <div className="flex mb-1">
            <Tippy
              interactive={true}
              hideOnClick={true}
              render={(attrs) => (
                <Tooltip {...attrs} content={t("entry_price.tooltip") ?? ""} />
              )}
              placement={"bottom"}
            >
              <p className={`list-text-secondary mr-2 ${DOT_UNDERLINE_CLASS}`}>
                {t("entry_price.text")}
              </p>
            </Tippy>
            <p className="list-text-base mr-2">
              {position.entry_price
                ? "$" + parseFloat(position.entry_price.toFixed(8))
                : "-"}
            </p>
          </div>
          <div className="flex mb-1">
            <Tippy
              interactive={true}
              hideOnClick={true}
              render={(attrs) => (
                <Tooltip {...attrs} content={t("next_tp.tooltip") ?? ""} />
              )}
              placement={"bottom"}
            >
              <p className={`list-text-secondary mr-2 ${DOT_UNDERLINE_CLASS}`}>
                {t("next_tp.text")}
              </p>
            </Tippy>
            <p className="list-text-base mr-2">
              {position.nexttp
                ? "$" + parseFloat(position.nexttp.toString())
                : "-"}
            </p>
          </div>
          <div className="flex mb-1">
            <Tippy
              interactive={true}
              hideOnClick={true}
              render={(attrs) => (
                <Tooltip {...attrs} content={t("next_dca.tooltip") ?? ""} />
              )}
              placement={"bottom"}
            >
              <Tippy
                interactive={true}
                hideOnClick={true}
                render={(attrs) => (
                  <Tooltip {...attrs} content={t("next_dca.tooltip") ?? ""} />
                )}
                placement={"bottom"}
              >
                <p
                  className={`list-text-secondary mr-2 ${DOT_UNDERLINE_CLASS}`}
                >
                  {t("next_dca.text")}
                </p>
              </Tippy>
            </Tippy>
            <p className="list-text-base mr-2">
              {position.nextdca
                ? "$" + parseFloat(position.nextdca.toString())
                : "-"}
            </p>
          </div>
          <div className="flex mb-1">
            <Tippy
              interactive={true}
              hideOnClick={true}
              render={(attrs) => (
                <Tooltip
                  {...attrs}
                  content={t("liquidation_price.tooltip") ?? ""}
                />
              )}
              placement={"bottom"}
            >
              <Tippy
                interactive={true}
                hideOnClick={true}
                render={(attrs) => (
                  <Tooltip
                    {...attrs}
                    content={t("liquidation_price.tooltip") ?? ""}
                  />
                )}
                placement={"bottom"}
              >
                <p
                  className={`list-text-secondary mr-2 ${DOT_UNDERLINE_CLASS}`}
                >
                  {t("liquidation_price.text")}
                </p>
              </Tippy>
            </Tippy>
            <p className={`list-text-base mr-2 ${liqPriceClass}`}>
              {position.liquidation_price && position.liquidation_price > 0
                ? "$" + parseFloat(position!.liquidation_price!.toString())
                : "-"}
            </p>
          </div>
          <div className="flex mb-1">
            <p className={`list-text-secondary mr-2 `}>
              {t("position_size.text")}
            </p>
            <p className="list-text-base mr-2">
              {position.position_size ? position.position_size : "-"}
            </p>
          </div>
          {isAdmin && (
            <>
              <div className="flex mb-1">
                <p className={`list-text-secondary mr-2 `}>
                  {t("lasttp.text")}
                </p>
                <p className="list-text-base mr-2">
                  {position.lasttp
                    ? new Date(position.lasttp ?? "").toLocaleDateString()
                    : "-"}
                </p>
              </div>
              <div className="flex mb-1">
                <p className={`list-text-secondary mr-2 `}>
                  {t("symbol_performance_30d.text")}
                </p>
                <p className="list-text-base mr-2">
                  {position.symbol_performance
                    ? "$" + position.symbol_performance.toFixed(2)
                    : "-"}
                </p>
              </div>
              <div className="flex mb-1">
                <p className={`list-text-secondary mr-2 `}>{t("risk.text")}</p>
                <p className="list-text-base mr-2">
                  {position.risk?.toFixed(2) ?? "-"}
                </p>
              </div>
            </>
          )}
          <div className="flex mb-1">
            <p className={`list-text-secondary mr-2 `}>Side</p>
            <p className="list-text-base mr-2">{position.side ?? "-"}</p>
          </div>
        </div>
        <div className="flex ml-auto mt-auto  mb-1">
          <div className="mt-auto mr-6">
            <p className={`list-dca-rounded ${dcasClass}`}>{position.dcas}</p>
            <Tippy
              interactive={true}
              hideOnClick={true}
              render={(attrs) => (
                <Tooltip {...attrs} content={t("dcas.tooltip") ?? ""} />
              )}
              placement={"bottom"}
            >
              <p className={`list-text-secondary ${DOT_UNDERLINE_CLASS}`}>
                {t("dcas.text")}
              </p>
            </Tippy>
          </div>
          <div className="self-end">
            <p className="list-text-wallet-exposure-percentage">
              {position.walletExposure
                ? (Math.abs(position.walletExposure ?? 0) / 3)?.toFixed(2) + "%"
                : "-"}
            </p>
            <div className="relative pt-1 w-28 m-auto">
              <div className="overflow-hidden h-1 mb-2 text flex rounded dark:bg-placeholder-text-dark bg-placeholder-text-light">
                <div
                  className={`shadow-none flex flex-col ${widthPercentageClass} text-center whitespace-nowrap text-white justify-center bg-gradient-to-r from-primary-gradien1-dark to-primary-gradien2-dark`}
                />
              </div>
            </div>
            <Tippy
              interactive={true}
              hideOnClick={true}
              render={(attrs) => (
                <Tooltip
                  {...attrs}
                  content={t("wallet_exposure.tooltip") ?? ""}
                />
              )}
              placement={"bottom"}
            >
              <p
                className={`list-text-secondary text-center ${DOT_UNDERLINE_CLASS}`}
              >
                {t("wallet_exposure.text")}
              </p>
            </Tippy>
          </div>
        </div>
      </div>
    </div>
  )
}

export { PositionListBot }
