export type SubHeaderModelType = {
  avatar?: string
  title?: string
}

export enum SubHeaderType {
  BotDetails,
  Home,
  Dashboard,
  TradingTerminal,
}
