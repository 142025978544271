import { ExchangeAccount } from "../../models/exchangeAccount/ExchangeAccount"
import ExchangeAccountsProvider from "./ExchangeAccountsApiProvider"
import ApiProvider from "../ApiProvider"
import ApiKeyOrSecretInvalidError from "../../exceptions/ApiKeyOrSecretInvalidError"

export default class ExchangeAccountsApiProvider
  extends ApiProvider
  implements ExchangeAccountsProvider
{
  constructor(private baseUrl: string) {
    super()
  }

  async updateExchangeAccount(values: {
    name: string
    apiKey: string
    apiSecret: string
  }): Promise<ExchangeAccount> {
    return new Promise((resolve, reject) => {}).then(() => {
      return {
        id: 2,
        created: "string",
        modified: "string",
        account_name: "string",
        account_type: "string",
        key: "string",
        password: "string",
        account_status: "string",
        account_provider: 2,
        account_user: 3,
      }
    })
  }

  async addExchangeAccount(values: {
    name: string
    accountType: string
    accountProviderId: number
    apiKey: string
    apiSecret: string
    token: string
  }): Promise<ExchangeAccount> {
    return fetch(`${this.baseUrl}api/v1/exchangeaccounts/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf8",
        Authorization: "Bearer " + values.token,
      },
      body: JSON.stringify({
        account_name: values.name,
        account_type: values.accountType,
        key: values.apiKey,
        password: values.apiSecret,
        account_provider: values.accountProviderId,
      }),
    }).then((response) => {
      this.checkAddExchangeAccountErrors(response)
      return response.json() as Promise<ExchangeAccount>
    })

    // return new Promise((resolve, reject) => {}).then(() => {
    //   return {
    //     id: 2,
    //     created: "string",
    //     modified: "string",
    //     account_name: "string",
    //     account_type: "string",
    //     key: "string",
    //     password: "string",
    //     account_status: "string",
    //     account_provider: 2,
    //     account_user: 3,
    //   }
    // })
  }

  async getExchangeAccounts(token: string): Promise<[ExchangeAccount]> {
    return fetch(`${this.baseUrl}api/v1/exchangeaccounts/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf8",
        Authorization: "Bearer " + token,
      },
    }).then((response) => {
      super.checkErrors(response)

      return response.json() as Promise<[ExchangeAccount]>
    })
  }

  private checkAddExchangeAccountErrors(response: Response) {
    if (response.status === 405) {
      console.log("ApiKeyOrSecretInvalidError()")
      throw new ApiKeyOrSecretInvalidError()
    }
    super.checkErrors(response)
  }
}
