export enum BotTabs {
  Positions = 0,
  OpenOrders = 1,
  Transactions = 2,
  MarketPrices = 3,
  Other = -1,
}
export enum BotTabsPath {
  Positions = "positions",
  OpenOrders = "open-orders",
  Transactions = "transactions",
  MarketPrices = "market-prices",
}

export function getBotTabPath(tab: BotTabs): string {
  switch (tab) {
    case BotTabs.Positions:
      return BotTabsPath.Positions
    case BotTabs.OpenOrders:
      return BotTabsPath.OpenOrders
    case BotTabs.Transactions:
      return BotTabsPath.Transactions
    case BotTabs.MarketPrices:
      return BotTabsPath.MarketPrices
    default:
      return ""
  }
}
