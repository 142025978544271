import { SubHeader, SubHeaderType } from "components/SubHeader"
import React, { FC, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import BotApiProvider from "../../../shared/providers/bots/BotApiProvider"
import { BotInfo } from "../../../shared/models/bot/BotInfo"
import LoadingData from "../../../models/LoadingData"
import DataTrackApiProvider from "../../../shared/providers/dataTrack/DataTrackApiProvider"
import { Income } from "../../../shared/models/dataTrack/Income"
import { SingleDataInfo } from "../../SingleDataInfo"
import { useBotInfoDataResponseAtom } from "../../../shared/store/store"
import PaginatedData from "../../../models/PaginatedData"
import { BotSubscription } from "../../../shared/models/bot/BotSubscription"
import actionByError from "../../common/actionByError"
import { WalletExposure } from "./components/WalletExposure"
import UsersApiProvider from "../../../shared/providers/users/UsersApIProvider"
import { useUserOwn } from "../../../shared/store/useOwnUser"
import { UserOwn } from "../../../shared/models/user/UserOwn"
import { BASE_URL } from "../../../shared/constants/constants"
import CustomError from "../../../shared/exceptions/CustomError"

const DashboardPage: FC = () => {
  const history = useNavigate()
  const [openTab] = React.useState(0)
  const [filterTime, setFilterTime] = React.useState(new Date())
  const { token } = useUserOwn()
  const [incomeResponse, setIncomeResponse] = React.useState<{
    loading: boolean
    data?: Income[]
  }>({ loading: true, data: undefined })
  const [botInfoDataResponse, setBotInfoDataResponse] =
    useBotInfoDataResponseAtom()

  const [botSubscription, setBotSubscription] = React.useState<{
    loading: boolean
    data?: BotSubscription
  }>({ loading: true, data: undefined })

  /*useEffect(() => {
    new DataTrackApiProvider(BASE_URL)
      .getIncomes(token)
      .then((incomeResponse: Income[]) => {
        setIncomeResponse({
          loading: false,
          data: incomeResponse,
        } as LoadingData<Income[]>)
      })
  }, [])*/

  useEffect(() => {
    new UsersApiProvider(token, BASE_URL)
      .getOwn()
      .then((ownUser: UserOwn) => {
        new BotApiProvider(BASE_URL)
          .getSubscriptions(token, 1)
          .then((data: PaginatedData<BotSubscription>) => {
            if (!data.items) return
            for (const botSubscription of data?.items ?? []) {
              if (
                (botSubscription.status === "INITIAL" ||
                  botSubscription.status === "RUNNING" ||
                  botSubscription.status === "STOPPED") &&
                ownUser.id === botSubscription.user_id
              ) {
                setBotSubscription({ loading: false, data: botSubscription })
                const botId = botSubscription.bot_id

                new BotApiProvider(BASE_URL)
                  .getBotInfo(token, String(botId))
                  .then((botInfoResponse: BotInfo) => {
                    setBotInfoDataResponse({
                      loading: false,
                      data: botInfoResponse,
                    } as LoadingData<BotInfo>)
                  })

                if (!botSubscription.id) return
                new DataTrackApiProvider(BASE_URL)
                  .getIncomes(token, botSubscription.id)
                  .then((incomeResponse: Income[]) => {
                    setIncomeResponse({
                      loading: false,
                      data: incomeResponse,
                    } as LoadingData<Income[]>)
                  })
                  .catch(() => {
                    setIncomeResponse({
                      loading: false,
                      data: [],
                    } as LoadingData<Income[]>)
                  })
                return
              }
            }
          })
          .catch((error: CustomError) => {})
      })
      .catch((error: CustomError) => {
        actionByError(error.errorCode, history) // FIXME: cambiar esto por un useCustomError hook en el MainLayout para mostrar un mensaje o redirigir al login como este caso
      })
  }, [token])

  const onCreateBotClick = () => {}
  const onTabChanged = (tab: number) => {
    refreshTabFilter(openTab)
  }

  function refreshTabFilter(tab: number): void {
    switch (tab) {
      case 0:
        setFilterTime(new Date())
        break
      case 1:
        setFilterTime(new Date())
        break
      case 2:
        setFilterTime(new Date())
        break
    }
    setFilterTime(new Date())
  }

  return (
    <div className="">
      <SubHeader
        className="mx-3 mb-6 lg:mx-12"
        onButtonClick={onCreateBotClick}
        type={SubHeaderType.Dashboard}
      />

      {/*<SimpleTabs
        className="mx-3 mb-8 lg:mx-12"
        onTabChanged={onTabChanged}
        tabs={tabs}
      />*/}

      <div className="mx-3 mb-4 lg:mx-12 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-flow-col">
        <SingleDataInfo
          className="mb-2 sm:mb-2 sm:mr-4"
          loading={false}
          title="Wallet Ballance"
          value={
            botInfoDataResponse?.data?.balance?.totalWalletBalance
              ? botInfoDataResponse?.data?.balance?.totalWalletBalance.toFixed(
                  2
                )
              : "-"
          }
        />
        <SingleDataInfo
          className="mb-2 sm:mb-2 sm:mr-4"
          loading={false}
          title="Available Balance"
          value={
            botInfoDataResponse?.data?.balance?.availableBalance
              ? botInfoDataResponse?.data?.balance?.availableBalance.toFixed(2)
              : "-"
          }
        />
        <SingleDataInfo
          className="mb-2 sm:mb-2 sm:mr-4"
          loading={false}
          title="Unrealized PnL"
          value={
            botInfoDataResponse?.data?.balance?.totalUnrealizedProfit
              ? botInfoDataResponse?.data?.balance?.totalUnrealizedProfit.toFixed(
                  2
                )
              : "-"
          }
        />
        <SingleDataInfo
          className="mb-2 sm:mb-2"
          loading={false}
          title="Today Income"
          value={
            botInfoDataResponse?.data?.todayIncome
              ? botInfoDataResponse?.data?.todayIncome?.toFixed(2)
              : "-"
          }
        />
      </div>
      {/*<IncomesGraph
        dataBalance={incomeResponse?.data ?? []}
        loading={incomeResponse?.loading ?? true}
        className="mb-4 mx-3 lg:mx-12"
      />*/}
      <WalletExposure
        className="mb-4 mx-3 lg:mx-12"
        data={botInfoDataResponse?.data?.positions ?? []}
      />
      {/*<TablePositionsBot
        positions={botInfoDataResponse?.data?.positions}
        loading={botInfoDataResponse?.loading}
        className="mx-3 lg:mx-12"
      />*/}
      {/*<div className={`xl:flex mx-3 lg:mx-12`}>
        <div className="flex-grow xl:mr-4">
          <CumulativePNL className="mb-4" />
          <DailyPNL className="mb-4" />
        </div>

        <AssetNetWorth className="mb-4 " />
        <div className="xl:flex xl:flex-col">
          <AssetAllocation className="mb-4 " />
          <AssetNetWorth className="mb-4 xl:flex-grow" />
        </div>
      </div>*/}
    </div>
  )
}

export { DashboardPage }
