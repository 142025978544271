import React, { FC } from "react"
import { Navigate } from "react-router-dom"
import MainLayout from "components/containers/MainLayout"
import { useUserOwn } from "../../shared/store/useOwnUser"

type Props = {
  component: JSX.Element
}

const PrivateRoute: FC<Props> = ({ component }) => {
  const { token } = useUserOwn()
  return token ? (
    <MainLayout>{component}</MainLayout>
  ) : (
    <Navigate to="/login" replace />
  )
}

export default PrivateRoute
