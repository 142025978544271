import { toast } from "react-toastify"
import React from "react"
import { t } from "i18next"

export const notify = (content: String) =>
  toast.error(content, {
    toastId: "notify",
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: false,
  })

export function notifyVerifyAccount(onClick?: () => void) {
  toast.info(
    <div>
      <p>{t<string>("api_message_errors.account_not_activated")}</p>
      <button
        className={`text-left`}
        onClick={() => {
          onClick?.()
        }}
      >
        <p className="text-primary-text-dark font-medium">
          {t<string>("resend_confirmation_email.text")}
        </p>
      </button>
    </div>,
    {
      toastId: "notifyVerifyAccount",
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: false,
    }
  )
}

export function notifySuccess(message: string, className?: string) {
  toast.info(
    <div>
      <p
        className={`dark:text-secondary-text-dark text-secondary-text-light font-semibold ${className}`}
      >
        {message}
      </p>
    </div>,
    {
      toastId: "notifySuccess",
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: false,
    }
  )
}

export function notifyError(message: string) {
  toast.info(
    <div>
      <p className="text-text-red-danger-light font-semibold">{message}</p>
    </div>,
    {
      toastId: "notifyError",
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: false,
    }
  )
}
