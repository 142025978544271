import React, { FC, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Toast } from "../../Toasts"
import { Player } from "@lottiefiles/react-lottie-player"
import successAnim from "../../../assets/lotties/success_bg_component_anim.json"
import loadingAnim from "../../../assets/lotties/loading_dots.json"
import AuthApIProvider from "../../../shared/providers/auth/AuthApIProvider"
import BadRequestError from "../../../shared/exceptions/BadRequestError"
import AlreadyVerifiedError from "../../../shared/exceptions/AlreadyVerifiedError"
import LogoText from "../../../assets/logo-text-long-circle-white.png"
import { BASE_URL } from "../../../shared/constants/constants"

const ActivatePage: FC = () => {
  const { t } = useTranslation()

  const history = useNavigate()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const token = query.get("token")
  const uid = query.get("uid")

  const [response, setResponse] = React.useState<{
    loading: boolean
    isSuccessValidation: boolean
    displayedAnimationSuccess: boolean
  }>({
    loading: true,
    isSuccessValidation: false,
    displayedAnimationSuccess: false,
  })

  useEffect(() => {
    if (!token || !uid) {
      history("/login")
      return
    }
    new AuthApIProvider(BASE_URL)
      .verifyUser(uid, token)
      .then(() => {
        setResponse({
          loading: false,
          isSuccessValidation: true,
          displayedAnimationSuccess: false,
        })
      })
      .catch((e: Error) => {
        if (e instanceof BadRequestError) {
          setResponse({
            loading: false,
            isSuccessValidation: false,
            displayedAnimationSuccess: false,
          })
          return
        }
        if (e instanceof AlreadyVerifiedError) {
          setResponse({
            loading: false,
            isSuccessValidation: true,
            displayedAnimationSuccess: false,
          })
          return
        }
      })
  }, [])

  return (
    <>
      <div className="flex h-screen dark:bg-bg-dark">
        <div className="m-auto sm:w-[32rem] w-full pl-4 pr-4 ">
          <div className="flex justify-center">
            <div>
              <a href="/">
                <img
                  src={LogoText}
                  className={"w-56 sm:w-72 mb-6"}
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <div className="w-full self-center px-6 py-8 rounded shadow-2xl bg-container-panel-bg-light dark:bg-container-panel-bg-dark">
            {!response.displayedAnimationSuccess && (
              <>
                <div className="mt-4 text-center text-white font-medium text-xl">
                  <p className="mb-2">{t("activate_page.header")}</p>
                  <p className="mb-6">{t("activate_page.body1")}</p>
                </div>
                <div className="h-28 flex m-auto">
                  <div className="m-auto">
                    {!response.loading &&
                      response.isSuccessValidation &&
                      !response.displayedAnimationSuccess && (
                        <Player
                          className="h-28"
                          autoplay
                          src={successAnim}
                          speed={0.6}
                          onEvent={(event) => {
                            if (event === "complete") {
                              setResponse({
                                loading: false,
                                isSuccessValidation: true,
                                displayedAnimationSuccess: true,
                              })
                            }
                          }}
                        />
                      )}

                    {response.loading && (
                      <Player
                        className="m-auto h-8"
                        autoplay
                        loop
                        src={loadingAnim}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
            {!response.loading &&
              response.isSuccessValidation &&
              response.displayedAnimationSuccess && (
                <>
                  <div className="mt-4 text-center text-white font-medium text-xl">
                    <p className="mb-2">{t("activate_page.body2")}</p>
                    <p>{t("activate_page.body3")}</p>
                  </div>
                  <button
                    className="mt-12 w-full font-bold text-primary-text-dark text-lg"
                    onClick={() => {
                      history("/login")
                    }}
                  >
                    <p>{t("activate_page.button")}</p>
                  </button>
                </>
              )}
            {!response.loading && !response.isSuccessValidation && (
              <>
                <div className="mt-4 text-center text-white font-medium text-xl">
                  <p className="mb-6">{t("activate_page.body4")}</p>
                  <p className="mb-2">{t("activate_page.body5")}</p>
                  <p className="mb-2">{t("activate_page.email")}</p>
                </div>
                <button
                  className="mt-12 w-full font-bold text-primary-text-dark text-lg"
                  onClick={() => {
                    history("/login")
                  }}
                >
                  <p>{t("activate_page.button")}</p>
                </button>
              </>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  )
}

export { ActivatePage }
