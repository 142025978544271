import React, { FC } from "react"

type Props = {
  tabs: string[]
  className?: string
  selectedStyles: string
  unselectedStyles: string
  onTabChanged: (tabPosition: number) => void
}

const UnderLineTabs: FC<Props> = ({
  tabs,
  className,
  selectedStyles,
  unselectedStyles,
  onTabChanged,
}) => {
  const [openTab, setOpenTab] = React.useState(0)
  const onTabClick = (tabClicked: number) => {
    onTabChanged(tabClicked)
    setOpenTab(tabClicked)
  }
  const tabsElements = tabs.map((tab, index) => (
    <div key={`${index}`} className="mr-2 cursor-pointer lg:mr-4">
      <p
        className={
          "pb-1 px-2 cursor-pointer " +
          (openTab === index
            ? "dark:text-primary-1-dark border-b-2 border-primary-1-dark"
            : "dark:text-main-text-light")
        }
        onClick={(e) => {
          e.preventDefault()
          onTabClick(index)
        }}
        data-toggle="tab"
        role="tablist"
      >
        {tab}
      </p>
    </div>
  ))

  return (
    <div className={`flex list-none flex-wrap flex-row  ${className}`}>
      {tabsElements}
    </div>
  )
}

export { UnderLineTabs }
