import React, { FC } from "react"
import { TransactionsBot } from "../../../shared/models/bot/BotInfo"
import { useTranslation } from "react-i18next"
import { getColorTextPositiveNegative } from "../../../Utils"

type Props = {
  transaction: TransactionsBot
  className?: string
  onClickSymbol?: (symbol?: string) => void
}

const TransactionListBot: FC<Props> = ({
  transaction,
  className,
  onClickSymbol,
}) => {
  const { t } = useTranslation()

  return (
    <div className={`py-8 ${className}`}>
      <div className="flex mb-8">
        <div
          className={"flex"}
          onClick={() => {
            onClickSymbol?.(transaction.symbol)
          }}
        >
          <p className="list-text-title">{transaction.symbol}</p>
        </div>
        <p className={`list-text-title ml-auto`}>{transaction.type}</p>
      </div>

      <div className="flex">
        <div className="">
          <div className="flex mb-1">
            <p className={`list-text-secondary mr-2`}>{t("income.text")}</p>
            <p
              className={`${getColorTextPositiveNegative(
                transaction.income ?? 0
              )} font-medium text-xs mr-2`}
            >
              {`${transaction?.asset === "USDT" ? "$" : transaction?.asset}${
                transaction?.income
              }` ?? ""}
            </p>
          </div>
        </div>
        <div className="flex ml-auto mt-auto">
          <p className="list-text-secondary">
            {new Date(transaction.time ?? "").toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  )
}

export { TransactionListBot }
