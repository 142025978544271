import React, { FC } from "react"
import { Column } from "react-table"
import { TableRowShimmer } from "../../../../shimmers/TableRowShimmer"
import { useTranslation } from "react-i18next"
import Affiliate from "../../../../../shared/models/Affiliate"
import { AffiliatesTableBody } from "./AffiliatesTableBody"

type Props = {
  className?: string
  loading?: boolean
  data?: Affiliate[]
  onClickSymbol?: (symbol: string) => void
}

const AffiliatesTable: FC<Props> = ({ className, loading, data }) => {
  const { t } = useTranslation()
  const columns = [
    {
      id: "id",
      Header: "ID",
      accessor: "id",
    },
    {
      Header: t("first_name.input.placeholder").toUpperCase(),
      accessor: "first_name",
    },
    {
      Header: t("last_name.input.placeholder").toUpperCase(),
      accessor: "last_name",
    },
    {
      Header: t("current_accumulated.input.placeholder").toUpperCase(),
      accessor: "current_accumulated",
    },
  ] as Column<Affiliate>[]

  return (
    <div className={`table-base ${className}`}>
      {loading ? (
        <table className={"mt-12"}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index} className="px-6 pb-4 table-text-header">
                  {column.Header as string}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            <TableRowShimmer nRow={columns.length} />
            <TableRowShimmer nRow={columns.length} />
            <TableRowShimmer nRow={columns.length} />
            <TableRowShimmer nRow={columns.length} />
            <TableRowShimmer nRow={columns.length} />
            <TableRowShimmer nRow={columns.length} />
          </tbody>
        </table>
      ) : (
        <AffiliatesTableBody
          className={"pt-12"}
          columns={columns}
          data={data ?? []}
        />
      )}
    </div>
  )
}

export { AffiliatesTable }
