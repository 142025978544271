import LoaderIcon from "components/icons/LoaderIcon"
import React, { FC } from "react"
import { PrimaryButtonSize } from "shared/components/PrimaryButton/PrimaryButtonTypes"

type Props = {
  children: React.ReactNode
  className?: string
  onClick?: () => void
  fill?: boolean
  loading?: boolean
  size?: PrimaryButtonSize
  type?: "submit" | "reset" | "button"
  color?: string
}

function getButtonSizeClass(size: PrimaryButtonSize): string {
  switch (size) {
    case PrimaryButtonSize.S:
      return "py-2 px-4 sm:px-4 text-sm "
    case PrimaryButtonSize.M:
      return "py-2.5 px-4 sm:px-10 text-base "
    case PrimaryButtonSize.L:
      return "py-2.5 px-4 sm:px-10 text-lg "
  }
}

const PrimaryButton: FC<Props> = ({
  children,
  onClick,
  className,
  fill = true,
  loading = false,
  size = PrimaryButtonSize.M,
  type = "button",
  color,
}) => {
  const classFill =
    "text-white bg-gradient-to-r from-primary-gradien1-dark to-primary-gradien2-dark "
  const classColorNotFill = color ? color : "text-primary-text-dark"
  const classNotFill = "border-2 " + classColorNotFill + " "
  let classNameType = fill ? classFill : classNotFill

  if (loading) classNameType += " cursor-not-allowed"

  return (
    <button
      disabled={loading}
      type={type}
      className={`focus:outline-none font-semibold transform hover:-translate-y-0.5 hover:shadow-lg rounded-md ${getButtonSizeClass(
        size
      )} ${classNameType} ${className}`}
      onClick={onClick}
    >
      {loading && (
        <div className="flex place-content-center ">
          <LoaderIcon className="w-5 h-5 animate-spin" />
        </div>
      )}
      {!loading && children}
    </button>
  )
}

export { PrimaryButtonSize }
export { PrimaryButton }
