import CustomError from "../../shared/exceptions/CustomError"

const actionByError = (errorCode: string, history) => {
  if (CustomError.TOKEN_INVALID === errorCode) {
    history(`/login`, {
      replace: true,
    })
  }
}

export default actionByError
