import { PrimaryButton } from "components/PrimaryButton"
import React, { FC, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Toast } from "../../Toasts"
import { ValidationFieldForm } from "../../common/forms/ValidationFieldForm"
import { useFormik } from "formik"
import * as Yup from "yup"
import BinanceLogoIcon from "../../icons/CryptoApps/BinanceLogoIcon"
import ExchangeAccountsApiProvider from "../../../shared/providers/exchangeAccounts/ExchangeAccountsProvider"
import { ExchangeAccount } from "../../../models/exchangeAccount/ExchangeAccount"
import { notifyError, notifySuccess } from "../../Toasts/ToastUtils"
import ApiKeyOrSecretInvalidError from "../../../shared/exceptions/ApiKeyOrSecretInvalidError"
import {
  ExchangeAccountId,
  ExchangeAccountType,
} from "../../../shared/models/exchangeAccount/ExchangeAccount"
import { BackButtonIcon } from "../../../assets/BackButtonIcon"
import { useUserOwn } from "../../../shared/store/useOwnUser"
import CustomError from "../../../shared/exceptions/CustomError"
import { BASE_URL } from "../../../shared/constants/constants"

const WelcomeApiKeyPage: FC = () => {
  const { t } = useTranslation()
  const { token } = useUserOwn()
  const history = useNavigate()
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: "",
      apiKey: "",
      apiSecret: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("exchange_account_name.input.validation")),
      apiKey: Yup.string().required(t("api_key.input.validation")),
      apiSecret: Yup.string().required(t("api_secret.input.validation")),
    }),
    onSubmit: (values) => {
      setLoading(true)
      new ExchangeAccountsApiProvider(BASE_URL)
        .addExchangeAccount({
          name: values.name,
          accountType: ExchangeAccountType.FUTURES,
          accountProviderId: ExchangeAccountId.BINANCE,
          apiKey: values.apiKey,
          apiSecret: values.apiSecret,
          token: token,
        })
        .then((dataRequest: ExchangeAccount) => {
          setLoading(false)
          notifySuccess(t("api_message_success.exchange_account_created"))
          history(`/`)
        })
        .catch((error: CustomError) => {
          setLoading(false)
          if (error instanceof ApiKeyOrSecretInvalidError) {
            notifyError(
              t("api_message_errors.invalid_api_key_and_secret_credentials")
            )
            return
          }
          notifyError(t("api_message_errors.unexpected_error"))
        })
    },
  })

  return (
    <>
      <div className="flex dark:bg-bg-dark">
        <div className="mx-auto sm:mt-12 md:mt-16 lg:mt-32 sm:w-[64rem] w-full pl-4 pr-4 ">
          <div className="flex justify-center">
            <h1 className="text-5xl mb-12 font-bold dark:text-main-text-dark text-main-text-light">
              {t("welcome_api_key_page.title")}
            </h1>
          </div>
          <div className="w-full self-center align-middle justify-center px-6 pb-8 pt-4 rounded shadow-2xl bg-container-panel-bg-light dark:bg-container-panel-bg-dark">
            <button className="" onClick={() => history(-1)}>
              <BackButtonIcon className="h-10 dark:text-main-text-dark text-main-text-light p-2 fill-white outline-none  shadow-none" />
            </button>

            <div className="mt-4 text-center dark:text-main-text-dark text-main-text-light font-medium text-xl">
              <p className="mb-6">{t("welcome_api_key_page.header")}</p>
              <p className="mb-16">{t("welcome_api_key_page.body")}</p>
            </div>

            <div className="flex">
              <BinanceLogoIcon
                className="mx-auto h-16 w-16 mb-2"
                isSelected={false}
              />
            </div>

            <div className="flex">
              <form className=" mx-auto" onSubmit={formik.handleSubmit}>
                <div className="mt-4">
                  <input
                    className={`w-72 sm:w-96 px-2 rounded-md dark:border-input-border-dark dark:bg-input-bg-dark dark:text-white dark:placeholder-input-text-placeholder-dark focus:ring-1 focus:ring-secondary-gradien1-dark`}
                    id="name"
                    type="text"
                    placeholder={t("exchange_account_name.input.placeholder")}
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.name}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  <input
                    className={`w-full px-2 rounded-md  dark:border-input-border-dark dark:bg-input-bg-dark dark:text-white dark:placeholder-input-text-placeholder-dark focus:ring-1 focus:ring-secondary-gradien1-dark`}
                    id="apiKey"
                    type="text"
                    placeholder={t("api_key.input.placeholder")}
                    {...formik.getFieldProps("apiKey")}
                  />
                  {formik.touched.apiKey && formik.errors.apiKey ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.apiKey}
                    />
                  ) : null}
                </div>
                <div className="mt-4">
                  <input
                    className={`w-full px-2 rounded-md  dark:border-input-border-dark dark:bg-input-bg-dark dark:text-white dark:placeholder-input-text-placeholder-dark focus:ring-1 focus:ring-secondary-gradien1-dark`}
                    id="apiSecret"
                    type="password"
                    placeholder={t("api_secret.input.placeholder")}
                    {...formik.getFieldProps("apiSecret")}
                  />
                  {formik.touched.apiSecret && formik.errors.apiSecret ? (
                    <ValidationFieldForm
                      className="mt-2"
                      text={formik.errors.apiSecret}
                    />
                  ) : null}
                </div>
                <div className="flex mb-8">
                  <PrimaryButton
                    type="submit"
                    loading={loading}
                    className="h-12 mt-16 !py-0 mx-auto"
                  >
                    <p>{t("welcome_api_key_page.button")}</p>
                  </PrimaryButton>
                </div>
              </form>
            </div>

            <div className="flex">
              <button
                className="mx-auto dark:text-main-text-dark text-main-text-light font-semibold"
                onClick={() => history("/")}
              >
                <p>{t("welcome_api_key_page.subbutton")}</p>
              </button>
            </div>
          </div>
        </div>
        <Toast />
      </div>
    </>
  )
}

export { WelcomeApiKeyPage }
