import TokenInvalidError from "../exceptions/TokenInvalidError"
import BadRequestError from "../exceptions/BadRequestError"

export default class ApiProvider {
  checkErrors(response: Response) {
    if (response.status === 401) {
      console.log("TokenInvalidError()")
      throw new TokenInvalidError()
    }
    if (response.status === 400) {
      console.log("BadRequest()")
      throw new BadRequestError()
    }
  }
}
