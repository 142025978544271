import { FC } from "react"

type Props = {
  className?: string
  model: ModelProps
  onClick: (item: ModelProps) => void
}

type ModelProps = {
  id: number
  title: string
  description: string
  comments: number
  likes: number
}

const FeatureRequestItem: FC<Props> = ({ className, model, onClick }) => {
  return (
    <div
      onClick={() => {
        onClick(model)
      }}
      className={`cursor-pointer border-b-[1px] border-light-divider-bg-light-dark flex py-6 px-6 ${className}`}
    >
      <div className="flex-1 mr-16">
        <p className="font-medium text-main-text-light">{model.title}</p>
        <p className="text-sm font-medium text-sub-text-light">
          {model.description}
        </p>
      </div>
      {/* <div className="flex self-center mr-4">
        <ChatMenuIcon color="#8C8B93" />
        <p className="self-center text-sm font-medium text-sub-text-light">
          {model.comments}
        </p>
      </div>
      <div className="flex self-center">
        <ChatMenuIcon color="#8C8B93" />
        <p className="self-center text-sm font-medium text-sub-text-light">
          {model.likes}
        </p>
      </div> */}
    </div>
  )
}

export { FeatureRequestItem }
export type { ModelProps }
