import React, { FC } from "react"
import BaseIcon from "./BaseIcon"

type Props = {
  height?: number
  width?: number
  className?: string
  onClick?: () => void
}

const InfoIcon: FC<Props> = ({
  height = 1546,
  width = 8175,
  className,
  onClick,
}) => {
  return (
    <BaseIcon
      onClick={onClick}
      className={className}
      viewBox="0 0 48 48"
      height={height}
      width={width}
      content={[
        <path
          key="1"
          d="M24 4C12.972 4 4 12.972 4 24s8.972 20 20 20 20-8.972 20-20S35.028 4 24 4zm0 3c9.407 0 17 7.593 17 17s-7.593 17-17 17S7 33.407 7 24 14.593 7 24 7zm0 7a2 2 0 0 0 0 4 2 2 0 0 0 0-4zm-.023 6.979A1.5 1.5 0 0 0 22.5 22.5v11a1.5 1.5 0 1 0 3 0v-11a1.5 1.5 0 0 0-1.523-1.521z"
        />,
      ]}
    />
  )
}

export default InfoIcon
