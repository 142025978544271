import React, { FC } from "react"
import { EyeOffThinIcon } from "../../assets/EyeOffThinIcon"
import { EyeThinIcon } from "../../assets/EyeThinIcon"

type Props = {
  className?: string
  showedIcon: boolean
}

const ShowHideToggleIcon: FC<Props> = ({ className, showedIcon }) => {
  return showedIcon ? (
    <EyeOffThinIcon
      className={`dark:text-main-text-dark text-main-text-light ${className}`}
    />
  ) : (
    <EyeThinIcon
      className={`dark:text-main-text-dark text-main-text-light ${className}`}
    />
  )
}

export { ShowHideToggleIcon }
