import React, { FC, useState } from "react"
import SpainFlag from "../../assets/flags/Flag_of_Spain.svg"
import USAFlag from "../../assets/flags/Flag_of_the_United_States.svg"
import { useTranslation } from "react-i18next"

type Props = {
  className?: string
}

const LanguagePicker: FC<Props> = ({ className }) => {
  const lang = window.localStorage.getItem("bttm-lang") || "es"
  const [isOpen, setOpen] = useState(false)
  const { i18n } = useTranslation()
  const [selectedLang, setSelectedLang] = useState(lang)

  const selectedName = selectedLang === "es" ? "ESP" : "ENG"
  const selectedSrc = selectedLang === "es" ? SpainFlag : USAFlag

  function setLanguage(lang: string) {
    window.localStorage.setItem("bttm-lang", lang)
    i18n.changeLanguage(lang)
    setSelectedLang(lang)
  }

  return (
    <div className="relative self-center">
      <button
        className={`flex items-center text-center self-center m-auto ${className}`}
        onClick={() => setOpen(!isOpen)}
      >
        <img
          src={selectedSrc}
          className="h-4 my-2 text-white self-center m-auto"
          alt={"Flag country"}
        />
        <p className="text-sm self-center dark:text-main-text-dark text-main-text-light ml-2">
          {selectedName}
        </p>
      </button>
      {isOpen && (
        <ul
          tabIndex={1}
          className="absolute z-10 mt-1 bg-white dark:bg-dropdown-bg-dark rounded-md shadow-lg max-h-56 py-1 overflow-auto focus:outline-none"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-option-3"
        >
          <li
            className="cursor-default select-none relative py-2 pr-8"
            id="listbox-option-0"
            role="option"
            aria-selected
            onClick={() => {
              setLanguage("es")
              setOpen(false)
            }}
          >
            <div className={`flex items-center ${className}`}>
              <img
                src={SpainFlag}
                className="w-6 my-2 text-white flex-shrink-0 mr-4"
                alt={"Spain flag"}
              />
              <p className="text-sm self-center dark:text-main-text-dark text-main-text-light">
                ESP
              </p>
            </div>
          </li>
          <li
            className="cursor-default select-none relative py-2 pr-8"
            id="listbox-option-0"
            role="option"
            aria-selected
            onClick={() => {
              setLanguage("en")
              setOpen(false)
            }}
          >
            <div className={`flex items-center ${className}`}>
              <img
                src={USAFlag}
                className="w-6 my-2 text-white flex-shrink-0 mr-4"
                alt={"USA flag"}
              />
              <p className="text-sm self-center dark:text-main-text-dark text-main-text-light">
                ENG
              </p>
            </div>
          </li>
        </ul>
      )}
    </div>
  )
}

export { LanguagePicker }
