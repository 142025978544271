import { FC } from "react"

type Props = {
  className?: string
  onClick?: () => void
  children: React.ReactNode
  myRef?: React.RefObject<HTMLElement>
}

const BaseSingleBorderedContainer: FC<Props> = ({
  className,
  onClick,
  children,
  myRef,
}) => {
  return (
    <div
      className={`px-2 rounded-md border-2 border-search-bar-border-input-dark bg-search-bar-bg-dark text-search-bar-text-dark placeholder-search-bar-text-placeholder-dark focus:outline-none ${className}`}
      onClick={onClick}
      ref={myRef as React.RefObject<HTMLDivElement>}
    >
      {children}
    </div>
  )
}

export { BaseSingleBorderedContainer }
