import { PrimaryButton } from "components/PrimaryButton"
import React, { FC, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { ref } from "yup"
import { ValidationFieldForm } from "../../common/forms/ValidationFieldForm"
import AuthApIProvider from "shared/providers/auth/AuthApIProvider"
import { useTranslation } from "react-i18next"
import { ShowHideToggleIcon } from "../../ShowHideToggleIcon"
import { notifyError, notifySuccess } from "../../Toasts/ToastUtils"
import { BASE_URL } from "../../../shared/constants/constants"

const RecoverPasswordPage: FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)
  const [passwordShown, setPasswordShown] = React.useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = React.useState(false)
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown)
  }
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(!confirmPasswordShown)
  }

  const history = useNavigate()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const token = query.get("token")
  const uid = query.get("uid")

  useEffect(() => {
    if (!token || !uid) {
      notifyError(t("invalid_url.text"))
      history("/login")
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t("password.input.validation")),
      confirm_password: Yup.string()
        .required(t("confirm_password.input.validation_required"))
        .oneOf([ref("password")], t("confirm_password.input.validation_match")),
    }),
    onSubmit: (values) => {
      setLoading(true)

      new AuthApIProvider(BASE_URL)
        .recoverPassword(values.password, token!, uid!)
        .then(() => {
          notifySuccess(t("password_successfully_updated.text"))
          setLoading(false)
          history("/login")
        })
        .catch((e: Error) => {
          setLoading(false)
          console.log(e)
          notifyError(t("api_message_errors.unexpected_error"))
        })
    },
  })

  return (
    <>
      <div className="flex h-screen dark:bg-bg-dark bg-bg-light">
        <div className="m-auto sm:w-96 w-full pl-4 pr-4 ">
          <div className="flex justify-center">
            <h1 className="text-3xl text-center mb-12 font-bold dark:text-main-text-dark text-main-text-light uppercase">
              {t("insert_the_new_password.text")}
            </h1>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="w-full self-center px-6 py-8 rounded shadow-2xl bg-container-panel-bg-light dark:bg-container-panel-bg-dark">
              <div className="mt-4">
                <div className="relative flex w-full ">
                  <input
                    className={`!pl-2 !pr-12 input-sign-up-login`}
                    id="password"
                    type={passwordShown ? "text" : "password"}
                    autoComplete={"off"}
                    placeholder={t("password.input.placeholder")}
                    {...formik.getFieldProps("password")}
                  />

                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute right-4 top-[20%]"
                  >
                    <ShowHideToggleIcon
                      showedIcon={passwordShown}
                      className="h-6"
                    />
                  </button>
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <ValidationFieldForm
                    className="mt-2"
                    text={formik.errors.password}
                  />
                ) : null}
              </div>

              <div className="mt-4">
                <div className="relative flex w-full ">
                  <input
                    className={`!pl-2 !pr-12 input-sign-up-login`}
                    id="confirm_password"
                    type={confirmPasswordShown ? "text" : "password"}
                    autoComplete={"off"}
                    placeholder={t("confirm_password.input.placeholder")}
                    {...formik.getFieldProps("confirm_password")}
                  />

                  <button
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                    className="absolute right-4 top-[20%]"
                  >
                    <ShowHideToggleIcon
                      showedIcon={confirmPasswordShown}
                      className="h-6"
                    />
                  </button>
                </div>
                {formik.touched.confirm_password &&
                formik.errors.confirm_password ? (
                  <ValidationFieldForm
                    className="mt-2"
                    text={formik.errors.confirm_password}
                  />
                ) : null}
              </div>

              <PrimaryButton
                type="submit"
                loading={loading}
                className="h-10 mt-8 w-full !py-0"
              >
                <p>{t("recover_password.text")}</p>
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export { RecoverPasswordPage }
