import { createContext, FC, ReactNode, useContext } from "react"
import useWebSocket from "react-use-websocket"
import { useUserOwn } from "../shared/store/useOwnUser"
import {
  BalanceBotInfo,
  BotOpenOrder,
  BotPosition,
} from "../shared/models/bot/BotInfo"

type Context = ReturnType<typeof useLiveSubscriptionDataContext>
const LiveSubscriptionDataContext = createContext<Context | undefined>(
  undefined
)

export const LiveSubscriptionDataProvider: FC<{
  children: ReactNode
  subscriptionId: string
}> = ({ children, subscriptionId }) => {
  return (
    <LiveSubscriptionDataContext.Provider
      value={useLiveSubscriptionDataContext(subscriptionId)}
    >
      {children}
    </LiveSubscriptionDataContext.Provider>
  )
}

export const useLiveSubscription = () => {
  const context = useContext(LiveSubscriptionDataContext)
  if (!context) throw Error("LiveSubscriptionDataContext not available")
  return context
}

function useLiveSubscriptionDataContext(subscriptionId: string): {
  positions: BotPosition[] | undefined
  orders: BotOpenOrder[] | undefined
  account: BalanceBotInfo | undefined
} {
  function getPositions(parsedData): BotPosition[] | undefined {
    return parsedData?.positions?.__root__?.map((position) => {
      return {
        symbol: position.symbol,
        entry_price: position.entry_price,
        position_size: position.position_size,
        side: position.side,
        unrealizedProfit: position.unrealizedProfit,
        initial_margin: position.initial_margin,
        liquidation_price: position.liquidation_price,
        dcas: position.dcas,
        live_price: position.current_symbol_price,
        lasttp: position.lasttp,
        nextdca: position.nextdca,
        risk: position.risk,
        nexttp: position.nexttp,
        managed_symbol: position.managed_symbol,
        walletExposure: position.walletExposure,
        symbol_performance: position.symbol_performance,
      } as BotPosition
    })
  }

  function getOpenOrders(parsedData): BotOpenOrder[] | undefined {
    return parsedData?.orders?.__root__?.map((order) => {
      return {
        id: order.order_id,
        symbol: order.symbol,
        price: order.price,
        quantity: order.quantity,
        side: order.side,
        position_side: order.position_side,
        type: order.type,
        modified: order.modified,
      } as BotOpenOrder
    })
  }

  function getBalanceBotInfo(parsedData): BalanceBotInfo | undefined {
    const balanceInfo = parsedData?.account
    if (!balanceInfo) return undefined
    return {
      registration_datetime: balanceInfo.registration_datetime,
      totalWalletBalance: balanceInfo.totalWalletBalance,
      totalUnrealizedProfit: balanceInfo.totalUnrealizedProfit,
      totalInitialMargin: balanceInfo.totalUnrealizedProfit,
      totalMaintMargin: balanceInfo.totalMaintMargin,
      totalMarginBalance: balanceInfo.totalMarginBalance,
      totalPositionInitialMargin: balanceInfo.totalPositionInitialMargin,
      totalOpenOrderInitialMargin: balanceInfo.totalOpenOrderInitialMargin,
      availableBalance: balanceInfo.availableBalance,
      maxWithdrawAmount: balanceInfo.totalOpenOrderInitialMargin,
      exchange: balanceInfo.exchange,
    } as BalanceBotInfo
  }

  const { token } = useUserOwn()

  let socketUrl = `ws://fast.botthemoon.com/items/${subscriptionId}/ws?token=${token}`
  const { lastMessage } = useWebSocket(socketUrl)
  let parsedData = undefined
  try {
    const jsonMessage =
      "{" +
      lastMessage?.data
        .replace(/'/g, '"')
        .replace(/True/g, "true")
        .replace(/False/g, "false")
        .replace(/None/g, "null") +
      "}"
    parsedData = lastMessage?.data ? JSON.parse(jsonMessage) : undefined
  } catch (e) {
    console.log(e)
  }
  return {
    positions: getPositions(parsedData),
    orders: getOpenOrders(parsedData),
    account: getBalanceBotInfo(parsedData),
  }
}
