import { useFormik } from "formik"
import * as Yup from "yup"
import AuthApIProvider from "../shared/providers/auth/AuthApIProvider"
import { JwtInfo } from "../models/user/JwtInfo"
import { notify, notifyVerifyAccount } from "../components/Toasts/ToastUtils"
import InvalidCredentialsError from "../shared/exceptions/InvalidCredentialsError"
import AccountNotActivatedError from "../shared/exceptions/AccountNotActivatedError"
import { useUserOwn } from "../shared/store/useOwnUser"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import React from "react"
import { BASE_URL } from "../shared/constants/constants"

export const useLogin = () => {
  const { t } = useTranslation()
  const { setToken } = useUserOwn()
  const history = useNavigate()
  const [loading, setLoading] = React.useState(false)

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required(t("email.input.validation")),
      password: Yup.string().required(t("password.input.validation")),
    }),
    onSubmit: (values) => {
      setLoading(true)
      new AuthApIProvider(BASE_URL)
        .login(values.username, values.password)
        .then((loginResponse: JwtInfo | null) => {
          setLoading(false)
          if (loginResponse?.access_token) {
            setToken(loginResponse?.access_token)
            history(`/`)
            return
          }
          notify(t("invalid_credentials.text"))
        })
        .catch((e: Error) => {
          setLoading(false)
          if (e instanceof InvalidCredentialsError) {
            notify(t("invalid_credentials.text"))
            return
          }
          if (e instanceof AccountNotActivatedError) {
            notifyVerifyAccount(() => {
              new AuthApIProvider(BASE_URL)
                .resendVerificationEmail(values.username)
                .then(() => {
                  notify(t("email_sent.text"))
                })
                .catch((e: Error) => {
                  notify(t("api_message_errors.unexpected_error"))
                })
              return
            })
          }
          notify(t("api_message_errors.unexpected_error"))
        })
    },
  })

  return {
    formik,
    loading,
  }
}
