import React, { FC } from "react"
import { BotOpenOrder } from "../../../shared/models/bot/BotInfo"
import { useTranslation } from "react-i18next"
import EyeIcon from "../../icons/EyeIcon"
import {
  DOT_UNDERLINE_CLASS,
  getColorSidePosition,
  getSideText,
} from "../../../Utils"
import { Tooltip } from "../../Tooptip"
import Tippy from "@tippyjs/react/headless"
import "tippy.js/dist/tippy.css"

type Props = {
  openOrder: BotOpenOrder
  className?: string
  onClickSymbol?: (symbol?: string) => void
}

const OpenOrderListBot: FC<Props> = ({
  openOrder,
  className,
  onClickSymbol,
}) => {
  const { t } = useTranslation()

  return (
    <div className={`py-8 ${className}`}>
      <div className="flex mb-8">
        <div
          className={"flex"}
          onClick={() => {
            onClickSymbol?.(openOrder.symbol)
          }}
        >
          <EyeIcon onClick={() => {}} className="list-eye-icon" />
          <p className="list-text-title">{openOrder.symbol}</p>
        </div>
        <p
          className={`${getColorSidePosition(
            openOrder.side
          )} font-medium ml-auto`}
        >
          {getSideText(openOrder.position_side, openOrder.side)}
        </p>
      </div>

      <div className="flex">
        <div className="">
          <div className="flex mb-1">
            <Tippy
              interactive={true}
              hideOnClick={true}
              render={(attrs) => (
                <Tooltip {...attrs} content={t("entry_price.tooltip") ?? ""} />
              )}
              placement={"bottom"}
            >
              <p className={`list-text-secondary mr-2 ${DOT_UNDERLINE_CLASS}`}>
                {t("order_price.text")}
              </p>
            </Tippy>
            <p className="list-text-base mr-2">
              {"$" + parseFloat(openOrder.price.toString())}
            </p>
          </div>
          <div className="flex mb-1">
            <Tippy
              interactive={true}
              hideOnClick={true}
              render={(attrs) => (
                <Tooltip {...attrs} content={t("quantity.tooltip") ?? ""} />
              )}
              placement={"bottom"}
            >
              <p className={`list-text-secondary mr-2 ${DOT_UNDERLINE_CLASS}`}>
                {t("quantity.text")}
              </p>
            </Tippy>
            <p className="list-text-base mr-2">
              {openOrder.quantity?.toFixed(4)}
            </p>
          </div>
        </div>
        <div className="flex ml-auto mt-auto">
          <p className="list-text-secondary">
            {new Date(openOrder.modified ?? "").toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  )
}

export { OpenOrderListBot }
