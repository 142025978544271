import { FC, useState } from "react"

type Props = {
  isCheckedLeft: boolean
  svgPathLeft: string
  svgPathRight: string
  classSvgLeft?: string
  classSvgRight?: string
  classSelected: string
  classNotSelected: string
  onChange: (isCheckedLeft: boolean) => void
  className?: string
}

const SwitchCustom: FC<Props> = ({
  isCheckedLeft,
  classSvgLeft = "",
  classSvgRight = "",
  svgPathLeft,
  svgPathRight,
  classSelected,
  classNotSelected,
  onChange,
  className,
}) => {
  const [isCheckedLeftState, setIsCheckedLeftState] = useState(isCheckedLeft)
  const toggle = () => {
    onChange(!isCheckedLeftState)
    setIsCheckedLeftState(!isCheckedLeftState)
  }

  const classLeft = isCheckedLeftState ? classSelected : classNotSelected
  const classRight = !isCheckedLeftState ? classSelected : classNotSelected

  return (
    <div className={`flex ${className}`}>
      <div
        className={`w-7 self-center rounded-l-2xl ${classLeft}`}
        onClick={() => {
          if (isCheckedLeftState) return
          toggle()
        }}
      >
        <img
          src={svgPathLeft}
          className={`h-3 my-1.5 text-white self-center m-auto ${classSvgLeft}`}
          alt={"switch"}
        />
      </div>
      <div
        className={`w-7 self-center rounded-r-2xl ${classRight}`}
        onClick={() => {
          if (!isCheckedLeftState) return
          toggle()
        }}
      >
        <img
          src={svgPathRight}
          className={`h-4 my-2 text-white self-center m-auto ${classSvgRight}`}
          alt={"switch"}
        />
      </div>
    </div>
  )
}

export { SwitchCustom }
