import { useThemeAtom } from "../UseTheme"
import { getInitialTheme } from "../Utils"

export default function useColorTicksGraph(colorTicksLighterInDark: boolean) {
  const [theme] = useThemeAtom()
  let themeAux = getInitialTheme()
  themeAux = theme === "" ? themeAux : theme
  return themeAux === "dark"
    ? colorTicksLighterInDark
      ? "#76757E"
      : "#fff"
    : "#EDEDEE"
}
