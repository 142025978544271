import { useEffect, useState } from "react"
import LoadingData from "../models/LoadingData"
import { BotInfo } from "../shared/models/bot/BotInfo"
import { useUserOwn } from "../shared/store/useOwnUser"
import BotProvider from "../shared/providers/bots/BotProvider"

export const useBotInfo = (
  subscriptionId: string | undefined,
  provider: BotProvider
) => {
  const { token } = useUserOwn()

  const [botInfoDataResponse, setBotInfoDataResponse] = useState<
    LoadingData<BotInfo> | undefined
  >(undefined)

  useEffect(() => {
    setBotInfoDataResponse({
      loading: true,
      data: undefined,
    } as LoadingData<BotInfo>)
    provider
      .getBotInfo(token, String(subscriptionId ?? -1))
      .then((botInfoResponse: BotInfo) => {
        botInfoResponse?.positions?.forEach(function (position, index) {
          botInfoResponse.positions[index].symbol = position?.symbol?.replace(
            "1000SHIBUSDT",
            "SHIBUSDT"
          )
        })
        botInfoResponse?.openOrders?.forEach(function (openOrder, index) {
          botInfoResponse.openOrders[index].symbol = openOrder?.symbol?.replace(
            "1000SHIBUSDT",
            "SHIBUSDT"
          )
        })
        /*function getRealized(): number {
          let total = 0
          for (const transaction of botInfoResponse.transactions ?? []) {
            if (
              transaction.type === "REALIZED_PNL" &&
              (transaction?.income ?? -1) > 0
            ) {
              total += transaction.income!
            }
          }
          return total
        }

        function getNegativeRealized(): number {
          let total = 0
          for (const transaction of botInfoResponse.transactions ?? []) {
            if (
              transaction.type === "REALIZED_PNL" &&
              (transaction.income ?? 1) < 0
            ) {
              total += transaction.income!
            }
          }
          return total
        }

        function getNetoRealized(): number {
          let total = 0
          for (const transaction of botInfoResponse.transactions ?? []) {
            if (transaction.type === "REALIZED_PNL") {
              //console.log(total, transaction.income)
              total += transaction?.income ?? 0
            }
          }
          return total
        }

        function getCommisions(): number {
          let total = 0
          for (const transaction of botInfoResponse.transactions ?? []) {
            if (transaction.type === "COMMISSION") {
              total += transaction?.income ?? 0
            }
          }
          return total
        }

        function getSymbols(): Set<string> {
          const symbols = new Set<string>()
          for (const transaction of botInfoResponse.transactions ?? []) {
            if (transaction.type === "COMMISSION") {
              symbols.add(transaction.symbol ?? "")
            }
          }
          return symbols
        }
        function percentage(partialValue, totalValue) {
          return (100 * partialValue) / totalValue
        }
        function printCommissions(symbols: Set<string>) {
          let toPrint: number[] = []
          let commissions = 0
          let realized = 0
          for (const symbol of symbols) {
            toPrint = []
            commissions = 0
            for (const transaction of botInfoResponse.transactions ?? []) {
              if (
                transaction.type === "COMMISSION" &&
                transaction.symbol === symbol
              ) {
                if (transaction?.income) {
                  toPrint.push(transaction?.income)
                  commissions += transaction.income
                }
              } else {
                if (
                  transaction.type === "REALIZED_PNL" &&
                  transaction.symbol === symbol
                ) {
                  if (transaction?.income) {
                    realized += transaction.income
                  }
                }
              }
            }
            console.log(symbol, "COMMISSION", commissions)
            console.log(symbol, "REALIZED_PNL", realized)
            console.log(
              symbol,
              "COMMISSION PERCENTAGE",
              percentage(Math.abs(commissions), Math.abs(realized)).toFixed(2)
            )
          }
          return symbols
        }

        console.log("REALIZED", getRealized())
        console.log("NEGATIVE REALIZED", getNegativeRealized())
        console.log("NETO REALIZED", getNetoRealized())
        console.log("COMMISSIONS", getCommisions())
        const symbols = getSymbols()
        printCommissions(symbols)*/
        setBotInfoDataResponse({
          loading: false,
          data: botInfoResponse,
        } as LoadingData<BotInfo>)
      })
      .catch((error) => {
        console.log(error)
        setBotInfoDataResponse({
          loading: false,
          data: undefined,
        } as LoadingData<BotInfo>)
      })
  }, [token])

  return {
    botInfoData: botInfoDataResponse,
    setBotInfoDataResponse,
  }
}
