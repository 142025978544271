import React, { FC } from "react"

import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom"
import HomePage from "components/pages/HomePage/HomePage"
import BotPage from "components/pages/Bot/BotPage"
import { DashboardPage } from "components/pages/DashboardPage"
import LoginPage from "components/pages/LoginPage/LoginPage"
import PrivateRoute from "./PrivateRoute"
import "react-toastify/dist/ReactToastify.css"
import { SignUpPage } from "../pages/SignUpPage"
import { VerifyEmailPage } from "../pages/VerifyEmailPage"
import { ActivatePage } from "../pages/ActivatePage"
import { WelcomePage } from "../pages/WelcomePage"
import { WelcomeVideoPage } from "../pages/WelcomeVideoPage"
import { WelcomeApiKeyPage } from "../pages/WelcomeApiKeyPage"
import ForgotPasswordPage from "../pages/ForgotPasswordPage/ForgotPasswordPage"
import { ForgotPasswordEmailSentPage } from "../pages/ForgotPasswordEmailSentPage"
import { RecoverPasswordPage } from "../pages/RecoverPasswordPage"
import { PrivacyPolicyPage } from "../pages/PrivacyPolicyPage"
import { TermsAndConditionsPage } from "../pages/TermsAndConditionsPage"
import { LiteSignUpPage } from "../pages/LeadFormPage"
import { LiteSignUpSuccessPage } from "../pages/LiteSignUpSuccessPage"
import { UserOwnProvider } from "../../shared/store/useOwnUser"
import { LivePricesProvider } from "../../hooks/useLivePricesContext"
import ReactGA from "react-ga4"
import { UserOwn } from "../../shared/models/user/UserOwn"
import { useCustomNavigation } from "../../hooks/useCustomNavigation"
import { LiveSubscriptionDataProvider } from "../../hooks/useLiveSubscriptionDataContext"
import TestSocketPage from "../../TestSocketPage"
import { TestSocketProvider } from "../../hooks/useTestSocketContext"
import AffiliatePage from "../pages/AffiliatesPage/AffiliatePage"

/*
 *
 * !!!!!! CEHCK THIS ART https://stackoverflow.com/questions/69864165/error-privateroute-is-not-a-route-component-all-component-children-of-rou
 *
 *
 * */
const Router: FC = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
}

const App: FC = () => {
  const { setErrorCode } = useCustomNavigation()
  return (
    <UserOwnProvider
      onUserSuccess={(ownUser: UserOwn) => {
        ReactGA.initialize("G-B0G1R9R199", {
          gaOptions: { userId: ownUser.id },
        })
      }}
      onUserError={(errorCode: string) => {
        setErrorCode(errorCode)
      }}
    >
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/sign-up-lite" element={<LiteSignUpPage />} />
        <Route
          path="/sign-up-lite-success"
          element={<LiteSignUpSuccessPage />}
        />
        <Route path="/verify-email" element={<VerifyEmailPage />} />
        <Route path="/activate" element={<ActivatePage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/recover-password" element={<RecoverPasswordPage />} />
        <Route
          path="/forgot-password-email-sent"
          element={<ForgotPasswordEmailSentPage />}
        />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditionsPage />}
        />

        <Route path="/" element={<PrivateRoute component={<HomePage />} />} />
        <Route
          path="/welcome"
          element={<PrivateRoute component={<WelcomePage />} />}
        />
        <Route
          path="/welcome-video"
          element={<PrivateRoute component={<WelcomeVideoPage />} />}
        />
        <Route
          path="/welcome-api-key"
          element={<PrivateRoute component={<WelcomeApiKeyPage />} />}
        />
        <Route
          path="/affiliates"
          element={<PrivateRoute component={<AffiliatePage />} />}
        />
        <Route
          path="/bot/:subscriptionId"
          element={
            <PrivateRoute
              component={
                <LivePricesProvider>
                  <BotPage />
                </LivePricesProvider>
              }
            />
          }
        />
        <Route
          path="/dashboard"
          element={<PrivateRoute component={<DashboardPage />} />}
        />
        {/*<PrivateRoute
            exact
            path="/trading-terminal"
            component={TradingTerminalPage}
          />*/}
        {/* <Route path="/login">
          <LoginPage setToken={setToken} />
        </Route>
        <MainLayout>
          <Switch>
            <AuthRoute isLogged={token} path="/" component={HomePage} />
            <AuthRoute
              isLogged={token}
              path="/bot/:botId"
              component={BotPage}
            />
            <AuthRoute
              isLogged={token}
              path="/c"
              component={DashboardPage}
            />
            <AuthRoute
              isLogged={token}
              path="/trading-terminal"
              component={TradingTerminalPage}
            />
          </Switch>
        </MainLayout> */}
      </Routes>
    </UserOwnProvider>
  )
}
export default Router
