import React, { FC } from "react"
import { EyeThinIcon } from "../../assets/EyeThinIcon"
import { EyeOffThinIcon } from "../../assets/EyeOffThinIcon"
import { usePrivateModeAtom } from "../../shared/store/store"

type Props = {
  className?: string
}

const PrivateViewSwitch: FC<Props> = ({ className }) => {
  const [privateMode, setPrivateMode] = usePrivateModeAtom()

  const classEyeOff = privateMode
    ? " bg-primary-gradien1-dark "
    : " bg-component-bg-light-dark "
  const classEyeOn = !privateMode
    ? " bg-primary-gradien1-dark "
    : " bg-component-bg-light-dark "

  return (
    <div className={`flex ${className}`}>
      <div
        className={`w-8 self-center rounded-l-2xl ${classEyeOff}`}
        onClick={() => {
          if (privateMode) return
          setPrivateMode(true)
        }}
      >
        <EyeOffThinIcon
          className={`h-5 my-1.5 text-white self-center m-auto`}
        />
      </div>
      <div
        className={`w-8 self-center rounded-r-2xl ${classEyeOn}`}
        onClick={() => {
          if (!privateMode) return
          setPrivateMode(false)
        }}
      >
        <EyeThinIcon className={`h-5 my-1.5 text-white self-center m-auto`} />
      </div>
    </div>
  )
}

export { PrivateViewSwitch }
