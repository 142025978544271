import React, { FC } from "react"
import { Column } from "react-table"
import { TableOpenOrders } from "./TableOpenOrders"
import { TableRowShimmer } from "../../../../shimmers/TableRowShimmer"

import { BotOpenOrder } from "../../../../../shared/models/bot/BotInfo"
import { useTranslation } from "react-i18next"

type Props = {
  className?: string
  loading?: boolean
  positions?: BotOpenOrder[]
  onClickSymbol?: (symbol: string) => void
}

const TableOpenOrdersBot: FC<Props> = ({
  className,
  loading,
  positions,
  onClickSymbol,
}) => {
  const { t } = useTranslation()
  const columns = [
    {
      id: "symbol",
      Header: t("symbol.text").toUpperCase(),
      accessor: "symbol",
    },
    {
      Header: t("order_price.text").toUpperCase(),
      tooltip: t("order_price.tooltip"),
      accessor: "price",
    },
    {
      Header: t("quantity.text").toUpperCase(),
      tooltip: t("quantity.tooltip"),
      accessor: "quantity",
    },
    {
      Header: t("buy_sell.text").toUpperCase(),
      accessor: "side",
    },
    /*{
      Header: "POSITION SIDE",
      accessor: "position_side",
    },*/
    /*{
        Header: "TYPE",
        accessor: "type",
      },*/
  ] as Column<BotOpenOrder>[]

  return (
    <div className={`table-base ${className}`}>
      {loading ? (
        <table className={"mt-7"}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index} className="px-6 pb-4 table-text-header">
                  {column.Header as string}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            <TableRowShimmer nRow={columns.length} />
            <TableRowShimmer nRow={columns.length} />
            <TableRowShimmer nRow={columns.length} />
            <TableRowShimmer nRow={columns.length} />
            <TableRowShimmer nRow={columns.length} />
            <TableRowShimmer nRow={columns.length} />
          </tbody>
        </table>
      ) : (
        <TableOpenOrders
          className={"pt-12"}
          columns={columns}
          dataPositions={positions ?? []}
          onClickSymbol={onClickSymbol}
        />
      )}
    </div>
  )
}

export { TableOpenOrdersBot }
