import React, { useEffect, useState } from "react"
import { BotSubscription } from "../shared/models/bot/BotSubscription"
import BotApiProvider from "../shared/providers/bots/BotApiProvider"
import PaginatedData from "../models/PaginatedData"
import actionByError from "../components/common/actionByError"
import { useNavigate } from "react-router-dom"
import { useUserOwn } from "../shared/store/useOwnUser"
import { BASE_URL } from "../shared/constants/constants"
import CustomError from "../shared/exceptions/CustomError"
import { BotMockProvider } from "../shared/providers/bots/BotMockProvider"
import { useBotInfo } from "./useBotInfo"
import BotProvider from "../shared/providers/bots/BotProvider"

export const useSubscriptions = (provider: BotProvider) => {
  const { token } = useUserOwn() //TODO REMOVE
  const [botSubscriptions, setBotSubscriptions] = React.useState(
    [] as BotSubscription[]
  )
  const [page, setPage] = React.useState(1)
  const [pendingPages, setPendingPages] = React.useState<number[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const history = useNavigate()

  useEffect(() => {
    if (isLoading || pendingPages.length <= 0) return
    setLoading(true)
    const currentPage = pendingPages.shift() ?? -1
    setPendingPages([...pendingPages])

    provider
      .getSubscriptions(token, currentPage)
      .then((data: PaginatedData<BotSubscription>) => {
        if (data?.items?.length === 0 || data?.items?.length === undefined) {
          setHasMore(false)
          return
        }
        setBotSubscriptions([...botSubscriptions, ...(data?.items ?? [])])
        setLoading(false)
      })
      .catch((error: CustomError) => {
        console.log("error")
        actionByError(error.errorCode, history) // FIXME: cambiar esto por un useCustomError hook en el MainLayout para mostrar un mensaje o redirigir al login como este caso
      })
  }, [pendingPages, isLoading])

  useEffect(() => {
    setPendingPages([...pendingPages, page])
  }, [page])

  const nextPage = () => {
    setPage(page + 1)
  }
  return {
    botSubscriptions,
    nextPage,
    hasMore,
    setHasMore,
  }
}
