import { t } from "i18next"

export function getInitialTheme(): string {
  if (typeof window !== "undefined" && window.localStorage) {
    const storedPrefs = window.localStorage.getItem("bttm-theme")
    if (typeof storedPrefs === "string") {
      return storedPrefs
    }

    const userMedia = window.matchMedia("(prefers-color-scheme: dark)")
    if (userMedia.matches) {
      return "dark"
    }
  }

  // If you want to use light theme as the default, return "light" instead
  return "dark"
}

export function compareNumericReactTableString(rowA, rowB, id, desc) {
  let a = Number.parseFloat(rowA.values[id])
  let b = Number.parseFloat(rowB.values[id])
  if (Number.isNaN(a)) {
    // Blanks and non-numeric strings to bottom
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

export function compareNumericAbsoluteReactTableString(rowA, rowB, id, desc) {
  let a = Math.abs(Number.parseFloat(rowA.values[id]))
  let b = Math.abs(Number.parseFloat(rowB.values[id]))
  if (Number.isNaN(a)) {
    // Blanks and non-numeric strings to bottom
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY
  }
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

export function getColorTextPositiveNegative(value: number): string {
  if (value > 0) {
    return " dark:text-secondary-text-dark text-secondary-text-light "
  }
  return " dark:text-red-danger-dark text-red-danger-light "
}

export function getDcaClass(dca?: number): string {
  if (!dca || dca === 0) return " dark:bg-red-danger-dark bg-red-danger-light "

  if (dca <= 2) {
    return " dark:bg-red-danger-dark bg-red-danger-light "
  }
  if (dca <= 3) {
    return " dark:bg-orange-danger-dark bg-orange-danger-dark "
  }
  return " dark:bg-component-bg-light-dark bg-component-bg-light-dark "
}

export function getColorSidePosition(side: string): string {
  return side === "SELL"
    ? " dark:text-red-danger-dark  text-red-danger-light "
    : " dark:text-secondary-text-dark text-secondary-text-light "
}

export function getSideText(positionSide: string, side: string): string {
  switch (positionSide) {
    case "SHORT":
      return side === "SELL"
        ? t("side_short_sell.text")
        : t("side_short_buy.text")
    case "LONG":
      return side === "SELL"
        ? t("side_long_sell.text")
        : t("side_long_buy.text")
    case "LONG|SHORT":
      return side
  }
  return ""
}

export const DOT_UNDERLINE_CLASS = "border-dotted border-b-[1px]"

export const ScreenSize = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
}

export function isWidderThan(
  currentWidth: number,
  withToCompare: number
): boolean {
  return currentWidth > withToCompare
}

export function isThinnerEqualThan(
  currentWidth: number,
  withToCompare: number
): boolean {
  return currentWidth <= withToCompare
}

export function getRefFromUrl(url: string): String | undefined {
  if (url.includes("#")) {
    return url.slice(url.indexOf("#") + 1)
  }
  return undefined
}

export function getRefFromLocation(location: Location): String | undefined {
  const ref = location.hash.replace("#", "")
  return ref.length > 0 ? ref : undefined
}
