import React, { FC, useEffect } from "react"
import ReactGA from "react-ga4"
import { useAffiliates } from "../../../hooks/useAffiliates"
import { AffiliatesTable } from "./components/AffiliatesTable/AffiliatesTable"
import { SingleDataInfo } from "../../SingleDataInfo"
import { useTranslation } from "react-i18next"
import { PrimaryButton } from "../../PrimaryButton"
//import CopyIcon from "../../../assets/copy.svg"
import { notifyError, notifySuccess } from "../../Toasts/ToastUtils"
import { useUserOwn } from "../../../shared/store/useOwnUser"
import DiscordIcon from "../../icons/DiscordIcon"
import { CopyIcon } from "../../../assets/CopyIcon"
import { Toast } from "../../Toasts"
import { BASE_APP_URL } from "../../../shared/constants/constants"

const AffiliatePage: FC = () => {
  const { ownUser } = useUserOwn()
  const { t } = useTranslation()
  useEffect(() => {
    document.getElementById("baseScroll")?.scrollTo(0, 0)
  }, [])

  const { affiliates } = useAffiliates()
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.href })
  }, [window.location.href])

  const referralUrl = `${BASE_APP_URL}/sign-up-lite?referral=${ownUser?.invitation_code}`

  return (
    <>
      <div className="self-center mx-3 mb-4 lg:mx-12 px-6 py-8 rounded bg-container-panel-bg-light dark:bg-container-panel-bg-dark">
        <div className="mt-4 mb-16 text-center dark:text-main-text-dark text-main-text-light font-medium text-2xl">
          <p className="mb-2">{t("affiliates_page.header")}</p>
          <p className="mb-2">{t("affiliates_page.body_1")}</p>
        </div>

        <div className={"text-center"}>
          <div className={"mb-8"}>
            <div className={"flex justify-center"}>
              <p
                className={
                  "mr-2 text-xl font-semibold font-bold text-secondary-1-dark"
                }
              >
                {referralUrl}
              </p>

              <CopyIcon
                onClick={() => {
                  console.log("COPY TEXT OUT")
                  navigator.clipboard.writeText(referralUrl).then(() => {
                    console.log("COPY TEXT IN")
                    notifySuccess(
                      t("text_copied"),
                      "text-main-text-light dark:text-main-text-light"
                    )
                  })
                }}
                className="h-6 ml-6 fill-current dark:text-main-text-dark text-main-text-light cursor-pointer self-center"
              />
              {/*<img
                src={CopyIcon}
                className={`h-6 ml-6 mt-1 fill-current dark:text-sub-text-dark cursor-pointer self-center`}
                onClick={() => {
                  navigator.clipboard.writeText(referralUrl).then(() => {
                    notifySuccess(
                      t("text_copied"),
                      "!dark:text-secondary-text-light !text-secondary-text-light"
                    )
                  })
                }}
                alt={"Copy icon"}
              />*/}
            </div>
          </div>

          <PrimaryButton
            loading={false}
            className="h-10 !py-0"
            onClick={() => console.log("Click")}
          >
            <p>{t("affiliates_page.button_share")}</p>
          </PrimaryButton>
        </div>
      </div>

      <div className="grid mx-3 mb-4 lg:mx-12 md:grid-flow-col">
        <SingleDataInfo
          className="mb-4 md:mr-4 md:mb-0"
          loading={affiliates.loading}
          title={t("affiliates_number.text")}
          value={affiliates.data?.length?.toString() ?? "0"}
        />
        <SingleDataInfo
          className="mb-4 md:mb-0"
          loading={affiliates.loading}
          title={t("total_earnings.text")}
          value={
            "$" +
            Object.values(affiliates?.data ?? []).reduce(
              (previousValue, { current_accumulated }) =>
                previousValue + current_accumulated,
              0
            )
          }
          tooltipContent={t("funding_fee.tooltip")}
        />
      </div>
      <AffiliatesTable
        data={affiliates.data ?? []}
        loading={affiliates?.loading}
        className="mx-3 lg:mx-12"
      />
      <Toast />
    </>
  )
}

export default AffiliatePage
