import ApiProvider from "../ApiProvider"
import DataTrackProvider from "./DataTrackProvider"
import { Income } from "../../models/dataTrack/Income"
import { DailyBalance } from "../../models/dataTrack/DailyBalance"
import { MonthlyTrackData } from "../../models/dataTrack/MonthlyTrackData"

export default class DataTrackApiProvider
  extends ApiProvider
  implements DataTrackProvider
{
  constructor(private baseUrl: string) {
    super()
  }

  async getIncomes(token: string, subscriptionId: number): Promise<Income[]> {
    return fetch(
      `${this.baseUrl}api/v1/botsubsdatatrack/incomes?subscription=${subscriptionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: "Bearer " + token,
        },
      }
    ).then((response) => {
      super.checkErrors(response)
      return response.json() as Promise<Income[]>
    })
  }

  async getDailyBalance(
    token: string,
    subscriptionId: number
  ): Promise<DailyBalance[]> {
    return fetch(
      `${this.baseUrl}api/v1/dailybalance/?subscription=${subscriptionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: "Bearer " + token,
        },
      }
    ).then((response) => {
      super.checkErrors(response)
      return response.json() as Promise<DailyBalance[]>
    })
  }

  async getMonthlyTrack(
    token: string,
    subscriptionId: number
  ): Promise<DailyBalance[]> {
    return fetch(
      `${this.baseUrl}api/v1/dailybalance/monthtrack?subscription=${subscriptionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf8",
          Authorization: "Bearer " + token,
        },
      }
    ).then((response) => {
      super.checkErrors(response)
      return response.json() as Promise<MonthlyTrackData[]>
    })
  }
}
