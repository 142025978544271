import { BaseSingleBorderedContainer } from "components/BaseSingleBorderedContainer"
import { HeaderModal } from "components/modals/HeaderModal"
import { PrimaryButton } from "components/PrimaryButton"
import { UnderLineTabs } from "components/tabs/UnderLineTabs"
import { useFormik } from "formik"
import * as Yup from "yup"

import React, { FC } from "react"
import { BaseModal } from "../BaseModal"

import {
  ConnectExchangeModalTabs,
  TabsPositions,
} from "./ConnectExchageModalTabs/ConnectExchangeModalTabs"
import { ExchangeLogoRadioGroup } from "./ExchangeLogoRadioGroup"
import { ValidationFieldForm } from "components/common/forms/ValidationFieldForm"
import { ExchangeAccount } from "models/exchangeAccount/ExchangeAccount"
import LoadingData from "models/LoadingData"
import ExchangeAccountsApiProvider from "../../../shared/providers/exchangeAccounts/ExchangeAccountsProvider"
import { useUserOwn } from "../../../shared/store/useOwnUser"
import { BASE_URL } from "../../../shared/constants/constants"
import CustomError from "../../../shared/exceptions/CustomError"

type Props = {
  onExchangeAddedSuccess?: (exchangeAccount: ExchangeAccount) => void
  onExchangeEditedSuccess?: (exchangeAccount: ExchangeAccount) => void
  onCloseClick?: () => void
  onBackClick?: () => void
  onRightClick: () => void
  onLeftClick: () => void
  displayBlackBg?: boolean
  type?: "create" | "update"
  data?: { name: string; apiKey: string; apiSecret: string }
}

function getLeftButtonText(type: "create" | "update"): string {
  switch (type) {
    case "create":
      return "Cancel"
    case "update":
      return "Cancel"
  }
}

function getRightButtonText(type: "create" | "update"): string {
  switch (type) {
    case "create":
      return "Next"
    case "update":
      return "Update"
  }
}

const ConnectExchangeAccountModal: FC<Props> = ({
  onExchangeAddedSuccess,
  onExchangeEditedSuccess,
  onCloseClick,
  onBackClick,
  onRightClick,
  onLeftClick,
  displayBlackBg = true,
  data,
  type = "create",
}) => {
  const [, setOpenTab] = React.useState(0)
  const onTabChanged = (position: number) => {
    setOpenTab(position)
  }
  const { token } = useUserOwn()
  const [exchangeDataRequest, setExchangeDataRequest] = React.useState({
    loading: false,
    data: null,
  } as LoadingData<ExchangeAccount | null>)

  function requestCreate(values: {
    name: string
    accountType: string
    accountProviderId: number
    apiKey: string
    apiSecret: string
  }) {
    setExchangeDataRequest({ loading: true, data: null })
    new ExchangeAccountsApiProvider(BASE_URL)
      .addExchangeAccount({
        ...values,
        token: token,
      })
      .then((dataRequest: ExchangeAccount) => {
        setExchangeDataRequest({ loading: false, data: dataRequest })
        onExchangeAddedSuccess?.(dataRequest)
      })
      .catch((error: CustomError) => {
        //actionByError(error.errorCode, history) // FIXME: cambiar esto por un useCustomError hook en el MainLayout para mostrar un mensaje o redirigir al login como este caso
      })
  }

  function requestUpdate(values: {
    name: string
    apiKey: string
    apiSecret: string
  }) {
    setExchangeDataRequest({ loading: true, data: null })
    new ExchangeAccountsApiProvider(BASE_URL)
      .updateExchangeAccount({ ...values })
      .then((dataRequest: ExchangeAccount) => {
        setExchangeDataRequest({ loading: false, data: dataRequest })
        onExchangeEditedSuccess?.(dataRequest)
      })
      .catch((error: CustomError) => {
        //actionByError(error.errorCode, history) // FIXME: cambiar esto por un useCustomError hook en el MainLayout para mostrar un mensaje o redirigir al login como este caso
      })
  }

  const formik = useFormik({
    initialValues: {
      name: data?.name,
      apiKey: data?.apiKey,
      apiSecret: data?.apiSecret,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("* Required"),
      apiKey: Yup.string().required("* Required"),
      apiSecret: Yup.string().required("* Required"),
    }),
    onSubmit: (values) => {
      switch (type) {
        case "create":
          requestCreate({
            name: values.name!,
            apiKey: values.apiKey!,
            apiSecret: values.apiSecret!,
            accountProviderId: 2,
            accountType: "futures",
          })
          return
        case "update":
          requestUpdate({
            name: values.apiKey!,
            apiKey: values.apiKey!,
            apiSecret: values.apiSecret!,
          })
          return
      }
    },
  })

  return (
    <BaseModal displayBlackBg={displayBlackBg}>
      <HeaderModal
        onCloseClick={onCloseClick}
        onBackClick={onBackClick}
        title={"Connect Exchange Account"}
        subtitle={"Lorem ipsum dolor sit ammet"}
        hasCloseIcon={onCloseClick !== undefined}
        hasBackArrow={onBackClick !== undefined}
      />
      <div className="overflow-scroll max-h-[70vh] sm:max-h-[80vh]">
        <h3 className="mt-8 ml-6 font-semibold text-main-text-light">
          CHOOSE EXCHANGE (currently just Binance)
        </h3>

        <form onSubmit={formik.handleSubmit}>
          <ExchangeLogoRadioGroup
            onChangeSelected={(selectedPosition: number) => {}}
            className="flex mt-4 ml-6"
          />
          <BaseSingleBorderedContainer className="flex mt-4 ml-6 mr-6 bg-white border-gray-highligted-light !border-[1px]">
            <input
              className={`py-3 ring-0 focus:ring-0 px-1 flex-1 border-0  text-main-text-light placeholder-placeholder-text-dark focus:outline-none`}
              id="name"
              type="text"
              placeholder="e. g. Futures Binance API key"
              {...formik.getFieldProps("name")}
            />
          </BaseSingleBorderedContainer>
          {formik.touched.name && formik.errors.name ? (
            <ValidationFieldForm
              className="mt-2 ml-6"
              text={formik.errors.name}
            />
          ) : null}
          <h3 className="mt-8 ml-6 font-semibold text-main-text-light">TYPE</h3>
          <UnderLineTabs
            className="mt-4 ml-6"
            selectedStyles=""
            unselectedStyles=""
            onTabChanged={onTabChanged}
            //tabs={["Spot", "Futures"]}
            tabs={["Futures"]}
          />
          <hr className="ml-6 mr-6 border-1 border-my-bot-light-divider-dark" />
          <ConnectExchangeModalTabs
            className="mt-8 ml-6 mr-6"
            selectedTab={TabsPositions.Futures}
            formikFutures={formik}
            //dataFutures={data}
          />
          {/* <div className={` ml-6 mr-6`}>
          <BaseSingleBorderedContainer className="flex mt-4 dark:bg-white dark:border-gray-highligted-light !border-[1px]">
            <input
              className={`py-3 ring-0 focus:ring-0 px-1 flex-1 border-0  text-main-text-light placeholder-placeholder-text-light focus:outline-none`}
              id="account_api_key"
              type="text"
              placeholder="Account API Key"
            />
          </BaseSingleBorderedContainer>
          <BaseSingleBorderedContainer className="flex mt-4 dark:bg-white dark:border-gray-highligted-light !border-[1px]">
            <input
              className={`py-3 ring-0 focus:ring-0 px-1 flex-1 border-0  text-main-text-light placeholder-placeholder-text-light focus:outline-none`}
              id="account_api_secret"
              type="text"
              placeholder="Account API Secret"
            />
          </BaseSingleBorderedContainer>
          <p className="mt-4 text-sm font-medium text-placeholder-text-light">
            Where to find this keys?
          </p>
          <p className="mt-1 text-xs font-medium text-placeholder-text-light">
            - Open https://accounts.binance.com
            <br />- Log in to your account - Hover over profile and click “API
            Management” <br />- Give the API key a label at the top of the
            screen <br />- Click create <br />- Follow authentication
            instructions <br />- When done, copy the API keys of the newly
            created API back into the corresponding input fields above <br />-
            Done
          </p>
        </div> */}
          <div className="flex flex-wrap justify-center pb-6 mt-11">
            <PrimaryButton className="mr-2" onClick={onLeftClick} fill={false}>
              {getLeftButtonText(type)}
            </PrimaryButton>
            <PrimaryButton
              type="submit"
              loading={exchangeDataRequest.loading}
              className="ml-2"
              onClick={onRightClick}
              fill={true}
            >
              {getRightButtonText(type)}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </BaseModal>
  )
}

export { ConnectExchangeAccountModal }
