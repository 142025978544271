import React, { FC } from "react"
import { BaseModal } from "../BaseModal"
import { ModelProps as FeatureRequestItemType } from "../FeatureRequestsModal/FeatureRequestItem"
import { HeaderModal } from "../HeaderModal"
import { ModelProps as ItemCommentModelType } from "./ItemCommentFeature"

type Props = {
  item: FeatureRequestItemType //TODO: create onw type
  onBackClick: () => void
}

const comments = [
  {
    id: 1,
    avatar: "https:..",
    date: "20 days",
    name: "Jonnie D.",
    comment:
      "would it be better to have more information of the trader, to know how he manages the risk?",
  },
  {
    id: 1,
    avatar: "https:..",
    date: "8 days",
    name: "Jonnie D.",
    comment: "This is an amazing idea!",
  },
  {
    id: 3,
    avatar: "https:..",
    date: "10 days",
    name: "Jonnie D.",
    comment:
      "I would really like this too. Especially when buying dips with limit orders. The recent BTC drop 2 days ago filled all of my limit buys in highly leveraged positions. I didn't have too many luckily but it would be fantastic to implement this so traders can limit exposure when certain scenarios happen.",
  },
  {
    id: 1,
    avatar: "https:..",
    date: "20 days",
    name: "Jonnie D.",
    comment:
      "would it be better to have more information of the trader, to know how he manages the risk?",
  },
  {
    id: 1,
    avatar: "https:..",
    date: "8 days",
    name: "Jonnie D.",
    comment: "This is an amazing idea!",
  },
  {
    id: 3,
    avatar: "https:..",
    date: "10 days",
    name: "Jonnie D.",
    comment:
      "I would really like this too. Especially when buying dips with limit orders. The recent BTC drop 2 days ago filled all of my limit buys in highly leveraged positions. I didn't have too many luckily but it would be fantastic to implement this so traders can limit exposure when certain scenarios happen.",
  },
  {
    id: 1,
    avatar: "https:..",
    date: "20 days",
    name: "Jonnie D.",
    comment:
      "would it be better to have more information of the trader, to know how he manages the risk?",
  },
  {
    id: 1,
    avatar: "https:..",
    date: "8 days",
    name: "Jonnie D.",
    comment: "This is an amazing idea!",
  },
  {
    id: 3,
    avatar: "https:..",
    date: "10 days",
    name: "Jonnie D.",
    comment:
      "I would really like this too. Especially when buying dips with limit orders. The recent BTC drop 2 days ago filled all of my limit buys in highly leveraged positions. I didn't have too many luckily but it would be fantastic to implement this so traders can limit exposure when certain scenarios happen.",
  },
  {
    id: 1,
    avatar: "https:..",
    date: "20 days",
    name: "Jonnie D.",
    comment:
      "would it be better to have more information of the trader, to know how he manages the risk?",
  },
  {
    id: 1,
    avatar: "https:..",
    date: "8 days",
    name: "Jonnie D.",
    comment: "This is an amazing idea!",
  },
  {
    id: 3,
    avatar: "https:..",
    date: "10 days",
    name: "Jonnie D.",
    comment:
      "I would really like this too. Especially when buying dips with limit orders. The recent BTC drop 2 days ago filled all of my limit buys in highly leveraged positions. I didn't have too many luckily but it would be fantastic to implement this so traders can limit exposure when certain scenarios happen.",
  },
] as Array<ItemCommentModelType>

const FeatureRequestModal: FC<Props> = ({ item, onBackClick }) => {
  return (
    <BaseModal displayBlackBg={true} maxWidth="md:!max-w-[70%]">
      <HeaderModal
        onBackClick={onBackClick}
        title={"Feature detail"}
        subtitle={
          "Tell us about new features we should consider or how we can improve existing features."
        }
        hasCloseIcon={false}
        hasBackArrow={true}
      />

      <div
        className="overflow-scroll max-h-[70vh] sm:max-h-[80vh] mx-6 pb-6
      "
      >
        <h3 className="text-2xl font-bold mt-7 text-main-text-light">
          {item.title}
        </h3>
        <p className="mt-10 text-main-text-light">{item.description}</p>
        <div className="flex items-center justify-between mt-6">
          <div className="flex items-center">
            <img
              className="object-cover w-10 h-10 mr-2 rounded-full"
              src="https://jonathandelasen.com/style/images/art/devjdelasen.jpg"
              alt="avatar"
            />

            <div className="">
              <span className="block text-sm font-medium text-main-text-light">
                Corderlia Burton
              </span>
              <span className="block text-sm text-sub-text-light">
                2 months ago
              </span>
            </div>
          </div>
          {/* <PrimaryButton fill={false} onClick={() => {}}>
            <div className="flex">
              <HelpIcon
                onClick={() => {}}
                className="self-center mr-2 fill-current text-primary-1-dark"
              />
              <p>{item.likes + " votes"}</p>
            </div>
          </PrimaryButton> */}
        </div>
        {/* <hr className="mt-4 h-[1px] border-light-divider-bg-light-dark" />
        <p className="mt-4 font-bold text-main-text-light">
          {`Comments (${item.comments})`}
        </p>
        <BaseSingleBorderedContainer className="flex mt-6 dark:bg-white dark:border-gray-highligted-light !border-[1px]">
          <input
            className={`py-3 ring-0 focus:ring-0 px-1 flex-1 border-0  text-main-text-light placeholder-placeholder-text-light focus:outline-none`}
            id="writte_a_comment_key"
            type="text"
            placeholder="Write a comment"
          />
        </BaseSingleBorderedContainer>

        {comments.map((comment) => (
          <ItemCommentFeature model={comment} />
        ))} */}
      </div>
    </BaseModal>
  )
}

export { FeatureRequestModal }
