import BackArrow from "components/icons/BackArrowIcon"
import CloseIcon from "components/icons/CloseIcon"
import { PrimaryButton } from "components/PrimaryButton"
import React, { FC } from "react"

type Props = {
  title?: string
  avatar?: string
  subtitle?: string
  subtitle2?: string
  subtitle3?: string
  buttonMessage?: string
  hasBackArrow?: boolean
  hasCloseIcon?: boolean
  onButtonClick?: () => void
  onCloseClick?: () => void
  onBackClick?: () => void
}

const HeaderModal: FC<Props> = ({
  onCloseClick,
  onBackClick,
  onButtonClick,
  avatar = "",
  title = "",
  subtitle = "",
  subtitle2 = "",
  subtitle3 = "",
  buttonMessage = "",
  hasCloseIcon = false,
  hasBackArrow = false,
}) => {
  return (
    <div className="flex | rounded-t bg-gradient-to-r from-primary-gradien1-dark to-primary-gradien2-dark">
      {hasBackArrow && (
        <button className="pl-4 focus:outline-none" onClick={onBackClick}>
          <BackArrow className="text-white fill-current" />
        </button>
      )}
      {avatar.length !== 0 && (
        <img
          className="object-cover w-12 h-12 mt-4 mb-4 ml-4 rounded-full "
          src={avatar}
          alt="avatar"
        />
      )}

      <div className="self-center flex-grow p-4">
        {title.length !== 0 && (
          <h3 className="text-lg md:text-2xl font-bold text-white">{title}</h3>
        )}
        {subtitle.length !== 0 && (
          <h4 className="text-base font-medium text-white">{subtitle}</h4>
        )}
        {subtitle2.length !== 0 && (
          <h4 className="text-base font-medium text-white">{subtitle2}</h4>
        )}
        {subtitle3.length !== 0 && (
          <h4 className="text-base font-medium text-white">{subtitle3}</h4>
        )}
      </div>

      {hasCloseIcon && (
        <button className="px-4 py-1 focus:outline-none" onClick={onCloseClick}>
          <CloseIcon className={"fill-current text-white"} />
        </button>
      )}
      {onButtonClick && buttonMessage?.length > 0 && (
        <PrimaryButton
          className="mx-4 mt-5 mb-5 !text-white !border-white"
          fill={false}
          onClick={onButtonClick}
        >
          {buttonMessage}
        </PrimaryButton>
      )}
    </div>
  )
}

export { HeaderModal }
