import React, { FC } from "react"
import { ToastContainer } from "react-toastify"

type Props = {
  className?: string
}

const Toast: FC<Props> = ({ className }) => {
  return (
    <ToastContainer
      toastClassName={() =>
        " mb-4 bg-white relative flex p-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
      }
      bodyClassName={() => "flex text-sm font-white p-3 text-main-text-light"}
    />
  )
}

export { Toast }
